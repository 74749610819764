import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { PopupService } from '../../../infrastructure/services/popup.service';
import { LeaseAbstractStore } from '../../services/lease-abstract.store';

import { DocumentViewerOptions } from '../../models/document-viewer-options.model';

import * as models from '../../../../shared/models/generated';

import { Tools } from '../../../../shared/tools';

import { ImportDocumentViewerOptionsEditDialogComponent } from '../import-document-viewer-options-edit-dialog/import-document-viewer-options-edit-dialog.component';

@Component({
  selector: 'app-import-document-viewer-options',
  templateUrl: 'import-document-viewer-options.component.html',
  styleUrls: ['import-document-viewer-options.component.scss'],
})
export class ImportDocumentViewerOptionsComponent implements OnInit, OnDestroy {
  private static readonly _importTaskStatuses: Array<string> = Tools
    .EnumToArray(models.ImportTaskStatus)
    .map(x => {
      const tokens = x.text.replace(/([A-Z])/g, ' $1');
      return tokens.charAt(0).toUpperCase() + tokens.slice(1);
    });

  @Input() importTaskId: number;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  @Input() lease: models.ILeaseViewModel;

  @Input() reloadLeaseAbstractImportDraft: () => void;

  LeaseTypes: typeof models.LeaseType = models.LeaseType;
  LeaseMetrics: typeof models.BaseRentalRateUnitMetrics = models.BaseRentalRateUnitMetrics;

  TaskStatus: typeof models.ImportTaskStatus = models.ImportTaskStatus;

  importTaskStatus: Observable<models.ImportTaskStatus>;

  tools = Tools;

  private readonly _leaseAbstractStore: LeaseAbstractStore;
  private readonly _popupService: PopupService;
  private readonly _destroy: Subject<void>;

  constructor(leaseAbstractStore: LeaseAbstractStore, popupService: PopupService) {
    this._leaseAbstractStore = leaseAbstractStore;
    this._popupService = popupService;

    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.documentViewerOptions = this.documentViewerOptions || <DocumentViewerOptions>{};
    this.importTaskStatus = this._leaseAbstractStore.getImportTaskStatus();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  handleDocumentViewerOptionsChange(): void {
    this.documentViewerOptionsChange.next({...this.documentViewerOptions});
  }

  getImportTaskStatusName(taskStatus: models.ImportTaskStatus): string {
    return ImportDocumentViewerOptionsComponent._importTaskStatuses[taskStatus];
  }

  openOptionsEditDialog(): void {
    if (!this.lease) {
      return;
    }

    const popupRef = this._popupService.show(ImportDocumentViewerOptionsEditDialogComponent, {
      title: 'Edit lease abstract options',
      width: 750,
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      fullScreen: false,
      dragEnabled: false,
      injectableData: {
        lease: this.lease,
      },
    });

    popupRef
      .onHidden
      .pipe(
        tap(() => this.reloadLeaseAbstractImportDraft()),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }
}
