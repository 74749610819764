<h2 class="content-block">Profile</h2>

<div class="content-block dx-card responsive-paddings">
  <div class="form-avatar">
    <a [routerLink]="['/avatar-editor']">
      <img class="profile-avatar" [src]="getAvatarUrl()" />
    </a>
  </div>
  <div class="display-name">{{ profile?.firstName }} {{ profile?.lastName }}</div>
</div>

<div class="content-block dx-card responsive-paddings">
  <form (submit)="onSubmit()">
    <dx-form
      id="profileForm"
      [formData]="profile"
      labelLocation="top"
      colCount="3"
      [showColonAfterLabel]="true"
      [showValidationSummary]="true"
      validationGroup="profileData"
    >
      <dxi-item itemType="group">
        <dxi-item dataField="firstName" [editorOptions]="{ placeholder: 'First name' }">
          <dxi-validation-rule type="required" message="First name is required"> </dxi-validation-rule>
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>
        <dxi-item dataField="lastName" [editorOptions]="{ placeholder: 'Last name' }">
          <dxi-validation-rule type="required" message="Last name is required"> </dxi-validation-rule>
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>
        <dxi-item
          itemType="button"
          alignment="right"
          [buttonOptions]="{
            text: 'Save',
            type: 'success',
            useSubmitBehavior: true
          }"
        ></dxi-item>
      </dxi-item>
    </dx-form>
  </form>
</div>
