import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import {
  DxDataGridModule,
  DxFormModule,
  DxLookupModule,
  DxTextBoxModule,
  DxSelectBoxModule,
  DxValidatorModule,
  DxFileUploaderModule,
  DxButtonModule,
  DxChartModule,
  DxPopupModule,
  DxSwitchModule,
  DxLoadIndicatorModule,
  DxToastModule,
  DxToolbarModule,
  DxRadioGroupModule,
  DxAccordionModule,
  DxValidationGroupModule,
  DxLoadPanelModule,
  DxTagBoxModule,
} from 'devextreme-angular';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { CroppieModule } from 'angular-croppie-module';

import { InfrastructureModule } from './modules/infrastructure/infrastructure.module';
import { LeaseAbstractModule } from './modules/lease-abstract/lease-abstract.module';
import { ListComponent as LeaseTemplateListComponent } from './modules/lease-template/components/list/list.component';
import { PlanModule } from './modules/plan/plan.module';
import { PointOfInterestModule } from './modules/point-of-interest/point-of-interest.module';
import { PortfolioInvitationModule } from './modules/portfolio-invitation/portfolio-invitation.module';
import { PortfolioInvitationUserModule } from './modules/portfolio-invitation-user/portfolio-invitation-user.module';

import { AuthGuardService } from './shared/services';
import { ProfileService } from './shared/services/profile.service';

import { LoginFormComponent } from './shared/components';

import { BuildingListComponent } from './modules/building/components/list/list.component';
import { BuildingUnitListComponent } from './modules/building-unit/components/list/list.component';
import { BuildingUnitListingListComponent } from './modules/building-unit-listing/components/list/list.component';
import { FloorPlanListComponent } from './modules/plan/components/floor-plan-list/floor-plan-list.component';
import { ImportTaskListComponent } from './modules/import/components/import-task-list/import-task-list.component';
import { LeaseAmendmentListComponent } from './modules/lease-amendment/components/lease-amendment-list/lease-amendment-list.component';
import { LeaseFloorPlanListComponent } from './modules/lease-floor-plan/components/list/list.component';
import { LeaseSignatureListComponent } from './modules/lease-signature/components/lease-signature-list/lease-signature-list.component';
import { LeaseUserListComponent } from './modules/lease-user/components/lease-user-list/lease-user-list.component';
import { PointOfInterestListComponent } from './modules/point-of-interest/components/point-of-interest-list/point-of-interest-list.component';
import { PortfolioInvitationListComponent } from './modules/portfolio-invitation/components/list/list.component';
import { PortfolioInvitationUserListComponent } from './modules/portfolio-invitation-user/components/list/list.component';
import { SitePlanListComponent } from './modules/plan/components/site-plan-list/site-plan-list.component';
import { UserInvitationRequestsComponent } from './modules/invitation/components/user-invitation-requests/user-invitation-requests.component';

import { AvatarEditorComponent } from './pages/avatar-editor/avatar-editor.component';
import { BaseRentalRateComponent } from './pages/lease-terms/base-rental-rate/base-rental-rate.component';
import { CleanCopyComponent } from './pages/clean-copy/clean-copy.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import { FreeRentTermComponent } from './pages/lease-terms/free-rent-term/free-rent-term.component';
import { HomeComponent } from './pages/home/home.component';
import { HvacComponent } from './pages/lease-terms/hvac/hvac.component';
import { LeaseSignersComponent } from './pages/lease-signers/lease-signers.component';
import { LeasesComponent } from './pages/leases/leases.component';
import { MarketRentalRatesComponent } from './pages/market-rental-rates/market-rental-rates.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { PortfoliosComponent } from './pages/portfolios/portfolios.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { RealEstateTaxesCamExpensesComponent } from './pages/lease-terms/real-estate-taxes-cam-expenses/real-estate-taxes-cam-expenses.component';
import { RenewalOptionComponent } from './pages/lease-terms/renewal-option/renewal-option.component';
import { RentalRateAnnualEscalationComponent } from './pages/lease-terms/rental-rate-annual-escalation/rental-rate-annual-escalation.component';
import { SecurityDepositComponent } from './pages/lease-terms/security-deposit/security-deposit.component';
import { StateraClaimGroupsComponent } from './pages/statera-claim-groups/statera-claim-groups.component';
import { StateraUserClaimsComponent } from './pages/statera-user-claims/statera-user-claims.component';
import { TenantImprovementsComponent } from './pages/lease-terms/tenant-improvements/tenant-improvements.component';
import { TerminationOptionComponent } from './pages/lease-terms/termination-option/termination-option.component';
import { ThirdPartyLeasesComponent } from './pages/third-party-leases/third-party-leases.component';
import { ThumbnailsComponent } from './pages/thumbnails/thumbnails.component';
import { UsersComponent } from './pages/users/users.component';
import {BuildingAttachmentListComponent} from './modules/building-attachments/components/list/list.component';
import { CompanyFeatureListComponent } from './modules/company-feature/components/company-feature-list/company-feature-list.component';
import { GlobalFeatureListComponent } from './modules/global-feature/components/global-feature-list/global-feature-list.component';

const routes: Routes = [
  {
    path: 'pages/building-units',
    component: BuildingUnitListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'building-unit-listings',
    component: BuildingUnitListingListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'avatar-editor',
    component: AvatarEditorComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'thumbnails',
    component: ThumbnailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'buildings',
    component: BuildingListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'floor-plans',
    component: FloorPlanListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'site-plans',
    component: SitePlanListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'points-of-interest',
    component: PointOfInterestListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'portfolios',
    component: PortfoliosComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'portfolio-invitations',
    component: PortfolioInvitationListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'portfolio-invitation-users',
    component: PortfolioInvitationUserListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'leases',
    component: LeasesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'lease-templates',
    component: LeaseTemplateListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'lease-floor-plans',
    component: LeaseFloorPlanListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'display-data',
    component: DisplayDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'signin-user',
    component: LoginFormComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'baserentalrates',
    component: BaseRentalRateComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'freerentterm',
    component: FreeRentTermComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'securitydeposit',
    component: SecurityDepositComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'terminationOption',
    component: TerminationOptionComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'renewalOption',
    component: RenewalOptionComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'hvac',
    component: HvacComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'realEstateTaxesCamExpenses',
    component: RealEstateTaxesCamExpensesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'rentalRateAnnualEscalation',
    component: RentalRateAnnualEscalationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'tenantImprovements',
    component: TenantImprovementsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'clean-copy',
    component: CleanCopyComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'market-rental-rates',
    component: MarketRentalRatesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'import-tasks',
    component: ImportTaskListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'lease-signers',
    component: LeaseSignersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'lease-signatures',
    component: LeaseSignatureListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'lease-amendments',
    component: LeaseAmendmentListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'statera-user-claims',
    component: StateraUserClaimsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'statera-claim-groups',
    component: StateraClaimGroupsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'lease-users',
    component: LeaseUserListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'user-invitation-requests',
    component: UserInvitationRequestsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '3rd-party-leases',
    component: ThirdPartyLeasesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'building-attachments',
    component: BuildingAttachmentListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'company-features',
    component: CompanyFeatureListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'global-features',
    component: GlobalFeatureListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    FormsModule,
    BrowserModule,

    CroppieModule,

    DxFormModule,
    DxDataGridModule,
    DxFormModule,
    DxLookupModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxiValidationRuleModule,
    DxValidatorModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxChartModule,
    DxPopupModule,
    DxLoadIndicatorModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxToastModule,
    DxToolbarModule,
    DxRadioGroupModule,
    DxAccordionModule,
    DxValidationGroupModule,
    DxLoadPanelModule,

    InfrastructureModule,
    LeaseAbstractModule,
    PlanModule,
    PointOfInterestModule,
    PortfolioInvitationModule,
    PortfolioInvitationUserModule,
  ],
  declarations: [
    AvatarEditorComponent,
    BaseRentalRateComponent,
    CleanCopyComponent,
    DisplayDataComponent,
    FreeRentTermComponent,
    HomeComponent,
    HvacComponent,
    LeasesComponent,
    MarketRentalRatesComponent,
    OnboardingComponent,
    PortfoliosComponent,
    ProfileComponent,
    ProjectsComponent,
    RealEstateTaxesCamExpensesComponent,
    RenewalOptionComponent,
    RentalRateAnnualEscalationComponent,
    SecurityDepositComponent,
    TenantImprovementsComponent,
    TerminationOptionComponent,
    ThumbnailsComponent,
    UsersComponent,
  ],
  providers: [
    AuthGuardService,
    ProfileService,
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {}
