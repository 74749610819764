import { Component, OnInit, ViewChild } from '@angular/core';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DevExpress from 'devextreme';

import { environment } from 'src/environments/environment';

import { Tools } from 'src/app/shared/tools';

import * as models from 'src/app/shared/models/generated';

@Component({
  selector: 'app-lease-floor-plan-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class LeaseFloorPlanListComponent implements OnInit {
  @ViewChild('floorPlan', { read: DxiItemComponent, static: false }) floorPlanControl: DxiItemComponent;

  selectedShortLeaseViewModel: models.IShortLeaseViewModel;

  leaseFloorPlanDataSource: DevExpress.data.CustomStore;
  leaseDataSource: DevExpress.data.CustomStore;
  floorPlanDataSource: DevExpress.data.CustomStore;

  leaseControlOptions: any;
  floorPlanControlOptions: any;

  tools: typeof Tools;

  constructor() {
    this.tools = Tools;
  }

  ngOnInit() {
    this.leaseFloorPlanDataSource = AspNetData.createStore({
      key: ['id'],
      loadUrl: `${environment.adminUrl}/leasefloorplan`,
      insertUrl: `${environment.adminUrl}/leasefloorplan`,
      updateUrl: `${environment.adminUrl}/leasefloorplan`,
      deleteUrl: `${environment.adminUrl}/leasefloorplan`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
        if (ajaxOptions.data.values) {
          this.prepareSave(ajaxOptions);
        }
      },
    });

    this.leaseDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/leases/shortLeaseModels/?desc=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });

    this.floorPlanDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/plan`,
      onBeforeSend: (operation, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.selectedShortLeaseViewModel) {
          ajaxOptions.data.buildingUnitId = this.selectedShortLeaseViewModel.buildingUnitId;
        }

        ajaxOptions.data.kind = models.PlanKind.Floor;
      },
    });

    this.leaseControlOptions = {
      dataSource: this.leaseDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select Lease',
      value: this.selectedShortLeaseViewModel,
      valueExpr: '',
      fieldTemplate: data => this.getLookupLeaseTemplate(data),
      itemTemplate: data => this.getLookupLeaseTemplate(data),
      onValueChanged: (event: { value: models.IShortLeaseViewModel, previousValue: models.IShortLeaseViewModel }) => {
        this.selectedShortLeaseViewModel = event.value;

        if (this.floorPlanControl && this.floorPlanControl.instance) {
          const floorPlanDataSource = this.floorPlanControl.instance.getDataSource();

          floorPlanDataSource.load();

          this.floorPlanControlOptions.disabled = false;
        }

        return event.value;
      },
    };

    this.floorPlanControlOptions = {
      dataSource: this.floorPlanDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select Floor Plan',
      valueExpr: 'id',
      disabled: true,
      fieldTemplate: data => this.getLookupFloorPlanTemplate(data),
      itemTemplate: data => this.getLookupFloorPlanTemplate(data),
    };
  }

  getWindow(): Window {
    return window;
  }

  getLeaseDisplayNameExpression(shortLeaseViewModel: models.IShortLeaseViewModel): string {
    if (!shortLeaseViewModel) {
      return '';
    }

    const leaseCompaniesParts = [
      shortLeaseViewModel.landlordCompanyName,
      shortLeaseViewModel.tenantCompanyName,
    ];

    const leaseCompaniesExpression = leaseCompaniesParts
      .filter(x => !!x)
      .join(' - ');

    const leaseNameParts = [
      shortLeaseViewModel.leaseId,
      shortLeaseViewModel.buildingName,
      shortLeaseViewModel.buildingUnitName,
      leaseCompaniesExpression,
    ];

    return leaseNameParts
      .filter(x => !!x)
      .join(', ');
  }

  getLookupLeaseTemplate(item: models.IShortLeaseViewModel) {
    if (!item) {
      return '';
    }

    const lookupLeaseDisplayNameExpression = this.getLeaseDisplayNameExpression(item);

    return `
      <div class="lookup-user-field">
        <div class="text-truncate">${lookupLeaseDisplayNameExpression}</div>
      </div>
    `;
  }

  getLookupFloorPlanTemplate(item: models.IPlanViewModel) {
    if (!item) {
      return '';
    }

    const lookupFloorPlanName = item.name;
    const lookupFloorPlanPictureSrc = item && item.picture
      ? item.picture.thumbnailUrl
      : Tools.noImageUrl;

    return `
      <div class="lookup-user-field">
        <img width="20" height="20" src="${lookupFloorPlanPictureSrc}"/>
        <div class="text-truncate">${lookupFloorPlanName}</div>
      </div>
    `;
  }

  prepareSave(ajaxOptions) {
    const values = JSON.parse(ajaxOptions.data.values);
    delete values.anchors;
    delete values.plan;
    delete values.lease;
    values.leaseId = values.leaseId.id;
    ajaxOptions.data.values = JSON.stringify(values);
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'leaseId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.leaseControlOptions.onValueChanged;

      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (JSON.stringify(event.value) === JSON.stringify(event.previousValue)) {
          return;
        }

        e.setValue(event.value);

        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'planId') {
      e.editorName = 'dxLookup';
    }
  }
}
