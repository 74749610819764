<div id="building-import-popup">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form" novalidate
          (ngSubmit)="submitImportFile()" #form="ngForm">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <!-- Start: Landlord lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && landlord.invalid">
            Landlord
          </div>
          <div class="dx-field-value">
            <dx-lookup name="landlord" required
                       noDataText="No landlords found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="landlordsList$ | async"
                       [isValid]="form.submitted ? landlord.valid : true" [searchExpr]="setupSearchableUserFields"
                       [(ngModel)]="buildingsImportTaskPayload.landlordId" #landlord="ngModel"
                       (onValueChanged)="handleLandlordChange($event)">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let landlordProfile of 'field'">
                <ng-container *ngIf="landlordProfile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img class="rounded-circle" width="20" height="20"
                           [src]="landlordProfile.avatar && landlordProfile.avatar.url ? landlordProfile.avatar.url : 'assets/img/avatar.png'"
                           [alt]="landlordProfile.displayName" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{landlordProfile.displayName}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!landlordProfile">
                  <span class="text-muted">
                    Select landlord
                  </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let landlordProfile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img class="rounded-circle" width="40" height="40"
                         [src]="landlordProfile.avatar && landlordProfile.avatar.url  ? landlordProfile.avatar.url : 'assets/img/avatar.png'"
                         [alt]="landlordProfile.displayName" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{landlordProfile.displayName}}
                    </p>
                    <p class="mb-0 small w-100 text-truncate"
                       *ngIf="landlordProfile.company && landlordProfile.company.name">
                      {{landlordProfile.company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && landlord.hasError('required')">
              Landlord is required
            </div>
          </div>
        </div>
        <!-- End: Landlord lookup -->
        <!-- Start: Portfolio lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && portfolio.invalid">
            Portfolio
          </div>
          <div class="dx-field-value">
            <dx-lookup name="portfolio" required
                       noDataText="No portfolios found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="portfoliosList$ | async"
                       [isValid]="form.submitted && !portfolio.disabled ? portfolio.valid : true"
                       [(ngModel)]="buildingsImportTaskPayload.portfolioId" #portfolio="ngModel"
                       [disabled]="!buildingsImportTaskPayload.landlordId">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let portfolioProfile of 'field'">
                <ng-container *ngIf="portfolioProfile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{portfolioProfile.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!portfolioProfile">
                  <span class="text-muted">
                    Select portfolio
                  </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let portfolioProfile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{portfolioProfile.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && portfolio.hasError('required')">
              Portfolio is required
            </div>
          </div>
        </div>
        <!-- End: Portfolio lookup -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <!-- Start: File uploader -->
        <div class="dx-field">
          <div class="dx-field-label">
            File
          </div>
          <!-- Start: File uploader control -->
          <div class="file-control"
               *ngIf="!importFile">
            <dx-file-uploader name="file" uploadMode="useForm"
                              [accept]="csvMimeTypes.join(',')" [multiple]="false" [showFileList]="false"
                              [allowedFileExtensions]="['.csv']"
                              (onValueChanged)="handleImportFileChange($event)">
            </dx-file-uploader>
          </div>
          <!-- End: File uploader control -->
          <!-- Start: File preview -->
          <div class="d-flex flex-row align-items-center flex-nowrap mx-n1 mt-1"
               *ngIf="importFile">
            <div class="px-1">
              <dx-button stylingMode="outlined" type="danger" icon="fa fa-times"
                         (onClick)="removeImportFile()">
              </dx-button>
            </div>
            <div class="px-1">
              <p class="mb-0">
                {{importFile.name}}
              </p>
              <p class="mb-0 invalid-feedback d-block"
                 *ngIf="!isCSVFile(importFile)">
                File type is not allowed
              </p>
            </div>
          </div>
          <!-- End: File preview -->
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && !importFile">
            File is required
          </div>
        </div>
        <!-- End: File uploader -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row mx-n1">
          <div class="px-1">
            <dx-button type="success" text="Import"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-1">
            <dx-button type="normal" text="Cancel"
                       (click)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>
