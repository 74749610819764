import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Tools } from '../../../../shared/tools';

import { PopupService } from '../../../infrastructure/services/popup.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { ImportService } from '../../../import/services/import.service';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';
import { PointOfInterestImportMappingComponent } from '../point-of-interest-import-mapping/point-of-interest-import-mapping.component';

import * as models from '../../../../shared/models/generated';

@Component({
  templateUrl: 'point-of-interest-import.component.html',
  styleUrls: ['point-of-interest-import.component.scss'],
})
export class PointOfInterestImportComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm, {static: false}) form: NgForm;
  @ViewChild(DxFileUploaderComponent, {static: false}) fileUploader: DxFileUploaderComponent;

  importTaskPayload: models.IImportPointsOfInterestTaskPayload;

  importFile: File;

  pointOfInterestStatusDataSource: DataSource;
  pointOfInterestKindDataSource: DataSource;

  isLoading: BehaviorSubject<boolean>;

  private readonly _popupService: PopupService;
  private readonly _popupRefService: PopupRefService;
  private readonly _importService: ImportService;

  private readonly _destroy: Subject<void>;

  constructor(
    popupService: PopupService,
    popupRefService: PopupRefService,
    importService: ImportService,
  ) {
    this._popupService = popupService;
    this._popupRefService = popupRefService;
    this._importService = importService;

    this._destroy = new Subject<void>();

    this.isLoading = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
    this.importTaskPayload = <models.IImportPointsOfInterestTaskPayload>{
      status: models.PointOfInterestStatus.Draft,
    };

    this.pointOfInterestStatusDataSource = new DataSource({
      key: 'id',
      store: Tools.EnumToArray(models.PointOfInterestStatus),
    });

    this.pointOfInterestKindDataSource = new DataSource({
      key: 'id',
      store: Tools.EnumToArray(models.PointOfInterestKind),
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  handleUploaderFileChange(event: { value: Array<File>, previousValue: Array<File> }): void {
    if (event.value && event.value instanceof Array && 0 < event.value.length) {
      this.importFile = event.value.shift();
      return;
    }

    this.importFile = null;
  }

  removeImportFile(): void {
    this.importFile = null;

    if (this.fileUploader && this.fileUploader.instance) {
      this.fileUploader.instance.reset();
    }
  }

  submit(): void {
    if ((this.form && this.form.invalid) || !this.importFile) {
      return;
    }

    this.isLoading.next(true);

    this._importService
      .uploadImportFile(this.importFile)
      .pipe(
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe((importFile: models.IFileViewModel): void => {
        this.isLoading.next(false);

        this._popupService.show(PointOfInterestImportMappingComponent, {
          title: 'Import Points Of Interest – Field Mapping',
          width: 550,
          maxHeight: '90%',
          showCloseButton: false,
          closeOnOutsideClick: false,
          injectableData: {
            importFile: importFile,
            importTaskPayload: this.importTaskPayload,
          },
        });
      });
  }

  cancel(): void {
    if (this.form && this.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel?',
        acceptFn: () => {
          this._popupRefService.hide();
        },
      },
    });
  }
}
