<form name="form" novalidate
      #form="ngForm" (ngSubmit)="submit(form)">
  <dx-scroll-view width="100%" height="100%">
    <ng-container *ngIf="!areAllRequiredTermsValid() || !areAllOptionalTermsValid()">
      <div class="wrapper"
           [class.pb-0]="areAllRequiredTermsValid() && !areAllOptionalTermsValid()">
        <div class="alert"
             [class.alert-danger]="!areAllRequiredTermsValid()"
             [class.alert-warning]="areAllRequiredTermsValid() && !areAllOptionalTermsValid()">
          <p>
            Not all terms are filled in correctly, check the following:
          </p>

          <ul class="mb-0">
            <li *ngIf="!isCommencementTermValid()">
              Commencement term
            </li>
            <li *ngIf="!isLengthTermValid()">
              Term term
            </li>
            <li *ngIf="!isTenantSquareFootageValid()">
              Size term
            </li>
            <li *ngIf="!isBaseRentalRateTermValid()">
              Base Rental Rate term
            </li>
            <li *ngIf="!isFreeRentTermValid()">
              Free Rent term
            </li>
            <li *ngIf="!isRentalRateEscalationTermValid()">
              Rental Rate Escalation term
            </li>
          </ul>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="previewChangeReason">
      <div class="wrapper pb-0">
        <div class="alert alert-info">
          <p>
            The table is not up to date. <br/>
            To update the data, click the "Recalculate" button.
          </p>
          <p class="mb-0">
            Reason: {{previewChangeReason}}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="areAllRequiredTermsValid()">
      <div class="wrapper">
        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
            <tr>
              <th>
                Start
              </th>
              <th>
                End
              </th>
              <th>
                Base Rent
                <sup>
                  <ng-container *ngIf="unitMetrics === UnitMetrics.PsfPerMonth; else defaultUnitMetrics">
                    PSF/Mo
                  </ng-container>
                  <ng-template #defaultUnitMetrics>
                    PSF/Yr
                  </ng-template>
                </sup>
              </th>
              <th>
                Monthly Rent
              </th>
              <th>
                Annual Rent
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let scheduleRow of scheduleRows; let index = index;">
              <dx-tooltip position="left" showEvent="dxhoverstart" hideEvent="dxhoverend" *ngIf="scheduleRow.current"
                          target="#current">
                <div *dxTemplate="let data = data; of: 'content'"> Current period </div>
              </dx-tooltip>
              <tr [class.current]="scheduleRow.current"
                  [id]="scheduleRow.current ? 'current' : undefined">
                <!-- Start: Start -->
                <td class="align-middle">
                  {{scheduleRow.start | date:'MM/dd/yyyy'}}
                </td>
                <!-- End: Start -->
                <!-- Start: End -->
                <td class="align-middle">
                  {{scheduleRow.end | date:'MM/dd/yyyy'}}
                </td>
                <!-- End: End -->
                <!-- Start: Base Rent -->
                <td class="align-middle">
                  {{scheduleRow.baseRent | currency}}
                </td>
                <!-- End: Base Rent -->
                <!-- Start: Monthly Rent -->
                <td class="align-middle p-0">
                  <dx-number-box name="monthlyRent_{{index}}" format="$#,##0.00"
                                 min="0" step="0"
                                 [(ngModel)]="scheduleRow.monthlyRent" #monthlyRent>
                  </dx-number-box>
                </td>
                <!-- End: Monthly Rent -->
                <!-- Start: Annual Rent -->
                <td class="align-middle p-0">
                  <dx-number-box name="annualRent_{{index}}" format="$#,##0.00"
                                 min="0" step="0"
                                 [(ngModel)]="scheduleRow.annualRent" #annualRent>
                  </dx-number-box>
                </td>
                <!-- End: Annual Rent -->
              </tr>
            </ng-container>

            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </dx-scroll-view>

  <ng-container *ngIf="areAllRequiredTermsValid()">
    <div class="footer">
      <div class="row">
        <div class="col-6">
          <dx-button type="danger" text="Recalculate"
                     (click)="recalculateScheduleWithWarningDialog()">
          </dx-button>
        </div>
        <div class="col-6 text-right">
          <dx-button type="success" text="Save"
                     [useSubmitBehavior]="true">
          </dx-button>
          <dx-button type="normal" text="Cancel"
                     (click)="cancel()">
          </dx-button>
        </div>
      </div>
    </div>
  </ng-container>
</form>
