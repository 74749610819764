import { Component, HostBinding, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { environment } from 'src/environments/environment';
import { DxToastComponent } from 'devextreme-angular';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { ToastService } from './shared/services/toast.service';
import { ProgressBarService } from './shared/services/progress-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter(cl => this.screen.sizes[cl])
      .join(' ');
  }

  @ViewChild('signOutForm', { static: false }) signOutForm: any;
  @ViewChild('appToast', { static: false }) appToast: DxToastComponent;
  @ViewChild('progressBar', { static: false }) progressBar: NgProgressComponent;

  get showContent() {
    return !this.authService.signingOut && !this.authService.appStarting;
  };
  signOutUrl = `${environment.adminUrl}/authentication/Signout`;

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private toastService: ToastService,
    private progressBarService: ProgressBarService
  ) {
    this.authService.getStartupInfo();
  }

  isAutorized() {
    return this.authService.isLoggedIn;
  }

  ngOnInit(): void {
    this.toastService.toast = this.appToast;
    this.progressBarService.progressBar = this.progressBar;
  }

  ngAfterViewInit(): void {
    this.authService.logoutForm = this.signOutForm.nativeElement;
  }
}
