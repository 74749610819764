import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { EditGlobalFeatureDialogComponent } from './components/edit-global-feature-dialog/edit-global-feature-dialog.component';
import { GlobalFeatureListComponent } from './components/global-feature-list/global-feature-list.component';

import { GlobalFeatureService } from './services/global-feature.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxDataGridModule,
    dx.DxScrollViewModule,
    dx.DxButtonModule,
    dx.DxLookupModule,
    dx.DxCheckBoxModule,
  ],
  declarations: [
    EditGlobalFeatureDialogComponent,
    GlobalFeatureListComponent,
  ],
  providers: [
    GlobalFeatureService,
  ],
  entryComponents: [
    EditGlobalFeatureDialogComponent,
  ],
})
export class GlobalFeatureModule {
  static forRoot(): ModuleWithProviders<GlobalFeatureModule> {
    return {
      ngModule: GlobalFeatureModule,
      providers: [
        GlobalFeatureService,
      ],
    };
  }
}
