<!-- Start: Tenant improvements fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Tenant improvements
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="tenant-improvements-type" valueExpr="value" displayExpr="name"
                     [(items)]="tenantImprovementsTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsType"
                     #tenantImprovementsType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.tenantImprovementsTerm.tenantImprovementsType === TenantImprovementsType.TurnKey">
    <!-- Start: Turnkey -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsTurnKey.invalid">
        Turnkey
      </div>
      <div class="dx-field-value">
        <dx-text-area name="tenant-improvements-turn-key" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? tenantImprovementsTurnKey.valid : true"
                      [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsOptionDescription"
                      #tenantImprovementsTurnKey="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback text-muted d-block"
             *ngIf="!form.submitted || !tenantImprovementsTurnKey.invalid">
          Please enter a list of improvements separated by a semicolon
        </div>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsTurnKey.hasError('required')">
          Turnkey is required
        </div>
      </div>
    </div>
    <!-- End: Turnkey -->
  </ng-container>

  <ng-container *ngIf="lease.tenantImprovementsTerm.tenantImprovementsType === TenantImprovementsType.DollarAllowance">
    <!-- Start: Dollar allowance -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsDollarAllowance.invalid">
        Dollar allowance
      </div>
      <div class="dx-field-value">
        <dx-number-box name="tenant-improvements-dollar-allowance" format="$#,##0.00" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? tenantImprovementsDollarAllowance.valid : true"
                       [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsValue"
                       #tenantImprovementsDollarAllowance="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsDollarAllowance.hasError('required')">
          Dollar allowance is required
        </div>
      </div>
    </div>
    <!-- End: Dollar allowance -->
    <!-- Start: Requested improvements -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsRequestedImprovements.invalid">
        Requested improvements
      </div>
      <div class="dx-field-value">
        <dx-text-area name="tenant-improvements-requested-improvements" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? tenantImprovementsRequestedImprovements.valid : true"
                      [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsOptionDescription"
                      #tenantImprovementsRequestedImprovements="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback text-muted d-block"
             *ngIf="!form.submitted || !tenantImprovementsRequestedImprovements.invalid">
          Please enter a list of improvements separated by a semicolon
        </div>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsRequestedImprovements.hasError('required')">
          Requested improvements is required
        </div>
      </div>
    </div>
    <!-- End: Requested improvements -->
    <!-- Start: Amortization type -->
    <div class="dx-field">
      <div class="dx-field-label">
        Amortization type
      </div>
      <div class="dx-field-value">
        <dx-select-box name="tenant-improvements-amortization-type" valueExpr="value" displayExpr="name"
                       [(items)]="tenantImprovementsAmortizationTypesList"
                       [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsAmortizationOption"
                       #tenantImprovementsAmortizationType="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Amortization type -->

    <ng-container *ngIf="lease.tenantImprovementsTerm.tenantImprovementsAmortizationOption === TenantImprovementsAmortizationType.AmortizationOption">
      <!-- Start: Amortization amount -->
      <div class="dx-field">
        <div class="dx-field-label"
             [class.text-danger]="form.submitted && tenantImprovementsAmortizationAmount.invalid">
          Amortization amount
        </div>
        <div class="dx-field-value">
          <dx-number-box name="tenant-improvements-amortization-amount" format="$#,##0.00" required
                         min="0" max="9999999" step="0"
                         [isValid]="form.submitted ? tenantImprovementsAmortizationAmount.valid : true"
                         [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsAmortizationAmount"
                         #tenantImprovementsAmortizationAmount="ngModel">
          </dx-number-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && tenantImprovementsAmortizationAmount.hasError('required')">
            Amortization amount is required
          </div>
        </div>
      </div>
      <!-- End: Amortization amount -->
      <!-- Start: Amortization term -->
      <div class="dx-field">
        <div class="dx-field-label"
             [class.text-danger]="form.submitted && tenantImprovementsAmortizationTerm.invalid">
          Amortization term
        </div>
        <div class="dx-field-value">
          <dx-number-box appIgnoreScrollwheel name="tenant-improvements-amortization-term" format="#0 month(s)" required
                         min="0" max="9999999"
                         [isValid]="form.submitted ? tenantImprovementsAmortizationTerm.valid : true"
                         [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsAmortizationTerm"
                         #tenantImprovementsAmortizationTerm="ngModel">
          </dx-number-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && tenantImprovementsAmortizationTerm.hasError('required')">
            Amortization term is required
          </div>
        </div>
      </div>
      <!-- End: Amortization term -->
      <!-- Start: Amortization interest rate -->
      <div class="dx-field">
        <div class="dx-field-label"
             [class.text-danger]="form.submitted && tenantImprovementsAmortizationInterestRate.invalid">
          Amortization interest rate
        </div>
        <div class="dx-field-value">
          <dx-number-box appIgnoreScrollwheel name="tenant-improvements-interest-rate" format="#0.##'%'" required
                         min="0" max="9999999"
                         [isValid]="form.submitted ? tenantImprovementsAmortizationInterestRate.valid : true"
                         [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsAnnualInterestRate"
                         #tenantImprovementsAmortizationInterestRate="ngModel">
          </dx-number-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && tenantImprovementsAmortizationInterestRate.hasError('required')">
            Amortization interest rate is required
          </div>
        </div>
      </div>
      <!-- End: Amortization interest rate -->
    </ng-container>
  </ng-container>

  <ng-container *ngIf="lease.tenantImprovementsTerm.tenantImprovementsType === TenantImprovementsType.CustomAmortized">
    <!-- Start: Amortization amount -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsAmortizationAmount.invalid">
        Amortization amount
      </div>
      <div class="dx-field-value">
        <dx-number-box name="tenant-improvements-amortization-amount" format="$#,##0.00" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? tenantImprovementsAmortizationAmount.valid : true"
                       [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsAmortized"
                       #tenantImprovementsAmortizationAmount="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsAmortizationAmount.hasError('required')">
          Amortization amount is required
        </div>
      </div>
    </div>
    <!-- End: Amortization amount -->
    <!-- Start: Amortization term -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsAmortizationTerm.invalid">
        Amortization term
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="tenant-improvements-amortization-term" format="#0 month(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? tenantImprovementsAmortizationTerm.valid : true"
                       [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsAmortizedMonthsCount"
                       #tenantImprovementsAmortizationTerm="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsAmortizationTerm.hasError('required')">
          Amortization term is required
        </div>
      </div>
    </div>
    <!-- End: Amortization term -->
    <!-- Start: Amortization interest rate -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsAmortizationInterestRate.invalid">
        Amortization interest rate
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="tenant-improvements-interest-rate" format="#0.##'%'" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? tenantImprovementsAmortizationInterestRate.valid : true"
                       [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsPercentageRate"
                       #tenantImprovementsAmortizationInterestRate="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsAmortizationInterestRate.hasError('required')">
          Amortization interest rate is required
        </div>
      </div>
    </div>
    <!-- End: Amortization interest rate -->
    <!-- Start: Requested improvements -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsRequestedImprovements.invalid">
        Requested improvements
      </div>
      <div class="dx-field-value">
        <dx-text-area name="tenant-improvements-requested-improvements" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? tenantImprovementsRequestedImprovements.valid : true"
                      [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsOptionDescription"
                      #tenantImprovementsRequestedImprovements="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback text-muted d-block"
             *ngIf="!form.submitted || !tenantImprovementsRequestedImprovements.invalid">
          Please enter a list of improvements separated by a semicolon
        </div>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsRequestedImprovements.hasError('required')">
          Requested improvements is required
        </div>
      </div>
    </div>
    <!-- End: Requested improvements -->
  </ng-container>

  <ng-container *ngIf="lease.tenantImprovementsTerm.tenantImprovementsType === TenantImprovementsType.DollarAllowance || lease.tenantImprovementsTerm.tenantImprovementsType === TenantImprovementsType.CustomAmortized">
    <!-- Start: Contractor type -->
    <div class="dx-field">
      <div class="dx-field-label">
        Contractor type
      </div>
      <div class="dx-field-value">
        <dx-select-box name="tenant-improvements-contractor-type" valueExpr="value" displayExpr="name"
                       [(items)]="tenantImprovementsContractorTypesList"
                       [(ngModel)]="lease.tenantImprovementsTerm.contractorTypeForTenantImprovementsWork"
                       #tenantImprovementsContractorType="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Contractor type -->

    <ng-container *ngIf="lease.tenantImprovementsTerm.contractorTypeForTenantImprovementsWork === TenantImprovementsContractorType.UseLandlordsContractors">
      <!-- Start: Warranty duration -->
      <div class="dx-field">
        <div class="dx-field-label">
          Warranty duration
        </div>
        <div class="dx-field-value">
          <dx-select-box name="tenant-improvements-warranty-duration-type" valueExpr="value" displayExpr="name"
                         [(items)]="tenantImprovementsWarrantyDurationTypesList"
                         [(ngModel)]="lease.tenantImprovementsTerm.warrantyDurationTypeForLandlordsContractors"
                         (ngModelChange)="handleTenantImprovementsWarrantyDurationTypeChange($event)"
                         #tenantImprovementsWarrantyDuration="ngModel">
          </dx-select-box>
        </div>
      </div>
      <!-- End: Warranty duration -->

      <ng-container *ngIf="lease.tenantImprovementsTerm.warrantyDurationTypeForLandlordsContractors === TenantImprovementsWarrantyDurationType.Custom">
        <!-- Start: Custom warranty duration -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && tenantImprovementsCustomWarrantyDuration.invalid">
            Custom warranty duration
          </div>
          <div class="dx-field-value">
            <dx-number-box appIgnoreScrollwheel name="tenant-improvements-custom-warranty-duration" format="#0 month(s)" required
                           min="0" max="9999999"
                           [isValid]="form.submitted ? tenantImprovementsCustomWarrantyDuration.valid : true"
                           [(ngModel)]="lease.tenantImprovementsTerm.warrantyDurationInMonthsForLandlordContractors"
                           #tenantImprovementsCustomWarrantyDuration="ngModel">
            </dx-number-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && tenantImprovementsCustomWarrantyDuration.hasError('required')">
              Custom warranty duration is required
            </div>
          </div>
        </div>
        <!-- End: Custom warranty duration -->
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="lease.tenantImprovementsTerm.tenantImprovementsType === TenantImprovementsType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantImprovementsCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="tenant-improvements-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? tenantImprovementsCustomLanguage.valid : true"
                      [(ngModel)]="lease.tenantImprovementsTerm.tenantImprovementsCustom"
                      #tenantImprovementsCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantImprovementsCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="tenant-improvements-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.tenantImprovementsTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="tenant-improvements-display-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.tenantImprovementsTerm.customDisplayValue"
                    #tenantImprovementsDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.tenantImprovementsTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.tenantImprovementsTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Tenant improvements fieldset -->

<!-- Start: Condition of Premises fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Condition of Premises
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="condition-of-premises-type" valueExpr="value" displayExpr="name"
                     [(items)]="conditionOfPremisesTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.conditionOfPremisesTerm.conditionOfPremisesType"
                     #conditionOfPremisesType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <!-- Start: Warranty Months -->
  <div class="dx-field" *ngIf="lease.conditionOfPremisesTerm.conditionOfPremisesType === ConditionOfPremisesType.LandlordDeliveredWithWarranty">
    <div class="dx-field-label"
         [class.text-danger]="form.submitted && conditionOfPremisesWarrantyMonths.invalid">
      Length
    </div>
    <div class="dx-field-value">
      <dx-number-box appIgnoreScrollwheel name="term" format="#0 month(s)" required
                     min="1" max="9999999"
                     [isValid]="form.submitted ? conditionOfPremisesWarrantyMonths.valid : true"
                     [(ngModel)]="lease.conditionOfPremisesTerm.warrantyMonthsCount"
                     #conditionOfPremisesWarrantyMonths="ngModel">
      </dx-number-box>
      <div class="my-1 invalid-feedback d-block"
           *ngIf="form.submitted && conditionOfPremisesWarrantyMonths.hasError('required')">
        Warranty length is required
      </div>
    </div>
  </div>
  <!-- End: Warranty Months -->

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.conditionOfPremisesTerm.conditionOfPremisesType === ConditionOfPremisesType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="condition-of-premises-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.conditionOfPremisesTerm.customDisplayValue"
                    #conditionOfPremisesCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="condition-of-premises-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.conditionOfPremisesTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.conditionOfPremisesTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.conditionOfPremisesTerm?.documentSection">
  </app-import-form-term-preview>
</div>
<!-- End: Condition of Premises fieldset -->

<!-- Start: Code Compliance fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Code Compliance
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="code-compliance-type" valueExpr="value" displayExpr="name"
                     [(items)]="codeComplianceTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.codeComplianceTerm.codeComplianceType"
                     #codeComplianceType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <!-- Start: Custom text -->
  <div class="dx-field" *ngIf="lease.codeComplianceTerm.codeComplianceType === CodeComplianceType.Custom">
    <div class="dx-field-label">
      Custom text
    </div>
    <div class="dx-field-value">
      <dx-text-area name="code-compliance-custom-text"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.codeComplianceTerm.customText"
                    #codeComplianceCustomText="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom text -->

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.codeComplianceTerm.codeComplianceType === CodeComplianceType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="code-compliance-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.codeComplianceTerm.customDisplayValue"
                    #codeComplianceCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="code-compliance-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.codeComplianceTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.codeComplianceTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.codeComplianceTerm?.documentSection">
  </app-import-form-term-preview>
</div>
<!-- End: Code Compliance fieldset -->

<!-- Start: Landlord maintenance or responsibilities fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Landlord maintenance or responsibilities
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="landlord-maintenance-type" valueExpr="value" displayExpr="name"
                     [(items)]="landlordMaintenanceTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.landlordMaintenanceTerm.landlordMaintenanceTermType"
                     #landlordMaintenanceType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.landlordMaintenanceTerm.landlordMaintenanceTermType === LandlordMaintenanceType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && landlordMaintenanceCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="landlord-maintenance-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? landlordMaintenanceCustomLanguage.valid : true"
                      [(ngModel)]="lease.landlordMaintenanceTerm.landlordMaintenance"
                      #landlordMaintenanceCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && landlordMaintenanceCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="landlord-maintenance-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.landlordMaintenanceTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.landlordMaintenanceTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.landlordMaintenanceTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Landlord maintenance or responsibilities fieldset -->
<!-- Start: Tenant maintenance or responsibilities fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Tenant maintenance or responsibilities
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="tenant-maintenance-type" valueExpr="value" displayExpr="name"
                     [(items)]="tenantMaintenanceTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.tenantMaintenanceTerm.tenantMaintenanceTermType"
                     #landlordMaintenanceType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.tenantMaintenanceTerm.tenantMaintenanceTermType === TenantMaintenanceType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantMaintenanceCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="tenant-maintenance-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? tenantMaintenanceCustomLanguage.valid : true"
                      [(ngModel)]="lease.tenantMaintenanceTerm.tenantMaintenance"
                      #tenantMaintenanceCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantMaintenanceCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="tenant-maintenance-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.tenantMaintenanceTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.tenantMaintenanceTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.tenantMaintenanceTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Tenant maintenance or responsibilities fieldset -->
<!-- Start: HVAC fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    HVAC
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="hvac-type" valueExpr="value" displayExpr="name"
                     [(items)]="hvacTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.hvacTerm.hvacTermType"
                     #hvacType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.hvacTerm.hvacTermType === HvacType.CustomValue">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && hvacCustomValue.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="hvac-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? hvacCustomValue.valid : true"
                      [(ngModel)]="lease.hvacTerm.hvacCustomValue"
                      #hvacCustomValue="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && hvacCustomValue.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <ng-container *ngIf="lease.hvacTerm.hvacTermType === HvacType.MoneyValue">
    <!-- Start: Annual cap -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && hvacAnnualCap.invalid">
        Annual cap
      </div>
      <div class="dx-field-value">
        <dx-number-box name="hvac-annual-cap" format="$#,##0.00" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? hvacAnnualCap.valid : true"
                       [(ngModel)]="lease.hvacTerm.hvacMoneyValue"
                       #hvacAnnualCap="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && hvacAnnualCap.hasError('required')">
          Annual cap is required
        </div>
      </div>
    </div>
    <!-- End: Annual cap -->
  </ng-container>

  <ng-container *ngIf="lease.hvacTerm.hvacTermType === HvacType.PercentValue">
    <!-- Start: Amortization rate -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && hvacAmortizationRate.invalid">
        Amortization rate
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="hvac-amortization-rate" format="#0.##'%'" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? hvacAmortizationRate.valid : true"
                       [(ngModel)]="lease.hvacTerm.hvacPercentValue"
                       #hvacAmortizationRate="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && hvacAmortizationRate.hasError('required')">
          Amortization rate is required
        </div>
      </div>
    </div>
    <!-- End: Amortization rate -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="hvac-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.hvacTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="hvac-display-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.hvacTerm.customDisplayValue"
                    #hvacDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.hvacTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.hvacTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: HVAC fieldset -->

<!-- Start: Environmental/Hazardous Materials fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Environmental/Hazardous Materials
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="hazardous-materials-type" valueExpr="value" displayExpr="name"
                     [(items)]="hazardousMaterialsTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.hazardousMaterialsTerm.hazardousMaterialsType"
                     #hazardousMaterialsType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.hazardousMaterialsTerm.hazardousMaterialsType === HazardousMaterialsType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="hazardous-materials-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.hazardousMaterialsTerm.customDisplayValue"
                    #hazardousMaterialsCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="hazardous-materials-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.hazardousMaterialsTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.hazardousMaterialsTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.hazardousMaterialsTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Environmental/Hazardous Materials fieldset -->
