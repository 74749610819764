<h2 class="content-block">Thumbnails</h2>
<div>
  Images without thumbnails: <span *ngIf="images.length">{{ images.length }}</span>
</div>
<div>
  {{generated.length}} thumbnails from {{images.length}} generated, {{getPercentage()}} % complete, errors {{errors.length}}
  <dx-load-indicator *ngIf="loading" height="20" width="20"></dx-load-indicator>
</div>

<br/>
<dx-button text="Generate" [disabled]="!images.length" (onClick)="generateClick()"> </dx-button>
