<!-- Start: Renewal option fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Renewal option
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="renewal-option-type" valueExpr="value" displayExpr="name"
                     [(items)]="renewalOptionTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.renewalOptionTerm.renewalOptionTermType"
                     #renewalOptionType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.renewalOptionTerm.renewalOptionTermType === RenewalOptionType.RenewalOptionValues">
    <!-- Start: Number of options -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionNumberOfRenewalOptions.invalid">
        Number of options
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-number-of-renewal-options" format="#0 option(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionNumberOfRenewalOptions.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionsCount"
                       #renewalOptionNumberOfRenewalOptions="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionNumberOfRenewalOptions.hasError('required')">
          Number of options is required
        </div>
      </div>
    </div>
    <!-- End: Number of options -->
    <!-- Start: Term length for options -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionTermLengthForOptions.invalid">
        Term length for options
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-term-length-for-options" format="#0 year(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionTermLengthForOptions.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionYearsCount"
                       #renewalOptionTermLengthForOptions="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionTermLengthForOptions.hasError('required')">
          Term length for options is required
        </div>
      </div>
    </div>
    <!-- End: Term length for options -->
    <!-- Start: Notice period -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionRenewalNoticePeriod.invalid">
        Notice period
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-renewal-notice-period" format="#0 month(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionRenewalNoticePeriod.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionHowManyMonthsNotice"
                       #renewalOptionRenewalNoticePeriod="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionRenewalNoticePeriod.hasError('required')">
          Notice period is required
        </div>
      </div>
    </div>
    <!-- End: Notice period -->
  </ng-container>

  <ng-container *ngIf="lease.renewalOptionTerm.renewalOptionTermType === RenewalOptionType.RenewalOptionValuesExtended">
    <!-- Start: Number of options -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionNumberOfRenewalOptions.invalid">
        Number of options
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-number-of-renewal-options" format="#0 option(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionNumberOfRenewalOptions.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionsCount"
                       #renewalOptionNumberOfRenewalOptions="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionNumberOfRenewalOptions.hasError('required')">
          Number of options is required
        </div>
      </div>
    </div>
    <!-- End: Number of options -->
    <!-- Start: Term length for options -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionTermLengthForOptions.invalid">
        Term length for options
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-term-length-for-options" format="#0 year(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionTermLengthForOptions.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionYearsCount"
                       #renewalOptionTermLengthForOptions="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionTermLengthForOptions.hasError('required')">
          Term length for options is required
        </div>
      </div>
    </div>
    <!-- End: Term length for options -->
    <!-- Start: Availability period -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionRenewalNoticePeriod.invalid">
        Availability period
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-available-period" format="#0 month(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionAvailablePeriod.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionAvailableMonths"
                       #renewalOptionAvailablePeriod="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionAvailablePeriod.hasError('required')">
          Availability period is required
        </div>
      </div>
    </div>
    <!-- End: Availability period -->
    <!-- Start: Notice period -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionRenewalNoticePeriod.invalid">
        Notice period
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-renewal-notice-period" format="#0 month(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionRenewalNoticePeriod.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionHowManyMonthsNotice"
                       #renewalOptionRenewalNoticePeriod="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionRenewalNoticePeriod.hasError('required')">
          Notice period is required
        </div>
      </div>
    </div>
    <!-- End: Notice period -->
    <!-- Start: Notice period -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionRenewalNoticePeriod.invalid">
        Max rental rate
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="renewal-option-max-rental-rate" format="#0.##'%'" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? renewalOptionMaxRentalRate.valid : true"
                       [(ngModel)]="lease.renewalOptionTerm.renewalOptionMaxRentPercentage"
                       #renewalOptionMaxRentalRate="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionMaxRentalRate.hasError('required')">
          Max rental rate is required
        </div>
      </div>
    </div>
    <!-- End: Notice period -->
  </ng-container>

  <ng-container *ngIf="lease.renewalOptionTerm.renewalOptionTermType === RenewalOptionType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && renewalOptionCustomLanguage.invalid">
        Document language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="renewal-option-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? renewalOptionCustomLanguage.valid : true"
                      [(ngModel)]="lease.renewalOptionTerm.renewalOptionCustomText"
                      #renewalOptionCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && renewalOptionCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Notice date -->
  <div class="dx-field">
    <div class="dx-field-label">
      Notice date
    </div>
    <div class="dx-field-value">
      <dx-date-box type="date" name="renewal-option-notice-date"
                   displayFormat="MMMM d, yyyy" dateSerializationFormat="yyyy-MM-dd"
                   [(ngModel)]="lease.renewalOptionTerm.renewalDate"
                   #renewalOptionNoticeDate="ngModel">
      </dx-date-box>
    </div>
  </div>
  <!-- End: Notice date -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="renewal-option-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.renewalOptionTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="renewal-option-display-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.renewalOptionTerm.customDisplayValue"
                    #renewalOptionDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.renewalOptionTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.renewalOptionTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Renewal option fieldset -->
<!-- Start: Termination option fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Termination option
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="termination-option-type" valueExpr="value" displayExpr="name"
                     [(items)]="terminationOptionTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.terminationOptionTerm.terminationOptionType"
                     #terminationOptionType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.terminationOptionTerm.terminationOptionType === TerminationOptionType.RightToTerminateLease">
    <!-- Start: Termination month -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && terminationOptionTerminationMonth.invalid">
        Termination month
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="termination-option-termination-month" format="#0 lease month" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? terminationOptionTerminationMonth.valid : true"
                       [(ngModel)]="lease.terminationOptionTerm.terminationMonth"
                       #terminationOptionTerminationMonth="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && terminationOptionTerminationMonth.hasError('required')">
          Termination month is required
        </div>
      </div>
    </div>
    <!-- End: Termination month -->
    <!-- Start: Notice period -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && terminationOptionNoticePeriod.invalid">
        Notice period
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="termination-option-notice-period" format="#0 month(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? terminationOptionNoticePeriod.valid : true"
                       [(ngModel)]="lease.terminationOptionTerm.howManyMonthsToNotice"
                       #terminationOptionNoticePeriod="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && terminationOptionNoticePeriod.hasError('required')">
          Notice period is required
        </div>
      </div>
    </div>
    <!-- End: Notice period -->
    <!-- Start: Fee type -->
    <div class="dx-field">
      <div class="dx-field-label">
        Fee type
      </div>
      <div class="dx-field-value">
        <dx-select-box name="termination-option-fee-type" valueExpr="value" displayExpr="name"
                       [(items)]="terminationOptionFeeTypesList"
                       [(ngModel)]="lease.terminationOptionTerm.terminationOptionFeeType"
                       #terminationOptionFeeType="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Fee type -->

    <ng-container *ngIf="lease.terminationOptionTerm.terminationOptionFeeType === TerminationOptionFeeType.AmortizationRate">
      <!-- Start: Amortization rate -->
      <div class="dx-field">
        <div class="dx-field-label"
             [class.text-danger]="form.submitted && terminationOptionAmortizationRate.invalid">
          Amortization rate
        </div>
        <div class="dx-field-value">
          <dx-number-box appIgnoreScrollwheel name="termination-option-amortization-rate" format="#0.##'%'" required
                         min="0" max="9999999"
                         [isValid]="form.submitted ? terminationOptionAmortizationRate.valid : true"
                         [(ngModel)]="lease.terminationOptionTerm.amortizationRate"
                         #terminationOptionAmortizationRate="ngModel">
          </dx-number-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && terminationOptionAmortizationRate.hasError('required')">
            Amortization rate is required
          </div>
        </div>
      </div>
      <!-- End: Amortization rate -->
    </ng-container>

    <ng-container *ngIf="lease.terminationOptionTerm.terminationOptionFeeType === TerminationOptionFeeType.DollarValue">
      <!-- Start: Flat penalty total -->
      <div class="dx-field">
        <div class="dx-field-label"
             [class.text-danger]="form.submitted && terminationOptionFlatPenalty.invalid">
          Flat penalty total
        </div>
        <div class="dx-field-value">
          <dx-number-box name="termination-option-flat-penalty" format="$#,##0.00" required
                         min="0" max="9999999" step="0"
                         [isValid]="form.submitted ? terminationOptionFlatPenalty.valid : true"
                         [(ngModel)]="lease.terminationOptionTerm.terminationOptionDollarValue"
                         #terminationOptionFlatPenalty="ngModel">
          </dx-number-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && terminationOptionFlatPenalty.hasError('required')">
            Flat penalty total is required
          </div>
        </div>
      </div>
      <!-- End: Flat penalty total -->
    </ng-container>
  </ng-container>

  <ng-container *ngIf="lease.terminationOptionTerm.terminationOptionType === TerminationOptionType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && terminationOptionCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="termination-option-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? terminationOptionCustomLanguage.valid : true"
                      [(ngModel)]="lease.terminationOptionTerm.terminationOptionCustomText"
                      #terminationOptionCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && terminationOptionCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Termination date -->
  <div class="dx-field">
    <div class="dx-field-label">
      Termination date
    </div>
    <div class="dx-field-value">
      <dx-date-box type="date" name="termination-option-termination-date"
                   displayFormat="MMMM d, yyyy" dateSerializationFormat="yyyy-MM-dd"
                   [(ngModel)]="lease.terminationOptionTerm.terminationDate"
                   #terminationOptionTerminationDate="ngModel">
      </dx-date-box>
    </div>
  </div>
  <!-- End: Termination date -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="termination-option-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.terminationOptionTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="termination-option-display-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.terminationOptionTerm.customDisplayValue"
                    #terminationOptionDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.terminationOptionTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.terminationOptionTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Termination option fieldset -->
<!-- Start: Expansion option fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Expansion option
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="expansion-option-type" valueExpr="value" displayExpr="name"
                     [(items)]="expansionOptionTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.expansionOptionTerm.expansionOptionTermType"
                     #expansionOptionType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.expansionOptionTerm.expansionOptionTermType === ExpansionOptionType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && expansionOptionCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="expansion-option-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? expansionOptionCustomLanguage.valid : true"
                      [(ngModel)]="lease.expansionOptionTerm.expansionOption"
                      #expansionOptionCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && expansionOptionCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Expansion date -->
  <div class="dx-field">
    <div class="dx-field-label">
      Expansion date
    </div>
    <div class="dx-field-value">
      <dx-date-box type="date" name="expansion-option-expansion-date"
                   displayFormat="MMMM d, yyyy" dateSerializationFormat="yyyy-MM-dd"
                   [(ngModel)]="lease.expansionOptionTerm.expansionDate"
                   #expansionOptionExpansionDate="ngModel">
      </dx-date-box>
    </div>
  </div>
  <!-- End: Expansion date -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="expansion-option-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.expansionOptionTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="expansion-option-display-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.expansionOptionTerm.customDisplayValue"
                    #expansionOptionDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.expansionOptionTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.expansionOptionTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Expansion option fieldset -->
<!-- Start: Assignment/subletting fieldset -->
<!-- Start: Renewal option fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Assignment/subletting
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="expansion-option-type" valueExpr="value" displayExpr="name"
                     [(items)]="assignmentOrSublettingTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.assignmentTerm.assignmentTermType"
                     #expansionOptionType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.assignmentTerm.assignmentTermType === AssignmentOrSublettingType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && assigmentOrSublettingCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="assignment-or-subletting-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? assigmentOrSublettingCustomLanguage.valid : true"
                      [(ngModel)]="lease.assignmentTerm.assignment"
                      #assigmentOrSublettingCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && assigmentOrSublettingCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="assignment-or-subletting-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.assignmentTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="assignment-or-subletting-display-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.assignmentTerm.customDisplayValue"
                    #assigmentOrSublettingDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.assignmentTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.assignmentTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Assignment/subletting fieldset -->
