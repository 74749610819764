import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import DevExpress from 'devextreme';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { CompanyFeatureService } from '../../services/company-feature.service';

import * as models from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

interface Feature {
  name: string;
  description: string;
  model: string;
}

@Component({
  templateUrl: 'edit-company-feature-dialog.component.html',
  styleUrls: ['edit-company-feature-dialog.component.scss'],
})
export class EditCompanyFeatureDialogComponent implements OnInit, OnDestroy {
  private static readonly _companyListUrl = `${environment.adminUrl}/company?withDeleted=true`;

  private static readonly _features: Array<Feature> = [
    {
      name: 'Colábo',
      description: 'Enables/disables Colábo two panel view',
      model: 'isColaboFeatureEnabled',
    },
    {
      name: 'Project Schedule',
      description: 'Enables/disabled Project Schedule',
      model: 'isProjectScheduleFeatureEnabled',
    },
    {
      name: 'Floor Plan',
      description: 'Enables/disables Floor Plan feature',
      model: 'isFloorPlanFeatureEnabled',
    },
  ];

  companyFeature: models.ICompanyFeatureToggleViewModel;

  isCreate: boolean;

  companyDataSource: DevExpress.data.CustomStore;

  features: Array<Feature>;

  readonly reloadList: () => void;

  private readonly _popupRefService: PopupRefService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _companyFeatureService: CompanyFeatureService;
  private readonly _destroy: Subject<void>;

  constructor(
    popupRefService: PopupRefService,
    changeDetectorRef: ChangeDetectorRef,
    companyFeatureService: CompanyFeatureService
  ) {
    this._popupRefService = popupRefService;
    this._changeDetectorRef = changeDetectorRef;
    this._companyFeatureService = companyFeatureService;
    this._destroy = new Subject<void>();

    this.features = EditCompanyFeatureDialogComponent._features;
  }

  ngOnInit(): void {
    const isRequestInjected = !!this.companyFeature;

    this.companyFeature = this.companyFeature || <models.ICompanyFeatureToggleViewModel>{
      isColaboFeatureEnabled: true,
      isIgnoresGlobals: false,
    };

    this.isCreate = !this.companyFeature.companyId;

    this.companyDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: EditCompanyFeatureDialogComponent._companyListUrl,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      }
    });

    if (isRequestInjected) {
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._companyFeatureService
      .createOrUpdateCompanyFeatureToggles(this.companyFeature)
      .pipe(
        tap(() => {
          this._popupRefService.hide();
          this.reloadList();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  cancel(): void {
    this._popupRefService.hide();
  }
}
