<div class="login-header">
  <div class="title">Statera Admin Tool</div>
  <div>Sign In to your account</div>
</div>

<form name="form" #form="ngForm" (ngSubmit)="onLoginFormSubmit()">
  <dx-form
    #dxLoginForm
    [formData]="vm"
    [readOnly]="false"
    [showColonAfterLabel]="true"
    [showValidationSummary]="false"
    validationGroup="loginValidationGroup"
  >
    <dxi-item
      dataField="emailAddress"
      [editorOptions]="{
        placeholder: 'Login (Email address)'
      }"
    >
      <dxo-label [visible]="false"></dxo-label>
      <dxi-validation-rule
        type="required"
        message="Login name (email address) is required"
      ></dxi-validation-rule>
      <dxi-validation-rule type="email" message="Email is invalid"> </dxi-validation-rule>
    </dxi-item>

    <dxi-item
      dataField="password"
      [editorOptions]="{
        mode: 'password',
        placeholder: 'Password'
      }"
    >
      <dxo-label [visible]="false"></dxo-label>
      <dxi-validation-rule type="required" message="Password is required"> </dxi-validation-rule>
    </dxi-item>
    <dxi-item>
      <dx-button type="default" width="100%" [useSubmitBehavior]="true">
        <div class="d-flex justify-content-center">
          <span
            *ngIf="submitted"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <div class="p-2">
            Login
          </div>
        </div>
      </dx-button>
    </dxi-item>
    <dxi-item>
      <p class="text-danger mb-0 mt-3">
        {{errorMessage}}
      </p>
    </dxi-item>
  </dx-form>
</form>

<form #signInForm novalidate ngNoForm method="POST" action="{{ signInUrl }}">
  <input type="hidden" name="emailAddress" id="emailAddress" [value]="vm.emailAddress" />
  <input type="hidden" name="password" id="password" [value]="vm.password" />
</form>
