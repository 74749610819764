<dx-popup class="image-viewer-container"
          [title]="config.title" [showTitle]="!!config.title"
          [showCloseButton]="config.showCloseButton" [closeOnOutsideClick]="config.closeOnOutsideClick"
          [width]="config.width" [height]="config.height" [fullScreen]="config.fullScreen" [dragEnabled]="config.dragEnabled"
          [maxWidth]="config.maxWidth" [maxHeight]="config.maxHeight" [visible]="isVisible"
          (onShowing)="onShowing.emit($event)" (onShown)="onShown.emit($event)"
          (onHiding)="onHiding.emit($event)" (onHidden)="onHidden.emit($event)"
          (onContentReady)="onContentReady.emit($event)"
          #dxPopupComponent>
  <div class="image-viewer-container-content"
       *ngIf="isReady"
       #contentElement>
    <app-image-viewer [images]="injectableData?.images" [allowChangeMarkers]="injectableData?.allowChangeMarkers"
                      [container]="contentElement" [activeImageIndex]="injectableData?.activeIndex"
                      [enablePreviewNavigation]="true" [enableArrowNavigation]="injectableData?.enableArrowNavigation"
                      [enableZoom]="injectableData?.enableZoom"
                      (markerCreated)="onMarkerCreated.emit($event)" (markerChanged)="onMarkerChanged.emit($event)"
                      (markerDeleted)="onMarkerDeleted.emit($event)">
    </app-image-viewer>
  </div>
  <div class="image-viewer-container-preloader"
       *ngIf="!isReady">
    <app-image-viewer-preloader>
    </app-image-viewer-preloader>
  </div>
</dx-popup>
