<div id="building-import-mapping-popup">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form" novalidate
          (ngSubmit)="createImportBuildingsTask()" #form="ngForm">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset"
           *ngIf="importFileError">
        <div class="alert alert-danger">
          {{importFileError}}
        </div>
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Main properties
        </div>
        <!-- Start: Name -->
        <div class="dx-field">
          <div class="dx-field-label">
            Name
          </div>
          <div class="dx-field-value">
            <dx-select-box name="name" id="name"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.name" #name="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Name -->
        <!-- Start: Total building size -->
        <div class="dx-field">
          <div class="dx-field-label">
            Total building size
          </div>
          <div class="dx-field-value">
            <dx-select-box name="totalBuildingSize" id="totalBuildingSize"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.totalBuildingSize" #totalBuildingSize="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Total building size -->
        <!-- Start: Year built -->
        <div class="dx-field">
          <div class="dx-field-label">
            Year Built
          </div>
          <div class="dx-field-value">
            <dx-select-box name="yearBuilt" id="yearBuilt"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.builtYear" #yearBuilt="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Year built -->
        <!-- Start: Building class -->
        <div class="dx-field">
          <div class="dx-field-label">
            Building class
          </div>
          <div class="dx-field-value">
            <dx-select-box name="buildingClass" id="buildingClass"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.buildingClass" #buildingClass="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Building class -->
        <!-- Start: Leed certification -->
        <div class="dx-field">
          <div class="dx-field-label">
            Leed certification
          </div>
          <div class="dx-field-value">
            <dx-select-box name="leedCertification" id="leedCertification"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.leedCertification" #leedCertification="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Leed certification -->
        <!-- Start: Amps -->
        <div class="dx-field">
          <div class="dx-field-label">
            Amps
          </div>
          <div class="dx-field-value">
            <dx-select-box name="amps" id="amps"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.amps" #amps="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Amps -->
        <!-- Start: Volts -->
        <div class="dx-field">
          <div class="dx-field-label">
            Volts
          </div>
          <div class="dx-field-value">
            <dx-select-box name="volts" id="volts"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.volts" #volts="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Volts -->
        <!-- Start: Phase -->
        <div class="dx-field">
          <div class="dx-field-label">
            Phase
          </div>
          <div class="dx-field-value">
            <dx-select-box name="phase" id="phase"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.phase" #phase="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Phase -->
        <!-- Start: Construction type -->
        <div class="dx-field">
          <div class="dx-field-label">
            Construction type
          </div>
          <div class="dx-field-value">
            <dx-select-box name="constructionType" id="constructionType"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.constructionType" #constructionType="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Construction type -->
      </div>
      <!-- End: Fieldset -->

      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Addition properties
        </div>
        <!-- Start: Clear height -->
        <div class="dx-field">
          <div class="dx-field-label">
            Clear height
          </div>
          <div class="dx-field-value">
            <dx-select-box name="clearHeight" id="clearHeight"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.clearHeight" #clearHeight="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Clear height -->
        <!-- Start: Total office area -->
        <div class="dx-field">
          <div class="dx-field-label">
            Total office area
          </div>
          <div class="dx-field-value">
            <dx-select-box name="totalOfficeArea" id="totalOfficeArea"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.totalOfficeArea" #totalOfficeArea="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Total office area -->
        <!-- Start: Renovated date -->
        <div class="dx-field">
          <div class="dx-field-label">
            Renovated date
          </div>
          <div class="dx-field-value">
            <dx-select-box name="renovatedDate" id="renovatedDate"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.renovatedDate" #renovatedDate="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Renovated date -->
        <!-- Start: Total land area -->
        <div class="dx-field">
          <div class="dx-field-label">
            Total land area
          </div>
          <div class="dx-field-value">
            <dx-select-box name="totalLandArea" id="totalLandArea"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.totalLandArea" #totalLandArea="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Total land area -->
        <!-- Start: Column spacing -->
        <div class="dx-field">
          <div class="dx-field-label">
            Column spacing
          </div>
          <div class="dx-field-value">
            <dx-select-box name="columnSpacing" id="columnSpacing"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.columnSpacing" #columnSpacing="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Column spacing -->
        <!-- Start: Total parking spaces -->
        <div class="dx-field">
          <div class="dx-field-label">
            Total parking spaces
          </div>
          <div class="dx-field-value">
            <dx-select-box name="totalParkingSpaces" id="totalParkingSpaces"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.totalParkingSpaces" #totalParkingSpaces="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Total parking spaces -->
        <!-- Start: Total docks interior -->
        <div class="dx-field">
          <div class="dx-field-label">
            Total docks interior
          </div>
          <div class="dx-field-value">
            <dx-select-box name="totalDocksInterior" id="totalDocksInterior"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.totalDocksInterior" #totalDocksInterior="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Total docks interior -->
        <!-- Start: Total docks exterior -->
        <div class="dx-field">
          <div class="dx-field-label">
            Total docks exterior
          </div>
          <div class="dx-field-value">
            <dx-select-box name="totalDocksExterior" id="totalDocksExterior"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.totalDocksExterior" #totalDocksExterior="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Total docks exterior -->
        <!-- Start: Sprinkler system -->
        <div class="dx-field">
          <div class="dx-field-label">
            Sprinkler system
          </div>
          <div class="dx-field-value">
            <dx-select-box name="sprinklerSystem" id="sprinklerSystem"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.sprinklerSystem" #sprinklerSystem="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Sprinkler system -->
        <!-- Start: Utility costs -->
        <div class="dx-field">
          <div class="dx-field-label">
            Utility costs
          </div>
          <div class="dx-field-value">
            <dx-select-box name="utilityCosts" id="utilityCosts"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.utilityCosts" #utilityCosts="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Utility costs -->
        <!-- Start: Utility costs text -->
        <div class="dx-field">
          <div class="dx-field-label">
            Utility costs text
          </div>
          <div class="dx-field-value">
            <dx-select-box name="utilityCostsText" id="utilityCostsText"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.utilityCostsText" #utilityCostsText="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Utility costs text -->
        <!-- Start: Most current real estate taxes PSF -->
        <div class="dx-field">
          <div class="dx-field-label">
            Most current real estate taxes PSF
          </div>
          <div class="dx-field-value">
            <dx-select-box name="mostCurrentRealEstateTaxesPsf" id="mostCurrentRealEstateTaxesPsf"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.mostCurrentRealEstateTaxesPsf" #mostCurrentRealEstateTaxesPsf="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most current real estate taxes PSF -->
        <!-- Start: Most current real estate taxes total -->
        <div class="dx-field">
          <div class="dx-field-label">
            Most current real estate taxes total
          </div>
          <div class="dx-field-value">
            <dx-select-box name="mostCurrentRealEstateTaxesTotal" id="mostCurrentRealEstateTaxesTotal"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.mostCurrentRealEstateTaxesTotal" #mostCurrentRealEstateTaxesTotal="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most current real estate taxes total -->
        <!-- Start: Operating expenses -->
        <div class="dx-field">
          <div class="dx-field-label">
            Operating expenses
          </div>
          <div class="dx-field-value">
            <dx-select-box name="operatingExpenses" id="operatingExpenses"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.operatingExpenses" #operatingExpenses="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Operating expenses -->
        <!-- Start: Most recent interior construction or update year -->
        <div class="dx-field">
          <div class="dx-field-label" title="Most recent interior construction or update year">
            Most recent interior construction or update year
          </div>
          <div class="dx-field-value">
            <dx-select-box name="mostRecentInteriorConstructionOrUpdateYear" id="mostRecentInteriorConstructionOrUpdateYear"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.mostRecentInteriorConstructionOrUpdateYear"
                           #mostRecentInteriorConstructionOrUpdateYear="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most recent interior construction or update year -->
        <!-- Start: Most recent exterior construction or update year -->
        <div class="dx-field">
          <div class="dx-field-label" title="Most recent exterior construction or update year">
            Most recent exterior construction or update year
          </div>
          <div class="dx-field-value">
            <dx-select-box name="mostRecentExteriorConstructionOrUpdateYear" id="mostRecentExteriorConstructionOrUpdateYear"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.mostRecentExteriorConstructionOrUpdateYear"
                           #mostRecentExteriorConstructionOrUpdateYear="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most recent exterior construction or update year -->
        <!-- Start: Most recent interior construction or update text -->
        <div class="dx-field">
          <div class="dx-field-label" title="Most recent interior construction or update text">
            Most recent interior construction or update text
          </div>
          <div class="dx-field-value">
            <dx-select-box name="mostRecentInteriorConstructionOrUpdateText" id="mostRecentInteriorConstructionOrUpdateText"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.mostRecentInteriorConstructionOrUpdateText"
                           #mostRecentInteriorConstructionOrUpdateText="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most recent interior construction or update text -->
        <!-- Start: Most recent exterior construction or update text -->
        <div class="dx-field">
          <div class="dx-field-label" title="Most recent exterior construction or update text">
            Most recent exterior construction or update text
          </div>
          <div class="dx-field-value">
            <dx-select-box name="mostRecentExteriorConstructionOrUpdateText" id="mostRecentExteriorConstructionOrUpdateText"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.mostRecentExteriorConstructionOrUpdateText"
                           #mostRecentExteriorConstructionOrUpdateText="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most recent exterior construction or update text -->
        <!-- Start: Rail line -->
        <div class="dx-field">
          <div class="dx-field-label">
            Rail line
          </div>
          <div class="dx-field-value">
            <dx-select-box name="railLine" id="railLine"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.railLine" #railLine="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Rail line -->
        <!-- Start: Pin number -->
        <div class="dx-field">
          <div class="dx-field-label">
            Pin number
          </div>
          <div class="dx-field-value">
            <dx-select-box name="pinNumber" id="pinNumber"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.pinNumber" #pinNumber="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Pin number -->
        <!-- Start: Roof type condition -->
        <div class="dx-field">
          <div class="dx-field-label">
            Roof type condition
          </div>
          <div class="dx-field-value">
            <dx-select-box name="roofTypeCondition" id="roofTypeCondition"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.roofTypeCondition" #roofTypeCondition="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Roof type condition -->
        <!-- Start: Most recent sale price -->
        <div class="dx-field">
          <div class="dx-field-label">
            Most recent sale price
          </div>
          <div class="dx-field-value">
            <dx-select-box name="mostRecentSalePrice" id="mostRecentSalePrice"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.mostRecentSalePrice" #mostRecentSalePrice="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most recent sale price -->
        <!-- Start: Most recent sale year -->
        <div class="dx-field">
          <div class="dx-field-label">
            Most recent sale year
          </div>
          <div class="dx-field-value">
            <dx-select-box name="saleYear" id="saleYear"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.saleYear" #saleYear="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Most recent sale year -->
      </div>
      <!-- End: Fieldset -->

      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Address
        </div>
        <!-- Start: Address line 1 -->
        <div class="dx-field">
          <div class="dx-field-label">
            Address line 1
          </div>
          <div class="dx-field-value">
            <dx-select-box name="addressLine1" id="addressLine1" required
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           [isValid]="form.submitted ? addressLine1.valid : true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.addressLine1" #addressLine1="ngModel">
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && addressLine1.hasError('required')">
              Address line 1 is required
            </div>
          </div>
        </div>
        <!-- End: Address line 1 -->
        <!-- Start: Address line 2 -->
        <div class="dx-field">
          <div class="dx-field-label">
            Address line 2
          </div>
          <div class="dx-field-value">
            <dx-select-box name="addressLine2" id="addressLine2"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.addressLine2" #addressLine2="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Address line 2 -->
        <!-- Start: Zip code -->
        <div class="dx-field">
          <div class="dx-field-label">
            Zip code
          </div>
          <div class="dx-field-value">
            <dx-select-box name="zipCode" id="zipCode" required
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           [isValid]="form.submitted ? zipCode.valid : true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.zipCode" #zipCode="ngModel">
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && zipCode.hasError('required')">
              Zip code is required
            </div>
          </div>
        </div>
        <!-- End: Zip code -->
        <!-- Start: City -->
        <div class="dx-field">
          <div class="dx-field-label">
            City
          </div>
          <div class="dx-field-value">
            <dx-select-box name="city" id="city"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.city" #city="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: City -->
        <!-- Start: Country code -->
        <div class="dx-field">
          <div class="dx-field-label">
            Country code
          </div>
          <div class="dx-field-value">
            <dx-select-box name="countryCode" id="countryCode"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.countryCode" #countryCode="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Country code -->
        <!-- Start: State code -->
        <div class="dx-field">
          <div class="dx-field-label">
            State code
          </div>
          <div class="dx-field-value">
            <dx-select-box name="stateCode" id="stateCode"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.stateCode" #stateCode="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: State code -->
        <!-- Start: County name -->
        <div class="dx-field">
          <div class="dx-field-label">
            County name
          </div>
          <div class="dx-field-value">
            <dx-select-box name="countyName" id="countyName"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.countyName" #countyName="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: County name -->
        <!-- Start: Market -->
        <div class="dx-field">
          <div class="dx-field-label">
            Market
          </div>
          <div class="dx-field-value">
            <dx-select-box name="market" id="market"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.market"
                           #marketSelectBox="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Market -->
        <!-- Start: SubMarket -->
        <div class="dx-field">
          <div class="dx-field-label">
            SubMarket
          </div>
          <div class="dx-field-value">
            <dx-select-box name="submarket" id="submarket"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.subMarket"
                           #subMarketSelectBox="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: SubMarket -->
      </div>
      <!-- End: Fieldset -->

      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row mx-n1">
          <div class="px-1">
            <dx-button type="success" text="Import"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-1">
            <dx-button type="normal" text="Cancel"
                       (click)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>
