<dx-popup [class]="getContainerClassNames" [title]="config.title" [showTitle]="!!config.title"
          [showCloseButton]="config.showCloseButton" [closeOnOutsideClick]="config.closeOnOutsideClick"
          [width]="config.width" [height]="config.height" [fullScreen]="config.fullScreen" [dragEnabled]="config.dragEnabled"
          [maxWidth]="config.maxWidth" [maxHeight]="config.maxHeight" [visible]="isVisible"
          (onShowing)="onShowing.emit($event)" (onShown)="onShown.emit($event)"
          (onHiding)="onHiding.emit($event)" (onHidden)="onHidden.emit($event)"
          (onContentReady)="onContentReady.emit($event)">
  <ng-content>
  </ng-content>
</dx-popup>
