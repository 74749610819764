import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

import { environment } from '../../../../environments/environment';

@Injectable()
export class GlobalFeatureService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  updateGlobalFeatureToggles(globalFeature: models.IGlobalFeatureToggleViewModel): Observable<models.IGlobalFeatureToggleViewModel> {
    return this._httpClient.post<models.IGlobalFeatureToggleViewModel>(
      `${environment.adminUrl}/feature-toggle/global`,
      globalFeature
    );
  }
}
