<h2 class="content-block">Portfolios</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="50"></dxi-column>
  <dxi-column dataField="name"></dxi-column>
  <dxi-column dataField="companyId" cellTemplate="companyFieldTemplate">
    <dxo-lookup [dataSource]="companyDataStore" valueExpr="id" [displayExpr]="Tools.getCompanyExpression">
    </dxo-lookup>
  </dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'companyFieldTemplate'">
    <div>
      {{ item?.data?.company?.name }}
    </div>
  </div>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup
      title="Add Portfolio"
      [maxWidth]="500"
      [showTitle]="true"
      [position]="{ my: 'center', at: 'center', of: _window }"
    >
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="name" isRequired="true"></dxi-item>
      <dxi-item dataField="companyId" isRequired="true"></dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
