import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { Tools } from 'src/app/shared/tools';
import { environment } from 'src/environments/environment';
import { IQuizAnswerViewModel, QuestionType } from '../../shared/models/generated';

@Component({
  selector: 'app-third-party-leases',
  templateUrl: './third-party-leases.component.html',
  styleUrls: ['./third-party-leases.component.scss'],
})
export class ThirdPartyLeasesComponent implements OnInit, AfterViewInit {
  Tools = Tools;
  dataSource: any;
  leaseDataSource: CustomStore;
  thirdPartyLeasesDataSource: CustomStore;
  url = `${environment.adminUrl}/LeaseWith3rdPartyLease`;
  defaultEditorOptions = {showClearButton: true};
  thirdPartyLeasesEditorOptions: any = {...this.defaultEditorOptions};
  leasesEditorOptions: any = {...this.defaultEditorOptions};
  private _leasesUrl = `${environment.adminUrl}/Leases/shortLeaseModels`;

  private _leasesWith3rdPartyLeasesUrl = `${environment.adminUrl}/Leases/3rd-party-leases`;

  get _window() {
    return window;
  }

  constructor() {
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      updateUrl: this.url,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
      onUpdating: function (key, values) {
        console.log(key, values);
      }
    });

    this.leaseDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this._leasesUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });

    this.leasesEditorOptions = {
      ...this.defaultEditorOptions,
      dataSource: this.leaseDataSource,
      valueExpr: 'id',
    };

    this.thirdPartyLeasesDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this._leasesWith3rdPartyLeasesUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });
    this.thirdPartyLeasesEditorOptions = {
      ...this.defaultEditorOptions,
      dataSource: this.thirdPartyLeasesDataSource,
      valueExpr: 'id',
    };
  }

  getBuildingPicture(item) {
    if (item && item.data && item.data.building) {
      return Tools.buildingPictureThumbnail(item.data.building) || Tools.noImageUrl;
    }

    if (item && item.picture) {
      return Tools.buildingPictureThumbnail(item) || Tools.noImageUrl;
    }

    return Tools.noImageUrl;
  }

  getBuildingName(item) {
    if (item && item.data && item.data.building) {
      return item.data.building.name;
    } else if (item) {
      return item.name;
    }
    return '';
  }

  getBuildingUnitName(item) {
    if (item && item.data && item.data.buildingUnit) {
      return item.data.buildingUnit.name;
    } else if (item) {
      return item.name;
    }
    return '';
  }

  downloadFiles($event, items: any) {
    $event.preventDefault();
    if (!items || !items.data || !items.data.quizAnswers || items.data.quizAnswers.length === 0) {
      return;
    }

    const quizAnswer = items.data.quizAnswers.find(item => item.quizQuestion.questionType === QuestionType.LeaseAmendments);

    if (!quizAnswer) {
      return;
    }

    for (let i = 0, num = quizAnswer.quizAnswerFiles.length; i < num; i++) {
      const quizAnswerFile = quizAnswer.quizAnswerFiles[i];
      if (!quizAnswerFile || !quizAnswerFile.file || !quizAnswerFile.file.url) {
        continue;
      }
      Tools.downloadFile(quizAnswerFile.file.url);
    }
  }

  ngAfterViewInit(): void {
  }
}
