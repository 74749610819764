import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import * as models from '../../../shared/models/generated';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  /**
   * Creates plan
   * @method PlanService.createPlan
   * @param plan Plan view model
   * @returns Created plan
   */
  createPlan(plan: models.IPlanViewModel): Observable<models.IPlanViewModel> {
    return this._httpClient
      .post<models.IPlanViewModel>(
        `${environment.adminUrl}/plan`,
        plan,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of plans
   * @method PlanService.getPlanList
   * @param kind Plan kind
   * @param buildingId Building id to filter plans
   * @returns The list of plans
   */
  getPlanList(kind?: models.PlanKind, buildingId?: number): Observable<{ data: Array<models.IPlanViewModel> }> {
    let params = new HttpParams();

    if (kind) {
      params = params.set('kind', kind.toString());
    }

    if (buildingId) {
      params = params.set('buildingId', buildingId.toString());
    }

    return this._httpClient
      .get<{ data: Array<models.IPlanViewModel> }>(
        `${environment.adminUrl}/plan`,
        {
          params,
        },
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Updates plan
   * @method PlanService.updatePlan
   * @param plan Plan view model
   * @returns Updated plan
   */
  updatePlan(plan: models.IPlanViewModel): Observable<models.IPlanViewModel> {
    return this._httpClient
      .put<models.IPlanViewModel>(
        `${environment.adminUrl}/plan/${plan.id}`,
        plan,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Uploads plan picture(s)
   * @method PlanService.uploadPlanPictures
   * @param file File
   * @returns Uploaded plan picture(s)
   */
  uploadPlanPictures(file: File): Observable<Array<models.IFileViewModel>> {
    const formData = new FormData();

    formData.append('file', file);

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this._httpClient
      .post<Array<models.IFileViewModel>>(
        `${environment.adminUrl}/plan/picture/upload`,
        formData,
        {
          headers,
        },
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Deletes plan picture
   * @method PlanService.deletePlanPicture
   * @param fileId Picture file id
   * @returns void
   */
  deletePlanPicture(fileId: number): Observable<void> {
    return this._httpClient
      .delete<void>(
        `${environment.adminUrl}/plan/picture/${fileId}`,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
