import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import DevExpress from 'devextreme';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { PopupService } from '../../../infrastructure/services/popup.service';

import { environment } from '../../../../../environments/environment';

import { EditGlobalFeatureDialogComponent } from '../edit-global-feature-dialog/edit-global-feature-dialog.component';

@Component({
  templateUrl: 'global-feature-list.component.html',
  styleUrls: ['global-feature-list.component.scss'],
})
export class GlobalFeatureListComponent implements OnInit {
  private static readonly _companyFeaturesListUrl = `${environment.adminUrl}/feature-toggle/global`;

  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: DevExpress.data.CustomStore;

  private readonly _popupService: PopupService;

  constructor(popupService: PopupService) {
    this._popupService = popupService;

    this.edit = this.edit.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: GlobalFeatureListComponent._companyFeaturesListUrl,
      deleteUrl: GlobalFeatureListComponent._companyFeaturesListUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });
  }

  edit(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._popupService.show(EditGlobalFeatureDialogComponent, {
      title: `Edit global feature flags`,
      width: 750,
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      injectableData: {
        globalFeature: event.row.data,
        reloadList: () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            dataSource.reload();
          }
        },
      },
    });
  }
}
