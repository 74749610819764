import { Component, OnInit } from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services';
import {IProject, ILease, IProjectTemplateItem} from 'src/app/shared/models/generated';
import { Tools } from 'src/app/shared/tools';
import * as vm from '../../shared/models/generated';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  dataSource: any;
  leases: any;
  url = `${environment.adminUrl}/project`;
  projectTypes = this.authService.startupInfo.lists.projectTypes;
  renewalProjectTemplateItemsDataSource: any;
  projectTemplateItems: IProjectTemplateItem[];
  ProjectStatuses = Tools.EnumToArray(vm.ProjectStatus);
  ProjectRenewalRejectionStatuses = Tools.EnumToArray(vm.ProjectRenewalRejectionStatus);
  oneDayMs = 86400000;
  get _window() {
    return window;
  }
  currentDetailsProject = {} as IProject;
  tools = Tools;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.renewalProjectTemplateItemsDataSource = this.createDataSource('id', `${environment.adminUrl}/ProjectTemplateItem`);
    this.renewalProjectTemplateItemsDataSource.load().then((data) => {
      this.projectTemplateItems = data;
    });
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });

    this.dataSource.on('loaded', (data: IProject[]) => {
      for (let i in data) {
        for (let j in data[i].milestones) {
          try {
            var milestone: any = data[i].milestones[j];
            milestone.startDate = new Date(milestone.startDate);
            milestone.endDate = new Date(milestone.endDate);
            milestone.start = milestone.startDate.getTime();
            milestone.end = milestone.endDate.getTime();
            milestone.duration = milestone.endDate.getTime() - milestone.startDate.getTime() + this.oneDayMs;
            milestone.projectTemplateItems = this.projectTemplateItems;

          } catch (ex) {
            console.log('Error when date conversion', milestone);
          }
        }
      }
    });

    this.leases = this.createDataSource('id', `${environment.adminUrl}/leases/shortLeaseModels/`);
  }

  createDataSource(key: string, loadUrl: string) {
    return AspNetData.createStore({
      key: key,
      loadUrl: loadUrl,
      onBeforeSend: function(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });
  }

  onRowExpanding(e) {
    this.currentDetailsProject = e.component
      .getDataSource()
      .items()
      .find(function(x) {
        return x.id == e.key;
      });
  }

  chartInitialized(e) {
    let chart = e.component.instance();
    let items = this.currentDetailsProject.milestones;
    if (items && items.length) {
      chart.option({
        valueAxis: [
          {
            visualRange: [
              Math.min(...items.map(x => (x as any).start)),
              Math.max(...items.map(x => (x as any).end))
            ],
            label: {
              customizeText: item => new Date(parseInt(item.value)).toDateString()
            }
          }
        ],
        width: '100%'
      });
    }
  }

  getDisplayExpression(item) {
    return item && item.firstName + ' ' + item.lastName;
  }

  onEditorPreparing(e) {
  }

  onRowPrepared(e): void {
    if (e.rowType === 'data' && e.data && e.data.deletedOn && e.rowElement) {
      e.rowElement.classList.add('is-deleted');
    }
  }

  customizeTooltip = e => {
    let item = e.point.data;
    return {
      text:
        e.seriesName === 'gantt' ? `${item.startDate.toDateString()} - ${item.endDate.toDateString()}` : ''
    };
  };

  customizePoint(e) {
    let color = 'transparent';
    if (e.seriesName === 'gantt') {
      let currentProjectTemplateItem = e.data.projectTemplateItems.find(x => x.id === e.data.templateItemId);

      if(currentProjectTemplateItem.parentProjectTemplateItemId){
        currentProjectTemplateItem = e.data.projectTemplateItems.find(x => x.id === currentProjectTemplateItem.parentProjectTemplateItemId);
      }

      color = currentProjectTemplateItem.id === e.series.tag ? 'red' : 'green';
    }
    return { color: color };
  }

  isEditButtonVisible(e): boolean {
    return e && e.row && e.row.data && !e.row.data.deletedOn;
  }
}
