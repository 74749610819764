<div class="plan-viewer-marker-popover-container"
     [class.dynamic-width]="injectableData.marker.buildingUnit && !getImages()"
     *ngIf="injectableData?.marker"
     [@popoverAnimation] #popoverContainerElementRef>
  <div class="plan-viewer-marker-popover">
    <div class="plan-viewer-marker-popover-image-viewer"
         *ngIf="getImages() as images; else noImage"
         #imageViewerContainerElement>
      <app-image-viewer [images]="images" [container]="imageViewerContainerElement" [isPreviewMode]="true"
                        [minHeight]="200" [enableArrowNavigation]="true"
                        (zoomClicked)="handleZoomClick($event)">
      </app-image-viewer>
    </div>
    <ng-template #noImage>
      <div class="plan-viewer-marker-popover-image-viewer no-image"
           *ngIf="!injectableData.marker.buildingUnit && !injectableData.marker.buildingUnitId">
        <img src="/assets/img/nofoto.png" alt="" />
      </div>
    </ng-template>
    <div class="plan-viewer-marker-popover-body">
      <h1>
        {{injectableData?.marker?.title}}
      </h1>
      <div class="plan-viewer-marker-popover-badges"
           *ngIf="injectableData?.marker?.isOwnSpace ||
                  injectableData?.marker?.isAvailableSpace">
        <div class="plan-viewer-marker-popover-badge"
             *ngIf="injectableData?.marker?.isOwnSpace">
          Current Space
        </div>
        <div class="plan-viewer-marker-popover-badge"
             *ngIf="injectableData?.marker?.isAvailableSpace">
          Available Space
        </div>
      </div>
      <ng-container *ngIf="!injectableData?.marker?.buildingUnitId &&
                           !injectableData?.marker?.buildingUnit; else buildingUnitDescriptionTemplate">
        <p class="plan-viewer-marker-popover-description"
           *ngIf="injectableData?.marker?.description">
          {{injectableData?.marker?.description}}
        </p>
      </ng-container>
      <ng-template #buildingUnitDescriptionTemplate>
        <p class="plan-viewer-marker-popover-description"
           *ngIf="injectableData?.marker?.buildingUnit && injectableData?.marker?.buildingUnit.description">
          {{injectableData?.marker?.buildingUnit.description}}
        </p>
      </ng-template>
    </div>
  </div>
</div>
