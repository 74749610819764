<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">

    <!-- Start: Features -->
    <div class="dx-fieldset">
      <div class="dx-fieldset-header mb-1">
        Features
      </div>

      <table class="table">
        <thead>
        <tr>
          <th>Flag</th>
          <th>Feature</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let feature of features; let index = index">
          <td>
            <dx-check-box [name]="'flag-' + index"
                          [(ngModel)]="globalFeature[feature.model]">
            </dx-check-box>
          </td>
          <td class="text-nowrap">{{feature.name}}</td>
          <td>{{feature.description}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- End: Features -->

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" text="Update"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="cancel()">
          </dx-button>
        </div>
      </div>
    </div>

  </form>
</dx-scroll-view>
