import { Component, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { delay, filter, repeatWhen, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';

import * as ng from '@angular/core';

import * as models from '../../../../shared/models/generated';

import { DocumentViewerOptions } from '../../models/document-viewer-options.model';

import { LeaseAbstractService } from '../../services/lease-abstract.service';
import { LeaseAbstractStore } from '../../services/lease-abstract.store';
import { ImportService } from '../../../import/services/import.service';

interface RenderedPDFPageView {
  index: number;
  pageWidth: number;
  pageHeight: number;
  pageScale: number;
  elementRef: HTMLElement;
}

interface ElementRect {
  width: number;
  height: number;
  left: number;
  top: number;
}

interface DocumentPage extends models.IPage {
  pageContents: Array<DocumentPageContent>;
}

interface DocumentPageContent extends models.IPageContent {
  highlight: boolean;
  extractionVariant: string;
  hashCode: number;
}

enum TextExtractionVariant {
  commencement = 'Commencement date',
  expiration = 'Expiration date',
  length = 'Lease term',
  operatingExpenses = 'Operating expenses',
  realEstateTaxes = 'Real estate taxes',
  squareFootage = 'Square footage',
  securityDeposit = 'Security deposit',
  baseRentalRate = 'Base rental rate',
  freeRent = 'Free rent',
  buildingPercentage = 'Building percentage',
  rentalRateEscalation = 'Rental rate escalation'
}

@Component({
  selector: 'app-import-document-viewer',
  templateUrl: 'import-document-viewer.component.html',
  styleUrls: ['import-document-viewer.component.scss'],
})
export class ImportDocumentViewerComponent implements ng.OnInit, ng.OnDestroy, ng.AfterViewInit {
  private static readonly _PPI: number = 96;
  private static readonly _importTaskCheckDelayMs: number = 10_000; // 10 seconds

  @Input() document: models.IFileViewModel;
  @Output() documentChange: ng.EventEmitter<models.IFileViewModel>;

  @Input() leaseAbstractImportDraftId: number;
  @Input() importTaskId: number;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  @ViewChild(PdfViewerComponent, {static: false}) pdfViewerComponent: PdfViewerComponent;
  @ViewChild('documentViewerContainerElementRef', {static: false}) documentViewerContainerElementRef: ng.ElementRef;

  isDocumentLoading$: BehaviorSubject<boolean>;
  isDocumentUploading$: BehaviorSubject<boolean>;
  isTextProcessing$: BehaviorSubject<boolean>;

  private _canvas: HTMLCanvasElement;
  private _canvasContext: CanvasRenderingContext2D;

  private _renderedPdfPageViews$: BehaviorSubject<Array<RenderedPDFPageView>>;
  private _analyzedPages$: BehaviorSubject<Array<DocumentPage>>;
  private _currentPdfPageIndex$: BehaviorSubject<number>;

  private readonly _leaseAbstractService: LeaseAbstractService;
  private readonly _leaseAbstractStore: LeaseAbstractStore;
  private readonly _importService: ImportService;
  private readonly _renderer: ng.Renderer2;
  private readonly _destroy$: Subject<void>;

  private readonly _wheelHandler: (event: WheelEvent) => void;

  constructor(
    leaseAbstractService: LeaseAbstractService,
    leaseAbstractStore: LeaseAbstractStore,
    importService: ImportService,
    renderer: ng.Renderer2,
  ) {
    this.documentChange = new ng.EventEmitter<models.IFileViewModel>();

    this._leaseAbstractService = leaseAbstractService;
    this._leaseAbstractStore = leaseAbstractStore;
    this._importService = importService;
    this._renderer = renderer;

    this._renderedPdfPageViews$ = new BehaviorSubject<Array<RenderedPDFPageView>>([]);
    this._analyzedPages$ = new BehaviorSubject<Array<DocumentPage>>([]);
    this._currentPdfPageIndex$ = new BehaviorSubject<number>(1);

    this._destroy$ = new Subject<void>();

    this._wheelHandler = event => event.stopImmediatePropagation();
  }

  ngOnInit(): void {
    this.isDocumentLoading$ = new BehaviorSubject<boolean>(Boolean(this.document && this.document.url));
    this.isDocumentUploading$ = new BehaviorSubject<boolean>(false);
    this.isTextProcessing$ = new BehaviorSubject<boolean>(false);

    this._canvas = this._renderer.createElement('canvas');
    this._canvasContext = this._canvas.getContext('2d');

    this._currentPdfPageIndex$
      .pipe(
        switchMap(currentIndex => combineLatest([this._renderedPdfPageViews$, this._analyzedPages$])
          .pipe(
            filter(([renderedPdfPageViews, analyzedPages]) => {
              return Boolean(renderedPdfPageViews?.length && analyzedPages?.length);
            }),
            tap(([renderedPdfPageViews, analyzedPages]) => {
              const currentRenderedPdfPageView = renderedPdfPageViews.find(x => x.index === currentIndex);
              const currentAnalyzedPage = analyzedPages.find(x => x.number === currentIndex);

              if (!currentRenderedPdfPageView || !currentAnalyzedPage) {
                return;
              }

              this._renderCustomTextLayer(currentRenderedPdfPageView, currentAnalyzedPage);
            }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.documentViewerOptionsChange
      .pipe(
        tap((options) => {
          if (options.hashCode) {
            setTimeout(
              () => {
                const documentViewerContainer = this.documentViewerContainerElementRef?.nativeElement;
                if (!documentViewerContainer) {
                  return;
                }

                const highlightedElement = documentViewerContainer.querySelector(`#highlight-${options.hashCode}`);
                if (!highlightedElement) {
                  return;
                }

                this._renderer.addClass(highlightedElement, 'highlighted');

                setTimeout(
                  () => {
                    this._renderer.removeClass(highlightedElement, 'highlighted');
                    this._updateDocumentViewerOptions({ hashCode: null });
                  },
                  2000
                );
              },
              100
            );
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._startDocumentReading();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    if (this.documentViewerContainerElementRef && this.documentViewerContainerElementRef.nativeElement) {
      const {nativeElement} = this.documentViewerContainerElementRef;
      nativeElement.removeEventListener('wheel', this._wheelHandler, true);
    }
  }

  ngAfterViewInit(): void {
    if (this.documentViewerContainerElementRef && this.documentViewerContainerElementRef.nativeElement) {
      const {nativeElement} = this.documentViewerContainerElementRef;
      nativeElement.addEventListener('wheel', this._wheelHandler, true);
    }
  }

  showUploadDocumentDialog(): void {
    const inputElement = this._renderer.createElement('input');
    if (!inputElement) {
      return;
    }

    this._renderer.setAttribute(inputElement, 'type', 'file');
    this._renderer.setAttribute(inputElement, 'accept', '.pdf');

    inputElement.onchange = (event) => {
      if (!event || !inputElement.files || !inputElement.files.length) {
        return;
      }

      const [file] = inputElement.files;
      if (!file) {
        return;
      }

      this.isDocumentUploading$.next(true);

      this._leaseAbstractService
        .uploadNextLeaseAbstractDocument(this.documentViewerOptions.draftId, file)
        .pipe(
          take(1),
          tap(leaseAbstractImportDraft => {
            if (!leaseAbstractImportDraft) {
              return;
            }

            this.document = leaseAbstractImportDraft.document;
            this.importTaskId = leaseAbstractImportDraft.importTaskId;

            this._startDocumentReading();

            this._renderedPdfPageViews$.next([]);
            this._analyzedPages$.next([]);
            this._currentPdfPageIndex$.next(1);
          }),
          takeUntil(this._destroy$),
        )
        .subscribe()
        .add(() => this.isDocumentUploading$.next(false));
    };

    inputElement.click();
  }

  handleDocumentLoadComplete(pdfDocumentProxy: PDFDocumentProxy): void {
    this.isDocumentLoading$.next(false);

    this._updateDocumentViewerOptions({maxPage: pdfDocumentProxy.numPages});
  }

  handleDocumentPageChange(index: number): void {
    this._updateDocumentViewerOptions({page: index});

    this._currentPdfPageIndex$.next(index);
  }

  handleDocumentPageRendered(event): void {
    if (!event || !event.source) {
      return;
    }

    const view = <RenderedPDFPageView>{
      index: event.pageNumber,
      pageWidth: event.source.width,
      pageHeight: event.source.height,
      pageScale: event.source.scale,
      elementRef: event.source.div,
    };

    const previousSet = this._renderedPdfPageViews$.value;

    const pageViewIndex = previousSet.findIndex(x => x.index === event.pageNumber);
    if (0 <= pageViewIndex) {
      previousSet[pageViewIndex] = view;

      this._renderedPdfPageViews$.next([...previousSet]);

      return;
    }

    this._renderedPdfPageViews$.next([
      ...previousSet,
      view,
    ]);
  }

  private _renderCustomTextLayer(renderedPdfPageView: RenderedPDFPageView, currentAnalyzedPage: DocumentPage): void {
    const {elementRef} = renderedPdfPageView;
    if (!elementRef || !currentAnalyzedPage) {
      return;
    }

    this._removeAllCustomTextLayers(elementRef);

    const customTextLayer = this._createCustomTextLayer(renderedPdfPageView.pageWidth, renderedPdfPageView.pageHeight);

    const pageScale = renderedPdfPageView.pageScale;

    for (const pageContent of currentAnalyzedPage.pageContents) {
      const pageContentElement = this._createPageContentElement(pageContent, currentAnalyzedPage, pageScale);

      for (const textLine of pageContent.textLines) {
        const textLineElement = this._createTextLineElement(textLine, pageContent, currentAnalyzedPage, pageScale);

        this._renderer.appendChild(pageContentElement, textLineElement);
      }

      this._renderer.appendChild(customTextLayer, pageContentElement);
    }

    this._renderer.appendChild(elementRef, customTextLayer);
  }

  private _removeAllCustomTextLayers(elementRef: HTMLElement): void {
    const {parentElement} = elementRef;
    if (!parentElement) {
      return;
    }

    const customTextLayers = parentElement.getElementsByClassName('custom-text-layer');
    if (!customTextLayers || !customTextLayers.length) {
      return;
    }

    for (let i = 0, len = customTextLayers.length; i < len; i++) {
      const customTextLayer = customTextLayers[i];

      this._renderer.removeChild(customTextLayer.parentElement, customTextLayer);
    }
  }

  private _createCustomTextLayer(width: number, height: number): HTMLElement {
    const customTextLayer = this._renderer.createElement('div');

    this._renderer.addClass(customTextLayer, 'custom-text-layer');

    this._renderer.setStyle(customTextLayer, 'width', `${Math.round(width)}px`);
    this._renderer.setStyle(customTextLayer, 'height', `${Math.round(height)}px`);

    return customTextLayer;
  }

  private _getElementRect(boundingBox: models.IBoundingBox, unit: string, scale: number): ElementRect {
    const defaultPoint = {x: 0, y: 0};

    const topLeftCoordinate = boundingBox?.points[0] || defaultPoint;
    const topRightCoordinate = boundingBox?.points[1] || defaultPoint;
    const bottomLeftCoordinate = boundingBox?.points[3] || defaultPoint;

    let width = topRightCoordinate.x - topLeftCoordinate.x;
    let height = bottomLeftCoordinate.y - topLeftCoordinate.y;
    let top = topLeftCoordinate.y;
    let left = topLeftCoordinate.x;

    if (unit === 'inch') {
      width = this._convertInchesToPixels(width);
      height = this._convertInchesToPixels(height);
      top = this._convertInchesToPixels(top);
      left = this._convertInchesToPixels(left);
    }

    return <ElementRect>{
      width: width * scale,
      height: height * scale,
      top: top * scale,
      left: left * scale,
    };
  }

  private _createPageContentElement(
    pageContent: DocumentPageContent,
    currentAnalyzedPage: DocumentPage,
    pageScale: number
  ): HTMLElement {
    const pageContentElement: HTMLElement = this._renderer.createElement('span');

    pageContentElement.classList.add('page-content');

    if (pageContent.highlight && pageContent.extractionVariant) {
      pageContentElement.classList.add(`highlight`);
      pageContentElement.classList.add(pageContent.extractionVariant);

      pageContentElement.id = `highlight-${pageContent.hashCode}`;
    }

    const elementRect = this._getElementRect(pageContent.boundingBox, currentAnalyzedPage.unit, pageScale);

    this._renderer.setStyle(pageContentElement, 'width', `${elementRect.width}px`);
    this._renderer.setStyle(pageContentElement, 'height', `${elementRect.height}px`);
    this._renderer.setStyle(pageContentElement, 'top', `${elementRect.top}px`);
    this._renderer.setStyle(pageContentElement, 'left', `${elementRect.left}px`);
    this._renderer.setStyle(pageContentElement, 'transform', `rotate(${currentAnalyzedPage.angle}deg)`);

    let title = models.PageContentKind[pageContent.kind];
    if (pageContent.extractionVariant) {
      title = `${TextExtractionVariant[pageContent.extractionVariant]} • ${title}`;
    }

    this._renderer.setProperty(pageContentElement, 'title', title);

    if (pageContent.hasOwnProperty('text')) {
      pageContentElement.addEventListener('dblclick', event => {
        if (!navigator || !navigator.clipboard || typeof navigator.clipboard.writeText !== 'function') {
          return;
        }

        event.preventDefault();

        if (typeof document.createRange === 'function' && typeof window.getSelection === 'function') {
          const range = document.createRange();
          const selection = window.getSelection();

          range.setStart(pageContentElement.firstChild, 0);
          range.setEnd(pageContentElement.lastChild, 1);

          selection.removeAllRanges();
          selection.addRange(range);
        }

        navigator.clipboard.writeText((<any>pageContent).text);
      });
    }

    return pageContentElement;
  }

  private _createTextLineElement(
    textLine: models.ITextLine,
    pageContent: DocumentPageContent,
    currentAnalyzedPage: DocumentPage,
    pageScale: number
  ): HTMLElement {
    const textLineElement = this._renderer.createElement('span');

    textLineElement.classList.add('text-line');

    const elementRect = this._getElementRect(textLine.boundingBox, currentAnalyzedPage.unit, pageScale);
    const pageContentElementRect = this._getElementRect(pageContent.boundingBox, currentAnalyzedPage.unit, pageScale);

    this._renderer.setStyle(textLineElement, 'width', `${elementRect.width}px`);
    this._renderer.setStyle(textLineElement, 'height', `${elementRect.height}px`);
    this._renderer.setStyle(textLineElement, 'top', `${elementRect.top - pageContentElementRect.top}px`);
    this._renderer.setStyle(textLineElement, 'left', `${elementRect.left - pageContentElementRect.left}px`);

    if (!textLine.hasOwnProperty('text')) {
      return textLineElement;
    }

    const text = textLine.text;

    const fontSizeInch = (elementRect.height / pageScale) / ImportDocumentViewerComponent._PPI;
    const textWidth = this._getTextWidth(fontSizeInch, text);

    const scaledFontSizeInch = fontSizeInch * (elementRect.width / textWidth);

    this._renderer.setStyle(textLineElement, 'font-size', `${scaledFontSizeInch}in`);
    this._renderer.setStyle(textLineElement, 'line-height', `${elementRect.height}px`);

    this._renderer.setProperty(textLineElement, 'textContent', `${text}\u00A0`);

    return textLineElement;
  }

  private _updateDocumentViewerOptions(documentViewerOptions: Partial<DocumentViewerOptions>): void {
    this.documentViewerOptions = {
      ...this.documentViewerOptions,
      ...documentViewerOptions,
    };

    this.documentViewerOptionsChange.next(this.documentViewerOptions);
  }

  private _startDocumentReading(): void {
    if (!this.leaseAbstractImportDraftId || !this.importTaskId) {
      return;
    }

    this.isTextProcessing$.next(true);

    this._importService
      .getImportTaskStatus(this.importTaskId)
      .pipe(
        tap(importTaskStatus => {
          this._leaseAbstractStore.storeImportTaskStatus(importTaskStatus);

          if (importTaskStatus === models.ImportTaskStatus.Running) {
            this._leaseAbstractStore.storeIsInterfaceLocked(true);
          }
        }),
        repeatWhen(importTaskStatusObserver => importTaskStatusObserver
          .pipe(
            delay(ImportDocumentViewerComponent._importTaskCheckDelayMs),
          ),
        ),
        filter(importTaskStatus =>
          importTaskStatus !== models.ImportTaskStatus.NotStarted &&
          importTaskStatus !== models.ImportTaskStatus.Running &&
          importTaskStatus !== models.ImportTaskStatus.Failed
        ),
        take(1),
        tap(() => this._leaseAbstractStore.storeIsInterfaceLocked(true)),
        switchMap(() => this._leaseAbstractService
          .getLeaseAbstractImportDraft(this.leaseAbstractImportDraftId)
          .pipe(
            tap(leaseAbstractImportDraft => this._leaseAbstractStore
              .storeLeaseAbstractImportDraft(leaseAbstractImportDraft)
            ),
          ),
        ),
        switchMap(() => this._leaseAbstractService
          .getLeaseAbstractAnalysis(this.leaseAbstractImportDraftId)
          .pipe(
            tap(analyzeResult => {
              if (!analyzeResult) {
                return;
              }

              const { formRecognitionResult, termExtractionResult } = analyzeResult;

              if (!formRecognitionResult || !formRecognitionResult.pages || !formRecognitionResult.pages.length) {
                return;
              }

              const documentPages = <Array<DocumentPage>>formRecognitionResult.pages;

              const termExtractionVariants = Object.keys(termExtractionResult);
              for (const variant of termExtractionVariants) {
                if (!termExtractionResult.hasOwnProperty(variant)) {
                  continue;
                }

                const extractionResults: Array<models.IExtractionResultSimplifiedView> = termExtractionResult[variant];
                for (const extractionResult of extractionResults) {
                  if (!extractionResult.phrase) {
                    continue;
                  }

                  // Highlight spans
                  if (extractionResult.spans && extractionResult.spans.length) {
                    extractionResult.spans.forEach(span => {
                      const { pageNumber, boundingBox } = span;

                      const documentPage = documentPages.find(x => x.number === pageNumber);
                      if (!documentPage) {
                        return;
                      }

                      const pageContent = documentPage.pageContents?.find(x => x?.boundingBox?.hashCode === boundingBox.hashCode);
                      if (!pageContent) {
                        return;
                      }

                      pageContent.highlight = true;
                      pageContent.extractionVariant = variant;
                      pageContent.hashCode = boundingBox.hashCode;
                    });
                  }
                }
              }

              this._analyzedPages$.next(documentPages);

              this._leaseAbstractStore.storeTermExtractionResult(termExtractionResult);
            }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => {
        this.isTextProcessing$.next(false);
        this._leaseAbstractStore.storeIsInterfaceLocked(false);
      });
  }

  private _getTextWidth(fontSizeInch: number, text: string): number {
    if (!this._canvasContext || !fontSizeInch || !text) {
      return 0;
    }

    this._canvasContext.font = `${fontSizeInch}in sans-serif`;

    const textProperties = this._canvasContext.measureText(text);
    if (!textProperties) {
      return 0;
    }

    return textProperties.width;
  }

  private _convertInchesToPixels(inch: number): number {
    return inch * ImportDocumentViewerComponent._PPI;
  }
}
