<div id="floor-plan-upsert">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form" novalidate
          (ngSubmit)="createPlan()"
          #form="ngForm">
      <!-- Start: Picture upload -->
      <div class="dx-fieldset mt-2 mb-2">
        <div class="dx-fieldset-header">
          Picture
        </div>
        <ng-container *ngIf="!floorPlan.id; else existingPlanPictureTemplate">
          <div class="dx-field"
               [class.dx-field-disabled]="isUploading | async">
            <div class="dx-field-value file-control">
              <div class="file-control-icon">
                <i class="fa fa-paperclip"></i>
              </div>
              <div class="file-control-text">
                <div>
                  Attach
                  <strong>File(s)</strong>
                </div>
                <div class="subscript">
                  .jpg, .jpeg, .png, .heic, .pdf files are allowed
                </div>
              </div>
              <dx-file-uploader name="file" uploadMode="useForm"
                                accept="image/jpg,image/jpeg,image/png,image/heic,application/pdf"
                                [allowedFileExtensions]="['.jpg', '.jpeg', '.png', '.heic', '.pdf']"
                                [multiple]="false" [showFileList]="false"
                                (onValueChanged)="handleUploaderFilesChange($event)">
              </dx-file-uploader>
            </div>
          </div>
          <div>
            <ng-container *ngFor="let picture of uploadedPlanPictures; let index = index;"
                          [ngTemplateOutlet]="planPicturePreviewTemplate"
                          [ngTemplateOutletContext]="{ picture: picture, index: index }">
            </ng-container>
          </div>
          <div class="preloader-container"
               *ngIf="isUploading | async">
            <div class="preloader">
              <span></span>
            </div>
          </div>
        </ng-container>
        <ng-template #existingPlanPictureTemplate>
          <ng-container
            [ngTemplateOutlet]="planPicturePreviewTemplate"
            [ngTemplateOutletContext]="{ picture: floorPlan.picture }">
          </ng-container>
        </ng-template>
        <ng-template #planPicturePreviewTemplate
                     let-picture="picture"
                     let-index="index">
          <div class="d-flex flex-row flex-nowrap mw-100 my-2 align-items-center">
            <div class="mr-2 p-1 preview">
              <img alt="" class="preview-image"
                   [src]="picture.thumbnailUrl" />
              <a role="button" class="preview-zoom"
                 (click)="handleZoomClick(picture)">
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
            <div class="pl-2 mw-0 flex-grow-1">
              <p class="mb-1 small font-weight-bold w-100 text-truncate"
                 [title]="picture.name">
                {{picture.name}}
              </p>
              <p class="mb-0"
                 *ngIf="index || index === 0">
                <a role="button" class="cursor-pointer font-weight-bold small text-danger"
                   [class.disabled]="isUploading | async"
                   (click)="deleteUploadedPlanPicture(index)">
                  Remove
                </a>
              </p>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- End: Picture upload -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Main properties
        </div>
        <!-- Start: Name -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && nameField.invalid">
            Name
          </div>
          <div class="dx-field-value">
            <dx-text-box name="name" required
                         [isValid]="form.submitted && !nameField.disabled ? nameField.valid : true"
                         [(ngModel)]="floorPlan.name" #nameField="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && nameField.hasError('required')">
              Name is required
            </div>
          </div>
        </div>
        <!-- End: Name -->
        <!-- Start: Portfolio lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && portfolioField.invalid">
            Portfolio
          </div>
          <div class="dx-field-value">
            <dx-lookup name="portfolio" required
                       noDataText="No portfolios found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [dataSource]="portfolioDataSource"
                       [isValid]="form.submitted ? portfolioField.valid : true"
                       [searchExpr]="setupSearchablePortfolioFields"
                       [(ngModel)]="portfolioId" #portfolioField="ngModel"
                       (ngModelChange)="handlePortfolioChange($event)">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let portfolio of 'field'">
                <ng-container *ngIf="portfolio">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{portfolio.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!portfolio">
                    <span class="text-muted">
                      Select portfolio
                    </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let portfolio of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{portfolio.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && portfolioField.hasError('required')">
              Portfolio is required
            </div>
          </div>
        </div>
        <!-- End: Portfolio lookup -->
        <!-- Start: Building lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && building.invalid">
            Building
          </div>
          <div class="dx-field-value">
            <dx-lookup name="building" required
                       noDataText="No buildings found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [dataSource]="buildingDataSource"
                       [disabled]="!portfolioId"
                       [isValid]="form.submitted && !building.disabled ? building.valid : true"
                       [searchExpr]="setupSearchableBuildingFields"
                       [(ngModel)]="floorPlan.buildingId" #building="ngModel"
                       (ngModelChange)="handleBuildingChange($event)">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let buildingProfile of 'field'">
                <ng-container *ngIf="buildingProfile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img width="20" height="20"
                           [src]="Tools.buildingPictureThumbnail(buildingProfile) || Tools.noImageUrl"
                           [alt]="buildingProfile.name" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{buildingProfile.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!buildingProfile">
                    <span class="text-muted">
                      Select building
                    </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let buildingProfile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="40" height="40"
                         [src]="Tools.buildingPictureThumbnail(buildingProfile) || Tools.noImageUrl"
                         [alt]="buildingProfile.name" />
                  </div>
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{buildingProfile.name}}
                      </p>
                      <p class="mb-0 small w-100 text-truncate"
                         *ngIf="buildingProfile.address && buildingProfile.address.displayString">
                        {{buildingProfile.address.displayString}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && building.hasError('required')">
              Building is required
            </div>
          </div>
        </div>
        <!-- End: Building lookup -->
        <!-- Start: Building unit lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && buildingUnit.invalid">
            Building Unit
          </div>
          <div class="dx-field-value">
            <dx-lookup name="buildingUnit" required
                       noDataText="No building units found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [dataSource]="buildingUnitDataSource"
                       [disabled]="!floorPlan.buildingId"
                       [isValid]="form.submitted && !buildingUnit.disabled ? buildingUnit.valid : true"
                       [searchExpr]="setupSearchableBuildingUnitFields"
                       [(ngModel)]="floorPlan.buildingUnitId" #buildingUnit="ngModel">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let buildingUnitProfile of 'field'">
                <ng-container *ngIf="buildingUnitProfile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img width="20" height="20"
                           [src]="Tools.buildingUnitPictureThumbnail(buildingUnitProfile) || Tools.noImageUrl"
                           [alt]="buildingUnitProfile.name" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{buildingUnitProfile.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!buildingUnitProfile">
                    <span class="text-muted">
                      Select building unit
                    </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let buildingUnitProfile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="40" height="40"
                         [src]="Tools.buildingUnitPictureThumbnail(buildingUnitProfile) || Tools.noImageUrl"
                         [alt]="buildingUnitProfile.name" />
                  </div>
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{buildingUnitProfile.name}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && buildingUnit.hasError('required')">
              Building unit is required
            </div>
          </div>
        </div>
        <!-- End: Building unit lookup -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row mx-n1">
          <div class="px-1">
            <dx-button type="success"
                       [text]="floorPlan.id ?
                          'Save' :
                          uploadedPlanPictures.length | i18nPlural: ({'=0': 'Create', '=1': 'Create', 'other': 'Create # Floor Plans'})"
                       [disabled]="isUploading | async"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-1">
            <dx-button type="normal" text="Cancel"
                       [disabled]="isUploading | async"
                       (click)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>

<dx-load-panel [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false"
               [position]="{of: '#floor-plan-upsert'}" [visible]="isLoading | async">
</dx-load-panel>
