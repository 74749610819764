import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  private readonly _sanitizer: DomSanitizer;

  constructor(sanitizer: DomSanitizer) {
    this._sanitizer = sanitizer;
  }

  transform(value: string): SafeHtml {
    console.log(value);
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
