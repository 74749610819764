import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../../environments/environment';

import * as models from '../../../shared/models/generated';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PlanAnchorService {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  /**
   * Creates plan anchor
   * @method PlanAnchorService.createPlanAnchor
   * @param planAnchor Plan anchor view model
   * @returns Created plan anchor
   */
  createPlanAnchor(planAnchor: models.IPlanAnchorViewModel): Observable<models.IPlanAnchorViewModel> {
    return this._httpClient
      .post<models.IPlanAnchorViewModel>(
        `${environment.adminUrl}/plananchor`,
        planAnchor,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Updates plan anchor
   * @method PlanAnchorService.updatePlanAnchor
   * @param planAnchor Plan anchor view model
   * @returns Updated plan anchor
   */
  updatePlanAnchor(planAnchor: models.IPlanAnchorViewModel): Observable<models.IPlanAnchorViewModel> {
    return this._httpClient
      .put<models.IPlanAnchorViewModel>(
        `${environment.adminUrl}/plananchor/${planAnchor.id}`,
        planAnchor,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Deletes plan anchor
   * @method PlanAnchorService.deletePlanAnchor
   * @param planAnchor Plan anchor view model
   * @returns void
   */
  deletePlanAnchor(planAnchor: models.IPlanAnchorViewModel): Observable<void> {
    return this._httpClient
      .delete<void>(
        `${environment.adminUrl}/plananchor/${planAnchor.id}`
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Uploads plan anchor picture
   * @method PlanAnchorService.uploadPlanAnchorPicture
   * @param picture Picture file
   * @returns Uploaded plan anchor picture
   */
  uploadPlanAnchorPicture(picture: File): Observable<models.IFileViewModel> {
    const formData = new FormData();

    formData.append('file', picture);

    return this._httpClient
      .post<models.IFileViewModel>(
        `${environment.adminUrl}/plananchor/upload-anchor-image`,
        formData,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
