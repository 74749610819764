<div class="plan-viewer">
  <ng-container *ngIf="(getImageRef$(image) | async) as imageRef; else isLoading">
    <div class="plan-viewer-editor"
         *ngIf="allowChangeMarkers">
      <app-plan-viewer-editor [mode]="mode"
                              [isDragEnabled]="isDragEnabled"
                              [isDrawCircleEnabled]="isDrawCircleEnabled"
                              [isDrawRectEnabled]="isDrawRectEnabled"
                              [isDrawCrossEnabled]="isDrawCrossEnabled"
                              [isDrawPolygonEnabled]="isDrawPolygonEnabled"
                              [rotationAngle]="imageRef.angle"
                              (isDragEnabledChange)="handleDragEnabledChange($event)"
                              (isDrawCircleEnabledChange)="handleDrawCircleEnabledChange($event)"
                              (isDrawRectEnabledChange)="handleDrawRectEnabledChange($event)"
                              (isDrawCrossEnabledChange)="handleDrawCrossEnabledChange($event)"
                              (isDrawPolygonEnabledChange)="handleDrawPolygonEnabledChange($event)"
                              (rotationAngleChange)="handleRotationAngleChange($event)">
      </app-plan-viewer-editor>
    </div>
  </ng-container>
  <div class="plan-viewer-konva"
       [class.d-none]="!(getImageRef$(image) | async)"
       #planViewerKonvaContainerElementRef>
  </div>

  <ng-template #isLoading>
    <div class="plan-viewer-preloader">
      <span></span>
    </div>
  </ng-template>
</div>
