import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-import-form-term-preview',
  templateUrl: 'import-form-term-preview.component.html',
  styleUrls: ['import-form-term-preview.component.scss'],
})
export class ImportFormTermPreviewComponent {
  @Input() leaseTermText: string;
  @Input() documentSection: string;
  @Input() documentSections: Array<string>;
}
