import { StaticProvider } from '@angular/core';

import { PopupService } from '../services/popup.service';

export class PopupOptions {
  id?: number;
  title?: string;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  showCloseButton?: boolean;
  closeOnOutsideClick?: boolean;
  fullScreen?: boolean;
  dragEnabled?: boolean;
  containerClassNames?: Array<string>;

  providers?: Array<StaticProvider>;
  popupService?: PopupService;

  injectableData?: any;
}
