import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { ImportMappingComponent } from './components/import-mapping/import-mapping.component';
import { ImportComponent } from './components/import/import.component';
import { BuildingListComponent } from './components/list/list.component';

import { BuildingService } from './services/building.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxAccordionModule,
    dx.DxButtonModule,
    dx.DxDataGridModule,
    dx.DxFileUploaderModule,
    dx.DxLoadIndicatorModule,
    dx.DxLoadPanelModule,
    dx.DxLookupModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
    dx.DxTabsModule,
    dx.DxTextBoxModule,
    dx.DxFormModule,
  ],
  declarations: [
    ImportComponent,
    ImportMappingComponent,
    BuildingListComponent,
  ],
  providers: [
    BuildingService,
  ],
  entryComponents: [
    ImportComponent,
    ImportMappingComponent,
  ],
})
export class BuildingModule {
  static forRoot(): ModuleWithProviders<BuildingModule> {
    return {
      ngModule: BuildingModule,
      providers: [
        BuildingService,
      ],
    };
  }
}
