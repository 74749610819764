import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import CustomStore from 'devextreme/data/custom_store';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { UploadComponent } from '../upload/upload.component';

import { LeaseTemplateService } from '../../services/lease-template.service';
import { CompanyService } from '../../../company/services/company.service';
import { PopupService } from '../../../infrastructure/services/popup.service';

import { Tools } from '../../../../shared/tools';

import * as models from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

@Component({
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class ListComponent implements OnInit {
  private static readonly _listUrl = `${environment.adminUrl}/lease-templates`;

  @ViewChild(DxDataGridComponent, {static: false}) dxDataGridComponent: DxDataGridComponent;

  readonly Tools: typeof Tools = Tools;

  dataSource: CustomStore;

  landlordCompanies: Observable<Array<models.ICompanyViewModel>>;

  private readonly _leaseTemplateService: LeaseTemplateService;
  private readonly _companyService: CompanyService;
  private readonly _popupService: PopupService;

  constructor(
    leaseTemplateService: LeaseTemplateService,
    companyService: CompanyService,
    popupService: PopupService,
  ) {
    this._leaseTemplateService = leaseTemplateService;
    this._companyService = companyService;
    this._popupService = popupService;

    this.landlordCompanies = this._companyService
      .getCompaniesList(models.CompanyType.LandlordCompany)
      .pipe(
        map(x => x.data)
      );
  }

  ngOnInit(): void {
    this.dataSource = AspNetData.createStore({
      loadUrl: ListComponent._listUrl,
    });
  }

  getCompanyName(companies: Array<models.ICompanyViewModel>, id: number): string {
    const company = companies.find(x => x.id === id);
    if (!company) {
      return `Company #${id}`;
    }

    return company.name;
  }

  prepareToolbar(event: {[key: string]: any}): void {
    if (event.toolbarOptions) {
      event.toolbarOptions.items = [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'leaseDocumentMarkingTool',
          options: {
            icon: 'fa fa-upload',
            disabled: false,
            text: 'Import abstract lease',
            onClick: () => this.openDocumentUploadPopup(),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        ...event.toolbarOptions.items,
      ];
    }
  }

  openDocumentUploadPopup(): void {
    this._popupService.show(UploadComponent, {
      title: 'Upload template',
      width: 750,
      maxHeight: '90%',
      showCloseButton: false,
      closeOnOutsideClick: false,
      injectableData: {
        reloadLeases: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      },
    });
  }
}
