import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import * as dx from 'devextreme-angular';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { ImportComponent } from './components/import/import.component';
import { ImportDocumentViewerComponent } from './components/import-document-viewer/import-document-viewer.component';
import { ImportDocumentViewerOptionsComponent } from './components/import-document-viewer-options/import-document-viewer-options.component';
import { ImportDocumentViewerOptionsEditDialogComponent } from './components/import-document-viewer-options-edit-dialog/import-document-viewer-options-edit-dialog.component';
import { ImportFormComponent } from './components/import-form/import-form.component';
import { ImportFormBuildingConditionsComponent } from './components/import-form-building-conditions/import-form-building-conditions.component';
import { ImportFormMainTermsComponent } from './components/import-form-main-terms/import-form-main-terms.component';
import { ImportFormLeaseOptionsComponent } from './components/import-form-lease-options/import-form-lease-options.component';
import { ImportFormOtherLeaseRightsComponent } from './components/import-form-other-lease-rights/import-form-other-lease-rights.component';
import { ImportFormTermExtractionResultComponent } from './components/import-form-term-extraction-result/import-form-term-extraction-result.component';
import { ImportFormTermPreviewComponent } from './components/import-form-term-preview/import-form-term-preview.component';
import { UploadComponent } from './components/upload/upload.component';

import { LeaseAbstractService } from './services/lease-abstract.service';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PdfViewerModule,

    InfrastructureModule,

    dx.DxAutocompleteModule,
    dx.DxButtonModule,
    dx.DxButtonGroupModule,
    dx.DxDateBoxModule,
    dx.DxLoadPanelModule,
    dx.DxLookupModule,
    dx.DxNumberBoxModule,
    dx.DxPopupModule,
    dx.DxTabsModule,
    dx.DxTemplateModule,
    dx.DxTextAreaModule,
    dx.DxTextBoxModule,
    dx.DxValidatorModule,
    dx.DxValidationSummaryModule,
    dx.DxScrollViewModule,
    dx.DxFileUploaderModule,
    dx.DxSelectBoxModule,
    dx.DxCheckBoxModule,
    dx.DxTagBoxModule,
    SharedModule,
  ],
  declarations: [
    ImportComponent,
    ImportDocumentViewerComponent,
    ImportDocumentViewerOptionsComponent,
    ImportDocumentViewerOptionsEditDialogComponent,
    ImportFormComponent,
    ImportFormBuildingConditionsComponent,
    ImportFormMainTermsComponent,
    ImportFormLeaseOptionsComponent,
    ImportFormOtherLeaseRightsComponent,
    ImportFormTermExtractionResultComponent,
    ImportFormTermPreviewComponent,
    UploadComponent,
  ],
  exports: [
    ImportComponent,
    UploadComponent,
  ],
  providers: [
    LeaseAbstractService,
  ],
  entryComponents: [
    ImportComponent,
    ImportFormBuildingConditionsComponent,
    ImportFormMainTermsComponent,
    ImportFormLeaseOptionsComponent,
    ImportDocumentViewerOptionsEditDialogComponent,
    ImportFormOtherLeaseRightsComponent,
    UploadComponent,
  ],
})
export class LeaseAbstractModule {
  static forRoot(): ModuleWithProviders<LeaseAbstractModule> {
    return {
      ngModule: LeaseAbstractModule,
      providers: [
        LeaseAbstractService,
      ],
    };
  }
}
