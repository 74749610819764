import { Component, OnInit, ViewChild } from '@angular/core';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { DxFileUploaderComponent } from 'devextreme-angular';
import DevExpress from 'devextreme';
import urlParser from 'js-video-url-parser';

import { environment } from 'src/environments/environment';

import { Tools } from 'src/app/shared/tools';

import * as models from 'src/app/shared/models/generated';

@Component({
  selector: 'app-building-attachment-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class BuildingAttachmentListComponent implements OnInit {
  private static EmptyAttachment = <models.IBuildingAttachment>{
    id: 0,
    file: {}
  };

  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;

  @ViewChild('portfolioControl', {read: DxiItemComponent, static: false}) portfolioControl: DxiItemComponent;
  @ViewChild('buildingControl', {read: DxiItemComponent, static: false}) buildingControl: DxiItemComponent;
  @ViewChild('buildingUnitControl', {read: DxiItemComponent, static: false}) buildingUnitControl: DxiItemComponent;

  uploaderTemplate: DevExpress.core.template;
  popupVisible: boolean;
  fileEditor: any;

  currentAttachment: models.IBuildingAttachment;
  companyId: number;
  portfolioId: number;
  buildingId: number;
  buildingUnitId: number;
  BuildingAttachmentType = models.BuildingAttachmentType;

  dataSource: DevExpress.data.CustomStore;
  companyDataSource: DevExpress.data.CustomStore;
  portfolioDataSource: DevExpress.data.CustomStore;
  buildingDataSource: DevExpress.data.CustomStore;
  buildingUnitDataSource: DevExpress.data.CustomStore;

  companyControlOptions: any;
  portfolioControlOptions: any;
  buildingControlOptions: any;
  buildingUnitControlOptions: any;
  fileControlOptions: any;

  uploadUrl: string;
  tools: typeof Tools;

  get _window() {
    return window;
  }

  constructor() {
    this.currentAttachment = BuildingAttachmentListComponent.EmptyAttachment;
    this.popupVisible = false;
    this.uploadUrl = `${environment.apiUrl}/storage/Upload`;
    this.tools = Tools;
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/BuildingAttachment`,
      insertUrl: `${environment.adminUrl}/BuildingAttachment`,
      updateUrl: `${environment.adminUrl}/BuildingAttachment`,
      deleteUrl: `${environment.adminUrl}/BuildingAttachment`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
        if (ajaxOptions.data.values) {
          this.prepareSave(ajaxOptions);
        }
      },
    });

    this.companyDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/company?companyType=LandlordCompany&withDeleted=true`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      }
    });

    this.portfolioDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/portfolio?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.companyId) {
          ajaxOptions.data.companyId = this.companyId;
        }
      },
    });

    this.buildingDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/building?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.portfolioId) {
          ajaxOptions.data.id = this.portfolioId;
        }
      },
    });

    this.buildingUnitDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/buildingUnit?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.buildingId) {
          ajaxOptions.data.buildingId = this.buildingId;
        }
      },
    });

    this.fileControlOptions = {
      visible: false,
      onInitialized: e => this.fileEditor = e.component,
    };

    this.companyControlOptions = {
      dataSource: this.companyDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select company',
      valueExpr: 'id',
      value: this.companyId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.companyId = event.value;
        this.portfolioId = null;
        this.buildingId = null;
        this.buildingUnitId = null;
        if (this.portfolioControl && this.portfolioControl.instance) {
          const dataSource = this.portfolioControl.instance.getDataSource();
          dataSource.load();
          this.portfolioControl.disabled = false;
        }
        return event.value;
      },
    };

    this.portfolioControlOptions = {
      dataSource: this.portfolioDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select portfolio',
      valueExpr: 'id',
      value: this.portfolioId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.portfolioId = event.value;
        this.buildingId = null;
        this.buildingUnitId = null;
        if (this.buildingControl && this.buildingControl.instance) {
          const dataSource = this.buildingControl.instance.getDataSource();
          dataSource.load();
          this.buildingControl.disabled = false;
        }
        return event.value;
      },
    };

    this.buildingControlOptions = {
      dataSource: this.buildingDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select building',
      valueExpr: 'id',
      value: this.buildingId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.buildingId = event.value;
        this.buildingUnitId = null;
        if (this.buildingUnitControl && this.buildingUnitControl.instance) {
          const dataSource = this.buildingUnitControl.instance.getDataSource();
          dataSource.load();
          this.buildingUnitControl.disabled = false;
        }
        return event.value;
      },
    };

    this.buildingUnitControlOptions = {
      dataSource: this.buildingUnitDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select building unit',
      valueExpr: 'id',
      value: this.buildingUnitId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.buildingUnitId = event.value;
        return event.value;
      },
    };
  }

  prepareSave(ajaxOptions) {
    if (!ajaxOptions || !ajaxOptions.data || !ajaxOptions.data.values) {
      return;
    }

    const obj = JSON.parse(ajaxOptions.data.values);
    delete obj.picture;
    const values = obj as models.IBuildingAttachment;
    values.fileId = this.currentAttachment.file?.id;
    values.buildingId = this.buildingId;
    if (this.buildingUnitId) {
      values.buildingUnitId = this.buildingUnitId;
    }
    ajaxOptions.data.values = JSON.stringify(values);
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'building.portfolio.companyId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.companyControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'building.portfolio.id') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.portfolioControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'buildingId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.buildingControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'buildingUnitId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.buildingUnitControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'pictureId') {
      e.editorName = 'dxLookup';
    }
  }

  onInitNewRow() {
    this.currentAttachment = BuildingAttachmentListComponent.EmptyAttachment;

    this.companyId = null;
    this.portfolioId = null;
    this.buildingId = null;
    this.buildingUnitId = null;

    if (this.portfolioControl && this.portfolioControl.instance) {
      this.portfolioControl.disabled = true;
    }
    if (this.buildingControl && this.buildingControl.instance) {
      this.buildingControl.disabled = true;
    }
    if (this.buildingUnitControl && this.buildingUnitControl.instance) {
      this.buildingUnitControl.disabled = true;
    }
  }

  onEditingStart(e) {
    const buildingFile = e.data as models.IBuildingAttachment;

    this.currentAttachment = buildingFile ? buildingFile : BuildingAttachmentListComponent.EmptyAttachment;
    this.companyId = buildingFile.building?.portfolio?.companyId;
    this.portfolioId = buildingFile.building?.portfolio?.id;
    this.buildingId = buildingFile.buildingId;
    this.buildingUnitId = buildingFile.buildingUnitId;

    if (this.portfolioControl && this.portfolioControl.instance) {
      this.portfolioControl.disabled = false;
    }
    if (this.buildingControl && this.buildingControl.instance) {
      this.buildingControl.disabled = false;
    }
    if (this.buildingUnitControl && this.buildingUnitControl.instance) {
      this.buildingUnitControl.disabled = false;
    }
  }

  onUploaded(e) {
    if (!e || !e.request || !e.request.responseText || !this.fileUploader || !this.fileUploader.instance) {
      return;
    }

    this.currentAttachment.file = JSON.parse(e.request.responseText);
    this.fileUploader.instance.reset();
    this.fileEditor.option('value', this.currentAttachment.file);
    this.popupVisible = false;
  }

  getExternalVideoId(link) {
    if (link) {
      return urlParser.parse(link)?.id;
    }
  }

  getVimeoLink(link) {
    const id = this.getExternalVideoId(link);
    if (id) {
      return `https://player.vimeo.com/video/${id}`;
    }
  }
}
