import { Component, OnInit } from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { environment } from 'src/environments/environment';

import { Tools } from 'src/app/shared/tools';
import * as models from '../../../../shared/models/generated';

@Component({
  selector: 'app-portfolio-invitation-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class PortfolioInvitationListComponent implements OnInit {
  url = `${environment.adminUrl}/portfolio/invitation`;
  tools = Tools;

  dataSource: any;
  companiesDataSource: any;

  invitationKindDataSource = Tools.EnumToArray(models.PortfolioInvitationKind);

  get _window() {
    return window;
  }

  constructor() {}

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });

    this.companiesDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/company`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });
  }

  onRowPrepared(e): void {
    if (e.rowType === 'data' && e.data && e.data.deletedOn && e.rowElement) {
      e.rowElement.classList.add('is-deleted');
    }
  }

  getUserAvatar(item): string {
    if (!item?.data?.invitedBy?.profile?.avatar) {
      return 'assets/img/avatar.png';
    }

    return item.data.invitedBy.profile.avatar.url;
  }

  getUserDisplayName(item): string {
    if (!item?.data?.invitedBy?.profile) {
      return '';
    }

    const { firstName, lastName } = item.data.invitedBy.profile;

    return [firstName, lastName]
      .filter(Boolean)
      .join(' ');
  }
}
