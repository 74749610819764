import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as models from '../../../shared/models/generated';

import { CompanyService } from '../../company/services/company.service';

@Injectable()
export class LeaseAbstractService {
  private readonly _companyService: CompanyService;
  private readonly _httpClient: HttpClient;

  constructor(companyService: CompanyService, httpClient: HttpClient) {
    this._companyService = companyService;
    this._httpClient = httpClient;
  }

  /**
   * Requests and returns a lease abstract import draft by its id
   * @method LeaseAbstractService.getLeaseAbstractImportDraft
   * @param leaseAbstractImportDraftId Lease abstract import draft id
   * @returns A lease abstract import draft
   */
  getLeaseAbstractImportDraft(leaseAbstractImportDraftId: number): Observable<models.ILeaseAbstractImportDraftViewModel> {
    return this._httpClient
      .get<models.ILeaseAbstractImportDraftViewModel>(`/admin/lease-abstract-import/${leaseAbstractImportDraftId}`)
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns a lease draft preview by its id
   * @method LeaseAbstractService.getLeaseDraftPreview
   * @param leaseAbstractImportDraft Lease abstract import draft
   * @returns A lease model
   */
  getLeaseDraftPreview(leaseAbstractImportDraft: models.ILeaseAbstractImportDraftViewModel): Observable<models.ILeaseViewModel> {
    return this._httpClient
      .post<models.ILeaseViewModel>(`/admin/lease-abstract-import/preview-draft`, leaseAbstractImportDraft)
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Creates a new lease abstract import draft
   * @method LeaseAbstractService.createLeaseAbstractImportDraft
   * @param leaseAbstractImportDraft Lease abstract import draft to create
   * @returns Created lease abstract import draft
   */
  createLeaseAbstractImportDraft(leaseAbstractImportDraft: models.ILeaseAbstractImportDraftViewModel):
    Observable<models.ILeaseAbstractImportDraftViewModel> {
    return this._httpClient
      .post<models.ILeaseAbstractImportDraftViewModel>(`/admin/lease-abstract-import`, leaseAbstractImportDraft)
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Updates a lease abstract import draft
   * @method LeaseAbstractService.updateLeaseAbstractImportDraft
   * @param leaseAbstractImportDraft Lease abstract import draft to update
   * @returns Updated lease abstract import draft from store
   */
  updateLeaseAbstractImportDraft(leaseAbstractImportDraft: models.ILeaseAbstractImportDraftViewModel):
    Observable<models.ILeaseAbstractImportDraftViewModel> {
    return this._httpClient
      .put<models.ILeaseAbstractImportDraftViewModel>(
        `/admin/lease-abstract-import/${leaseAbstractImportDraft.leaseId}`,
        leaseAbstractImportDraft,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Publish a lease abstract import draft
   * @method LeaseAbstractService.publishLeaseAbstractImportDraft
   * @param leaseAbstractImportDraftId Lease abstract import draft id
   * @returns Lease abstract import draft
   */
  publishLeaseAbstractImportDraft(leaseAbstractImportDraftId: number): Observable<models.ILeaseAbstractImportDraftViewModel> {
    return this._httpClient
      .post<models.ILeaseAbstractImportDraftViewModel>(
        `/admin/lease-abstract-import/${leaseAbstractImportDraftId}/publish`,
        null,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Draft a lease abstract import draft
   * @method LeaseAbstractService.draftLeaseAbstractImportDraft
   * @param leaseAbstractImportDraftId Lease abstract import draft id
   * @returns Lease abstract import draft
   */
  draftLeaseAbstractImportDraft(leaseAbstractImportDraftId: number): Observable<models.ILeaseAbstractImportDraftViewModel> {
    return this._httpClient
      .post<models.ILeaseAbstractImportDraftViewModel>(
        `/admin/lease-abstract-import/${leaseAbstractImportDraftId}/draft`,
        null,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Change lease action type
   * @method LeaseAbstractService.changeLeaseActionType
   * @param leaseAbstractImportDraftId Lease abstract import draft id
   * @returns Lease abstract import draft
   */
  changeLeaseActionType(leaseAbstractImportDraftId: number,
                        leaseActionType: models.LeaseActionType): Observable<models.ILeaseAbstractImportDraftViewModel> {
    const params = new HttpParams().set('leaseActionType', leaseActionType.toString());
    return this._httpClient
      .post<models.ILeaseAbstractImportDraftViewModel>(
        `/admin/lease-abstract-import/${leaseAbstractImportDraftId}/action-types`,
        null,
        {
          params
        }
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of landlord companies
   * @method LeaseAbstractService.getLandlordCompaniesList
   * @returns The list of landlord companies
   */
  getLandlordCompaniesList(): Observable<Array<models.ICompanyViewModel>> {
    return this._companyService
      .getCompaniesList(models.CompanyType.LandlordCompany)
      .pipe(
        map(x => x && x.data || []),
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of tenant companies
   * @method LeaseAbstractService.getTenantCompaniesList
   * @returns The list of tenant companies
   */
  getTenantCompaniesList(): Observable<Array<models.ICompanyViewModel>> {
    return this._companyService
      .getCompaniesList(models.CompanyType.TenantCompany)
      .pipe(
        map(x => x && x.data || []),
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of landlord company buildings
   * @method LeaseAbstractService.getLandlordCompanyBuildingsList
   * @param landlordCompanyId Landlord company id
   * @returns The list of landlord company buildings
   */
  getLandlordCompanyBuildingsList(landlordCompanyId?: number): Observable<Array<models.IBuildingViewModel>> {
    let params = new HttpParams();

    if (landlordCompanyId) {
      params = params.set('landlordCompanyId', landlordCompanyId.toString());
    }

    return this._httpClient
      .get<Array<models.IBuildingViewModel>>(`/admin/building/landlord-company-buildings`, {params})
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of buildings
   * @method LeaseAbstractService.getLandlordCompanyBuildingsList
   * @returns The list of buildings
   */
  get3rdPartyBuildingsList(): Observable<Array<models.IBuildingViewModel>> {
    return this._httpClient
      .get<Array<models.IBuildingViewModel>>(`/admin/building/3rd-party-buildings`)
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of abstract leases
   * @method LeaseAbstractService.getAbstractLeasesList
   * @param lease Lease
   * @returns The list of abstract leases
   */
  getAbstractLeasesList(lease: models.ILeaseViewModel): Observable<Array<models.ILeaseViewModel>> {
    let params = new HttpParams();

    if (lease) {
      if (lease.landlordCompanyId) {
        params = params.set('landlordCompanyId', lease.landlordCompanyId.toString());
      }

      if (lease.tenantCompanyId) {
        params = params.set('tenantCompanyId', lease.tenantCompanyId.toString());
      }

      if (lease.buildingId) {
        params = params.set('buildingId', lease.buildingId.toString());
      }

      if (lease.buildingUnitId) {
        params = params.set('buildingUnitId', lease.buildingUnitId.toString());
      }
    }

    return this._httpClient
      .get<{data: Array<models.ILeaseViewModel>}>(`/admin/leases/abstract-leases`, {params})
      .pipe(
        map(x => x && x.data || []),
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Uploads a lease abstract document
   * @method LeaseAbstractService.uploadLeaseAbstractDocument
   * @param leaseAbstractDocument Lease abstract document
   * @returns An uploaded file info
   */
  uploadLeaseAbstractDocument(leaseAbstractDocument: File): Observable<models.IFileViewModel> {
    const body = new FormData();

    body.append('leaseAbstractDocument', leaseAbstractDocument);

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this._httpClient
      .post<models.IFileViewModel>(`/admin/lease-abstract-import/documents`, body, {headers})
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Uploads a lease abstract document and links it to an existing lease abstract import draft
   * @method LeaseAbstractService.uploadNonExistentLeaseAbstractDocument
   * @param leaseAbstractImportDraftId Lease abstract import draft id
   * @param leaseAbstractDocument Lease abstract document
   * @returns An lease abstract import draft model
   */
  uploadNextLeaseAbstractDocument(
    leaseAbstractImportDraftId: number,
    leaseAbstractDocument: File
  ): Observable<models.ILeaseAbstractImportDraftViewModel> {
    const body = new FormData();

    body.append('leaseAbstractDocument', leaseAbstractDocument);

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this._httpClient
      .put<models.ILeaseAbstractImportDraftViewModel>(
        `/admin/lease-abstract-import/${leaseAbstractImportDraftId}/documents`,
        body,
        {headers}
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Copies a lease abstract import draft by its id and returns a new copy
   * @method LeaseAbstractService.copyLeaseAbstractImportDraft
   * @param leaseAbstractImportDraftId Lease abstract import draft id
   * @returns A new copy of lease abstract import draft
   */
  copyLeaseAbstractImportDraft(leaseAbstractImportDraftId: number): Observable<models.ILeaseAbstractImportDraftViewModel> {
    return this._httpClient
      .post<models.ILeaseAbstractImportDraftViewModel>(
        `/admin/lease-abstract-import/${leaseAbstractImportDraftId}/copy`,
        null,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and gets lease abstract import draft analysis result
   * @method LeaseAbstractService.getLeaseAbstractAnalysis
   * @param leaseAbstractImportDraftId Lease abstract import draft id
   * @returns Lease abstract analysis result
   */
  getLeaseAbstractAnalysis(leaseAbstractImportDraftId: number): Observable<models.ILeaseAbstractAnalyzeResult> {
    return this._httpClient
      .get<models.ILeaseAbstractAnalyzeResult>(`/admin/lease-abstract-import/${leaseAbstractImportDraftId}/analysis`)
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
