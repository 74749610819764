import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { CompanyFeatureListComponent } from './components/company-feature-list/company-feature-list.component';
import { EditCompanyFeatureDialogComponent } from './components/edit-company-feature-dialog/edit-company-feature-dialog.component';

import { CompanyFeatureService } from './services/company-feature.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxDataGridModule,
    dx.DxScrollViewModule,
    dx.DxButtonModule,
    dx.DxLookupModule,
    dx.DxCheckBoxModule,
  ],
  declarations: [
    CompanyFeatureListComponent,
    EditCompanyFeatureDialogComponent,
  ],
  providers: [
    CompanyFeatureService,
  ],
  entryComponents: [
    EditCompanyFeatureDialogComponent,
  ],
})
export class CompanyFeatureModule {
  static forRoot(): ModuleWithProviders<CompanyFeatureModule> {
    return {
      ngModule: CompanyFeatureModule,
      providers: [
        CompanyFeatureService,
      ],
    };
  }
}
