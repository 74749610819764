import { Component, OnInit } from '@angular/core';
import { IFile } from 'src/app/shared/models/generated';
import { ThumbnailsService } from 'src/app/shared/services';

@Component({
  selector: 'app-thumbnails',
  templateUrl: './thumbnails.component.html',
  styleUrls: ['./thumbnails.component.css']
})
export class ThumbnailsComponent implements OnInit {
  Math = Math;
  images: IFile[] = [];
  loading = false;
  toGenerate: number[] = [];
  generated: number[] = [];
  errors: number[] = [];
  generation = false;
  constructor(private thService: ThumbnailsService) { }

  ngOnInit() {
    this.loading = true;
    this.thService.getImagesWithoutThumbnails()
      .subscribe(x => this.images = x)
      .add(() => this.loading = false);
  }

  generateClick() { 
    this.toGenerate = this.images.map(x => x.id);
    this.generatePortion();
  }

  generatePortion() {
    this.generation = true;
    this.loading = true;
    var portion = this.toGenerate.splice(0, 5);
    this.thService.generate(portion).subscribe((ids) => { 
      if (ids) {
        this.generated = this.generated.concat(ids.filter(x => !this.generated.includes(x)));
        var portionErrors = portion.filter(x => !ids.includes(x));
        this.errors = this.errors.concat(portionErrors.filter(x => !this.errors.includes(x)));
      }
      this.loading = false;
      if ((this.generated.length + this.errors.length) < this.images.length) {
        this.generatePortion();
      } else {
        this.generation = false;
      }
    })
  }

  getPercentage() { 
    return this.images.length
      ? Math.round(100 * (this.generated.length + this.errors.length) / this.images.length)
      : 0;
  }

}
