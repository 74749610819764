<h2 class="content-block">Portfolio Invitation Users</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              (onRowPrepared)="onRowPrepared($event)"
              #dxDataGrid>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <div class="lookup-user-field"
       *dxTemplate="let item of 'userTemplate'">
    <img alt=""
         [src]="getUserAvatar(item)" />
    <div>
      {{ getUserDisplayName(item) }}
    </div>
  </div>

  <dxi-column dataField="portfolioInvitationId" caption="Portfolio Invitation Id">
  </dxi-column>

  <dxi-column dataField="portfolioInvitation.companyId" caption="Company">
    <dxo-lookup [dataSource]="companiesDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="portfolioInvitation.invitedBy" caption="Invited By" cellTemplate="userTemplate">
  </dxi-column>

  <dxi-column dataField="userId" caption="User" cellTemplate="userTemplate">
  </dxi-column>

  <dxi-column dataField="acceptanceStatus" dataType="number" caption="Acceptance Status">
    <dxo-lookup [dataSource]="acceptanceStatusDataSource" valueExpr="id" displayExpr="text">
    </dxo-lookup>
  </dxi-column>

  <dxi-column type="buttons" caption="Actions">
    <dxi-button text="Accept"
                [visible]="isAcceptRejectButtonsVisible"
                [onClick]="acceptPortfolioInvitationUser">
    </dxi-button>
    <dxi-button text="Reject"
                [visible]="isAcceptRejectButtonsVisible"
                [onClick]="rejectPortfolioInvitationUser">
    </dxi-button>
  </dxi-column>
</dx-data-grid>
