<dx-scroll-view class="plan-viewer-marker-dialog-demo" width="100%" height="100%">
  <form name="form" novalidate
        #form="ngForm" (ngSubmit)="submit()">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          {{ mode === modes.Unit ? 'Unit' : 'Title' }}
        </div>
        <div class="dx-field-value">
          <ng-container *ngIf="!isCustomTitleControlVisible">
            <dx-select-box name="title" id="title" required
                           [dataSource]="suggestions" [displayExpr]="suggestionsDisplayExpr"
                           [isValid]="form.submitted ? title.valid : true"
                           [(ngModel)]="selectedSuggestion" #title="ngModel"
                           (ngModelChange)="handleTitleSelectBoxChange($event)">
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && title.hasError('required')">
              Title is required
            </div>
          </ng-container>
          <ng-container *ngIf="isCustomTitleControlVisible">
            <dx-text-area name="title" id="title" required
                          [isValid]="form.submitted ? title.valid : true"
                          [(ngModel)]="marker.title" [autoResizeEnabled]="true"
                          #title="ngModel">
            </dx-text-area>

            <ng-container *ngIf="1 < suggestions.length">
              <div class="my-1 text-right">
                <a role="button" class="cursor-pointer text-primary text-underline d-inline-block"
                   (click)="isCustomTitleControlVisible = false">
                  ← Back to the list
                </a>
              </div>
            </ng-container>

            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && title.hasError('required')">
              Title is required
            </div>
          </ng-container>
        </div>
      </div>

      <div class="dx-field"
           *ngIf="mode === modes.Marker">
        <div class="dx-field-label">
          Area
        </div>
        <div class="dx-field-value">
          <dx-select-box name="area" id="area" valueExpr="id" displayExpr="text" required
                         [dataSource]="areas" [isValid]="form.submitted ? area.valid : true"
                         [(ngModel)]="marker.area" #area="ngModel">
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && area.hasError('required')">
            Area is required
          </div>
        </div>
      </div>

      <div class="dx-field"
           *ngIf="mode === modes.Marker">
        <div class="dx-field-label">
          Description
        </div>
        <div class="dx-field-value">
          <dx-text-area name="description" id="description" required rows="5" maxLength="2500"
                        [isValid]="form.submitted ? description.valid : true"
                        [(ngModel)]="marker.description" [autoResizeEnabled]="true"
                        #description="ngModel">
          </dx-text-area>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && description.hasError('required')">
            Description is required
          </div>
        </div>
      </div>

      <ng-container *ngIf="mode === modes.Marker && !marker.id">
        <div class="dx-field">
          <div class="dx-field-label">
            Panoramic or Standard Images
          </div>
          <div class="dx-field-value file-control">
            <div class="file-control-icon">
              <i class="icon-uploader"></i>
            </div>
            <div class="file-control-text">
              <strong>Choose a file</strong>
              or drag it here
            </div>

            <dx-file-uploader name="file" accept="image/*,image/heic" uploadMode="useForm"
                              [multiple]="true" [showFileList]="false"
                              (onValueChanged)="handleFilesChange($event)">
            </dx-file-uploader>
          </div>
        </div>

        <div class="pt-2">
          <div class="d-flex flex-row flex-nowrap mw-100 my-2 align-items-center"
               *ngFor="let image of images; let index = index;">
            <div class="pr-2">
              <ng-container *ngIf="(image.previewUrl | async) as imagePreviewUrl; else noPreviewImage">
                <img alt="" class="preview-image"
                     [src]="imagePreviewUrl" />
              </ng-container>
              <ng-template #noPreviewImage>
                <div class="preloader">
                  <span></span>
                </div>
              </ng-template>
            </div>
            <div class="pl-2 mw-0 flex-grow-1">
              <ng-container *ngIf="fileNameWithoutExtension(image.file?.name) as fileName">
                <p class="mb-1 small font-weight-bold w-100 text-truncate"
                   [title]="fileName">
                  {{fileName}}
                </p>
              </ng-container>
              <p class="mb-0">
                <a role="button" class="cursor-pointer font-weight-bold small text-danger"
                   (click)="removeImage(index)">
                  Remove
                </a>
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="d-flex flex-row mx-n2 pt-3">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%"
                     [text]="marker?.id ? 'Update' : 'Create'" [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="close()">
          </dx-button>
        </div>
      </div>
    </div>
  </form>
</dx-scroll-view>
