import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import DevExpress from 'devextreme';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { CompanyFeatureService } from '../../services/company-feature.service';
import { PopupService } from '../../../infrastructure/services/popup.service';

import { Tools } from '../../../../shared/tools';

import { ICompanyFeatureToggleViewModel } from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

import { EditCompanyFeatureDialogComponent } from '../edit-company-feature-dialog/edit-company-feature-dialog.component';
import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

@Component({
  templateUrl: 'company-feature-list.component.html',
  styleUrls: ['company-feature-list.component.scss'],
})
export class CompanyFeatureListComponent implements OnInit, OnDestroy {
  private static readonly _companyFeaturesListUrl = `${environment.adminUrl}/feature-toggle/companies`;
  private static readonly _companyListUrl = `${environment.adminUrl}/company?withDeleted=true`;

  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: DevExpress.data.CustomStore;
  companyDataSource: DevExpress.data.CustomStore;

  tools: typeof Tools;

  private readonly _companyFeatureService: CompanyFeatureService;
  private readonly _popupService: PopupService;
  private readonly _destroy: Subject<void>;

  constructor(companyFeatureService: CompanyFeatureService, popupService: PopupService) {
    this._companyFeatureService = companyFeatureService;
    this._popupService = popupService;
    this._destroy = new Subject<void>();

    this.tools = Tools;

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = AspNetData.createStore({
      key: 'companyId',
      loadUrl: CompanyFeatureListComponent._companyFeaturesListUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });

    this.companyDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: CompanyFeatureListComponent._companyListUrl,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onToolbarPreparing(event): void {
    if (event.toolbarOptions) {
      event.toolbarOptions.items = [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'createCompanyFeatures',
          options: {
            icon: 'fa fa-plus',
            disabled: false,
            text: 'Create',
            onClick: () => this._showCreateOrUpdateDialog(null),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        ...(event.toolbarOptions.items || [])
      ];
    }
  }

  edit(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._showCreateOrUpdateDialog(event.row.data);
  }

  delete(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to delete this record?',
        acceptFn: () => {
          this._companyFeatureService
            .deleteCompanyFeatureToggles(event.row.data)
            .pipe(
              tap(() => {
                if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
                  const dataSource = this.dxDataGridComponent.instance.getDataSource();
                  dataSource.reload();
                }
              }),
              takeUntil(this._destroy),
            )
            .subscribe();
        },
      },
    });
  }

  private _showCreateOrUpdateDialog(companyFeature: ICompanyFeatureToggleViewModel): void {
    const isCreate = !companyFeature || !companyFeature.companyId;

    this._popupService.show(EditCompanyFeatureDialogComponent, {
      title: `${isCreate ? 'Create' : 'Update'} feature flags`,
      width: 750,
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      injectableData: {
        companyFeature: companyFeature,
        reloadList: () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            dataSource.reload();
          }
        },
      },
    });
  }
}
