<div class="content-block">
  <h2>
    Lease Templates
  </h2>

  <div class="mb-4">

    <ng-container *ngIf="landlordCompanies | async as llCompanies">

      <dx-data-grid class="dx-card wide-card"
                    [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true" [columnHidingEnabled]="true"
                    (onToolbarPreparing)="prepareToolbar($event)">

        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
        <dxo-paging [pageSize]="15"></dxo-paging>

        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-filter-builder></dxo-filter-builder>
        <dxo-filter-builder-popup></dxo-filter-builder-popup>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>

        <dxi-column dataField="originalDocument" cellTemplate="originalDocumentFieldTemplate" caption="Template">
        </dxi-column>

        <div *dxTemplate="let item of 'originalDocumentFieldTemplate'">
          <div>
            <ng-container *ngIf="item?.data?.originalDocument as doc">
              <a target="_blank"
                 [href]="doc.url">
                <i class="fa fa-external-link mr-1" aria-hidden="true"></i>
                {{ doc.name }}
              </a>
            </ng-container>
          </div>
        </div>

        <dxi-column dataField="landlordCompanyId" dataType="number"
                    cellTemplate="landlordCompanyFieldTemplate" caption="Landlord company">
          <dxo-lookup valueExpr="id"
                      [dataSource]="llCompanies"
                      [displayExpr]="Tools.getCompanyExpression">
          </dxo-lookup>
        </dxi-column>

        <div *dxTemplate="let item of 'landlordCompanyFieldTemplate'">
          <div>
            {{ getCompanyName(llCompanies, item?.data?.landlordCompanyId) }}
          </div>
        </div>

        <dxi-column dataField="markers" cellTemplate="markersFieldTemplate" caption="Markers count">
        </dxi-column>

        <div *dxTemplate="let item of 'markersFieldTemplate'">
          <div>
            {{ item?.data?.markers?.length ?? 0 }}
          </div>
        </div>

        <dxi-column type="buttons">
          <dxi-button name="edit-custom" text="Edit">
          </dxi-button>
          <dxi-button name="delete">
          </dxi-button>
        </dxi-column>

      </dx-data-grid>

    </ng-container>

  </div>
</div>
