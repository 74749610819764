import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as dx from 'devextreme-angular';

import { PointOfInterestService } from './services/point-of-interest.service';

import { PointOfInterestImportComponent } from './components/point-of-interest-import/point-of-interest-import.component';
import { PointOfInterestImportMappingComponent } from './components/point-of-interest-import-mapping/point-of-interest-import-mapping.component';
import { PointOfInterestListComponent } from './components/point-of-interest-list/point-of-interest-list.component';
import { PointOfInterestUpsertComponent } from './components/point-of-interest-upsert/point-of-interest-upsert.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,

    dx.DxButtonModule,
    dx.DxDataGridModule,
    dx.DxFileUploaderModule,
    dx.DxLoadPanelModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
    dx.DxTextBoxModule,
  ],
  declarations: [
    PointOfInterestImportComponent,
    PointOfInterestImportMappingComponent,
    PointOfInterestListComponent,
    PointOfInterestUpsertComponent,
  ],
  exports: [
    PointOfInterestListComponent,
  ],
  providers: [
    PointOfInterestService,
  ],
  entryComponents: [
    PointOfInterestImportComponent,
    PointOfInterestImportMappingComponent,
    PointOfInterestUpsertComponent,
  ],
})
export class PointOfInterestModule {
  static forRoot(): ModuleWithProviders<PointOfInterestModule> {
    return {
      ngModule: PointOfInterestModule,
      providers: [
        PointOfInterestService,
      ],
    };
  }
}
