import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

@Injectable()
export class LeaseSignatureService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  updateLeaseSignature(leaseSignature: models.ILeaseSignature): Observable<models.ILeaseSignature> {
    return this._httpClient
      .put<models.ILeaseSignature>(`/admin/lease-signatures`, leaseSignature);
  }
}
