import { CommonModule, CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { TermService } from './services/term.service';

import { BaseRentalRateCustomTableEditorComponent } from './components/base-rental-rate-custom-table-editor/base-rental-rate-custom-table-editor.component';
import { RentalRateEscalationCustomTableEditorComponent } from './components/rental-rate-escalation-custom-table-editor/rental-rate-escalation-custom-table-editor.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxScrollViewModule,
    dx.DxButtonModule,
    dx.DxNumberBoxModule,
    dx.DxSelectBoxModule,
    dx.DxTooltipModule,
    SharedModule,
  ],
  providers: [
    TermService,
    CurrencyPipe,
  ],
  declarations: [
    BaseRentalRateCustomTableEditorComponent,
    RentalRateEscalationCustomTableEditorComponent,
  ],
  entryComponents: [
    BaseRentalRateCustomTableEditorComponent,
    RentalRateEscalationCustomTableEditorComponent,
  ],
})
export class TermModule {
  static forRoot(): ModuleWithProviders<TermModule> {
    return {
      ngModule: TermModule,
      providers: [
        TermService,
      ],
    };
  }
}
