<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <!-- Start: Landlord side -->
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        Landlord side
      </div>

      <div class="dx-field">
        <div class="dx-field-label">
          User type
        </div>
        <div class="dx-field-value">
          <dx-select-box name="landlord-type" valueExpr="value" displayExpr="name" required
                         [(items)]="userTypes" [(ngModel)]="leaseAmendment.isExternalLandlordEmail"
                         #userType="ngModel">
          </dx-select-box>
        </div>
      </div>

      <ng-container *ngIf="leaseAmendment.isExternalLandlordEmail; else internalLandlordTemplate">
        <!-- Start: Landlord name -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && landlordName.invalid">
            Name
          </div>
          <div class="dx-field-value">
            <dx-text-box name="landlord-name" required
                         [isValid]="form.submitted ? landlordName.valid : true"
                         [(ngModel)]="leaseAmendment.landlordName"
                         #landlordName="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && landlordName.hasError('required')">
              Name is required
            </div>
          </div>
        </div>
        <!-- End: Landlord name -->
        <!-- Start: Landlord email address -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && landlordEmail.invalid">
            Email address
          </div>
          <div class="dx-field-value">
            <dx-text-box name="landlord-email" required
                         [isValid]="form.submitted ? landlordEmail.valid : true"
                         [(ngModel)]="leaseAmendment.landlordEmail"
                         #landlordEmail="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && landlordEmail.hasError('required')">
              Email address is required
            </div>
          </div>
        </div>
        <!-- End: Landlord email address -->
      </ng-container>
      <ng-template #internalLandlordTemplate>
        <!-- Start: Internal landlord -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && landlord.invalid">
            Landlord
          </div>
          <div class="dx-field-value">
            <dx-lookup name="landlord" required
                       noDataText="No landlords found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="landlordsList$ | async"
                       [isValid]="form.submitted ? landlord.valid : true" [searchExpr]="setupSearchableUserFields"
                       [(ngModel)]="leaseAmendment.landlordId" #landlord="ngModel">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let profile of 'field'">
                <ng-container *ngIf="profile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img class="rounded-circle" width="20" height="20"
                           [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/images/avatar.png'"
                           [alt]="profile.displayName" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{profile.displayName}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!profile">
                  <span class="text-muted">
                    Select landlord
                  </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let profile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img class="rounded-circle" width="40" height="40"
                         [src]="profile.avatar && profile.avatar.url  ? profile.avatar.url : 'assets/images/avatar.png'"
                         [alt]="profile.displayName" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{profile.displayName}}
                    </p>
                    <p class="mb-0 small w-100 text-truncate"
                       *ngIf="profile.company && profile.company.name">
                      {{profile.company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
          </div>
        </div>
        <!-- End: Internal landlord -->
      </ng-template>
    </div>
    <!-- End: Landlord side -->
    <!-- Start: Tenant side -->
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        Tenant side
      </div>

      <div class="dx-field">
        <div class="dx-field-label">
          User type
        </div>
        <div class="dx-field-value">
          <dx-select-box name="tenant-type" valueExpr="value" displayExpr="name" required
                         [(items)]="userTypes" [(ngModel)]="leaseAmendment.isExternalTenantEmail"
                         #userType="ngModel">
          </dx-select-box>
        </div>
      </div>

      <ng-container *ngIf="leaseAmendment.isExternalTenantEmail; else internalTenantTemplate">
        <!-- Start: Tenant name -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && tenantName.invalid">
            Name
          </div>
          <div class="dx-field-value">
            <dx-text-box name="tenant-name" required
                         [isValid]="form.submitted ? tenantName.valid : true"
                         [(ngModel)]="leaseAmendment.tenantName"
                         #tenantName="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && tenantName.hasError('required')">
              Name is required
            </div>
          </div>
        </div>
        <!-- End: Tenant name -->
        <!-- Start: Tenant email address -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && tenantEmail.invalid">
            Email address
          </div>
          <div class="dx-field-value">
            <dx-text-box name="tenant-email" required
                         [isValid]="form.submitted ? tenantEmail.valid : true"
                         [(ngModel)]="leaseAmendment.tenantEmail"
                         #tenantEmail="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && tenantEmail.hasError('required')">
              Email address is required
            </div>
          </div>
        </div>
        <!-- End: Tenant email address -->
      </ng-container>
      <ng-template #internalTenantTemplate>
        <!-- Start: Internal landlord -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && tenant.invalid">
            Tenant
          </div>
          <div class="dx-field-value">
            <dx-lookup name="tenant" required
                       noDataText="No tenants found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="tenantsList$ | async"
                       [isValid]="form.submitted ? tenant.valid : true" [searchExpr]="setupSearchableUserFields"
                       [(ngModel)]="leaseAmendment.tenantId" #tenant="ngModel">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let profile of 'field'">
                <ng-container *ngIf="profile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img class="rounded-circle" width="20" height="20"
                           [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/images/avatar.png'"
                           [alt]="profile.displayName" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{profile.displayName}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!profile">
                  <span class="text-muted">
                    Select tenant
                  </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let profile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img class="rounded-circle" width="40" height="40"
                         [src]="profile.avatar && profile.avatar.url  ? profile.avatar.url : 'assets/images/avatar.png'"
                         [alt]="profile.displayName" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{profile.displayName}}
                    </p>
                    <p class="mb-0 small w-100 text-truncate"
                       *ngIf="profile.company && profile.company.name">
                      {{profile.company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
          </div>
        </div>
        <!-- End: Internal landlord -->
      </ng-template>
    </div>
    <!-- End: Tenant side -->

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" text="Save"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="cancel()">
          </dx-button>
        </div>
      </div>
    </div>
  </form>
</dx-scroll-view>
