import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { expand } from 'rxjs/operators';

import * as models from '../../../shared/models/generated';

@Injectable()
export class ImportService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  /**
   * Creates import task
   * @method ImportService.createImportTask
   * @param importTask Import task
   * @returns Created import task
   */
  createImportTask(importTask: models.IImportTaskViewModel): Observable<models.IImportTaskViewModel> {
    return this._httpClient
      .post<models.IImportTaskViewModel>(
        `/admin/import`,
        importTask,
      );
  }

  /**
   * Requests and returns import task status
   * @method ImportService.getImportTaskStatus
   * @param importTaskId Import task id
   * @returns Actual import task status
   */
  getImportTaskStatus(importTaskId: number): Observable<models.ImportTaskStatus> {
    return this._httpClient.get<models.ImportTaskStatus>(`/admin/import/${importTaskId}/status`);
  }

  /**
   * Uploads an import file
   * @method ImportService.uploadImportFile
   * @param importFile File for import
   * @returns An uploaded file info
   */
  uploadImportFile(importFile: File): Observable<models.IFileViewModel> {
    const body = new FormData();

    body.append('importFile', importFile);

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this._httpClient
      .post<models.IFileViewModel>(`/admin/import/files`, body, {headers});
  }

  /**
   * Requests and returns a list of file headers
   * @method ImportService.getFileHeaders
   * @param fileUrl File url
   * @returns List of headers
   */
  getFileHeaders(fileUrl: string): Observable<Array<string>> {
    let params = new HttpParams();

    if (fileUrl) {
      params = params.set('fileUrl', fileUrl);
    }

    return this._httpClient
      .get<Array<string>>(`/admin/import/files/headers`, {params});
  }
}
