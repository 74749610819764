import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Tools } from 'src/app/shared/tools';
import * as vm from '../../shared/models/generated';

import * as models from '../../shared/models/generated';
import { AuthService } from '../../shared/services';
import DevExpress from 'devextreme';
import template = DevExpress.core.template;

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;
  uploaderTemplate: template;
  Tools = Tools;
  dataSource: any;
  url = `${environment.adminUrl}/company`;
  get _window() {
    return window;
  }
  uploadUrl = environment.apiUrl + '/storage/Upload';
  popupVisible = false;
  currentPicture = <models.IFile>{};
  phoneNumberFieldEditorOptions: any;
  pictureEditor: any;
  pictureEditorOptions = {
    visible: false,
    onInitialized: e => this.pictureEditor = e.component,
  };
  emptyPicture = <models.IFile>{
    id: 0,
    thumbnailUrl: Tools.noImageUrl
  };
  industryItems = [];

  companyTypes = Tools.EnumToArray(models.CompanyType);
  primaryAddressName = 'HQ Address';
  otherAddressName = 'Other Address';
  states: vm.IState[] = [];
  countries: vm.ICountry[] = [];
  counties: vm.ICounty[] = [];
  allCounties: vm.ICounty[] = [];
  primaryAddress: vm.IAddress;
  otherAddress: vm.IAddress;
  company: vm.ICompany;
  private readonly _authService: AuthService;
  constructor(authService: AuthService) {
    this._authService = authService;

    this.phoneNumberFieldEditorOptions = {
      mask: '+1 (X00) 000-0000',
      maskRules: { X: /[02-9]/ },
      useMaskedValue: true,
      maskInvalidMessage: 'The phone number must have a correct phone format'
    };
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        if (ajaxOptions.data.values) {
          this.prepareSave(ajaxOptions);
        }
      },
      onLoaded: (companies) => {
        companies.map(company => {
          company.primaryAddress = company.addresses.find(x => x.isPrimary);
          company.otherAddress = company.addresses.find(x => !x.isPrimary);
        });
      }
    });

    Tools.lookupDataSource('Industry')
      .on('loaded', data => {
        this.industryItems = data;
      })
      .load();

    this._authService.addressPropertiesCompletes$.subscribe(info => {
      if (info) {
        this.countries = info.countries;
        this.states = info.states;
      }
    });
  }

  onUploaded(e) {
    if (!e || !e.request || !e.request.responseText || !this.fileUploader || !this.fileUploader.instance) {
      return;
    }

    this.currentPicture = JSON.parse(e.request.responseText);
    this.fileUploader.instance.reset();
    this.popupVisible = false;
    this.pictureEditor.option('value', this.currentPicture);
  }

  prepareSave(ajaxOptions) {
    const values = <models.ICompany>JSON.parse(ajaxOptions.data.values);
    if (0 < this.currentPicture.id) {
      values.logoId = this.currentPicture.id;
    }
    if (!values.addresses) {
      values.addresses = [];
    }

    values.addresses[0] = {...(<any>this.company).primaryAddress, ...(<any>values).primaryAddress};
    values.addresses[1] = {...(<any>this.company).otherAddress, ...(<any>values).otherAddress};
    delete (<any> values).primaryAddress;
    delete (<any> values).otherAddress;
    ajaxOptions.data.values = JSON.stringify(values);
  }

  initNewRow(e) {
    this.company = <models.ICompany>{};
    this.currentPicture = this.emptyPicture;
    this._fillPrimaryAddress(e);
    this._fillOtherAddress(e);
  }

  editingStart(e) {
    const company = <models.ICompany>e.data;
    this.currentPicture = company.logo ? company.logo : this.emptyPicture;
    this.primaryAddress = company.addresses.find(x => x.isPrimary);
    this.otherAddress = company.addresses.find(x => !x.isPrimary);
    if (!this.primaryAddress) {
      this._fillPrimaryAddress(e);
    } else {
      e.data.primaryAddress = this.primaryAddress;
    }
    if (!this.otherAddress) {
      this._fillOtherAddress(e);
    } else {
      e.data.otherAddress = this.otherAddress;
    }
    this.company = company;
  }

  _fillPrimaryAddress(e) {
    this.primaryAddress =
    e.data.primaryAddress = <models.IAddress> {
      name: this.primaryAddressName,
      isPrimary: true
    };
  }

  _fillOtherAddress(e) {
    this.otherAddress =
    e.data.otherAddress = <models.IAddress> {
      name: this.otherAddressName,
      isPrimary: false
    };
  }
}
