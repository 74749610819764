import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SkipSelf } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';
import { DocumentViewerOptions } from '../../models/document-viewer-options.model';
import { ImportFormSelectItem } from '../../models/import-form-select-item.model';

//
// NOTE: ImportFormBuildingConditionsComponent component is used as nested form.
//       Before using, please make sure the ControlContainer is provided.
//

@Component({
  templateUrl: 'import-form-building-conditions.component.html',
  styleUrls: ['import-form-building-conditions.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class ImportFormBuildingConditionsComponent implements OnInit, OnDestroy {
  private static readonly _tenantImprovementsTypes: Array<ImportFormSelectItem<models.TenantImprovementsType>> = [
    {
      name: 'As is, no tenant improvements allowance provided',
      value: models.TenantImprovementsType.AsIs,
    },
    {
      name: 'Landlord shall provide Turnkey construction',
      value: models.TenantImprovementsType.TurnKey,
    },
    {
      name: 'Landlord shall provide a Tenant Improvement allowance',
      value: models.TenantImprovementsType.DollarAllowance,
    },
    {
      name: 'Tenant shall have the right to amortize any improvements',
      value: models.TenantImprovementsType.CustomAmortized,
    },
    {
      name: 'Custom',
      value: models.TenantImprovementsType.Custom,
    },
  ];

  private static readonly _tenantImprovementsAmortizationTypes: Array<ImportFormSelectItem<models.TenantImprovementsAmortizationOption>> = [
    {
      name: 'Amortization option',
      value: models.TenantImprovementsAmortizationOption.AmortizationOption,
    },
    {
      name: 'No amortization available',
      value: models.TenantImprovementsAmortizationOption.NoAmortizationAvailable,
    },
  ];

  private static readonly _tenantImprovementsContractorTypes:
    Array<ImportFormSelectItem<models.ContractorTypeForTenantImprovementsWork>> = [
    {
      name: `Landlord's contractor`,
      value: models.ContractorTypeForTenantImprovementsWork.UseLandlordsContractors,
    },
    {
      name: `Tenant can choose their own contractor`,
      value: models.ContractorTypeForTenantImprovementsWork.TenantCanChooseTheirOwnContractors,
    }
  ];

  private static readonly _tenantImprovementsWarrantyDurationTypesList:
    Array<ImportFormSelectItem<models.WarrantyDurationTypeForLandlordsContractors>> = [
    {
      name: 'None',
      value: models.WarrantyDurationTypeForLandlordsContractors.None,
    },
    {
      name: '3 months',
      value: models.WarrantyDurationTypeForLandlordsContractors._3Months,
    },
    {
      name: '6 months',
      value: models.WarrantyDurationTypeForLandlordsContractors._6Months,
    },
    {
      name: '12 months',
      value: models.WarrantyDurationTypeForLandlordsContractors._12Months,
    },
    {
      name: 'Custom',
      value: models.WarrantyDurationTypeForLandlordsContractors.Custom,
    },
  ];

  private static readonly _landlordMaintenanceTypes: Array<ImportFormSelectItem<models.LandlordMaintenanceTermType>> = [
    {
      name: 'Per current lease',
      value: models.LandlordMaintenanceTermType.PerCurrentLease,
    },
    {
      name: 'Custom',
      value: models.LandlordMaintenanceTermType.Custom,
    },
  ];

  private static readonly _conditionOfPremisesTypes: Array<ImportFormSelectItem<models.ConditionOfPremisesTermType>> = [
    {
      name: 'As is',
      value: models.ConditionOfPremisesTermType.AsIs
    },
    {
      name: 'Landlord Delivered',
      value: models.ConditionOfPremisesTermType.LandlordDelivered,
    },
    {
      name: 'Landlord Delivered with LL Warranty',
      value: models.ConditionOfPremisesTermType.LandlordDeliveredWithWarranty,
    },
    {
      name: 'Custom',
      value: models.ConditionOfPremisesTermType.Custom,
    },
  ];

  private static readonly _codeComplianceTypes: Array<ImportFormSelectItem<models.CodeComplianceTermType>> = [
    {
      name: 'Per current lease',
      value: models.CodeComplianceTermType.PerCurrentLease,
    },
    {
      name: 'To be addressed in lease document.',
      value: models.CodeComplianceTermType.ToBeAddressedInLease,
    },
    {
      name:
        'The Building and all systems, at Landlord’s expense, shall comply with all code requirements throughout the lease term. ' +
        'Any costs associated with Code Compliance in all Building common and core areas will be at the Landlord’s sole expense ' +
        'and not passed on to Tenant throughout the lease term and any extension, unless such compliance is due to a Tenant request.',
      value: models.CodeComplianceTermType.LandlordExpense,
    },
    {
      name: 'Custom',
      value: models.CodeComplianceTermType.Custom,
    },
  ];

  private static readonly _tenantMaintenanceTypes: Array<ImportFormSelectItem<models.TenantMaintenanceTermType>> = [
    {
      name: 'Per current lease',
      value: models.TenantMaintenanceTermType.PerCurrentLease,
    },
    {
      name: 'Custom',
      value: models.TenantMaintenanceTermType.Custom,
    },
  ];

  private static readonly _hvacTypes: Array<ImportFormSelectItem<models.HvacTermType>> = [
    {
      name: 'Per current lease',
      value: models.HvacTermType.PerCurrentLease,
    },
    {
      name: 'Responsibility by tenant',
      value: models.HvacTermType.ResponsibilityByTenant,
    },
    {
      name: 'Tenant to have an annual cap',
      value: models.HvacTermType.MoneyValue,
    },
    {
      name: 'Landlord shall be responsible for replacement and amortize',
      value: models.HvacTermType.PercentValue,
    },
    {
      name: 'Custom',
      value: models.HvacTermType.CustomValue,
    },
  ];

  private static readonly _hazardousMaterialsTypes: Array<ImportFormSelectItem<models.HazardousMaterialsTermType>> = [
    {
      name: 'Per current lease',
      value: models.HazardousMaterialsTermType.PerCurrentLease,
    },
    {
      name: 'To be addressed in the Lease document.',
      value: models.HazardousMaterialsTermType.ToBeAddressedInLease,
    },
    {
      name:
        'Landlord represents and warrants that the Building and Premises are free of all Hazardous Materials, ' +
        'including but not limited to PCBs and asbestos, and that Building and Premise shall be maintained in ' +
        'compliance with all environmental laws, rules and regulations. Landlord will indemnify and hold ' +
        'harmless Tenant against any pre-existing hazardous materials contained in the building or property ' +
        'that were introduced prior to Tenant’s occupancy.',
      value: models.HazardousMaterialsTermType.LandlordResponsibilityExtended,
    },
    {
      name:
        'Landlord will provide warranties concerning the absence of Hazardous Materials and any other ' +
        'Environmental defects and will be responsible for all costs associated with the removal of any that ' +
        'are present prior to the commencement of the Lease.  Landlord shall agree to indemnify and hold ' +
        'Tenant harmless for any existing Hazardous or Environmental conditions.',
      value: models.HazardousMaterialsTermType.LandlordResponsibility,
    },
    {
      name:
        'Landlord shall indemnify and hold Tenant harmless for any pre-existing Hazardous or Environmental ' +
        'conditions only. Environmental Phase I, if one exists on file, can be made available to Tenant upon ' +
        'execution of Letter of Intent.',
      value: models.HazardousMaterialsTermType.LandlordResponsibilityWithEnvironmentalPhaseOne,
    },
    {
      name: 'Custom',
      value: models.HazardousMaterialsTermType.Custom,
    },
  ];

  @Input() lease: models.ILeaseViewModel;
  @Output() leaseChange: EventEmitter<models.ILeaseViewModel>;

  @Input() leasePreview: models.ILeaseViewModel;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  TenantImprovementsType: typeof models.TenantImprovementsType = models.TenantImprovementsType;
  TenantImprovementsAmortizationType: typeof models.TenantImprovementsAmortizationOption = models.TenantImprovementsAmortizationOption;
  TenantImprovementsContractorType: typeof models.ContractorTypeForTenantImprovementsWork = models.ContractorTypeForTenantImprovementsWork;
  TenantImprovementsWarrantyDurationType: typeof models.WarrantyDurationTypeForLandlordsContractors = models.WarrantyDurationTypeForLandlordsContractors;
  LandlordMaintenanceType: typeof models.LandlordMaintenanceTermType = models.LandlordMaintenanceTermType;
  TenantMaintenanceType: typeof models.TenantMaintenanceTermType = models.TenantMaintenanceTermType;
  HvacType: typeof models.HvacTermType = models.HvacTermType;
  ConditionOfPremisesType: typeof models.ConditionOfPremisesTermType = models.ConditionOfPremisesTermType;
  CodeComplianceType: typeof models.CodeComplianceTermType = models.CodeComplianceTermType;
  HazardousMaterialsType: typeof models.HazardousMaterialsTermType = models.HazardousMaterialsTermType;

  tenantImprovementsTypesList: Array<ImportFormSelectItem<models.TenantImprovementsType>>;
  tenantImprovementsAmortizationTypesList: Array<ImportFormSelectItem<models.TenantImprovementsAmortizationOption>>;
  tenantImprovementsContractorTypesList: Array<ImportFormSelectItem<models.ContractorTypeForTenantImprovementsWork>>;
  tenantImprovementsWarrantyDurationTypesList: Array<ImportFormSelectItem<models.WarrantyDurationTypeForLandlordsContractors>>;
  landlordMaintenanceTypesList: Array<ImportFormSelectItem<models.LandlordMaintenanceTermType>>;
  conditionOfPremisesTypesList: Array<ImportFormSelectItem<models.ConditionOfPremisesTermType>>;
  codeComplianceTypesList: Array<ImportFormSelectItem<models.CodeComplianceTermType>>;
  tenantMaintenanceTypesList: Array<ImportFormSelectItem<models.TenantMaintenanceTermType>>;
  hvacTypesList: Array<ImportFormSelectItem<models.HvacTermType>>;
  hazardousMaterialsTypesList: Array<ImportFormSelectItem<models.HazardousMaterialsTermType>>;

  readonly form: NgForm;

  private readonly _destroy$: Subject<void>;

  constructor(form: NgForm) {
    this.form = form;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.lease = this._prepareLeaseModel();

    if (!this.leaseChange) {
      this.leaseChange = new EventEmitter<models.ILeaseViewModel>();
    }

    this._prepareDropDownOptions();

    // Reloads the dropdown options whenever the form model changes
    this.form
      .valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.leaseChange.next(this.lease);

          this._prepareDropDownOptions();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleTenantImprovementsWarrantyDurationTypeChange(warrantyDurationType: models.WarrantyDurationTypeForLandlordsContractors): void {
    if (!this.lease || !this.lease.tenantImprovementsTerm) {
      return;
    }

    if (!warrantyDurationType || warrantyDurationType === models.WarrantyDurationTypeForLandlordsContractors.None) {
      this.lease.tenantImprovementsTerm.warrantyDurationInMonthsForLandlordContractors = null;
      return;
    }

    switch (warrantyDurationType) {
      case models.WarrantyDurationTypeForLandlordsContractors._3Months:
        this.lease.tenantImprovementsTerm.warrantyDurationInMonthsForLandlordContractors = 3;
        break;

      case models.WarrantyDurationTypeForLandlordsContractors._6Months:
        this.lease.tenantImprovementsTerm.warrantyDurationInMonthsForLandlordContractors = 6;
        break;

      case models.WarrantyDurationTypeForLandlordsContractors._12Months:
        this.lease.tenantImprovementsTerm.warrantyDurationInMonthsForLandlordContractors = 12;
        break;
    }
  }

  private _prepareLeaseModel(): models.ILeaseViewModel {
    const lease = this.lease || <models.ILeaseViewModel>{};

    if (!lease.tenantImprovementsTerm || !lease.tenantImprovementsTerm.tenantImprovementsAmortizationOption) {
      if (!lease.tenantImprovementsTerm) {
        lease.tenantImprovementsTerm = <models.ITenantImprovementsViewModel>{};
      }

      lease.tenantImprovementsTerm.tenantImprovementsAmortizationOption = models.TenantImprovementsAmortizationOption.AmortizationOption;
    }

    if (!lease.tenantImprovementsTerm || !lease.tenantImprovementsTerm.contractorTypeForTenantImprovementsWork) {
      if (!lease.tenantImprovementsTerm) {
        lease.tenantImprovementsTerm = <models.ITenantImprovementsViewModel>{};
      }

      lease.tenantImprovementsTerm.contractorTypeForTenantImprovementsWork = models.ContractorTypeForTenantImprovementsWork
        .UseLandlordsContractors;
    }

    if (!lease.tenantImprovementsTerm || !lease.tenantImprovementsTerm.warrantyDurationTypeForLandlordsContractors) {
      if (!lease.tenantImprovementsTerm) {
        lease.tenantImprovementsTerm = <models.ITenantImprovementsViewModel>{};
      }

      lease.tenantImprovementsTerm.warrantyDurationTypeForLandlordsContractors = models.WarrantyDurationTypeForLandlordsContractors.None;
    }

    if (!lease.landlordMaintenanceTerm || !lease.landlordMaintenanceTerm.landlordMaintenanceTermType) {
      if (!lease.landlordMaintenanceTerm) {
        lease.landlordMaintenanceTerm = <models.ILandlordMaintenanceTermViewModel>{};
      }

      if (lease.abstractLeaseId) {
        lease.landlordMaintenanceTerm.landlordMaintenanceTermType = models.LandlordMaintenanceTermType.PerCurrentLease;
      }
    }

    if (!lease.tenantMaintenanceTerm || !lease.tenantMaintenanceTerm.tenantMaintenanceTermType) {
      if (!lease.tenantMaintenanceTerm) {
        lease.tenantMaintenanceTerm = <models.ITenantMaintenanceTermViewModel>{};
      }

      if (lease.abstractLeaseId) {
        lease.tenantMaintenanceTerm.tenantMaintenanceTermType = models.TenantMaintenanceTermType.PerCurrentLease;
      }
    }

    return lease;
  }

  private _prepareDropDownOptions(): void {
    this.tenantImprovementsTypesList = ImportFormBuildingConditionsComponent._tenantImprovementsTypes;
    this.tenantImprovementsAmortizationTypesList = ImportFormBuildingConditionsComponent._tenantImprovementsAmortizationTypes;
    this.tenantImprovementsContractorTypesList = ImportFormBuildingConditionsComponent._tenantImprovementsContractorTypes;
    this.tenantImprovementsWarrantyDurationTypesList = ImportFormBuildingConditionsComponent._tenantImprovementsWarrantyDurationTypesList;
    this.conditionOfPremisesTypesList = ImportFormBuildingConditionsComponent._conditionOfPremisesTypes;

    this.codeComplianceTypesList = ImportFormBuildingConditionsComponent._codeComplianceTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.CodeComplianceTermType.PerCurrentLease
        );

        return option;
      });

    this.landlordMaintenanceTypesList = ImportFormBuildingConditionsComponent._landlordMaintenanceTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.LandlordMaintenanceTermType.PerCurrentLease
        );

        return option;
      });

    this.tenantMaintenanceTypesList = ImportFormBuildingConditionsComponent._tenantMaintenanceTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.TenantMaintenanceTermType.PerCurrentLease
        );

        return option;
      });

    this.hvacTypesList = ImportFormBuildingConditionsComponent._hvacTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.HvacTermType.PerCurrentLease
        );

        return option;
      });

    this.hazardousMaterialsTypesList = ImportFormBuildingConditionsComponent._hazardousMaterialsTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.HazardousMaterialsTermType.PerCurrentLease
        );

        return option;
      });
  }
}
