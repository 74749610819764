import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

import { environment } from '../../../../environments/environment';

@Injectable()
export class PortfolioInvitationUserService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  acceptPortfolioInvitationUser(portfolioInvitationUser: models.IPortfolioInvitationUserViewModel): Observable<void> {
    const { portfolioInvitationId, userId } = portfolioInvitationUser;

    return this._httpClient
      .post<void>(
        `${environment.adminUrl}/portfolio/invitation/${portfolioInvitationId}/user/${userId}/accept`,
        null
      );
  }

  rejectPortfolioInvitationUser(portfolioInvitationUser: models.IPortfolioInvitationUserViewModel): Observable<void> {
    const { portfolioInvitationId, userId } = portfolioInvitationUser;

    return this._httpClient
      .post<void>(
        `${environment.adminUrl}/portfolio/invitation/${portfolioInvitationId}/user/${userId}/reject`,
        null
      );
  }
}
