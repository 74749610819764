import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ICompanyFeatureToggleViewModel } from '../../../shared/models/generated';

import { environment } from '../../../../environments/environment';

@Injectable()
export class CompanyFeatureService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  createOrUpdateCompanyFeatureToggles(companyFeature: ICompanyFeatureToggleViewModel): Observable<ICompanyFeatureToggleViewModel> {
    return this._httpClient.post<ICompanyFeatureToggleViewModel>(
      `${environment.adminUrl}/feature-toggle/companies`,
      companyFeature
    );
  }

  deleteCompanyFeatureToggles(companyFeature: ICompanyFeatureToggleViewModel): Observable<void> {
    if (!companyFeature || !companyFeature.companyId) {
      return of(null);
    }

    return this._httpClient.delete<void>(
      `${environment.adminUrl}/feature-toggle/companies/${companyFeature.companyId.toString(10)}`
    );
  }
}
