<div class="content-block">
  <h2>
    Import Tasks
  </h2>

  <div class="mb-4">
    <dx-data-grid class="dx-card wide-card" [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true"
                  [columnHidingEnabled]="true" [masterDetail]="{ enabled: true, template: 'details' }">
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
      <dxo-paging [pageSize]="15"></dxo-paging>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-builder></dxo-filter-builder>
      <dxo-filter-builder-popup></dxo-filter-builder-popup>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>

      <dxi-column dataField="id" dataType="number"></dxi-column>
      <dxi-column dataField="taskType">
        <dxo-lookup [dataSource]="taskTypes" valueExpr="id" displayExpr="text">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="taskStatus" cellTemplate="taskStatusCellTemplate">
        <dxo-lookup [dataSource]="taskStatuses" valueExpr="id" displayExpr="text">
        </dxo-lookup>
      </dxi-column>
      <dxi-column cellTemplate="fileCellTemplate" caption="File">
      </dxi-column>
      <dxi-column cellTemplate="importedRecordsCellTemplate" caption="Imported Records">
      </dxi-column>
      <dxi-column cellTemplate="failedRecordsCellTemplate" caption="Failed Records">
      </dxi-column>
      <dxi-column dataField="createdOn" dataType="datetime">
      </dxi-column>

      <div class="font-size-1rem"
           *dxTemplate="let taskStatusCell of 'taskStatusCellTemplate'">
        <span class="badge badge-pill"
              [class.badge-secondary]="taskStatusCell.value === TaskStatus.NotStarted"
              [class.badge-primary]="taskStatusCell.value === TaskStatus.Running"
              [class.badge-success]="taskStatusCell.value === TaskStatus.Success"
              [class.badge-warning]="taskStatusCell.value === TaskStatus.WithErrors"
              [class.badge-danger]="taskStatusCell.value === TaskStatus.Failed">
          {{taskStatusCell.text}}
        </span>
      </div>

      <div *dxTemplate="let fileCell of 'fileCellTemplate'">
        <a target="_blank"
           [href]="fileCell?.data?.file?.url">
          {{fileCell?.data?.file?.name}}
        </a>
      </div>

      <div *dxTemplate="let importedRecordsCell of 'importedRecordsCellTemplate'">
        {{getImportedRecordsCount(importedRecordsCell)}}
      </div>

      <div *dxTemplate="let failedRecordsCell of 'failedRecordsCellTemplate'">
        {{getFailedRecordsCount(failedRecordsCell)}}
      </div>

      <div class="flex-container"
           *dxTemplate="let detail of 'details'">
        <ng-container *ngIf="detail?.data?.taskStatus === TaskStatus.NotStarted">
          <p class="text-muted mb-0">
            Task is not yet started
          </p>
        </ng-container>

        <ng-container *ngIf="detail?.data?.taskStatus === TaskStatus.Success && getFailedRecordsCount(detail) === 0">
          <p class="text-muted mb-0">
            Task is completed without errors
          </p>
        </ng-container>

        <div *ngFor="let taskLog of detail?.data?.taskLogs">
          <ng-container *ngIf="taskLog?.message?.taskException">
            <div class="card">
              <div class="card-header p-4">
                <h5 class="mb-4">
                  Exception
                </h5>
                <pre class="mb-0 mw-100 pre-wrap"
                     [innerHTML]="taskLog?.message?.taskException?.Message">
                </pre>
              </div>
              <div class="card-body p-4">
                <h5 class="mb-4">
                  Stack trace
                </h5>
                <pre class="mb-0 mw-100 pre-wrap"
                     [innerHTML]="taskLog?.message?.taskException?.StackTraceString">
                </pre>
              </div>
            </div>
          </ng-container>

          <!-- Start: Failed Records -->
          <ng-container *ngIf="taskLog?.message?.failedRecords && taskLog?.message?.failedRecords?.length">
            <div class="my-2">
              <h4 class="font-weight-bold mb-2">
                Failed records
              </h4>
              <!-- Start: Record -->
              <div class="card my-4"
                   *ngFor="let record of taskLog?.message?.failedRecords;">
                <ng-container *ngIf="getGlobalErrors(record) as globalErrors">
                  <div class="card-header p-4">
                    <h5 class="mb-4">
                      {{globalErrors.length == 1 ? 'Error' : 'Errors'}}
                    </h5>

                    <p class="my-2"
                       *ngFor="let globalError of globalErrors">
                      {{globalError}}
                    </p>
                  </div>
                </ng-container>
                <div class="card-body p-0">
                  <!-- Start: Record Mapping -->
                  <div class="table-responsive">
                    <table class="table bg-white mb-0"
                           *ngIf="detail?.data?.payload?.fieldMappings; else noMappingsTemplate">
                      <thead>
                      <tr>
                        <th class="table-cell-field-position">Field Position</th>
                        <th>Field Name</th>
                        <th>Field Value</th>
                        <th class="table-cell-error">Error</th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container *ngIf="getRows(detail?.data?.payload?.fieldMappings, record) as rows">
                        <tr [class.table-row-errored]="hasRowError(record, row.fieldIndex, row.fieldName)"
                            *ngFor="let row of rows | sort: 'fieldIndex'">
                          <td class="table-cell-field-position">{{row.fieldIndex + 1}}</td>
                          <td>{{humanizeText(row.fieldName)}}</td>
                          <td>{{row.fieldValue}}</td>
                          <td class="table-cell-error">
                            <p class="mb-0 error-message">
                              {{getRowError(record, row.fieldIndex, row.fieldName)}}
                            </p>
                          </td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>

                    <ng-template #noMappingsTemplate>
                      <table class="table bg-white mb-0"
                             style="table-layout: fixed"
                             *ngIf="getRecordCells(record) as tableCells">
                        <thead>
                        <tr>
                          <th *ngFor="let header of tableCells.header">
                            {{header}}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                          <td *ngFor="let body of tableCells.body">
                            {{body}}
                          </td>
                        </tbody>
                      </table>
                    </ng-template>
                  </div>
                  <!-- End: Record Mapping -->
                </div>
              </div>
              <!-- End: Record -->
            </div>
          </ng-container>
          <!-- End: Failed Records -->
        </div>
      </div>
    </dx-data-grid>
  </div>
</div>

