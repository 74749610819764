<div id="lease-abstract-import-form" class="h-100">
  <form name="form" class="form" novalidate
        (ngSubmit)="updateLeaseAbstractImportDraft()" #form="ngForm">
    <div class="document-viewer-form-inner d-flex flex-column h-100" style="padding: 13px 0">
      <!-- Start: Import form header -->
      <div>
        <!-- Start: Fieldset -->
        <div class="dx-fieldset pb-0">
          <div class="dx-fieldset-header">
            <div class="d-flex flex-row flex-nowrap align-items-center mx-n1">
              <div class="px-1">
                Lease terms
              </div>
              <div class="px-1 ml-auto">
                <a role="button" class="close-cross cursor-pointer"
                   (click)="cancel.emit({form: form})">
                  <i class="fa fa-times">
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Fieldset -->
        <!-- Start: Fieldset -->
        <div class="dx-fieldset">
          <ul class="tabs">
            <li *ngFor="let tab of tabItems; let index = index;"
                [class.active]="tab.id === activeTabId">
              <a href="javascript:void(0)" role="button"
                 (click)="handleTabChange(tab)">
                {{tab.title}}
              </a>
            </li>
          </ul>
        </div>
        <!-- End: Fieldset -->
      </div>
      <!-- End: Import form header -->
      <div class="flex-grow-1 flex-shrink-1 overflow-hidden position-relative">
        <div class="achtung"
             *ngIf="isInterfaceLocked">
          <i class="fa fa-exclamation-triangle" style="font-size: 40px"></i>
          <p class="text-center header mt-4 mb-2">Attention!!!</p>
          <p class="text-center mb-1">The lease cannot be edited while the document is being analyzed</p>
          <p class="text-center mb-1">Please take a break and relax...</p>
          <p class="text-center mt-4 mb-0">
            <a class="btn btn-outline-danger btn-sm" href="https://img.randme.me/" target="_blank">
              Get random meme
            </a>
          </p>
        </div>
        <!-- Start: Import form tab container -->
        <dx-scroll-view width="100%" height="100%">
          <ng-container #tabContentContainer>
          </ng-container>
        </dx-scroll-view>
        <!-- End: Import form tab container -->
      </div>
      <div>
        <!-- Start: Fieldset -->
        <div class="dx-fieldset">
          <div class="d-flex flex-row mx-n2">
            <div class="px-2 w-50">
              <dx-button type="default" text="Save" width="100%"
                         [disabled]="isInterfaceLocked"
                         [useSubmitBehavior]="true">
              </dx-button>
            </div>
            <div class="px-2 w-50">
              <ng-container *ngIf="lease.leaseStatus === LeaseStatus.Draft; else leaseNotADraft">
                <dx-button type="default" text="Publish" width="100%"
                           [disabled]="isInterfaceLocked"
                           (onClick)="publishLeaseAbstractImportDraft()">
                </dx-button>
              </ng-container>
              <ng-template #leaseNotADraft>
                <dx-button type="default" text="Draft" width="100%"
                           [disabled]="isInterfaceLocked"
                           (onClick)="draftLeaseAbstractImportDraft()">
                </dx-button>
              </ng-template>
            </div>
          </div>
          <div class="mt-3" *ngIf="lease.leaseActionType === LeaseActionType.LeaseShouldBeAbstracted">
            <dx-button type="normal" text="Abstract Lease" width="100%"
                       [disabled]="isInterfaceLocked"
                       (onClick)="changeLeaseActionType()">
            </dx-button>
          </div>
          <div class="mt-3">
            <dx-button type="normal" text="Cancel" width="100%"
                       (onClick)="cancel.emit({form: form})">
            </dx-button>
          </div>
        </div>
        <!-- End: Fieldset -->
      </div>
    </div>
  </form>
</div>

<dx-load-panel [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false"
               [position]="{of: '#lease-abstract-import-form'}" [visible]="this.isLoading$ | async">
</dx-load-panel>
