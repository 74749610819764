import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';
import { TermService } from '../../services/term.service';

import * as models from '../../../../shared/models/generated';

@Component({
  templateUrl: 'rental-rate-escalation-custom-table-editor.component.html',
  styleUrls: ['rental-rate-escalation-custom-table-editor.component.scss'],
})
export class RentalRateEscalationCustomTableEditorComponent implements OnInit, OnDestroy {
  ValueType: typeof models.RentalRateAnnualEscalationTermCustomValueType = models.RentalRateAnnualEscalationTermCustomValueType;

  customValues: Array<models.IRentalRateAnnualEscalationTermCustomValue>;
  customResults: Array<models.IRentalRateAnnualEscalationTermCustomResult>;

  increaseTypeOptions: Array<{text: string, value: models.RentalRateAnnualEscalationTermCustomValueType}> = [
    {text: 'Percentage', value: models.RentalRateAnnualEscalationTermCustomValueType.FixedPercentagePerYear},
    {text: 'PSF increase', value: models.RentalRateAnnualEscalationTermCustomValueType.FixedAmountPsfPerYear},
  ];

  repeatToEndOptions: Array<{text: string, value: models.RentalRateAnnualEscalationTermCustomRepeatType}> = [
    {text: 'No', value: models.RentalRateAnnualEscalationTermCustomRepeatType.No},
    {text: 'Every year', value: models.RentalRateAnnualEscalationTermCustomRepeatType.EveryYear},
    {text: 'Month', value: models.RentalRateAnnualEscalationTermCustomRepeatType.Month},
  ];

  isPreviewCalculating: boolean;

  readonly lease: models.ILeaseViewModel;

  readonly saveCustomValues: (customValues: Array<models.IRentalRateAnnualEscalationTermCustomValue>) => void;
  readonly saveCustomResults: (customResults: Array<models.IRentalRateAnnualEscalationTermCustomResult>) => void;

  private readonly _popupService: PopupService;
  private readonly _popupRefService: PopupRefService;
  private readonly _termService: TermService;
  private readonly _currencyPipe: CurrencyPipe;
  private readonly _destroy$: Subject<void>;

  constructor(popupService: PopupService, popupRefService: PopupRefService, termService: TermService, currencyPipe: CurrencyPipe) {
    this._popupService = popupService;
    this._popupRefService = popupRefService;
    this._termService = termService;
    this._currencyPipe = currencyPipe;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.prepareCustomValues();

    this._popupRefService.onContentReady
      .pipe(
        tap((event) => {
          const contentElement = event.component.content();
          if (contentElement) {
            contentElement.style.padding = '0';
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  prepareCustomValues(): void {
    let customValues = [];
    if (
      this.lease &&
      this.lease.rentalRateAnnualEscalationTerm &&
      this.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomValues
    ) {
      customValues = [...this.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomValues];
    }

    this.customValues = customValues;
    this.customValues.forEach((x, i) => this.calculateIncreaseDate(x.leaseMonth, i));

    if (!this.customValues.length) {
      this.pushCustomValue();
    }

    this.calculatePreview();
  }

  pushCustomValue(): void {
    if (!this.customValues) {
      return;
    }

    const customValue = <models.IRentalRateAnnualEscalationTermCustomValue>{
      rentalRateAnnualEscalationTermCustomValueType: models.RentalRateAnnualEscalationTermCustomValueType.FixedAmountPsfPerYear,
      rentalRateAnnualEscalationTermCustomRepeatType: models.RentalRateAnnualEscalationTermCustomRepeatType.No,
      stepIncreasePsfValue: 0,
    };

    this.customValues.push(customValue);
  }

  clearCustomValues(): void {
    this.customValues = [];
    this.customResults = [];

    this.pushCustomValue();
  }

  removeCustomValue(index: number): void {
    if (!this.customValues) {
      return;
    }

    this.customValues.splice(index, 1);

    if (!this.customValues.length) {
      this.pushCustomValue();
    }
  }

  calculateIncreaseDate(leaseMonth: number, index: number): void {
    if (leaseMonth <= 0 || !this.customValues || !this.lease || !this.lease.commencementTerm) {
      return;
    }

    const commencementDate = this.lease.commencementTerm.commencement;
    if (!commencementDate) {
      return;
    }

    this.customValues[index].increaseDate = moment(commencementDate).startOf('day').add(leaseMonth - 1, 'months').format('YYYY-MM-DD');
  }

  reorderCustomValues(): void {
    if (!this.customValues) {
      return;
    }

    this.customValues = this.customValues.sort((a, b) => a.leaseMonth - b.leaseMonth);
  }

  canCalculatePreview(): boolean {
    if (!this.customValues) {
      return false;
    }

    let canCalculatePreview = false;

    for (let i = 0, num = this.customValues.length; i < num; i++) {
      const customValue = this.customValues[i];

      canCalculatePreview = canCalculatePreview || (customValue && !!customValue.leaseMonth);
    }

    return canCalculatePreview;
  }

  getPreviewUnitMetrics(): string {
    let unitMetrics = '$ / PSF / Yr';
    if (!this.lease || !this.lease.baseRentalRateTerm) {
      return unitMetrics;
    }

    if (this.lease.baseRentalRateTerm.baseRentalRateUnitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetrics = '$ / PSF / Mo';
    }

    return unitMetrics;
  }

  calculatePreview(): void {
    if (!this.canCalculatePreview()) {
      return;
    }

    this.isPreviewCalculating = true;

    this._requestPreview()
      .pipe(
        tap((customResults) => {
          this.customResults = customResults;
        }),
        catchError((err) => {
          console.error(err);

          return throwError(err);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => this.isPreviewCalculating = false);
  }

  submit(form: NgForm): void {
    if (form.invalid && !this.canCalculatePreview()) {
      return;
    }

    const customValues = [...this.customValues];

    this._requestPreview()
      .pipe(
        tap((customResults) => {
          if (typeof this.saveCustomValues === 'function') {
            this.saveCustomValues(customValues);
          }

          if (typeof this.saveCustomResults === 'function') {
            this.saveCustomResults(customResults);
          }

          this._popupRefService.hide();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _requestPreview(): Observable<Array<models.IRentalRateAnnualEscalationTermCustomResult>> {
    if (!this.canCalculatePreview()) {
      return of([]);
    }

    const lease = <models.ILeaseViewModel>{
      ...(this.lease || {}),
      rentalRateAnnualEscalationTerm: <models.IRentalRateAnnualEscalationTermViewModel>{
        ...(this.lease.rentalRateAnnualEscalationTerm || {}),
        leaseTermType: models.LeaseTermType.RentalRateAnnualEscalation,
        escalationTermType: models.EscalationTermType.Custom,
        rentalRateAnnualEscalationTermCustomValues: this.customValues.filter(x => x.leaseMonth),
      },
    };

    return this._termService.getRentalRateEscalationPreview(lease);
  }

  cancel(): void {
    this._popupRefService.hide();
  }

  getStartingRate(): string {
    if (!this.lease || !this.lease.baseRentalRateTerm) {
      return null;
    }

    const { baseRentalRateTerm } = this.lease;

    let unitMetrics = 'PSF/Yr';
    if (baseRentalRateTerm.baseRentalRateUnitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetrics = 'PSF/Mo';
    }

    const baseRentalRate = this._getBaseRentalRate();
    if (!baseRentalRate) {
      return `$0 ${unitMetrics}`;
    }

    return `${this._currencyPipe.transform(baseRentalRate)} ${unitMetrics}`;
  }

  areAllRequiredTermsValid(): boolean {
    if (!this.lease) {
      return false;
    }

    return (
      this.isCommencementTermValid() &&
      this.isLengthTermValid() &&
      this.isTenantSquareFootageValid() &&
      this.isBaseRentalRateTermValid()
    );
  }

  isCommencementTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const commencementTerm = <models.ICommencementTermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.Commencement);

    if (!commencementTerm) {
      return false;
    }

    return !!commencementTerm.commencement;
  }

  isLengthTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const lengthTerm = <models.ITermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.Term);

    if (!lengthTerm) {
      return false;
    }

    return (
      lengthTerm.termType &&
      !!lengthTerm.termValue
    );
  }

  isTenantSquareFootageValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const tenantSquareFootage = <models.ITenantSquareFootageTermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.TenantSquareFootage);

    if (!tenantSquareFootage) {
      return false;
    }

    return (
      tenantSquareFootage.tenantSquareFootageTermType &&
      (
        (
          tenantSquareFootage.tenantSquareFootageTermType !== models.TenantSquareFootageTermType.Custom
        ) ||
        (
          tenantSquareFootage.tenantSquareFootageTermType === models.TenantSquareFootageTermType.Custom &&
          !!tenantSquareFootage.tenantSquareFootageCustomValue
        )
      )
    );
  }

  isBaseRentalRateTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const baseRentalRate = <models.IBaseRentalRateViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.BaseRentalRate);

    const realEstateTaxesAndCamExpenses = <models.IRealEstateTaxesCamExpensesViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.RealEstateTaxesCamExpenses);

    if (!baseRentalRate) {
      return false;
    }

    return (
      baseRentalRate.baseRentalRateUnitMetrics &&
      baseRentalRate.baseRentalRateType &&
      (
        (
          baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Net &&
          !!baseRentalRate.baseRentalRateNetValue
        ) ||
        (
          baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Gross &&
          !!baseRentalRate.baseRentalRateGrossValue
        ) ||
        (
          baseRentalRate.baseRentalRateType === models.BaseRentalRateType.BaseYear &&
          !!baseRentalRate.actualYearRate &&
          (
            realEstateTaxesAndCamExpenses &&
            !!realEstateTaxesAndCamExpenses.baseYearForExpenses
          )
        )
      )
    );
  }

  private _getBaseRentalRate(): number {
    if (!this.lease || !this.lease.baseRentalRateTerm) {
      return 0;
    }

    const { baseRentalRateTerm } = this.lease;

    switch (baseRentalRateTerm.baseRentalRateType) {
      case models.BaseRentalRateType.Net:
        return baseRentalRateTerm.baseRentalRateNetValue;

      case models.BaseRentalRateType.Gross:
        return baseRentalRateTerm.baseRentalRateGrossValue;

      case models.BaseRentalRateType.BaseYear:
        return baseRentalRateTerm.actualYearRate;

      default:
        return 0;
    }
  }
}
