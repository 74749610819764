<div class="content-block">
  <h2>
    User Invitation Requests
  </h2>

  <dx-data-grid class="dx-card wide-card"
                [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true"
                [columnHidingEnabled]="true" [masterDetail]="{ enabled: false }" [remoteOperations]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                #dxDataGrid>

    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-editing mode="row"></dxo-editing>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder></dxo-filter-builder>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxi-column dataField="id" caption="Id"
                [width]="70">
    </dxi-column>

    <dxi-column dataField="requestStatus" caption="Request status" cellTemplate="requestStatusFieldTemplate">
      <dxo-lookup valueExpr="id" displayExpr="text"
                  [dataSource]="requestStatuses">
      </dxo-lookup>
    </dxi-column>

    <div class="lookup-user-field"
         *dxTemplate="let item of 'requestStatusFieldTemplate'">
      <div class="text-left">
        {{getRequestStatus(item?.data?.requestStatus)}}

        <div class="text-muted m-0"
             *ngIf="getRequestStatusDetails(item?.data?.requestStatus) as requestStatusDetails">
          {{requestStatusDetails}}
        </div>
      </div>
    </div>

    <dxi-column dataField="firstName" caption="First name">
    </dxi-column>

    <dxi-column dataField="lastName" caption="Last name">
    </dxi-column>

    <dxi-column dataField="role" caption="Role">
    </dxi-column>

    <dxi-column dataField="emailAddress" caption="Email address">
    </dxi-column>

    <dxi-column caption="Invited to company" cellTemplate="companyFieldTemplate">
    </dxi-column>

    <div class="lookup-user-field"
         *dxTemplate="let item of 'companyFieldTemplate'">
      <div class="text-left">
        {{item?.data?.companyName}}
        <ng-container *ngIf="item?.data?.companyId; else unexistingCompany">
          <span class="badge badge-pill badge-success">
            Existing: #{{item.data.companyId}}
          </span>
        </ng-container>
        <ng-template #unexistingCompany>
          <span class="badge badge-pill badge-warning">
            New
          </span>
        </ng-template>
      </div>
    </div>

    <dxi-column dataField="leaseId" caption="Invited to lease">
      <dxo-lookup valueExpr="leaseId"
                  [dataSource]="leaseDataSource" [displayExpr]="Tools.shortLeaseNameExpression">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="requestedById" caption="Invited by" cellTemplate="invitedByFieldTemplate">
      <dxo-lookup valueExpr="id"
                  [dataSource]="userDataSource" [displayExpr]="Tools.getDisplayExpression">
      </dxo-lookup>
    </dxi-column>

    <div class="lookup-user-field"
         *dxTemplate="let item of 'invitedByFieldTemplate'">
      <img alt=""
           [src]="getRequestedByUserAvatar(item)" />
      <div>
        {{ getRequestedByUserDisplayName(item) }}
      </div>
    </div>

    <dxi-column dataField="createdOn" caption="Invited on" dataType="date">
    </dxi-column>

    <dxi-column type="buttons">
      <dxi-button text="Invite user"
                  [visible]="isInviteButtonVisible"
                  [onClick]="acceptUserInvitationRequest">
      </dxi-button>
      <dxi-button text="Resend"
                  [visible]="isResendInvitationButtonVisible"
                  [onClick]="resendUserInvitationRequest">
      </dxi-button>
      <dxi-button text="Delete"
                  [visible]="isDeleteButtonVisible"
                  [onClick]="deleteUserInvitationRequest">
      </dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
