<h2 class="content-block">Users</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [showBorders]="false"
              [columnAutoWidth]="true" [columnHidingEnabled]="true"
              (onEditorPreparing)="onEditorPreparing($event)"
              (onEditingStart)="onEditingStart($event)">
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="popup"
               [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="true">
    <dxo-popup title="Edit user" width="70%" height="90%"
               [showTitle]="true" [position]="{ my: 'center', at: 'center', of: _window }">
    </dxo-popup>
    <dxo-form [colCount]="2">
      <dxi-item itemType="group">
        <dxi-item itemType="group" caption="User properties">
          <dxi-item dataField="role" [editorOptions]="{ showClearButton: true }"></dxi-item>
          <dxi-item dataField="primaryEmail"></dxi-item>
          <dxi-item dataField="password"></dxi-item>
          <dxi-item dataField="emailConfirmed"></dxi-item>
          <dxi-item dataField="phoneNumber" [editorOptions]="phoneNumberFieldEditorOptions"></dxi-item>
          <dxi-item dataField="twoFactorEnabled"></dxi-item>
          <dxi-item dataField="userStatus" [editorOptions]="{ showClearButton: true }"></dxi-item>
          <dxi-item dataField="companyId" isRequired="true"></dxi-item>
          <dxi-item dataField="emailDuplicatingToAdminEnabled"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Common properties">
          <dxi-item dataField="firstName">
            <dxi-validation-rule type="required" message="First name is required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="lastName">
            <dxi-validation-rule type="required" message="Last name is required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="title"></dxi-item>
          <dxi-item dataField="mobilePhone" [editorOptions]="phoneNumberFieldEditorOptions"></dxi-item>
          <dxi-item dataField="additionalEmailAddress"></dxi-item>
          <dxi-item dataField="documentsMode" [editorOptions]="{ showClearButton: true }"></dxi-item>
        </dxi-item>
      </dxi-item>
      <dxi-item itemType="group">
        <dxi-item itemType="group" caption="Tenant Profile">
          <dxi-item dataField="industryId" [editorOptions]="{ showClearButton: true }"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Landlord Profile">
          <dxi-item dataField="investorType" [editorOptions]="{ showClearButton: true }"></dxi-item>
          <dxi-item dataField="markets"></dxi-item>
          <dxi-item dataField="portfolioSizeIndustrial"></dxi-item>
          <dxi-item dataField="portfolioSizeLand"></dxi-item>
          <dxi-item dataField="portfolioSizeOffice"></dxi-item>
          <dxi-item dataField="portfolioSizeRetail"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Actions">
          <dxi-item>
            <div class="dx-item-content dx-box-item-content">
              <div class="dx-datagrid-edit-form-item dx-first-col dx-last-col dx-field-item dx-field-item-tab dx-col-0 dx-field-item-optional dx-flex-layout dx-label-h-align">
                <label class="dx-field-item-label dx-field-item-label-location-left">
                  <span class="dx-field-item-label-content">
                    <span class="dx-field-item-label-text">
                      Email Verification Message:
                    </span>
                  </span>
                </label>
                <div class="dx-field-item-content dx-field-item-content-location-right">
                  <a role="button" href="javascript:void(0)"
                     [class.disabled]="isEmailVerificationMessageLoading"
                     (click)="sendEmailVerificationMessage(userId)">
                    Send
                  </a>
                </div>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
      </dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxi-column dataField="id"></dxi-column>
  <dxi-column dataField="password" [visible]="false"></dxi-column>
  <dxi-column dataField="primaryEmail"></dxi-column>
  <dxi-column dataField="userStatus" dataType="number">
    <dxo-lookup [dataSource]="userStatusItems" valueExpr="id" displayExpr="text"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="emailConfirmed" dataType="boolean"></dxi-column>
  <dxi-column dataField="role">
    <dxo-lookup [dataSource]="roles" valueExpr="name" displayExpr="name"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="firstName" dataType="string"></dxi-column>
  <dxi-column dataField="lastName" dataType="string"></dxi-column>
  <dxi-column dataField="companyId" cellTemplate="companyFieldTemplate">
    <dxo-lookup [dataSource]="companyDataStore" valueExpr="id" [displayExpr]="Tools.getCompanyExpression">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="title" dataType="string"></dxi-column>
  <dxi-column dataField="phoneNumber">
    <dxi-validation-rule type="custom"
                         message="The phone number is required for two factor authentication"
                         [validationCallback]="twoFactorAuthPhoneNumberValidationCallback">
    </dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="phoneNumberConfirmed" dataType="boolean"></dxi-column>
  <dxi-column dataField="twoFactorEnabled" dataType="boolean"></dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'companyFieldTemplate'">
    <div>
      {{ item?.data?.company?.name }}
    </div>
  </div>
  <dxi-column dataField="additionalEmailAddress"></dxi-column>
  <dxi-column dataField="mobilePhone"></dxi-column>
  <dxi-column dataField="documentsMode" dataType="number">
    <dxo-lookup [dataSource]="documentsModeItems" valueExpr="id" displayExpr="text"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="industryId" dataType="number"  caption="Industry">
    <dxo-lookup [dataSource]="industryItems" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="investorType" dataType="number">
    <dxo-lookup [dataSource]="investorTypeItems" valueExpr="id" displayExpr="text"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="markets"></dxi-column>
  <dxi-column dataField="portfolioSizeIndustrial" dataType="number"></dxi-column>
  <dxi-column dataField="portfolioSizeLand" dataType="number"></dxi-column>
  <dxi-column dataField="portfolioSizeOffice" dataType="number"></dxi-column>
  <dxi-column dataField="portfolioSizeRetail" dataType="number"></dxi-column>
  <dxi-column dataField="emailDuplicatingToAdminEnabled" dataType="boolean"></dxi-column>


  <div *dxTemplate="let data of 'avatarCellTemplate'" class="avatar-image">
    <img [src]="data.value" width="50" />
  </div>

  <dxi-column type="buttons">
    <dxi-button name="edit">
    </dxi-button>
    <dxi-button name="impersonate-custom" text="Impersonate"
                [onClick]="handleImpersonateClick">
    </dxi-button>
  </dxi-column>
</dx-data-grid>
