import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { LeaseAbstractService } from '../../services/lease-abstract.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';

import * as models from '../../../../shared/models/generated';

import { ImportFormSelectItem } from '../../models/import-form-select-item.model';

import { ImportComponent } from '../import/import.component';
import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

@Component({
  templateUrl: 'upload.component.html',
  styleUrls: ['upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
  private static readonly _leaseTypes: Array<ImportFormSelectItem<models.LeaseType>> = [
    {
      name: 'Internal',
      value: models.LeaseType.Internal,
    },
    {
      name: '3rd Party Lease',
      value: models.LeaseType.ThirdPartyLease,
    },
  ];

  private static readonly _leaseMetrics: Array<ImportFormSelectItem<models.BaseRentalRateUnitMetrics>> = [
    {
      name: 'PSF/YR',
      value: models.BaseRentalRateUnitMetrics.PsfPerYear,
    },
    {
      name: 'PSF/MO',
      value: models.BaseRentalRateUnitMetrics.PsfPerMonth,
    },
  ];

  @ViewChild(NgForm, {static: false}) form: NgForm;
  @ViewChild(DxFileUploaderComponent, {static: false}) fileUploader: DxFileUploaderComponent;

  lease: models.ILeaseViewModel;
  leaseAbstractDocument: File;
  LeaseType = models.LeaseType;

  landlordCompaniesList$: Observable<Array<models.ICompanyViewModel>>;
  tenantCompaniesList$: Observable<Array<models.ICompanyViewModel>>;

  leaseTypeList: Array<ImportFormSelectItem<models.LeaseType>>;
  leaseMetrics: Array<ImportFormSelectItem<models.BaseRentalRateUnitMetrics>>;

  buildingsList: Array<models.IBuildingViewModel>;
  buildingUnitsList: Array<models.IBuildingUnitViewModel>;
  abstractLeasesList: Array<models.ILeaseViewModel>;

  shouldUploadAnother: boolean;

  isLoading$: BehaviorSubject<boolean>;

  reloadLeases: () => Promise<unknown>;

  private _leaseAbstractImportDraftSubscription: Subscription;

  private readonly _leaseAbstractService: LeaseAbstractService;
  private readonly _popupRefService: PopupRefService;
  private readonly _popupService: PopupService;
  private readonly _destroy$: Subject<void>;

  constructor(
    leaseAbstractService: LeaseAbstractService,
    popupRefService: PopupRefService,
    popupService: PopupService
  ) {
    this._leaseAbstractService = leaseAbstractService;
    this._popupRefService = popupRefService;
    this._popupService = popupService;
    this._destroy$ = new Subject<void>();

    this.landlordCompaniesList$ = this._leaseAbstractService.getLandlordCompaniesList();
    this.tenantCompaniesList$ =  this._leaseAbstractService.getTenantCompaniesList();

    this.buildingsList = [];
    this.buildingUnitsList = [];
    this.abstractLeasesList = [];
    this.leaseTypeList = UploadComponent._leaseTypes;
    this.leaseMetrics = UploadComponent._leaseMetrics;

    this.shouldUploadAnother = false;

    this.isLoading$ = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
    this.lease = <models.ILeaseViewModel>{ amendmentNumber: 0, leaseType: models.LeaseType.Internal };

    this._setupPopupConfig();
  }

  ngOnDestroy(): void {
    if (this._leaseAbstractImportDraftSubscription) {
      this._leaseAbstractImportDraftSubscription.unsubscribe();
    }

    this.resetForm();
  }

  setupSearchableCompanyFields(company: models.ICompanyViewModel): Array<string> {
    return [
      company.id.toString(10),
      company.name,
    ];
  }

  setupSearchableBuildingFields(building: models.IBuildingViewModel): Array<string> {
    const searchableFields = [
      building.id.toString(10),
      building.name,
    ];

    if (building.address) {
      if (building.address.addressLine1) {
        searchableFields.push(building.address.addressLine1);
      }

      if (building.address.addressLine2) {
        searchableFields.push(building.address.addressLine2);
      }

      if (building.address.city) {
        searchableFields.push(building.address.city);
      }

      if (building.address.stateCode) {
        searchableFields.push(building.address.stateCode);
      }

      if (building.address.zipCode) {
        searchableFields.push(building.address.zipCode);
      }
    }

    return searchableFields;
  }

  setupSearchableBuildingUnitFields(buildingUnit: models.IBuildingUnitViewModel): Array<string> {
    return [
      buildingUnit.id.toString(10),
      buildingUnit.name,
    ];
  }

  setupSearchableAbstractLeaseFields(abstractLease: models.ILeaseViewModel): Array<string> {
    const searchableFields = [
      abstractLease.id.toString(10),
    ];

    if (abstractLease.landlordCompany && abstractLease.landlordCompany.name) {
      searchableFields.push(abstractLease.landlordCompany.name);
    }

    if (abstractLease.tenantCompany && abstractLease.tenantCompany.name) {
      searchableFields.push(abstractLease.tenantCompany.name);
    }

    if (abstractLease.building) {
      if (abstractLease.building.name) {
        searchableFields.push(abstractLease.building.name);
      }

      if (abstractLease.building.address) {
        if (abstractLease.building.address.addressLine1) {
          searchableFields.push(abstractLease.building.address.addressLine1);
        }

        if (abstractLease.building.address.addressLine2) {
          searchableFields.push(abstractLease.building.address.addressLine2);
        }

        if (abstractLease.building.address.city) {
          searchableFields.push(abstractLease.building.address.city);
        }

        if (abstractLease.building.address.stateCode) {
          searchableFields.push(abstractLease.building.address.stateCode);
        }

        if (abstractLease.building.address.zipCode) {
          searchableFields.push(abstractLease.building.address.zipCode);
        }
      }
    }

    if (abstractLease.buildingUnit && abstractLease.buildingUnit.name) {
      searchableFields.push(abstractLease.buildingUnit.name);
    }

    return searchableFields;
  }

  handleLeaseTypeChange(event: { value: models.LeaseType, previousValue: models.LeaseType }) {
    if (!event || !event.value || event.value === event.previousValue) {
      return;
    }

    if (event.value === models.LeaseType.ThirdPartyLease) {
      this.lease.landlordCompanyId = null;
      this._getBuildingList();
    }
  }

  handleLandlordCompanyChange(event: { value: number, previousValue: number }): void {
    if (!event || !event.value || event.value === event.previousValue) {
      return;
    }

    this._getBuildingList(event.value);
  }

  handleTenantCompanyChange(event: { value: number, previousValue: number }): void {
    if (!event || !event.value || event.value === event.previousValue) {
      return;
    }

    this._dropAbstractLeasePropertiesAndFillAbstractLeasesStore();
  }

  handleBuildingChange(event: { value: number, previousValue: number }): void {
    if (!event || !event.value || event.value === event.previousValue) {
      return;
    }

    this.lease.buildingUnitId = null;

    this.buildingUnitsList = [];

    if (this.buildingsList && this.buildingsList.length) {
      const building = this.buildingsList.find(x => x.id === event.value);
      if (building) {
        this.buildingUnitsList = building.buildingUnits;
      }
    }

    this._dropAbstractLeasePropertiesAndFillAbstractLeasesStore();
  }

  handleBuildingUnitChange(event: { value: number, previousValue: number }): void {
    if (!event || !event.value || event.value === event.previousValue) {
      return;
    }

    this._dropAbstractLeasePropertiesAndFillAbstractLeasesStore();
  }

  handleAbstractLeaseChange(event: { selectedItem: models.ILeaseViewModel }): void {
    if (!event || !event.selectedItem) {
      return;
    }

    this.lease.amendmentNumber = event.selectedItem.amendmentNumber + 1;
  }

  handleFileChange(event: { value: Array<File>, previousValue: Array<File> }): void {
    if (event.value && event.value instanceof Array && 0 < event.value.length) {
      this.leaseAbstractDocument = event.value.shift();
      return;
    }

    this.leaseAbstractDocument = null;
  }

  getLeaseName(lease: models.ILeaseViewModel): string {
    if (!lease) {
      return null;
    }

    const leaseNameSegments = [
      lease.building && lease.building.name,
      lease.buildingUnit && lease.buildingUnit.name,
    ];

    const tenantLandlordRelationSegments = [
      lease.landlordCompany && lease.landlordCompany.name,
      lease.tenantCompany && lease.tenantCompany.name,
    ];

    const addressAndCompanyNames = leaseNameSegments
      .concat(
        tenantLandlordRelationSegments
          .filter(Boolean)
          .join(' - ')
      )
      .filter(Boolean)
      .join(', ');

    return `Lease #${lease.id}: ${addressAndCompanyNames}`;
  }

  removeLeaseAbstractDocument(): void {
    this.leaseAbstractDocument = null;

    if (this.fileUploader && this.fileUploader.instance) {
      this.fileUploader.instance.reset();
    }
  }

  createLeaseAbstractImportDraft(): void {
    if (this.form && this.form.invalid) {
      return;
    }

    const getLeaseAbstractObserver = (documentId?: number): Observable<models.ILeaseAbstractImportDraftViewModel> => {
      return this._leaseAbstractService
        .createLeaseAbstractImportDraft(<models.ILeaseAbstractImportDraftViewModel>{
          documentId: documentId,
          leaseId: null,
          lease: {...this.lease},
        });
    };

    let observer: Observable<models.ILeaseAbstractImportDraftViewModel>;

    if (!this.leaseAbstractDocument) {
      observer = getLeaseAbstractObserver();
    } else {
      observer = this._leaseAbstractService
        .uploadLeaseAbstractDocument(this.leaseAbstractDocument)
        .pipe(
          switchMap(leaseAbstractDocument => getLeaseAbstractObserver(leaseAbstractDocument.id)),
        );
    }

    this.isLoading$.next(true);

    observer
      .pipe(
        tap(() => {
          this.removeLeaseAbstractDocument();
          this._fillAbstractLeasesStore();

          this.isLoading$.next(false);
        }),
        catchError(err => {
          this.isLoading$.next(false);

          return throwError(err);
        }),
        switchMap(leaseAbstractImportDraft => {
          if (this.shouldUploadAnother) {
            return of(null);
          }

          this._popupRefService.hide();

          return this._popupRefService
            .onHidden
            .pipe(
              tap(() => {
                this._popupService.show(ImportComponent, {
                  fullScreen: true,
                  showCloseButton: false,
                  closeOnOutsideClick: false,
                  dragEnabled: false,
                  injectableData: {
                    leaseAbstractImportDraftId: leaseAbstractImportDraft.leaseId,
                    reloadLeases: () => this.reloadLeases(),
                  },
                });
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  resetForm(): void {
    if (this.form) {
      this.form.resetForm();
    }

    this.removeLeaseAbstractDocument();
  }

  cancel(): void {
    if (this.form && this.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  isLandlordCompanyRequired(lease: models.ILeaseViewModel) {
    return lease && lease.leaseType === models.LeaseType.Internal;
  }

  private _getBuildingList(buildingId: number = null) {
    this.lease.buildingId = null;

    this.buildingsList = [];

    this.isLoading$.next(true);

    let getBuildingsSubscriptions = this._leaseAbstractService
      .getLandlordCompanyBuildingsList(buildingId);

    if (buildingId == null) {
      getBuildingsSubscriptions = this._leaseAbstractService.get3rdPartyBuildingsList();
    }

    getBuildingsSubscriptions
      .pipe(
        take(1),
        tap(buildingsList => {
          this.buildingsList = buildingsList;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => this.isLoading$.next(false));

    this._dropAbstractLeasePropertiesAndFillAbstractLeasesStore();
  }

  private _dropAbstractLeasePropertiesAndFillAbstractLeasesStore(): void {
    if (!this.lease) {
      return;
    }

    this.lease.abstractLeaseId = null;

    this._fillAbstractLeasesStore();
  }

  private _fillAbstractLeasesStore(): void {
    if (
      !this.lease ||
      !this.lease.landlordCompanyId ||
      !this.lease.tenantCompanyId ||
      !this.lease.buildingId
    ) {
      return;
    }

    this.isLoading$.next(true);

    this._leaseAbstractService
      .getAbstractLeasesList(this.lease)
      .pipe(
        take(1),
        tap(abstractLeases => {
          if (!abstractLeases) {
            abstractLeases = [];
          }

          this.abstractLeasesList = abstractLeases;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => this.isLoading$.next(false));
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel the lease abstract uploading process?',
        acceptFn: () => this._popupRefService.hide(),
      },
    });
  }

  private _setupPopupConfig(): void {
    const subscription = this._popupRefService.onContentReady.subscribe((event) => {
      const contentElement = event.component.content();
      if (contentElement) {
        contentElement.style.padding = '0';
      }

      subscription.unsubscribe();
    });
  }
}
