import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as models from '../../../shared/models/generated';

@Injectable()
export class BuildingService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  getBuildingGeopointByAddress(address: string): Observable<models.IGeopoint> {
    let params = new HttpParams();

    if (address) {
      params = params.set('address', address);
    }

    return this._httpClient
      .get<models.IGeopoint>(`/admin/building/geopoint`, {params});
  }

  /**
   * Requests and returns the list of buildings in portfolio
   * @method BuildingService.getBuildingListByPortfolioId
   * @param portfolioId Portfolio id
   * @returns The list of buildings in portfolio
   */
  getBuildingListByPortfolioId(portfolioId?: number): Observable<{ data: Array<models.IBuildingViewModel> }> {
    let params = new HttpParams();

    if (portfolioId) {
      params = params.set('id', portfolioId.toString());
    }

    return this._httpClient
      .get<{ data: Array<models.IBuildingViewModel> }>(
        `/admin/building`,
        {
          params,
        },
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of units in building
   * @method BuildingService.getBuildingUnitsListByBuildingId
   * @param buildingId Building id
   * @returns The list of units in building
   */
  getBuildingUnitsListByBuildingId(buildingId?: number): Observable<{ data: Array<models.IBuildingUnitViewModel> }> {
    return this._httpClient
      .get<{ data: Array<models.IBuildingUnitViewModel> }>(
        `/admin/building/${buildingId}/units`,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
