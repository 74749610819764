import { Component, OnInit } from '@angular/core';

import { PopupRefService } from '../../services/popup-ref.service';

@Component({
  templateUrl: 'notice.component.html',
  styleUrls: ['notice.component.scss'],
})
export class NoticeComponent implements OnInit {
  private readonly _popupRefService: PopupRefService;

  message: string;
  acceptFn: () => void;
  declineFn: () => void;

  constructor(popupRefService: PopupRefService) {
    this._popupRefService = popupRefService;
  }

  ngOnInit(): void {
    this.message = this.message || '';
    this.acceptFn = this.acceptFn || Function;
    this.declineFn = this.declineFn || Function;
  }

  accept(): void {
    this._popupRefService.hide();

    const onHiddenSubscription = this._popupRefService.onHidden.subscribe(() => {
      this.acceptFn();
      onHiddenSubscription.unsubscribe();
    });
  }

  decline(): void {
    this._popupRefService.hide();

    const onHiddenSubscription = this._popupRefService.onHidden.subscribe(() => {
      this.declineFn();
      onHiddenSubscription.unsubscribe();
    });
  }
}
