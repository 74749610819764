<div class="document-viewer-wrapper">
  <ng-container *ngIf="leaseAbstractImportDraft$ | async as leaseAbstractImportDraft">
    <div class="document-viewer">
      <!-- Start: Document viewer options -->
      <div class="document-viewer-options">
        <dx-scroll-view width="100%" height="100%">
          <!-- Start: View options -->
            <app-import-document-viewer-options [documentViewerOptions]="documentViewerOptions"
                                                [documentViewerOptionsChange]="documentViewerOptionsChange"
                                                [lease]="leaseAbstractImportDraft.lease"
                                                [reloadLeaseAbstractImportDraft]="reloadLeaseAbstractImportDraft"
                                                [importTaskId]="leaseAbstractImportDraft.importTaskId">
            </app-import-document-viewer-options>
          <!-- End: View options -->
        </dx-scroll-view>
      </div>
      <!-- End: Document viewer options -->
      <!-- Start: PDF Viewer -->
      <div class="document-viewer-pdf">
        <app-import-document-viewer [documentViewerOptions]="documentViewerOptions"
                                    [documentViewerOptionsChange]="documentViewerOptionsChange"
                                    [document]="leaseAbstractImportDraft.document"
                                    [leaseAbstractImportDraftId]="leaseAbstractImportDraft.leaseId"
                                    [importTaskId]="leaseAbstractImportDraft.importTaskId">
        </app-import-document-viewer>
      </div>
      <!-- End: PDF Viewer -->
      <!-- Start: Document viewer form -->
      <div class="document-viewer-form">
        <app-import-form [(lease)]="leaseAbstractImportDraft.lease"
                         [documentViewerOptions]="documentViewerOptions"
                         [documentViewerOptionsChange]="documentViewerOptionsChange"
                         (done)="done()" (cancel)="cancel($event)">
        </app-import-form>
      </div>
      <!-- End: Document viewer form -->
    </div>
  </ng-container>
</div>
