import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SkipSelf } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';
import { DocumentViewerOptions } from '../../models/document-viewer-options.model';
import { ImportFormSelectItem } from '../../models/import-form-select-item.model';
import { NonDisturbanceTermType } from '../../../../shared/models/generated';

//
// NOTE: ImportFormOtherLeaseRightsComponent component is used as nested form.
//       Before using, please make sure the ControlContainer is provided.
//

interface SpaceUseOption {
  id: string;
  text: string;
}

interface TenantMeteredOption {
  id: string;
  text: string;
}

@Component({
  templateUrl: 'import-form-other-lease-rights.component.html',
  styleUrls: ['import-form-other-lease-rights.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class ImportFormOtherLeaseRightsComponent implements OnInit, OnDestroy {
  private static readonly _spaceUseTermTypes: Array<ImportFormSelectItem<models.SpaceUseTermType>> = [
    {
      name: 'Per current lease',
      value: models.SpaceUseTermType.PerCurrentLease,
    },
    {
      name: 'Custom',
      value: models.SpaceUseTermType.Custom,
    },
  ];

  private static readonly _selfHelpTypes: Array<ImportFormSelectItem<models.SelfHelpTermType>> = [
    {
      name: 'Per current lease',
      value: models.SelfHelpTermType.PerCurrentLease,
    },
    {
      name: 'None',
      value: models.SelfHelpTermType.None,
    },
    {
      name: 'Tenant requires a landlord default provision',
      value: models.SelfHelpTermType.TenantRequiresLandlordProvision,
    },
    {
      name: 'Custom',
      value: models.SelfHelpTermType.Custom,
    },
  ];

  private static readonly _estoppelCertificateTypes: Array<ImportFormSelectItem<models.EstoppelCertificateTermType>> = [
    {
      name: 'Per current lease',
      value: models.EstoppelCertificateTermType.PerCurrentLease,
    },
    {
      name: 'Tenant and landlord execute estoppel certificate',
      value: models.EstoppelCertificateTermType.TenantAndLandlordExecuteEstoppelCertificate,
    },
    {
      name: 'Tenant execute and deliver after Landlord request',
      value: models.EstoppelCertificateTermType.TenantExecuteAndProvideEstoppelCertificateAfterLandlordRequest,
    },
    {
      name: 'Custom',
      value: models.EstoppelCertificateTermType.Custom,
    },
  ];

  private static readonly _insuranceTypes: Array<ImportFormSelectItem<models.InsuranceTermType>> = [
    {
      name: 'Per current lease',
      value: models.InsuranceTermType.PerCurrentLease,
    },
    {
      name: 'Custom',
      value: models.InsuranceTermType.Custom,
    },
  ];

  private static readonly _holdoverProvisionTypes: Array<ImportFormSelectItem<models.HoldoverProvisionType>> = [
    {
      name: 'Per current lease',
      value: models.HoldoverProvisionType.PerCurrentLease,
    },
    {
      name: 'Tenant shall pay landlord holdover rent',
      value: models.HoldoverProvisionType.TenantRemainsFollowingEndTerm,
    },
    {
      name: (
        'Possession of Premises by Tenant after Lease termination shall not extend the Term and Monthly Base Rent shall ' +
        'be (INSERT HOLDOVER PERCENT VALUE) of Monthly Base Rent in effect immediately prior to the Holdover period'
      ),
      value: models.HoldoverProvisionType.MonthlyRentPriorToHoldover,
    },
    {
      name: 'Custom',
      value: models.HoldoverProvisionType.Custom,
    },
  ];

  private static readonly _utilitiesTypes: Array<ImportFormSelectItem<models.UtilitiesTermType>> = [
    {
      name: 'Per current lease',
      value: models.UtilitiesTermType.PerCurrentLease,
    },
    {
      name: 'To be addressed in the Lease document.',
      value: models.UtilitiesTermType.ToBeAddressedInLease,
    },
    {
      name:
        'Tenant shall be separately metered for all energy consumption for tenant\'s lighting and incidental ' +
        'uses. Landlord shall pay for all costs associated with metering Tenant\'s space.',
      value: models.UtilitiesTermType.LandlordPays,
    },
    {
      name: 'Landlord shall pay for all utilities, which shall be included in the Base Rental Rate.',
      value: models.UtilitiesTermType.LandlordPaysBaseRentalRateIncluded,
    },
    {
      name: 'Landlord shall pay for all utilities, except Tenant shall be separately metered for ...',
      value: models.UtilitiesTermType.LandlordPaysExcept,
    },
    {
      name: 'Custom',
      value: models.UtilitiesTermType.Custom,
    },
  ];

  private static readonly _utilitiesGreenLeaseOptionsList: Array<ImportFormSelectItem<models.UtilitiesTermGreenLeaseOption>> = [
    {
      name: 'Not Required',
      value: models.UtilitiesTermGreenLeaseOption.NotRequired,
    },
    {
      name: 'Required',
      value: models.UtilitiesTermGreenLeaseOption.Required,
    },
  ];

  private static readonly _parkingTypes: Array<ImportFormSelectItem<models.ParkingTermType>> = [
    {
      name: 'Per current lease',
      value: models.ParkingTermType.PerCurrentLease,
    },
    {
      name: 'Please identify all car/trailer Parking required for Tenant’s use.',
      value: models.ParkingTermType.PleaseIdentifyByLandlord,
    },
    {
      name: 'Please identify all car/trailer parking available for Tenant’s use.',
      value: models.ParkingTermType.PleaseIdentifyByTenant,
    },
    {
      name: 'Tenant shall have a minimum of ...',
      value: models.ParkingTermType.TenantRequested,
    },
    {
      name: 'To be addressed in the Lease document.',
      value: models.ParkingTermType.ToBeAddressedInLease,
    },
    {
      name: 'Custom',
      value: models.ParkingTermType.Custom,
    }
  ];

  private static readonly _signageTypes: Array<ImportFormSelectItem<models.SignageTermType>> = [
    {
      name: 'Per current lease',
      value: models.SignageTermType.PerCurrentLease,
    },
    {
      name:
        'Tenant shall have the right to install building standard signage on any monument, as well as the exterior ' +
        'of the building subject to final approval by Landlord and per applicable city code.',
      value: models.SignageTermType.LandlordApproved,
    },
    {
      name: 'To be addressed in lease document',
      value: models.SignageTermType.ToBeAddressedInLease,
    },
    {
      name: 'Custom',
      value: models.SignageTermType.Custom,
    },
  ];

  private static readonly _nonDisturbanceTypes: Array<ImportFormSelectItem<models.NonDisturbanceTermType>> = [
    {
      name: 'Per current lease',
      value: models.NonDisturbanceTermType.PerCurrentLease,
    },
    {
      name:
        'Landlord will provide Tenant with an acceptable Non-Disturbance Agreement (SNDA) from the lender of the building.',
      value: models.NonDisturbanceTermType.LandlordProvidesSnda,
    },
    {
      name: 'To be addressed in the the lease document',
      value: models.NonDisturbanceTermType.ToBeAddressedInLease,
    },
    {
      name: 'Custom',
      value: models.NonDisturbanceTermType.Custom,
    },
  ];

  private static readonly _railTypes: Array<ImportFormSelectItem<models.RailTermType>> = [
    {
      name: 'Per current lease',
      value: models.RailTermType.PerCurrentLease,
    },
    {
      name:
        'Landlord shall provide evidence that the switch and track is operational. ' +
        'If not operational, Landlord will be responsible for any repairs, maintenance or replacement.',
      value: models.RailTermType.LandlordResponsibility,
    },
    {
      name: 'Tenant shall be responsible for any repairs, maintenance or replacement necessary to make Rail active.',
      value: models.RailTermType.TenantResponsibility,
    },
    {
      name:
        'Rail is provided by [INSERT RAIL PROVIDER] and is serviced by [INSERT SERVICE PROVIDER]. ' +
        'Rail is active, letter to be provided.',
      value: models.RailTermType.RailProvidedBy,
    },
    {
      name:
        'If required, Landlord will execute an industry track agreement with Rail line provider and be responsible ' +
        'for any associated fees. In addition, Landlord, Tenant, and Rail line provider will execute a joint use agreement.',
      value: models.RailTermType.IndustryTrackAgreementPossible,
    },
    {
      name: 'Rail is inactive. Any work required to make Rail operational shall be outlined in the Tenant Improvements section.',
      value: models.RailTermType.Inactive,
    },
    {
      name: 'Custom',
      value: models.RailTermType.Custom,
    },
  ];

  spaceUseOptions: Array<SpaceUseOption> = [
    {
      id: 'office',
      text: 'Office',
    },
    {
      id: 'manufacturing',
      text: 'Manufacturing',
    },
    {
      id: 'distribution',
      text: 'Distribution',
    },
    {
      id: 'cold_storage',
      text: 'Cold Storage',
    },
    {
      id: 'food_processing',
      text: 'Food Processing',
    },
    {
      id: 'warehouse',
      text: 'Warehouse',
    },
    {
      id: 'data_center',
      text: 'Data Center',
    },
    {
      id: 'truck_terminal',
      text: 'Truck Terminal',
    },
    {
      id: 'showroom',
      text: 'Showroom',
    },
    {
      id: 'production',
      text: 'Production',
    },
    {
      id: 'rnd',
      text: 'R&D',
    },
  ];
  spaceUseItems = [];

  tenantMeteredItems = null;
  tenantMeteredOptions: Array<TenantMeteredOption> = [
    {
      id: 'water consumption',
      text: 'water consumption',
    },
    {
      id: 'electric',
      text: 'electric',
    },
    {
      id: 'gas',
      text: 'gas',
    },
    {
      id: 'sewer',
      text: 'sewer',
    },
    {
      id: 'trash removal',
      text: 'trash removal',
    },
  ];

  @Input() lease: models.ILeaseViewModel;
  @Output() leaseChange: EventEmitter<models.ILeaseViewModel>;

  @Input() leasePreview: models.ILeaseViewModel;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  SpaceUseType: typeof models.SpaceUseTermType = models.SpaceUseTermType;
  SelfHelpType: typeof models.SelfHelpTermType = models.SelfHelpTermType;
  EstoppelCertificateType: typeof models.EstoppelCertificateTermType = models.EstoppelCertificateTermType;
  InsuranceType: typeof models.InsuranceTermType = models.InsuranceTermType;
  HoldoverProvisionType: typeof models.HoldoverProvisionType = models.HoldoverProvisionType;
  ParkingType: typeof models.ParkingTermType = models.ParkingTermType;
  UtilitiesType: typeof models.UtilitiesTermType = models.UtilitiesTermType;
  SignageType: typeof models.SignageTermType = models.SignageTermType;
  NonDisturbanceTermType: typeof models.NonDisturbanceTermType = models.NonDisturbanceTermType;
  RailTermType: typeof models.RailTermType = models.RailTermType;

  spaceUseTypesList: Array<ImportFormSelectItem<models.SpaceUseTermType>>;
  selfHelpTypesList: Array<ImportFormSelectItem<models.SelfHelpTermType>>;
  estoppelCertificateTypesList: Array<ImportFormSelectItem<models.EstoppelCertificateTermType>>;
  insuranceTypesList: Array<ImportFormSelectItem<models.InsuranceTermType>>;
  holdoverProvisionTypesList: Array<ImportFormSelectItem<models.HoldoverProvisionType>>;
  parkingTypesList: Array<ImportFormSelectItem<models.ParkingTermType>>;
  utilitiesTypesList: Array<ImportFormSelectItem<models.UtilitiesTermType>>;
  utilitiesGreenLeaseOptionsList: Array<ImportFormSelectItem<models.UtilitiesTermGreenLeaseOption>>;
  signageTypesList: Array<ImportFormSelectItem<models.SignageTermType>>;
  nonDisturbanceTypesList: Array<ImportFormSelectItem<models.NonDisturbanceTermType>>;
  railTypesList: Array<ImportFormSelectItem<models.RailTermType>>;

  readonly form: NgForm;

  private readonly _destroy$: Subject<void>;

  constructor(form: NgForm) {
    this.form = form;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.lease = this._prepareLeaseModel();

    if (!this.leaseChange) {
      this.leaseChange = new EventEmitter<models.ILeaseViewModel>();
    }

    this._prepareDropDownOptions();

    // Reloads the dropdown options whenever the form model changes
    this.form
      .valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.leaseChange.next(this.lease);

          this._prepareDropDownOptions();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _prepareLeaseModel(): models.ILeaseViewModel {
    const lease = this.lease || <models.ILeaseViewModel>{};

    if (lease?.spaceUseTerm?.value) {
      const values = lease.spaceUseTerm.value.split('|');

      this.spaceUseItems = values
        .filter(x => x !== 'custom')
        .map(x => this.spaceUseOptions.find(o => o.id === x));

      if (values.includes('custom')) {
        this.spaceUseItems.push(<SpaceUseOption>{
          id: 'custom',
          text: lease.spaceUseTerm.customUseValue,
          hasProduct: true,
        });
      }
    }

    if (lease?.utilitiesTerm?.tenantMeteredUtilities) {
      const values = lease.utilitiesTerm.tenantMeteredUtilities.split(', ');

      this.tenantMeteredItems = values
        .map(x => this.tenantMeteredOptions.find(o => o.id === x))
        .filter(x => !!x);
    }

    return lease;
  }

  private _prepareDropDownOptions(): void {
    if (!this.lease) {
      return;
    }

    this.spaceUseTypesList = ImportFormOtherLeaseRightsComponent._spaceUseTermTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.SpaceUseTermType.PerCurrentLease
        );

        return option;
      });

    this.selfHelpTypesList = ImportFormOtherLeaseRightsComponent._selfHelpTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.SelfHelpTermType.PerCurrentLease
        );

        return option;
      });

    this.estoppelCertificateTypesList = ImportFormOtherLeaseRightsComponent._estoppelCertificateTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.EstoppelCertificateTermType.PerCurrentLease
        );

        return option;
      });

    this.insuranceTypesList = ImportFormOtherLeaseRightsComponent._insuranceTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.InsuranceTermType.PerCurrentLease
        );

        return option;
      });

    this.holdoverProvisionTypesList = ImportFormOtherLeaseRightsComponent._holdoverProvisionTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.HoldoverProvisionType.PerCurrentLease
        );

        return option;
      });

    this.parkingTypesList = ImportFormOtherLeaseRightsComponent._parkingTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.ParkingTermType.PerCurrentLease
        );

        return option;
      });

    this.utilitiesTypesList = ImportFormOtherLeaseRightsComponent._utilitiesTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.UtilitiesTermType.PerCurrentLease
        );

        return option;
      });

    this.signageTypesList = ImportFormOtherLeaseRightsComponent._signageTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.SignageTermType.PerCurrentLease
        );

        return option;
      });

    this.nonDisturbanceTypesList = ImportFormOtherLeaseRightsComponent._nonDisturbanceTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.NonDisturbanceTermType.PerCurrentLease
        );

        return option;
      });

    this.railTypesList = ImportFormOtherLeaseRightsComponent._railTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.RailTermType.PerCurrentLease
        );

        return option;
      });

    this.utilitiesGreenLeaseOptionsList = ImportFormOtherLeaseRightsComponent._utilitiesGreenLeaseOptionsList;
  }

  isSpaceUseProductRequired(): boolean {
    return this.spaceUseItems && this.spaceUseItems.some(x => x.hasProduct);
  }

  addCustomUse($event: any) {
    const text = $event.text.trim();

    $event.customItem = <SpaceUseOption>{
      id: 'custom',
      text: text,
      hasProduct: true,
    };

    this._updateSpaceUseTerm();
  }

  spaceUseChange($event) {
    this._updateSpaceUseTerm();
  }

  utilitiesChange($event) {
    this._updateUtilitiesTerm();
  }

  private _updateSpaceUseTerm() {
    const term = this.lease.spaceUseTerm || <models.ISpaceUseTermViewModel>{};

    if (this.spaceUseItems) {
      const items = this.spaceUseItems
        .filter(x => x.id !== 'custom')
        .map(x => x.id);

      let lastCustomItemIdx = -1;
      for (let i = this.spaceUseItems.length - 1; i >= 0; i--) {
        if (this.spaceUseItems[i].id === 'custom') {
          lastCustomItemIdx = i;
        }
      }

      if (lastCustomItemIdx !== -1) {
        items.push('custom');
        term.customUseValue = this.spaceUseItems[lastCustomItemIdx].text.trim();
      } else {
        term.customUseValue = null;
      }

      term.value = items.join('|');
      this.lease.spaceUseTerm = term;

      return;
    }

    term.value = null;
    term.customUseValue = null;
    this.lease.spaceUseTerm = term;
  }

  private _updateUtilitiesTerm() {
    const term = this.lease.utilitiesTerm || <models.IUtilitiesTermViewModel>{};

    if (this.tenantMeteredItems) {
      const items = this.tenantMeteredItems.map(x => x.id);

      term.tenantMeteredUtilities = items.join(', ');
      this.lease.utilitiesTerm = term;
    }
  }
}
