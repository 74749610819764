<h2 class="content-block">Building Attachments</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              (onEditorPreparing)="onEditorPreparing($event)" (onEditingStart)="onEditingStart($event)" (onInitNewRow)="onInitNewRow()">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true">
  </dxo-pager>

  <dxo-paging [pageSize]="15">
  </dxo-paging>

  <dxo-filter-row [visible]="true">
  </dxo-filter-row>

  <dxo-filter-panel [visible]="true">
  </dxo-filter-panel>

  <dxo-filter-builder>
  </dxo-filter-builder>

  <dxo-filter-builder-popup>
  </dxo-filter-builder-popup>

  <dxo-search-panel [visible]="true">
  </dxo-search-panel>

  <dxo-group-panel [visible]="true">
  </dxo-group-panel>

  <dxi-column dataField="id" [width]="70" dataType="number"></dxi-column>

  <dxi-column dataField="buildingId" caption="Building" cellTemplate="buildingFieldTemplate">
    <dxo-lookup [dataSource]="buildingDataSource" valueExpr="id" [displayExpr]="tools.buildingNameExpression">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'buildingFieldTemplate'" class="lookup-user-field">
    <div>{{ tools.buildingNameExpression(item.data.building) }}</div>
  </div>

  <dxi-column dataField="buildingUnitId" caption="Building Unit" cellTemplate="buildingUnitFieldTemplate">
    <dxo-lookup [dataSource]="buildingUnitDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'buildingUnitFieldTemplate'" class="lookup-user-field">
    <div>{{ item.data.buildingUnit?.name }}</div>
  </div>

  <dxi-column dataField="building.portfolio.id" caption="Portfolio">
    <dxo-lookup [dataSource]="portfolioDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="building.portfolio.companyId" caption="Company">
    <dxo-lookup [dataSource]="companyDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="picture" caption="Attachment" cellTemplate="pictureFieldTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'pictureFieldTemplate'">
    <ng-template *ngTemplateOutlet="tplAttachment; context: {$implicit: item.data}"></ng-template>
  </div>

  <dxi-column dataField="sortOrder" caption="Sort Order" dataType="number">
  </dxi-column>

  <dxi-column dataField="externalUrl" caption="External Url" dataType="string">
  </dxi-column>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup title="Add or edit Building Attachment"
               width="95%"
               [showTitle]="true"
               [maxWidth]="600"
               [position]="{ my: 'center', at: 'center', of: _window }">
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item itemType="group" caption="Attachment">
        <dxi-item [template]="uploaderTemplate"></dxi-item>
        <div *dxTemplate="let item of 'uploaderTemplate'" class="d-flex flex-row">
          <ng-template *ngTemplateOutlet="tplAttachment; context: {$implicit: currentAttachment}"></ng-template>
          <div>
            <dx-button text="Upload Attachment" (onClick)="popupVisible = true"></dx-button>
          </div>
        </div>
      </dxi-item>
      <dxi-item dataField="picture" [editorOptions]="fileControlOptions">
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>

      <dxi-item itemType="group" caption="Parameters">
        <dxi-item dataField="building.portfolio.companyId" [editorOptions]="companyControlOptions" isRequired="true"></dxi-item>
        <dxi-item dataField="building.portfolio.id" [editorOptions]="portfolioControlOptions" isRequired="true" #portfolioControl></dxi-item>
        <dxi-item dataField="buildingId" [editorOptions]="buildingControlOptions" isRequired="true" #buildingControl></dxi-item>
        <dxi-item dataField="buildingUnitId" [editorOptions]="buildingUnitControlOptions" #buildingUnitControl></dxi-item>
        <dxi-item dataField="externalUrl"></dxi-item>
        <dxi-item dataField="sortOrder"></dxi-item>
      </dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>

<dx-popup class="popup"
          title="Upload Attachment"
          [width]="400"
          [height]="250"
          [showTitle]="true"
          [dragEnabled]="false"
          [closeOnOutsideClick]="true"
          [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-file-uploader id="file" name="file" accept="video/mp4,video/x-m4v,video/*,image/*,image/heic"
                      [uploadUrl]="uploadUrl"
                      (onUploaded)="onUploaded($event)"
                      #fileUploader>
    </dx-file-uploader>
  </div>
</dx-popup>


<ng-template #tplAttachment let-attachment >
  <img *ngIf="attachment.buildingAttachmentType === BuildingAttachmentType.Picture" [src]="attachment.file?.thumbnailUrl" width="256" height="256"  alt=""/>
  <video *ngIf="attachment.buildingAttachmentType === BuildingAttachmentType.Video" [src]="attachment.file?.url" width="256" height="256"></video>
  <ng-container *ngIf="attachment.buildingAttachmentType === BuildingAttachmentType.External">
    <a [href]="attachment?.externalUrl">{{attachment.externalUrl}}</a>
  </ng-container>
  <youtube-player *ngIf="attachment?.buildingAttachmentType === BuildingAttachmentType.Youtube" [videoId]="getExternalVideoId(attachment?.externalUrl)" width="256" height="256"></youtube-player>
  <iframe *ngIf="attachment?.buildingAttachmentType === BuildingAttachmentType.Vimeo" [src]="getVimeoLink(attachment?.externalUrl) | safeHtml" height="256" width="256" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
</ng-template>

