import { Component, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as ng from '@angular/core';

import * as konva from 'konva/konva';

import * as models from '../../models/image-viewer-image.model';

import { PopupService } from '../../../infrastructure/services/popup.service';

import { ImageViewerMarkerDialogComponent } from '../image-viewer-marker-dialog/image-viewer-marker-dialog.component';

type DrawCallback = (markerGroup: konva.Group) => void;

@Component({
  selector: 'app-image-viewer-image-standard',
  templateUrl: 'image-viewer-image-standard.component.html',
  styleUrls: ['image-viewer-image-standard.component.scss'],
})
export class ImageViewerImageStandardComponent implements ng.OnInit, ng.OnChanges, ng.OnDestroy {
  private static readonly _scaleStep = 1.01;
  private static readonly _maxScale = 3;
  private static readonly _minScale = 0.3;

  private static readonly _markerSize = 42;
  private static readonly _markerFillColor = '#59b680';
  private static readonly _markerIconUrl = '/assets/img/image-viewer-marker-icon.svg';
  private static readonly _markerIconWidth = 14;
  private static readonly _markerIconHeight = 24;

  private static readonly _markerTextMaxWidth = 250;
  private static readonly _markerTextMargin = 6;
  private static readonly _markerTextPaddingX = 13;

  @ViewChild('imageViewerImageStandardElementRef', {static: true}) imageViewerImageStandardElementRef: ng.ElementRef;

  @Input() imageRef: models.ImageViewerImageRef;

  @Input() width: number;
  @Input() height: number;

  @Input() allowChangeMarkers: boolean;

  @Input() markerCreated$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerChanged$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerDeleted$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;

  private _konvaStage: konva.Stage;
  private _konvaLayer: konva.Layer;
  private _konvaImageGroup: konva.Group;

  private _markers: { [key: string]: models.ImageViewerImageMarkerRef };
  private _markersCount: number;

  private _initTimeout: any;

  private readonly _renderer: ng.Renderer2;
  private readonly _ngZone: ng.NgZone;
  private readonly _popupService: PopupService;

  private readonly _destroy$: Subject<void>;

  constructor(renderer: ng.Renderer2, ngZone: ng.NgZone, popupService: PopupService) {
    this._renderer = renderer;
    this._ngZone = ngZone;
    this._popupService = popupService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.width = this.width || 1024;
    this.height = this.height || 768;

    this.markerCreated$
      .pipe(
        takeUntil(this._destroy$),
        tap((marker: models.ImageViewerImageMarkerRef): void => {
          if (!this.imageRef || !marker) {
            return;
          }

          if (!this.imageRef.markers) {
            this.imageRef.markers = [];
          }

          this.imageRef.markers.push(marker);
        }),
      )
      .subscribe();

    this.markerChanged$
      .pipe(
        takeUntil(this._destroy$),
        tap((marker: models.ImageViewerImageMarkerRef): void => {
          if (!this.imageRef || !marker) {
            return;
          }

          if (!this.imageRef.markers) {
            this.imageRef.markers = [];
          }

          const markerIndex = this.imageRef.markers.findIndex(x => x.id === marker.id);
          if (0 <= markerIndex) {
            this.imageRef.markers[markerIndex] = marker;
            return;
          }

          this.imageRef.markers.push(marker);
        }),
      )
      .subscribe();

    this.markerDeleted$
      .pipe(
        takeUntil(this._destroy$),
        tap((marker: models.ImageViewerImageMarkerRef): void => {
          if (!this.imageRef || !marker) {
            return;
          }

          if (!this.imageRef.markers) {
            return;
          }

          const markerIndex = this.imageRef.markers.findIndex(x => x.id === marker.id);
          if (0 <= markerIndex) {
            this.imageRef.markers.splice(markerIndex, 1);
          }
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    this._ngZone.runOutsideAngular(() => {
      const timeoutFn = () => {
        if (this._initTimeout) {
          clearTimeout(this._initTimeout);
          this._initTimeout = null;
        }

        if (!changes) {
          return;
        }

        if (changes.imageRef && changes.imageRef.isFirstChange()) {
          this._initKonva();
          return;
        }

        if (
          changes.imageRef &&
          changes.imageRef.currentValue && changes.imageRef.previousValue &&
          changes.imageRef.currentValue.imageDataUrl !== changes.imageRef.previousValue.imageDataUrl) {
          this._initKonva();
        }

        if (
          (
            changes.width &&
            changes.width.currentValue && changes.width.previousValue &&
            changes.width.currentValue !== changes.width.previousValue
          ) ||
          (
            changes.height &&
            changes.height.currentValue && changes.height.previousValue &&
            changes.height.currentValue !== changes.height.previousValue
          )
        ) {
          this._initKonva();
        }
      };

      this._initTimeout = setTimeout(timeoutFn, 200);
    });
  }

  ngOnDestroy(): void {
    this._destroyKonva();
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _initKonva(): void {
    if (!this.imageViewerImageStandardElementRef || !this.imageViewerImageStandardElementRef.nativeElement) {
      return;
    }

    if (this._konvaStage) {
      this._destroyKonva();
    }

    this._konvaStage = new konva.Stage({
      container: this.imageViewerImageStandardElementRef.nativeElement,
      width: this.width,
      height: this.height,
    });

    this._konvaLayer = new konva.Layer();

    this._konvaStage.add(this._konvaLayer);

    this._markers = {};
    this._markersCount = 0;

    this._subscribeOnEvents();

    this._drawImage();
    this._drawMarkers();
  }

  private _destroyKonva(): void {
    if (!this._konvaStage) {
      return;
    }

    this._konvaStage.destroyChildren();
    this._konvaStage.destroy();
    this._konvaStage.clearCache();
    this._konvaStage.clear();

    this._konvaStage = null;

    this._unsubscribeOnEvents();
  }

  private readonly _stageWheelHandler = (event: konva.KonvaEventObject<WheelEvent>) => this._handleStageWheel(event);
  private readonly _containerDragOverHandler = (event: DragEvent) => this._handleContainerDragOver(event);
  private readonly _containerDropHandler = (event: DragEvent) => this._handleContainerDrop(event);

  private _subscribeOnEvents(): void {
    if (this._konvaStage) {
      this._konvaStage.on('wheel', this._stageWheelHandler);
    }

    if (
      this.imageViewerImageStandardElementRef &&
      this.imageViewerImageStandardElementRef.nativeElement &&
      this.allowChangeMarkers
    ) {
      const konvaContainerElement = this.imageViewerImageStandardElementRef.nativeElement;
      konvaContainerElement.addEventListener('dragover', this._containerDragOverHandler, false);
      konvaContainerElement.addEventListener('drop', this._containerDropHandler, false);
    }
  }

  private _unsubscribeOnEvents(): void {
    if (this._konvaStage) {
      this._konvaStage.off('wheel', this._stageWheelHandler);
    }

    if (
      this.imageViewerImageStandardElementRef &&
      this.imageViewerImageStandardElementRef.nativeElement &&
      this.allowChangeMarkers
    ) {
      const konvaContainerElement = this.imageViewerImageStandardElementRef.nativeElement;
      konvaContainerElement.removeEventListener('dragover', this._containerDragOverHandler, false);
      konvaContainerElement.removeEventListener('drop', this._containerDropHandler, false);
    }
  }

  private _getImageGroupTransform(): { width: number, height: number, x: number, y: number } {
    const containerWidth = this.width;
    const containerHeight = this.height;

    const originalImageWidth = this.imageRef.width;
    const originalImageHeight = this.imageRef.height;

    let imageWidth = originalImageWidth;
    let imageHeight = originalImageHeight;

    if (containerWidth < originalImageWidth || containerHeight < originalImageHeight) {
      if (containerHeight <= containerWidth) {
        const fitRatio = originalImageHeight / containerHeight;
        imageWidth = originalImageWidth / fitRatio;
        imageHeight = containerHeight;
      } else {
        const fitRatio = originalImageWidth / containerWidth;
        imageWidth = containerWidth;
        imageHeight = originalImageHeight / fitRatio;
      }
    }

    const imageCenterX = containerWidth / 2 - imageWidth / 2;
    const imageCenterY = containerHeight / 2 - imageHeight / 2;

    return {
      width: imageWidth,
      height: imageHeight,
      x: imageCenterX,
      y: imageCenterY,
    };
  }

  private _drawImage(): void {
    if (!this._konvaLayer || !this.imageRef) {
      return;
    }

    const transform = this._getImageGroupTransform();

    this._konvaImageGroup = new konva.Group({
      id: 'image-group',
      x: transform.x,
      y: transform.y,
      width: transform.width,
      height: transform.height,
      draggable: true,
    });

    this._konvaImageGroup.on('dragstart', () => this._handleImageGroupDragStart());
    this._konvaImageGroup.on('dragend', () => this._handleImageGroupDragEnd());
    this._konvaImageGroup.on('dragmove', () => this._handleImageGroupDragMove());

    const image = new konva.Image({
      id: 'image',
      image: this.imageRef.imageElement,
      width: transform.width,
      height: transform.height,
    });

    this._konvaImageGroup.add(image);

    this._konvaLayer.add(this._konvaImageGroup);
    this._konvaLayer.batchDraw();
  }

  private _drawMarkers(): void {
    if (!this.imageRef || !this.imageRef.markers || !this.imageRef.markers.length) {
      return;
    }

    const scaleX = this._getScaleX();
    const scaleY = this._getScaleY();

    for (let i = 0, num = this.imageRef.markers.length; i < num; i++) {
      const marker = this.imageRef.markers[i];

      this._drawMarker(
        {x: marker.x / scaleX, y: marker.y / scaleY},
        (markerGroup: konva.Group): void => {
          this._markers[markerGroup.id()] = marker;
        },
      );
    }
  }

  private _drawMarker(position: konva.Vector2d, drawCallback: DrawCallback = (() => null)): void {
    if (!this._konvaImageGroup || !position) {
      return;
    }

    konva.Image.fromURL(ImageViewerImageStandardComponent._markerIconUrl, (markerIcon: konva.Image) => {
      const markerGroup = new konva.Group({
        id: `marker-group-${++this._markersCount}`,
        name: 'marker-group',
        width: ImageViewerImageStandardComponent._markerSize,
        height: ImageViewerImageStandardComponent._markerSize,
        draggable: this.allowChangeMarkers,
      });

      markerGroup.setAttrs({
        x: this._getMarkerBoundingPositionX(markerGroup, position.x),
        y: this._getMarkerBoundingPositionY(markerGroup, position.y),
      });

      if (this.allowChangeMarkers) {
        markerGroup.on('dragstart', () => this._handleMarkerGroupDragStart(markerGroup));
        markerGroup.on('dragend', () => this._handleMarkerGroupDragEnd(markerGroup));
        markerGroup.on('dragmove', () => this._handleMarkerGroupDragMove(markerGroup));

        markerGroup.on('contextmenu', (event) => this._handleMarkerGroupContextMenu(markerGroup, event));
      }

      markerGroup.on('mouseenter', () => this._handleMarkerGroupMouseEnter(markerGroup));
      markerGroup.on('mouseleave', () => this._handleMarkerGroupMouseLeave(markerGroup));

      const markerRadius = ImageViewerImageStandardComponent._markerSize / 2;

      const marker = new konva.Circle({
        id: 'marker',
        x: markerRadius,
        y: markerRadius,
        radius: markerRadius,
        fill: ImageViewerImageStandardComponent._markerFillColor,
        strokeWidth: 0,
        shadowColor: 'black',
        shadowBlur: 5,
        shadowOffset: {
          x: 0,
          y: 2.5,
        },
        shadowOpacity: 0.25,
      });

      markerIcon.setAttrs({
        id: 'marker-icon',
        x: markerRadius - ImageViewerImageStandardComponent._markerIconWidth / 2,
        y: markerRadius - ImageViewerImageStandardComponent._markerIconHeight / 2,
        width: ImageViewerImageStandardComponent._markerIconWidth,
        height: ImageViewerImageStandardComponent._markerIconHeight,
      });

      markerGroup.add(marker);
      markerGroup.add(markerIcon);

      this._konvaImageGroup.add(markerGroup);

      markerGroup.draw();

      drawCallback(markerGroup);
    });
  }

  private _scaleImageGroup(position: konva.Vector2d, isZoomIn: boolean): void {
    if (!this._konvaLayer || !this._konvaImageGroup) {
      return;
    }

    const prevScale = this._konvaImageGroup.scaleX();

    const pointX = (position.x - this._konvaImageGroup.x()) / prevScale;
    const pointY = (position.y - this._konvaImageGroup.y()) / prevScale;

    let nextScale = prevScale / ImageViewerImageStandardComponent._scaleStep;
    if (isZoomIn) {
      nextScale = prevScale * ImageViewerImageStandardComponent._scaleStep;
    }

    if (
      nextScale <= ImageViewerImageStandardComponent._minScale ||
      ImageViewerImageStandardComponent._maxScale <= nextScale
    ) {
      return;
    }

    this._konvaImageGroup.scale({
      x: nextScale,
      y: nextScale,
    });

    this._moveImageGroup({x: position.x - pointX * nextScale, y: position.y - pointY * nextScale});

    this._konvaLayer.batchDraw();
  }

  private _moveImageGroup(position: konva.Vector2d, needBatchDraw: boolean = false): void {
    if (!this._konvaImageGroup) {
      return;
    }

    this._konvaImageGroup.position({
      x: this._getImageBoundingPositionX(position.x),
      y: this._getImageBoundingPositionY(position.y),
    });

    if (needBatchDraw && this._konvaLayer) {
      this._konvaLayer.batchDraw();
    }
  }

  private _moveMarkerGroup(markerGroup: konva.Group, position: konva.Vector2d, needBatchDraw: boolean = false): void {
    if (!markerGroup) {
      return;
    }

    markerGroup.position({
      x: this._getMarkerBoundingPositionX(markerGroup, position.x),
      y: this._getMarkerBoundingPositionY(markerGroup, position.y),
    });

    if (needBatchDraw && this._konvaLayer) {
      this._konvaLayer.batchDraw();
    }
  }

  private _drawMarkerTextTooltip(markerGroup: konva.Group): void {
    if (!markerGroup || !this._konvaImageGroup || !this._markers) {
      return;
    }

    const marker = this._markers[markerGroup.id()];
    if (!marker) {
      return;
    }

    const markerPosition = this._getAbsoluteMarkerPosition(markerGroup);

    const markerWidth = markerGroup.width() * this._konvaImageGroup.scaleX();
    const markerHeight = markerGroup.height() * this._konvaImageGroup.scaleY();

    const positionOffsetX = markerWidth / 2 - ImageViewerImageStandardComponent._markerTextPaddingX;
    const positionOffsetY = markerHeight + ImageViewerImageStandardComponent._markerTextMargin;

    const markerTextPositionX = <number>markerPosition.x + <number>positionOffsetX;
    const markerTextPositionY = <number>markerPosition.y + <number>positionOffsetY;

    this._createMarkerTextTooltip(
      `text-for-${markerGroup.id()}`,
      markerTextPositionX,
      markerTextPositionY,
      marker.text,
    );
  }

  private _openMarkerDialog(markerGroup: konva.Group): void {
    if (!markerGroup || !this.imageRef || !this._markers || !this._konvaLayer) {
      return;
    }

    const markerX = markerGroup.x() * this._getScaleX();
    const markerY = markerGroup.y() * this._getScaleY();

    const markerId = markerGroup.id();

    const isCreateEvent = !this._markers[markerId];
    const isChangeEvent = !isCreateEvent;

    let dialogTitle = 'Create Marker';
    let dialogMarker = new models.ImageViewerImageMarkerRef(this.imageRef, markerX, markerY);

    if (!isCreateEvent) {
      dialogTitle = 'Edit Marker';
      dialogMarker = this._markers[markerId];
    }

    const dialogRef = this._popupService.show(ImageViewerMarkerDialogComponent, {
      title: dialogTitle,
      showCloseButton: true,
      closeOnOutsideClick: false,
      width: 450,
      height: 'auto',
      maxHeight: 700,
      injectableData: {
        marker: dialogMarker,
      },
    });

    const hidingSubscription = dialogRef.onHiding
      .subscribe(() => {
        hidingSubscription.unsubscribe();

        if (isCreateEvent && !dialogRef.outputData) {
          markerGroup.destroy();
          this._konvaLayer.batchDraw();
          return;
        }

        if (!dialogRef.outputData || !dialogRef.outputData.marker) {
          return;
        }

        this._markers[markerId] = dialogRef.outputData.marker;

        if (isCreateEvent && this.markerCreated$) {
          this.markerCreated$.emit(this._markers[markerId]);
        }

        if (isChangeEvent && this.markerChanged$) {
          this.markerChanged$.emit(this._markers[markerId]);
        }
      });
  }

  private _removeMarker(markerGroup: konva.Group): void {
    if (!markerGroup || !this.imageRef || !this._markers || !this._konvaLayer) {
      return;
    }

    markerGroup.destroy();
    this._konvaLayer.batchDraw();

    const markerId = markerGroup.id();
    const marker = this._markers[markerId];

    if (marker && this.markerDeleted$) {
      this.markerDeleted$.emit(marker);
      delete this._markers[markerId];
    }
  }

  //
  // Bounding position getters
  //

  private _getImageBoundingPositionX(nextPosition: number = null): number {
    if (!this._konvaImageGroup || !this.width) {
      return 0;
    }

    const imageScale = this._konvaImageGroup.scaleX();
    const imageWidth = this._konvaImageGroup.width() * imageScale;

    const leftBoundary = 0;
    const rightBoundary = Math.abs(imageWidth - this.width);

    if (nextPosition === null) {
      nextPosition = this._konvaImageGroup.x();
    }

    if (this.width < imageWidth) {
      return Math.min(leftBoundary, Math.max(-rightBoundary, nextPosition));
    }

    return Math.max(leftBoundary, Math.min(rightBoundary, nextPosition));
  }

  private _getImageBoundingPositionY(nextPosition: number = null): number {
    if (!this._konvaImageGroup || !this.height) {
      return 0;
    }

    const imageScale = this._konvaImageGroup.scaleY();
    const imageHeight = this._konvaImageGroup.height() * imageScale;

    const topBoundary = 0;
    const bottomBoundary = Math.abs(imageHeight - this.height);

    if (nextPosition === null) {
      nextPosition = this._konvaImageGroup.y();
    }

    if (this.height < imageHeight) {
      return Math.min(topBoundary, Math.max(-bottomBoundary, nextPosition));
    }

    return Math.max(topBoundary, Math.min(bottomBoundary, nextPosition));
  }

  private _getMarkerBoundingPositionX(markerGroup: konva.Group, nextPosition: number = null): number {
    if (!this._konvaImageGroup) {
      return 0;
    }

    const leftBoundary = 0;
    const rightBoundary = this._konvaImageGroup.width() - markerGroup.width();

    if (nextPosition === null) {
      nextPosition = markerGroup.x();
    }

    return Math.max(leftBoundary, Math.min(rightBoundary, nextPosition));
  }

  private _getMarkerBoundingPositionY(markerGroup: konva.Group, nextPosition: number = null): number {
    if (!this._konvaImageGroup) {
      return 0;
    }

    const topBoundary = 0;
    const bottomBoundary = this._konvaImageGroup.height() - markerGroup.height();

    if (nextPosition === null) {
      nextPosition = markerGroup.y();
    }

    return Math.max(topBoundary, Math.min(bottomBoundary, nextPosition));
  }

  private _getScaleX(): number {
    if (!this.imageRef || !this._konvaImageGroup) {
      return 0;
    }

    return this.imageRef.width / this._konvaImageGroup.width();
  }

  private _getScaleY(): number {
    if (!this.imageRef || !this._konvaImageGroup) {
      return 0;
    }

    return this.imageRef.height / this._konvaImageGroup.height();
  }

  private _getAbsoluteMarkerPosition(markerGroup: konva.Group): konva.Vector2d {
    if (!this.imageViewerImageStandardElementRef || !this.imageViewerImageStandardElementRef.nativeElement) {
      return {x: 0, y: 0};
    }

    const containerClientRect = this.imageViewerImageStandardElementRef.nativeElement.getBoundingClientRect();

    const imageGroupTransform = this._konvaImageGroup.getAbsoluteTransform().copy();
    const markerPosition = imageGroupTransform.point(markerGroup.position());

    const absolutePositionX = <number>containerClientRect.left + <number>markerPosition.x;
    const absolutePositionY = <number>containerClientRect.top + <number>markerPosition.y;

    return {x: absolutePositionX, y: absolutePositionY};
  }

  //
  // DOM helpers
  //

  private _setContainerGrabbedClass(): void {
    if (!this.imageViewerImageStandardElementRef || !this.imageViewerImageStandardElementRef.nativeElement) {
      return;
    }

    this._renderer.addClass(this.imageViewerImageStandardElementRef.nativeElement, 'is-grabbed');
  }

  private _removeContainerGrabbedClass(): void {
    if (!this.imageViewerImageStandardElementRef || !this.imageViewerImageStandardElementRef.nativeElement) {
      return;
    }

    this._renderer.removeClass(this.imageViewerImageStandardElementRef.nativeElement, 'is-grabbed');
  }

  private _createMarkerTextTooltip(id: string, x: number, y: number, text: string): void {
    const canvasElement = this._renderer.createElement('canvas');
    const canvasContext = canvasElement.getContext('2d');

    canvasContext.font = '12px "Avenir Next Cyr", sans-serif';

    const textBoundary = (
      ImageViewerImageStandardComponent._markerTextMaxWidth - ImageViewerImageStandardComponent._markerTextPaddingX * 2
    );

    let markerTextWidth = canvasContext.measureText(text).width;
    if (textBoundary <= markerTextWidth) {
      markerTextWidth = textBoundary;
    }

    const markerTextHalfWidth = markerTextWidth / 2;

    const markerTextElement = this._renderer.createElement('div');
    const markerText = this._renderer.createText(text);

    this._renderer.setAttribute(markerTextElement, 'id', id);

    this._renderer.addClass(markerTextElement, 'image-viewer-image-standard-marker-text');

    this._renderer.setStyle(markerTextElement, 'transform', `translate(${x}px, ${y}px)`);
    this._renderer.setStyle(markerTextElement, 'margin-left', `-${markerTextHalfWidth}px`);

    this._renderer.appendChild(markerTextElement, markerText);
    this._renderer.appendChild(document.body, markerTextElement);
  }

  private _removeMarkerTextTooltip(id: string): void {
    const markerTextElement = document.body.querySelector(`#${id}`);
    if (!markerTextElement) {
      return;
    }

    markerTextElement.remove();
  }

  private _removeAllMarkerTextTooltips(): void {
    const markerTextElements = document.body.querySelectorAll('.image-viewer-image-standard-marker-text');
    if (!markerTextElements || !markerTextElements.length) {
      return;
    }

    markerTextElements.forEach(x => x.remove());
  }

  private _createContextMenu(markerGroup: konva.Group, x: number, y: number): void {
    if (!markerGroup) {
      return;
    }

    const contextMenuBackdrop = this._renderer.createElement('div');

    this._renderer.addClass(contextMenuBackdrop, 'image-viewer-image-standard-context-menu-backdrop');

    const contextMenuElement = this._renderer.createElement('div');
    const contextMenuListElement = this._renderer.createElement('ul');

    const contextMenuItems = [
      {text: 'Edit', action: () => this._openMarkerDialog(markerGroup)},
      {text: 'Remove', action: () => this._removeMarker(markerGroup)},
    ];

    for (let i = 0, num = contextMenuItems.length; i < num; i++) {
      const item = contextMenuItems[i];

      const contextMenuItemElement = this._renderer.createElement('li');
      const contextMenuItemButtonElement = this._renderer.createElement('button');
      const contextMenuItemButtonText = this._renderer.createText(item.text);

      contextMenuItemButtonElement.type = 'button';
      contextMenuItemButtonElement.addEventListener('mousedown', (event: MouseEvent) => {
        if (event) {
          event.preventDefault();
        }

        item.action();
      });

      this._renderer.appendChild(contextMenuItemButtonElement, contextMenuItemButtonText);
      this._renderer.appendChild(contextMenuItemElement, contextMenuItemButtonElement);
      this._renderer.appendChild(contextMenuListElement, contextMenuItemElement);
    }

    this._renderer.addClass(contextMenuElement, 'image-viewer-image-standard-context-menu');

    this._renderer.setStyle(contextMenuElement, 'left', `${x}px`);
    this._renderer.setStyle(contextMenuElement, 'top', `${y}px`);

    this._renderer.appendChild(contextMenuElement, contextMenuListElement);
    this._renderer.appendChild(contextMenuBackdrop, contextMenuElement);

    this._renderer.appendChild(document.body, contextMenuBackdrop);

    contextMenuBackdrop.addEventListener('click', () => {
      this._renderer.removeChild(document.body, contextMenuBackdrop);
    });
  }

  //
  // Event handlers
  //

  private _handleContainerDragOver(event: DragEvent): void {
    if (!this._konvaStage) {
      return;
    }

    event.preventDefault();
  }

  private _handleContainerDrop(event: DragEvent): void {
    if (!this._konvaStage || !this._konvaLayer || !this._konvaImageGroup) {
      return;
    }

    event.preventDefault();

    this._konvaStage.setPointersPositions(event);

    const position = this._konvaStage.getPointerPosition();
    const imageTransform = this._konvaImageGroup.getAbsoluteTransform().copy();

    imageTransform.invert();

    const markerCenterX = (ImageViewerImageStandardComponent._markerSize / 2) * this._konvaImageGroup.scaleX();
    const markerCenterY = (ImageViewerImageStandardComponent._markerSize / 2) * this._konvaImageGroup.scaleY();

    const markerPosition = imageTransform.point({
      x: position.x - markerCenterX,
      y: position.y - markerCenterY,
    });

    const leftBoundary = -markerCenterX;
    const rightBoundary = this._konvaImageGroup.width();

    const topBoundary = -markerCenterY;
    const bottomBoundary = this._konvaImageGroup.height();

    if (
      (markerPosition.x < leftBoundary || rightBoundary < markerPosition.x) ||
      (markerPosition.y < topBoundary || bottomBoundary < markerPosition.y)
    ) {
      return;
    }

    this._drawMarker(
      markerPosition,
      (markerGroup: konva.Group): void => this._handleMarkerDraw(markerGroup),
    );
  }

  private _handleImageGroupDragStart(): void {
    this._setContainerGrabbedClass();
  }

  private _handleImageGroupDragEnd(): void {
    this._removeContainerGrabbedClass();
  }

  private _handleImageGroupDragMove(): void {
    if (!this._konvaImageGroup) {
      return;
    }

    this._removeAllMarkerTextTooltips();

    this._moveImageGroup(this._konvaImageGroup.position());
  }

  private _handleStageWheel(event: konva.KonvaEventObject<WheelEvent>): void {
    if (!this._konvaStage || !event || !event.evt) {
      return;
    }

    event.evt.preventDefault();

    this._removeAllMarkerTextTooltips();

    this._scaleImageGroup(this._konvaStage.getPointerPosition(), 0 < event.evt.deltaY);
  }

  private _handleMarkerGroupDragStart(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._setContainerGrabbedClass();
    this._handleMarkerGroupMouseLeave(markerGroup);
  }

  private _handleMarkerGroupDragEnd(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._removeContainerGrabbedClass();
    this._handleMarkerMoveEnd(markerGroup);
  }

  private _handleMarkerGroupDragMove(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._moveMarkerGroup(markerGroup, markerGroup.position());
  }

  private _handleMarkerGroupMouseEnter(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._drawMarkerTextTooltip(markerGroup);
  }

  private _handleMarkerGroupMouseLeave(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._removeMarkerTextTooltip(`text-for-${markerGroup.id()}`);
  }

  private _handleMarkerDraw(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._openMarkerDialog(markerGroup);
  }

  private _handleMarkerMoveEnd(markerGroup: konva.Group): void {
    if (!this.imageRef || !markerGroup) {
      return;
    }

    const markerGroupId = markerGroup.id();

    const marker = this._markers[markerGroupId];
    if (!marker) {
      return;
    }

    marker.x = markerGroup.x() * this._getScaleX();
    marker.y = markerGroup.y() * this._getScaleY();

    if (this.markerChanged$) {
      this.markerChanged$.emit(marker);
    }
  }

  private _handleMarkerGroupContextMenu(markerGroup: konva.Group, event: konva.KonvaEventObject<MouseEvent>): void {
    if (!markerGroup || !event || !event.evt || !this._konvaStage) {
      return;
    }

    event.evt.preventDefault();

    this._handleMarkerGroupMouseLeave(markerGroup);

    const x = event.evt.clientX || event.evt.pageX;
    const y = event.evt.clientY || event.evt.pageY;

    this._createContextMenu(markerGroup, x, y);
  }
}
