import * as ng from '@angular/core';
import { Component, Input, Output, ViewChild } from '@angular/core';

import * as konva from 'konva/konva';

import { Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { BuildingAreaType } from '../../../../shared/models/generated';

import { PlanViewerMarkerPopoverOptions } from '../../models/plan-viewer-marker-popover-options.model';

import * as models from '../../models/plan-viewer.model';
import { PlanViewerMarkerColor, PlanViewerMarkerType, PlanViewerMode } from '../../models/plan-viewer.model';

import { PopupService } from '../../../infrastructure/services/popup.service';
import { ImageCacheService } from '../../../image-viewer/services/image-cache.service';
import { PlanViewerMarkerPopoverService } from '../../services/plan-viewer-marker-popover.service';
import { PlanViewerMarkerSortService } from '../../services/plan-viewer-marker-sort.service';
import { PlanViewerMarkerService } from '../../services/plan-viewer-marker.service';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';
import { PlanViewerMarkerDialogComponent } from '../plan-viewer-marker-dialog/plan-viewer-marker-dialog.component';
import { PlanViewerMarkerPopoverComponent } from '../plan-viewer-marker-popover/plan-viewer-marker-popover.component';

@Component({
  selector: 'app-plan-viewer',
  templateUrl: 'plan-viewer.component.html',
  styleUrls: ['plan-viewer.component.scss'],
  changeDetection: ng.ChangeDetectionStrategy.OnPush,
})
export class PlanViewerComponent implements ng.OnInit, ng.OnChanges, ng.OnDestroy {
  private static readonly _scaleStep = 1.01;
  private static readonly _maxScale = 3;
  private static readonly _minScale = 0.3;

  private static readonly _minimumMarkerWidth = 0;
  private static readonly _minimumMarkerHeight = 0;

  private static readonly _availableBoundaryOffsetX = 3; // 1/3 of half image width
  private static readonly _availableBoundaryOffsetY = 3; // 1/3 of half image height

  private static readonly _defaultMarkerColor = models.PlanViewerMarkerColor.Blue;

  private static readonly _circleRadius = 10; // radius of circle marker in px

  @ViewChild('planViewerKonvaContainerElementRef', {static: true}) planViewerKonvaContainerElementRef: ng.ElementRef;

  @Input() mode: models.PlanViewerMode;

  @Input() image: models.PlanViewerImage;

  @Input() width: number;
  @Input() height: number;

  @Input() allowChangeMarkers: boolean;

  @Input() markerColorChange: ng.EventEmitter<{ id: number, color: models.PlanViewerMarkerColor }>;
  @Input() markerCreationEnd: ng.EventEmitter<models.PlanViewerMarkerRef>;

  @Output() afterViewerInit: ng.EventEmitter<PlanViewerComponent>;

  @Output() markerCreated: ng.EventEmitter<models.PlanViewerMarkerRef>;
  @Output() markerChanged: ng.EventEmitter<models.PlanViewerMarkerRef>;
  @Output() markerDeleted: ng.EventEmitter<models.PlanViewerMarkerRef>;

  @Output() imageMarkerCreated: ng.EventEmitter<models.PlanViewerImageMarkerRef>;
  @Output() imageMarkerChanged: ng.EventEmitter<models.PlanViewerImageMarkerRef>;
  @Output() imageMarkerDeleted: ng.EventEmitter<models.PlanViewerImageMarkerRef>;

  @Output() planChanged: ng.EventEmitter<models.PlanViewerImageRef>;

  @Input() scale: number;
  @Output() scaleChange: ng.EventEmitter<number>;

  @Input() set selectedMarkerId(id: number) {
    this._selectedMarkerId = id;

    this._handleMarkerGroupSelectionChange(id);
  }
  get selectedMarkerId() {
    return this._selectedMarkerId;
  }

  @Output() selectedMarkerIdChange: ng.EventEmitter<number>;

  @Input() isDragEnabled: boolean;

  @Input() suggestions: Array<{ id?: number, name: string }>;

  get isDrawCircleEnabled(): boolean {
    return this._isDrawMarkerEnabled && this._drawMarkerType === models.PlanViewerMarkerType.Circle;
  }

  get isDrawRectEnabled(): boolean {
    return this._isDrawMarkerEnabled && this._drawMarkerType === models.PlanViewerMarkerType.Rect;
  }

  get isDrawCrossEnabled(): boolean {
    return this._isDrawMarkerEnabled && this._drawMarkerType === models.PlanViewerMarkerType.Cross;
  }

  get isDrawPolygonEnabled(): boolean {
    return this._isDrawMarkerEnabled && this._drawMarkerType === models.PlanViewerMarkerType.Polygon;
  }

  private _selectedMarkerId: number;

  private _konvaStage: konva.Stage;
  private _konvaLayer: konva.Layer;
  private _konvaImageGroup: konva.Group;

  private _imageRef: models.PlanViewerImageRef;

  private _markers: { [id: number]: models.PlanViewerMarkerRef };
  private _markersCount: number;

  private _hidePopoverTimeout: any;

  private _isDrawMarkerEnabled: boolean;
  private _drawMarkerType: models.PlanViewerMarkerType;
  private _drawMarkerGroup: konva.Group;

  private _popoverComponent: PlanViewerMarkerPopoverComponent;

  private readonly _renderer: ng.Renderer2;
  private readonly _ngZone: ng.NgZone;
  private readonly _popupService: PopupService;
  private readonly _planViewerMarkerService: PlanViewerMarkerService;
  private readonly _planViewerMarkerSortService: PlanViewerMarkerSortService;
  private readonly _planViewerMarkerPopoverService: PlanViewerMarkerPopoverService;
  private readonly _imageCacheService: ImageCacheService;

  private readonly _destroy$: Subject<void>;

  private readonly _stageWheelHandler = (event: konva.KonvaEventObject<WheelEvent>) => this._handleStageWheel(event);
  private readonly _stageClickHandler = (event: konva.KonvaEventObject<MouseEvent>) => this._handleStageClick(event);
  private readonly _stageMouseDownHandler = (event: konva.KonvaEventObject<MouseEvent>) => this._handleStageMouseDown(event);
  private readonly _stageMouseUpHandler = (event: konva.KonvaEventObject<MouseEvent>) => this._handleStageMouseUp(event);
  private readonly _stageMouseMoveHandler = (event: konva.KonvaEventObject<MouseEvent>) => this._handleStageMouseMove(event);

  constructor(
    renderer: ng.Renderer2,
    ngZone: ng.NgZone,
    popupService: PopupService,
    planViewerMarkerService: PlanViewerMarkerService,
    planViewerMarkerSortService: PlanViewerMarkerSortService,
    planViewerMarkerPopoverService: PlanViewerMarkerPopoverService,
    imageCacheService: ImageCacheService,
  ) {
    this._renderer = renderer;
    this._ngZone = ngZone;
    this._popupService = popupService;
    this._planViewerMarkerService = planViewerMarkerService;
    this._planViewerMarkerSortService = planViewerMarkerSortService;
    this._planViewerMarkerPopoverService = planViewerMarkerPopoverService;
    this._imageCacheService = imageCacheService;

    this._destroy$ = new Subject<void>();

    this._markers = {};
    this._markersCount = 0;

    this.afterViewerInit = new ng.EventEmitter<PlanViewerComponent>();

    this.markerCreated = new ng.EventEmitter<models.PlanViewerMarkerRef>();
    this.markerChanged = new ng.EventEmitter<models.PlanViewerMarkerRef>();
    this.markerDeleted = new ng.EventEmitter<models.PlanViewerMarkerRef>();

    this.imageMarkerCreated = new ng.EventEmitter<models.PlanViewerImageMarkerRef>();
    this.imageMarkerChanged = new ng.EventEmitter<models.PlanViewerImageMarkerRef>();
    this.imageMarkerDeleted = new ng.EventEmitter<models.PlanViewerImageMarkerRef>();

    this.planChanged = new ng.EventEmitter<models.PlanViewerImageRef>();

    this.scaleChange = new ng.EventEmitter<number>();
    this.selectedMarkerIdChange = new ng.EventEmitter<number>();
  }

  ngOnInit(): void {
    this.mode = typeof this.mode === typeof PlanViewerMode.Marker ? this.mode : PlanViewerMode.Marker;

    this.width = this.width || 1024;
    this.height = this.height || 768;
    this.allowChangeMarkers = this.allowChangeMarkers || false;
    this.isDragEnabled = this.isDragEnabled || false;

    this.markerColorChange = (
      this.markerColorChange ||
      new ng.EventEmitter<{ id: number, color: models.PlanViewerMarkerColor }>()
    );
    this.markerCreationEnd = (
      this.markerCreationEnd ||
      new ng.EventEmitter<models.PlanViewerMarkerRef>()
    );

    this.afterViewerInit.emit(this);

    this.markerColorChange
      .pipe(
        tap(({id, color}) => this._handleMarkerGroupColorChange(id, color)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.markerCreationEnd
      .pipe(
        tap((marker: models.PlanViewerMarkerRef) => this._handleMarkerGroupCreationEnd(marker)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.markerCreated
      .pipe(
        tap((marker: models.PlanViewerMarkerRef): void => {
          if (!this._imageRef || !marker) {
            return;
          }

          if (!this._imageRef.markers) {
            this._imageRef.markers = [];
          }

          this._imageRef.markers.push(marker);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.markerChanged
      .pipe(
        tap((marker: models.PlanViewerMarkerRef): void => {
          if (!this._imageRef || !marker) {
            return;
          }

          if (!this._imageRef.markers) {
            this._imageRef.markers = [];
          }

          const markerIndex = this._imageRef.markers.findIndex(x => x.id === marker.id);
          if (0 <= markerIndex) {
            this._imageRef.markers[markerIndex] = marker;
            return;
          }

          this._imageRef.markers.push(marker);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.markerDeleted
      .pipe(
        tap((marker: models.PlanViewerMarkerRef): void => {
          if (!this._imageRef || !marker) {
            return;
          }

          if (!this._imageRef.markers) {
            return;
          }

          const markerIndex = this._imageRef.markers.findIndex(x => x.id === marker.id);
          if (0 <= markerIndex) {
            this._imageRef.markers.splice(markerIndex, 1);
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.imageMarkerCreated
      .pipe(
        tap((ref: models.PlanViewerImageMarkerRef) => this._handleImageMarkerCreate(ref)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.imageMarkerChanged
      .pipe(
        tap((ref: models.PlanViewerImageMarkerRef) => this._handleImageMarkerUpdate(ref)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.imageMarkerDeleted
      .pipe(
        tap((ref: models.PlanViewerImageMarkerRef) => this._handleImageMarkerDelete(ref)),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    const isModeChanged = (
      changes.mode &&
      changes.mode.previousValue !== changes.mode.currentValue
    );

    const isImageChanged = (
      changes.image &&
      (
        changes.image.isFirstChange() ||
        (
          (changes.image.currentValue && changes.image.previousValue) &&
          changes.image.currentValue.imageUrl !== changes.image.previousValue.imageUrl
        )
      )
    );

    const isWidthChanged = (
      changes.width &&
      (
        changes.width.isFirstChange() ||
        (
          (changes.width.currentValue && changes.width.previousValue) &&
          changes.width.currentValue !== changes.width.previousValue
        )
      )
    );

    const isHeightChanged = (
      changes.height &&
      (
        changes.height.isFirstChange() ||
        (
          (changes.height.currentValue && changes.height.previousValue) &&
          changes.height.currentValue !== changes.height.previousValue
        )
      )
    );

    if (isImageChanged) {
      this.getImageRef$(changes.image.currentValue)
        .pipe(
          take(1),
          tap(imageRef => {
            this._imageRef = imageRef;
            this._markers = {};

            this._initKonva();
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }

    if ((isWidthChanged || isHeightChanged) && this._konvaStage) {
      this._handleCanvasResize();
    }

    if (isModeChanged && this._konvaStage) {
      this._konvaStage.off('mousemove', this._stageMouseMoveHandler);
      this._drawMarkerGroup = null;
    }
  }

  ngOnDestroy(): void {
    this._destroyKonva();
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleDragEnabledChange(isDragEnabled: boolean): void {
    if (!this._konvaImageGroup) {
      return;
    }

    this.isDragEnabled = isDragEnabled;

    this._konvaImageGroup.setAttr('draggable', isDragEnabled);
  }

  handleDrawCircleEnabledChange(isDrawCircleEnabled: boolean): void {
    this._setDrawMarkerState(isDrawCircleEnabled, models.PlanViewerMarkerType.Circle);
  }

  handleDrawRectEnabledChange(isDrawRectEnabled: boolean): void {
    this._setDrawMarkerState(isDrawRectEnabled, models.PlanViewerMarkerType.Rect);
  }

  handleDrawCrossEnabledChange(isDrawCrossEnabled: boolean): void {
    this._setDrawMarkerState(isDrawCrossEnabled, models.PlanViewerMarkerType.Cross);
  }

  handleDrawPolygonEnabledChange(isDrawPolygonEnabled: boolean): void {
    this._setDrawMarkerState(isDrawPolygonEnabled, models.PlanViewerMarkerType.Polygon);
  }

  handleRotationAngleChange(rotationAngle: number): void {
    if (!this._imageRef) {
      return;
    }

    this._rotateImageGroup(rotationAngle, true);

    this.planChanged.emit({...this._imageRef, angle: rotationAngle});
  }

  private _setDrawMarkerState(isDrawEnabled: boolean, markerType: models.PlanViewerMarkerType): void {
    if (isDrawEnabled) {
      this._unsetAllMarkerGroupAccent();
      this._fireMarkerSelect(null);
    } else {
      if (markerType === null) {
        this.isDragEnabled = true;
      }

      if (this._drawMarkerGroup) {
        this._drawMarkerGroup.destroy();
        this._konvaLayer.batchDraw();

        this._drawMarkerGroup = null;
      }
    }

    this._isDrawMarkerEnabled = isDrawEnabled;
    this._drawMarkerType = markerType;

    this._konvaImageGroup
      .getChildren((node) => (
        node.id().startsWith('marker-group')
      ))
      .forEach((node) => {
        node.draggable(this.allowChangeMarkers && !this._isDrawMarkerEnabled);
      });
  }

  //
  // Image Loading
  //

  getImageRef$(image: models.PlanViewerImage): Observable<models.PlanViewerImageRef> {
    if (!image || !image.imageUrl) {
      return of(null);
    }

    return this._imageCacheService
      .getCachedImage(image.imageUrl)
      .pipe(
        map((cachedImage) => {
          return <models.PlanViewerImageRef>{
            id: image.id,
            imageElement: cachedImage.element,
            imageDataUrl: cachedImage.dataUrl,
            width: cachedImage.width,
            height: cachedImage.height,
            angle: image.angle,
            markers: [...image.markers],
          };
        }),
      );
  }

  //
  // Konva
  //

  private _initKonva(): void {
    if (!this.planViewerKonvaContainerElementRef || !this.planViewerKonvaContainerElementRef.nativeElement) {
      return;
    }

    if (this._konvaStage) {
      this._destroyKonva();
    }

    this._konvaStage = new konva.Stage({
      container: this.planViewerKonvaContainerElementRef.nativeElement,
      width: this.width,
      height: this.height,
    });

    this._konvaLayer = new konva.Layer();

    this._konvaStage.add(this._konvaLayer);

    this._subscribeOnEvents();

    this._drawImage();
    this._drawMarkers();
  }

  private _destroyKonva(): void {
    if (!this._konvaStage) {
      return;
    }

    this._konvaStage.destroyChildren();
    this._konvaStage.destroy();
    this._konvaStage.clearCache();
    this._konvaStage.clear();

    this._konvaStage = null;

    this._unsubscribeOnEvents();
  }

  private _subscribeOnEvents(): void {
    if (this._konvaStage) {
      this._konvaStage.on('wheel', this._stageWheelHandler);

      this._konvaStage.on('click', this._stageClickHandler);

      this._konvaStage.on('mousedown', this._stageMouseDownHandler);
      this._konvaStage.on('mouseup', this._stageMouseUpHandler);
    }
  }

  private _unsubscribeOnEvents(): void {
    if (this._konvaStage) {
      this._konvaStage.off('wheel', this._stageWheelHandler);

      this._konvaStage.off('click', this._stageClickHandler);

      this._konvaStage.off('mousedown', this._stageMouseDownHandler);
      this._konvaStage.off('mouseup', this._stageMouseUpHandler);
    }
  }

  private _postCreateMarker(markerGroup: konva.Group, marker: models.PlanViewerMarkerRef): void {
    if (!markerGroup) {
      return;
    }

    if (marker) {
      const nodeId = markerGroup.id();

      this._markers[nodeId] = <models.PlanViewerMarkerRef>{
        ...marker,
        nodeId: nodeId,
      };

      if (marker.buildingUnit || marker.buildingUnitId) {
        const infoIcon = this._planViewerMarkerService.addInfoIcon(markerGroup);

        this._konvaStage.batchDraw();

        infoIcon.on('mouseenter', () => this._showMarkerPopover(markerGroup));
        infoIcon.on('mouseleave', () => this._hideMarkerPopover());
      } else {
        markerGroup.on('mouseenter', () => this._showMarkerPopover(markerGroup));
        markerGroup.on('mouseleave', () => this._hideMarkerPopover());
      }
    }

    markerGroup.on('mouseenter', () => this._handleMarkerGroupMouseEnter(markerGroup));
    markerGroup.on('mouseleave', () => this._handleMarkerGroupMouseLeave(markerGroup));

    markerGroup.on('click', () => this._handleMarkerGroupClick(markerGroup));

    if (this.allowChangeMarkers) {
      markerGroup.on('dragstart', () => this._handleMarkerGroupDragStart(markerGroup));
      markerGroup.on('dragend', () => this._handleMarkerGroupDragEnd(markerGroup));

      markerGroup.on('contextmenu', (event) => this._handleMarkerGroupContextMenu(markerGroup, event));
    }
  }

  private _getMarkerByNodeId(nodeId: string): models.PlanViewerMarkerRef {
    const markers = Object.values(this._markers);
    if (!markers) {
      return null;
    }

    return markers.find(x => x.nodeId === nodeId);
  }

  //
  // Drawing
  //

  private _drawImage(): void {
    if (!this._konvaLayer || !this._imageRef) {
      return;
    }

    const transform = this._getImageGroupTransform();

    this._konvaImageGroup = new konva.Group({
      id: 'image-group',
      x: transform.x,
      y: transform.y,
      offsetX: transform.width / 2,
      offsetY: transform.height / 2,
      width: transform.width,
      height: transform.height,
      rotation: this._imageRef.angle,
      draggable: this.isDragEnabled,
    });

    this._konvaImageGroup.on('dragstart', () => this._handleImageGroupDragStart());
    this._konvaImageGroup.on('dragend', () => this._handleImageGroupDragEnd());
    this._konvaImageGroup.on('dragmove', () => this._handleImageGroupDragMove());

    const image = new konva.Image({
      id: 'image',
      image: this._imageRef.imageElement,
      width: transform.width,
      height: transform.height,
    });

    this._konvaImageGroup.add(image);

    this._konvaLayer.add(this._konvaImageGroup);
    this._konvaLayer.batchDraw();
  }

  private _drawMarkers(): void {
    if (!this._konvaImageGroup || !this._imageRef || !this._imageRef.markers) {
      return;
    }

    const scaleX = this._getScaleX();
    const scaleY = this._getScaleY();

    const reorderedMarkers = this._planViewerMarkerSortService.sortByIntersection(this._imageRef.markers);

    for (let i = 0, num = reorderedMarkers.length; i < num; i++) {
      const marker = reorderedMarkers[i];

      const markerAttributes = <models.PlanViewerMarkerAttributes>{
        x: marker.x / scaleX,
        y: marker.y / scaleY,
        width: marker.width / scaleX,
        height: marker.height / scaleY,
        points: marker.points
          .map((value, index) => (
            index % 2 === 0 ? value / scaleX : value / scaleY
          )),
        closed: true,
        type: marker.type,
        color: marker.color,
        draggable: this.allowChangeMarkers && !this._isDrawMarkerEnabled,
        hasInfoIcon: !!(marker.buildingUnitId || marker.buildingUnit),
      };

      const markerGroup = this._drawMarker(markerAttributes);
      if (!markerGroup) {
        continue;
      }

      this._postCreateMarker(markerGroup, marker);
    }
  }

  private _drawMarker(attributes: models.PlanViewerMarkerAttributes): konva.Group {
    if (!this._konvaLayer || !this._konvaImageGroup || !attributes) {
      return;
    }

    const markerGroup = this._planViewerMarkerService.createMarker(attributes);
    if (!markerGroup) {
      return;
    }

    markerGroup.setAttrs({
      x: attributes.x,
      y: attributes.y
    });

    this._konvaImageGroup.add(markerGroup);
    this._konvaLayer.batchDraw();

    return markerGroup;
  }

  private _changeMarkerSize(
    markerGroup: konva.Group,
    attributes: models.PlanViewerMarkerSizeAttributes & models.PlanViewerMarkerTypeAttributes,
  ): void {
    if (!this._konvaLayer) {
      return;
    }

    this._planViewerMarkerService.changeMarkerSize(markerGroup, attributes);
    this._konvaLayer.batchDraw();
  }

  private _scaleImageGroup(position: konva.Vector2d, isZoomIn: boolean): void {
    if (!this._konvaLayer || !this._konvaImageGroup) {
      return;
    }

    const prevScale = this._konvaImageGroup.scaleX();

    const pointX = (position.x - this._konvaImageGroup.x()) / prevScale;
    const pointY = (position.y - this._konvaImageGroup.y()) / prevScale;

    let nextScale = prevScale / PlanViewerComponent._scaleStep;
    if (isZoomIn) {
      nextScale = prevScale * PlanViewerComponent._scaleStep;
    }

    if (nextScale <= PlanViewerComponent._minScale || PlanViewerComponent._maxScale <= nextScale) {
      return;
    }

    this._konvaImageGroup.scale({
      x: nextScale,
      y: nextScale,
    });

    const scalePercentage = nextScale * 100;

    this.scale = scalePercentage;
    this.scaleChange.emit(scalePercentage);

    this._moveImageGroup({x: position.x - pointX * nextScale, y: position.y - pointY * nextScale});

    this._konvaLayer.batchDraw();
  }

  private _moveImageGroup(position: konva.Vector2d, needBatchDraw: boolean = false): void {
    if (!this._konvaImageGroup) {
      return;
    }

    this._konvaImageGroup.position({
      x: this._getImageBoundingPositionX(position.x),
      y: this._getImageBoundingPositionY(position.y),
    });

    if (needBatchDraw && this._konvaLayer) {
      this._konvaLayer.batchDraw();
    }
  }

  private _rotateImageGroup(angle: number, needBatchDraw: boolean = false): void {
    if (!this._konvaImageGroup || !this._imageRef) {
      return;
    }

    this._konvaImageGroup.rotation(angle);

    if (needBatchDraw && this._konvaLayer) {
      this._konvaLayer.batchDraw();
    }
  }

  private _setMarkerGroupAccent(markerGroup: konva.Group): void {
    if (!this._konvaLayer || !markerGroup) {
      return;
    }

    this._planViewerMarkerService.setMarkerAccent(markerGroup);
    this._konvaLayer.batchDraw();
  }

  private _unsetAllMarkerGroupAccent(): void {
    if (!this._konvaImageGroup) {
      return;
    }

    const markers = this._konvaImageGroup.getChildren(x => x.id().startsWith('marker-group'));
    if (!markers || !markers.length) {
      return;
    }

    for (let i = 0, num = markers.length; i < num; i++) {
      this._unsetMarkerGroupAccent(<konva.Group>markers[i]);
    }
  }

  private _unsetMarkerGroupAccent(markerGroup: konva.Group): void {
    if (!this._konvaLayer || !markerGroup) {
      return;
    }

    this._planViewerMarkerService.unsetMarkerAccent(markerGroup);
    this._konvaLayer.batchDraw();
  }

  private _startDrawMarker(): void {
    if (!this._konvaStage || !this._isDrawMarkerEnabled) {
      return;
    }

    // Block circle drawing
    if (this._drawMarkerType === models.PlanViewerMarkerType.Circle) {
      return;
    }

    const relativeCursorPosition = this._getRelativeCursorPosition();

    this._drawMarkerGroup = this._drawMarker({
      type: this._drawMarkerType,
      x: relativeCursorPosition.x,
      y: relativeCursorPosition.y,
      width: 0,
      height: 0,
      points: [0, 0],
      closed: false,
      color: this.mode === PlanViewerMode.Unit ? PlanViewerMarkerColor.Green : PlanViewerComponent._defaultMarkerColor,
      draggable: this.allowChangeMarkers,
    });
  }

  private _doDrawMarker(): void {
    if (!this._drawMarkerGroup || !this._isDrawMarkerEnabled) {
      return;
    }

    const relativeCursorPosition = this._getRelativeCursorPosition();

    const previousPositionX = this._drawMarkerGroup.getAttr('x');
    const previousPositionY = this._drawMarkerGroup.getAttr('y');

    const deltaWidth = relativeCursorPosition.x - previousPositionX;
    const deltaHeight = relativeCursorPosition.y - previousPositionY;

    this._changeMarkerSize(this._drawMarkerGroup, <models.PlanViewerMarkerAttributes>{
      type: this._drawMarkerType,
      width: deltaWidth,
      height: deltaHeight,
    });
  }

  private _endDrawMarker(): void {
    if (!this._drawMarkerGroup || !this._isDrawMarkerEnabled) {
      return;
    }

    this._konvaLayer.batchDraw();

    const markerAttributes = this._planViewerMarkerService.getMarkerBoundingBox(this._drawMarkerGroup);
    if (
      !markerAttributes ||
      markerAttributes.width === PlanViewerComponent._minimumMarkerWidth ||
      markerAttributes.height === PlanViewerComponent._minimumMarkerHeight
    ) {
      this._drawMarkerGroup.destroy();
      this._konvaLayer.batchDraw();

      this._drawMarkerGroup = null;
      return;
    }

    this._openMarkerDialog(this._drawMarkerGroup);

    this._drawMarkerGroup = null;
  }

  private _drawFixedSizeMarker(
    markerType: models.PlanViewerMarkerType,
    size: models.PlanViewerMarkerSizeAttributes,
  ): void {
    if (!this._konvaStage || !this._isDrawMarkerEnabled) {
      return;
    }

    const relativeCursorPosition = this._getRelativeCursorPosition();

    const markerGroup = this._drawMarker({
      type: markerType,
      x: relativeCursorPosition.x,
      y: relativeCursorPosition.y,
      width: size.width,
      height: size.height,
      points: [0, 0],
      closed: true,
      color: this.mode === PlanViewerMode.Unit ? PlanViewerMarkerColor.Green : PlanViewerComponent._defaultMarkerColor,
      draggable: this.allowChangeMarkers,
    });

    this._openMarkerDialog(markerGroup);
  }

  //
  // Actions
  //

  private _fireMarkerSelect(markerGroup: konva.Group): void {
    if (!markerGroup) {
      this.selectedMarkerId = null;
      this.selectedMarkerIdChange.emit(null);
      return;
    }

    const markerGroupId = markerGroup.id();

    const marker = this._markers[markerGroupId];
    if (!marker) {
      return;
    }

    this.selectedMarkerId = marker.id;
    this.selectedMarkerIdChange.emit(marker.id);
  }

  private _fireMarkerMoveEnd(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    const markerGroupId = markerGroup.id();

    const marker = this._markers[markerGroupId];
    if (!marker) {
      return;
    }


    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to move this item?',
        acceptFn: () => {
          if (!marker || !markerGroup) {
            return;
          }

          marker.x = markerGroup.x() * this._getScaleX();
          marker.y = markerGroup.y() * this._getScaleY();

          this.markerChanged.emit(marker);
        },
        declineFn: () => {
          if (!marker || !markerGroup || !this._konvaStage) {
            return;
          }

          markerGroup.setAttrs({
            x: marker.x / this._getScaleX(),
            y: marker.y / this._getScaleY(),
          });

          this._konvaStage.batchDraw();
        },
      },
    });
  }

  private _showMarkerPopover(markerGroup: konva.Group): void {
    if (!this._konvaImageGroup || !markerGroup ||
      !this.planViewerKonvaContainerElementRef || !this.planViewerKonvaContainerElementRef.nativeElement) {
      return;
    }

    const markerId = markerGroup.id();
    if (!this._markers || !this._markers[markerId]) {
      return;
    }

    if (
      this._popoverComponent &&
      this._popoverComponent.injectableData &&
      this._popoverComponent.injectableData.marker
    ) {
      const prevMarker = this._popoverComponent.injectableData.marker;
      const currMarker = this._markers[markerId];

      if (prevMarker.id !== currMarker.id) {
        this._hideMarkerPopoverImmediately();
      }
    }

    if (this._popoverComponent && this._hidePopoverTimeout) {
      clearTimeout(this._hidePopoverTimeout);
      this._hidePopoverTimeout = null;
      return;
    }

    let popoverOptions = <PlanViewerMarkerPopoverOptions>{
      allowChangeMarkers: this.allowChangeMarkers,
    };

    const markerInfoIcon = this._planViewerMarkerService.getInfoIcon(markerGroup);

    if (markerInfoIcon) {
      const imageGroup = markerGroup.getParent();
      if (!imageGroup) {
        return;
      }

      const containerClientRect = this.planViewerKonvaContainerElementRef.nativeElement.getBoundingClientRect();
      const scale = imageGroup.scaleX();
      const infoIconElementRect = markerInfoIcon.getSelfRect();
      const absolutePosition = markerInfoIcon.getAbsolutePosition();

      let width = infoIconElementRect.width * scale;
      let height = infoIconElementRect.height * scale;
      let x = absolutePosition.x;
      let y = absolutePosition.y;

      if (width < 0) {
        width = Math.abs(width);
        x -= width;
      }

      if (height < 0) {
        height = Math.abs(height);
        y -= height;
      }

      const positionOffsetX = width / 2;
      const positionOffsetY = height;

      const absolutePositionX = (<number>containerClientRect.left + <number>x);
      const absolutePositionY = (<number>containerClientRect.top + <number>y);

      popoverOptions = {
        ...popoverOptions,
        x: (<number>absolutePositionX + <number>positionOffsetX),
        y: (<number>absolutePositionY + <number>positionOffsetY),
        markerWidth: width,
        markerHeight: height,
      };
    } else  {
      const markerBoundingBox = this._planViewerMarkerService.getMarkerBoundingBox(markerGroup);
      if (!markerBoundingBox) {
        return;
      }

      const containerClientRect = this.planViewerKonvaContainerElementRef.nativeElement.getBoundingClientRect();

      let positionOffsetX = markerBoundingBox.width / 2;
      let positionOffsetY = markerBoundingBox.height;

      const markerParent = markerGroup.getParent();
      if (markerParent && this._planViewerMarkerService.getMarkerType(markerGroup) === models.PlanViewerMarkerType.Rect) {
        const rotationAngle = markerParent.rotation();

        if (rotationAngle === 90 || rotationAngle === 180) {
          positionOffsetX = markerBoundingBox.width + markerBoundingBox.width / 2;
        }

        if (rotationAngle === 180 || rotationAngle === 270) {
          positionOffsetY = markerBoundingBox.height * 2;
        }
      }

      const absolutePositionX = (<number>containerClientRect.left + <number>markerBoundingBox.x);
      const absolutePositionY = (<number>containerClientRect.top + <number>markerBoundingBox.y);

      popoverOptions = {
        ...popoverOptions,
        x: (<number>absolutePositionX + <number>positionOffsetX),
        y: (<number>absolutePositionY + <number>positionOffsetY),
        markerWidth: markerBoundingBox.width,
        markerHeight: markerBoundingBox.height,
      };
    }

    this._popoverComponent = this._planViewerMarkerPopoverService.show(this._markers[markerId], popoverOptions);
    if (!this._popoverComponent) {
      return;
    }

    this._popoverComponent.setupHandlers(this.imageMarkerCreated, this.imageMarkerChanged, this.imageMarkerDeleted);

    this._popoverComponent.mouseIn$
      .pipe(
        tap(() => {
          if (!this._hidePopoverTimeout) {
            return;
          }

          this._setMarkerGroupAccent(markerGroup);

          clearTimeout(this._hidePopoverTimeout);
          this._hidePopoverTimeout = null;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._popoverComponent.mouseOut$
      .pipe(
        tap(() => {
          const marker = this._getMarkerByNodeId(markerGroup.attrs.id);
          if (marker && this.selectedMarkerId !== marker.id) {
            this._unsetMarkerGroupAccent(markerGroup);
          }

          this._hideMarkerPopover();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _hideMarkerPopover(): void {
    this._ngZone.runOutsideAngular(() => {
      this._hidePopoverTimeout = setTimeout(() => this._hideMarkerPopoverImmediately());
    });
  }

  private _hideMarkerPopoverImmediately() {
    if (!this._popoverComponent) {
      return;
    }

    this._planViewerMarkerPopoverService.hide();
    this._popoverComponent = null;

    if (this._hidePopoverTimeout) {
      clearTimeout(this._hidePopoverTimeout);
      this._hidePopoverTimeout = null;
    }
  }

  private _openMarkerDialog(markerGroup: konva.Group): void {
    if (!markerGroup || !this._imageRef || !this._markers || !this._konvaLayer) {
      return;
    }

    if (this.mode === PlanViewerMode.Unit && !this.suggestions.length) {
      return;
    }

    const markerX = markerGroup.x() * this._getScaleX();
    const markerY = markerGroup.y() * this._getScaleY();
    const markerWidth = markerGroup.width() * this._getScaleX();
    const markerHeight = markerGroup.height() * this._getScaleY();

    let points;
    const markerPolygon = markerGroup.findOne('#background-polygon');
    if (markerPolygon) {
      points = markerPolygon
        .points()
        .map((value, index) => (
          index % 2 === 0 ? value * this._getScaleX() : value * this._getScaleY()
        ));
    }

    const markerId = markerGroup.id();

    const isCreateEvent = !this._markers[markerId];
    const isChangeEvent = !isCreateEvent;

    let isUnit = this.mode === models.PlanViewerMode.Unit;
    if (isChangeEvent) {
      isUnit = !!(this._markers[markerId].buildingUnitId || this._markers[markerId].buildingUnit);
    }

    let dialogTitle = `Create ${isUnit ? 'Unit' : 'Marker'}`;
    let dialogMarker = <models.PlanViewerMarkerRef>{
      nodeId: markerId,
      type: this._planViewerMarkerService.getMarkerType(markerGroup),
      x: markerX,
      y: markerY,
      width: markerWidth,
      height: markerHeight,
      points: points,
      area: BuildingAreaType.InteriorOffice,
    };

    if (isChangeEvent) {
      dialogTitle = `Edit ${isUnit ? 'Unit' : 'Marker'}`;
      dialogMarker = this._markers[markerId];
    }

    const markers = Object.values(this._markers);

    const suggestions = this.suggestions
      .filter(suggestion =>
        suggestion.name === dialogMarker.title ||
        !markers.some(marker => marker.title === suggestion.name)
      );

    const dialogRef = this._popupService.show(PlanViewerMarkerDialogComponent, {
      title: dialogTitle,
      showCloseButton: true,
      closeOnOutsideClick: false,
      width: 450,
      height: 'auto',
      maxHeight: 700,
      injectableData: {
        mode: isUnit ? models.PlanViewerMode.Unit : models.PlanViewerMode.Marker,
        marker: dialogMarker,
        suggestions: suggestions,
      },
    });

    const hidingSubscription = dialogRef.onHiding
      .subscribe(() => {
        hidingSubscription.unsubscribe();

        if (isCreateEvent && !dialogRef.outputData) {
          markerGroup.destroy();
          this._konvaLayer.batchDraw();
          return;
        }

        if (!dialogRef.outputData || !dialogRef.outputData.marker) {
          return;
        }

        this._markers[markerId] = dialogRef.outputData.marker;

        if (isCreateEvent) {
          this.markerCreated.emit(this._markers[markerId]);
        }

        if (isChangeEvent) {
          this.markerChanged.emit(this._markers[markerId]);
        }

        this._setDrawMarkerState(false, null);
      });
  }

  private _removeMarker(markerGroup: konva.Group): void {
    if (!markerGroup || !this._imageRef || !this._markers || !this._konvaLayer) {
      return;
    }

    markerGroup.destroy();
    this._konvaLayer.batchDraw();

    const markerId = markerGroup.id();
    const marker = this._markers[markerId];

    if (!marker) {
      return;
    }

    this.markerDeleted.emit(marker);
    delete this._markers[markerId];
  }

  //
  // Rendering Helpers
  //

  private _getImageGroupTransform(): { width: number, height: number, x: number, y: number } {
    const containerWidth = this.width;
    const containerHeight = this.height;

    const originalImageWidth = this._imageRef.width;
    const originalImageHeight = this._imageRef.height;

    const imageRatio = (
      Math.max(originalImageWidth, originalImageHeight) / Math.min(originalImageWidth, originalImageHeight)
    );

    let imageWidth = originalImageWidth;
    let imageHeight = originalImageHeight;

    if (containerWidth < originalImageWidth || containerHeight < originalImageHeight) {
      if (originalImageHeight <= originalImageWidth) {
        imageWidth = containerHeight * imageRatio;
        imageHeight = containerHeight;
      } else {
        imageWidth = containerHeight / imageRatio;
        imageHeight = containerHeight;
      }
    }

    const imageCenterX = containerWidth / 2;
    const imageCenterY = containerHeight / 2;

    return {
      width: imageWidth,
      height: imageHeight,
      x: imageCenterX,
      y: imageCenterY,
    };
  }

  private _getImageBoundingPositionX(nextPosition: number = null): number {
    if (!this._konvaStage || !this._konvaImageGroup || !this.width) {
      return 0;
    }

    const canvasWidth = this._konvaStage.width();

    const imageScale = this._konvaImageGroup.scaleX();
    const imageWidth = this._konvaImageGroup.width() * imageScale;
    const imageOffset = this._konvaImageGroup.offsetX() * imageScale;

    const availableOffset = imageWidth / PlanViewerComponent._availableBoundaryOffsetX;

    let leftBoundary = Math.abs(imageOffset - availableOffset);
    let rightBoundary = Math.abs((imageOffset - availableOffset) - canvasWidth);

    if (canvasWidth < imageWidth) {
      leftBoundary = -(imageOffset - availableOffset);
      rightBoundary = (imageOffset + availableOffset);
    }

    if (nextPosition === null) {
      nextPosition = this._konvaImageGroup.x();
    }

    return Math.max(leftBoundary, Math.min(rightBoundary, nextPosition));
  }

  private _getImageBoundingPositionY(nextPosition: number = null): number {
    if (!this._konvaStage || !this._konvaImageGroup) {
      return 0;
    }

    const canvasHeight = this._konvaStage.height();

    const imageScale = this._konvaImageGroup.scaleY();
    const imageHeight = this._konvaImageGroup.height() * imageScale;
    const imageOffset = this._konvaImageGroup.offsetY() * imageScale;

    const availableOffset = imageHeight / PlanViewerComponent._availableBoundaryOffsetY;

    let topBoundary = Math.abs(imageOffset - availableOffset);
    let bottomBoundary = Math.abs((imageOffset - availableOffset) - canvasHeight);

    if (canvasHeight < imageHeight) {
      topBoundary = -(imageOffset - availableOffset);
      bottomBoundary = (imageOffset + availableOffset);
    }

    if (nextPosition === null) {
      nextPosition = this._konvaImageGroup.y();
    }

    return Math.max(topBoundary, Math.min(bottomBoundary, nextPosition));
  }

  private _getScaleX(): number {
    if (!this._imageRef || !this._konvaImageGroup) {
      return 0;
    }

    return this._imageRef.width / this._konvaImageGroup.width();
  }

  private _getScaleY(): number {
    if (!this._imageRef || !this._konvaImageGroup) {
      return 0;
    }

    return this._imageRef.height / this._konvaImageGroup.height();
  }

  private _getRelativeCursorPosition() {
    const cursorPosition = this._konvaStage.getPointerPosition();
    const transform = this._konvaImageGroup.getTransform().copy();

    transform.invert();

    return transform.point(cursorPosition);
  }

  //
  // Event handlers
  //

  private _handleCanvasResize(): void {
    if (!this._konvaStage || !this._konvaImageGroup || !this.width || !this.height) {
      return;
    }

    this._konvaStage.setAttrs({
      width: this.width,
      height: this.height,
    });

    const transform = this._getImageGroupTransform();
    if (!transform) {
      return;
    }

    const prevScale = this._konvaImageGroup.scaleX();
    const nextScale = transform.width / this._konvaImageGroup.width();

    this._konvaImageGroup.scale({
      x: nextScale,
      y: nextScale,
    });

    const x = this._konvaImageGroup.x();
    const y = this._konvaImageGroup.y();

    const nextX = x * nextScale;
    const nextY = y * nextScale;

    const deltaX = nextX - x;
    const deltaY = nextY - y;

    this._moveImageGroup({x: nextX - deltaX, y: nextY - deltaY});

    const scalePercentage = nextScale * 100;

    this.scale = scalePercentage;
    this.scaleChange.emit(scalePercentage);

    this._konvaStage.batchDraw();
  }

  private _handleStageWheel(event: konva.KonvaEventObject<WheelEvent>): void {
    if (!this._konvaStage || !event || !event.evt || !this.isDragEnabled) {
      return;
    }

    event.evt.preventDefault();

    this._scaleImageGroup(this._konvaStage.getPointerPosition(), 0 < event.evt.deltaY);
  }

  private _handleStageClick(event: konva.KonvaEventObject<MouseEvent>): void {
    if (
      !this._konvaStage ||
      !event ||
      !event.evt ||
      (this._isDrawMarkerEnabled && this._drawMarkerType !== PlanViewerMarkerType.Circle)
    ) {
      return;
    }

    event.evt.preventDefault();

    if (!event.target || !event.target.getParent() || !event.target.getParent().id().startsWith('marker-group')) {
      this._unsetAllMarkerGroupAccent();
      this._fireMarkerSelect(null);
    }

    if (this._isDrawMarkerEnabled && this._drawMarkerType === models.PlanViewerMarkerType.Circle) {
      const size = {
        width: PlanViewerComponent._circleRadius * 2,
        height: PlanViewerComponent._circleRadius * 2,
      };

      this._drawFixedSizeMarker(this._drawMarkerType, size);
    }
  }

  private _handleStageMouseDown(event: konva.KonvaEventObject<MouseEvent>): void {
    if (!this._konvaStage || !event || !event.evt) {
      return;
    }

    event.evt.preventDefault();

    // tslint:disable:deprecation
    if (
      (event.evt.which && event.evt.which === 3) ||
      (event.evt.button && event.evt.button === 2)
    ) {
      return;
    }
    // tslint:enable:deprecation

    // Continue polygon drawing
    if (this._drawMarkerType === models.PlanViewerMarkerType.Polygon && this._drawMarkerGroup) {
      const relativeCursorPosition = this._getRelativeCursorPosition();

      const previousPositionX = this._drawMarkerGroup.getAttr('x');
      const previousPositionY = this._drawMarkerGroup.getAttr('y');

      const deltaWidth = relativeCursorPosition.x - previousPositionX;
      const deltaHeight = relativeCursorPosition.y - previousPositionY;

      this._planViewerMarkerService
        .continueMarker(this._drawMarkerGroup, <models.PlanViewerMarkerAttributes>{
          type: this._drawMarkerType,
          width: deltaWidth,
          height: deltaHeight,
        });

      return;
    }

    this._startDrawMarker();

    this._konvaStage.on('mousemove', this._stageMouseMoveHandler);
  }

  private _handleStageMouseUp(event: konva.KonvaEventObject<MouseEvent>): void {
    if (!this._konvaImageGroup || !event || !event.evt) {
      return;
    }

    event.evt.preventDefault();

    // Do not end polygon marker while its path is not yet closed
    if (
      this._drawMarkerType === models.PlanViewerMarkerType.Polygon &&
      this._drawMarkerGroup && !this._drawMarkerGroup.attrs.closed
    ) {
      return;
    }

    this._endDrawMarker();

    this._konvaStage.off('mousemove', this._stageMouseMoveHandler);
  }

  private _handleStageMouseMove(event: konva.KonvaEventObject<MouseEvent>): void {
    if (!this._konvaStage || !event || !event.evt) {
      return;
    }

    event.evt.preventDefault();

    this._doDrawMarker();
  }

  private _handleImageGroupDragStart(): void {
    if (!this._konvaImageGroup || !this.isDragEnabled) {
      return;
    }

    this._setContainerGrabbedClass();

    this._hideMarkerPopoverImmediately();
  }

  private _handleImageGroupDragEnd(): void {
    if (!this._konvaImageGroup || !this.isDragEnabled) {
      return;
    }

    this._removeContainerGrabbedClass();
  }

  private _handleImageGroupDragMove(): void {
    if (!this._konvaImageGroup || !this.isDragEnabled) {
      return;
    }

    this._moveImageGroup(this._konvaImageGroup.position());
  }

  private _handleMarkerGroupColorChange(id: number, color: models.PlanViewerMarkerColor): void {
    if (!this._konvaLayer || !this._markers || !id || typeof (color) !== 'number') {
      return;
    }

    const markers = Object.values(this._markers);
    if (!markers) {
      return;
    }

    const marker = markers.find(x => x.id === id);
    if (!marker) {
      return;
    }

    const markerGroup = this._konvaImageGroup.findOne(`#${marker.nodeId}`);
    if (!markerGroup) {
      return;
    }

    this._planViewerMarkerService.changeMarkerColor(<konva.Group>markerGroup, {color});
    this._konvaLayer.batchDraw();
  }

  private _handleMarkerGroupCreationEnd(marker: models.PlanViewerMarkerRef): void {
    if (!marker) {
      return;
    }

    const markerGroup = this._konvaLayer.findOne(`#${marker.nodeId}`);
    if (!markerGroup) {
      return;
    }

    this._postCreateMarker(<konva.Group>markerGroup, marker);
  }

  private _handleMarkerGroupSelectionChange(id: number): void {
    if (!this._markers) {
      return;
    }

    this._unsetAllMarkerGroupAccent();

    if (!id) {
      return;
    }

    const markers = Object.values(this._markers);
    if (!markers) {
      return;
    }

    const marker = markers.find(x => x.id === id);
    if (!marker) {
      return;
    }

    const markerGroup = this._konvaImageGroup.findOne(`#${marker.nodeId}`);
    if (!markerGroup) {
      return;
    }

    this._setMarkerGroupAccent(<konva.Group>markerGroup);
  }

  private _handleMarkerGroupMouseEnter(markerGroup: konva.Group): void {
    if (!this._konvaLayer || !markerGroup || this._drawMarkerGroup || this._isDrawMarkerEnabled) {
      return;
    }

    // Create placeholder for circle marker
    this._planViewerMarkerService.createMarkerPlaceholder(markerGroup, markerGroup.getSize());
    this._konvaLayer.batchDraw();

    this._setMarkerGroupAccent(markerGroup);

    this._setContainerClickableClass();
  }

  private _handleMarkerGroupMouseLeave(markerGroup: konva.Group): void {
    if (!this._konvaLayer || !markerGroup || this._isDrawMarkerEnabled) {
      return;
    }

    this._planViewerMarkerService.removeMarkerPlaceholder(markerGroup);
    this._konvaLayer.batchDraw();

    const marker = this._getMarkerByNodeId(markerGroup.attrs.id);
    if (marker && this.selectedMarkerId !== marker.id) {
      this._unsetMarkerGroupAccent(markerGroup);
    }

    this._removeContainerClickableClass();
  }

  private _handleMarkerGroupDragStart(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._removeContainerClickableClass();
    this._setContainerGrabbedClass();

    this._hideMarkerPopoverImmediately();
  }

  private _handleMarkerGroupDragEnd(markerGroup: konva.Group): void {
    if (!markerGroup) {
      return;
    }

    this._removeContainerGrabbedClass();
    this._setContainerClickableClass();

    this._fireMarkerMoveEnd(markerGroup);

    this._showMarkerPopover(markerGroup);
  }

  private _handleMarkerGroupClick(markerGroup: konva.Group): void {
    if (!markerGroup || this._drawMarkerGroup || this._isDrawMarkerEnabled) {
      return;
    }

    this._unsetAllMarkerGroupAccent();

    this._fireMarkerSelect(markerGroup);
  }

  private _handleMarkerGroupContextMenu(markerGroup: konva.Group, event: konva.KonvaEventObject<MouseEvent>): void {
    if (!markerGroup || !event || !event.evt || !this._konvaStage) {
      return;
    }

    event.evt.preventDefault();

    this._handleMarkerGroupMouseLeave(markerGroup);
    this._unsetAllMarkerGroupAccent();
    this._fireMarkerSelect(markerGroup);

    const x = event.evt.clientX || event.evt.pageX;
    const y = event.evt.clientY || event.evt.pageY;

    this._createContextMenu(markerGroup, x, y);
  }

  private _handleImageMarkerCreate(ref: models.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.markerRef.images) {
      return;
    }

    const imageIndex = ref.markerRef.images
      .findIndex(x => x.imageViewerImage.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0) {
      return;
    }

    if (!ref.markerRef.images[imageIndex].imageViewerImage.markers) {
      ref.markerRef.images[imageIndex].imageViewerImage.markers = [];
    }

    ref.markerRef.images[imageIndex].imageViewerImage.markers.push(ref.imageMarkerRef);
  }

  private _handleImageMarkerUpdate(ref: models.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.markerRef.images) {
      return;
    }

    const imageIndex = ref.markerRef.images
      .findIndex(x => x.imageViewerImage.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0 || !ref.markerRef.images[imageIndex].imageViewerImage.markers) {
      return;
    }

    const markerIndex = ref.markerRef.images[imageIndex].imageViewerImage.markers
      .findIndex(x => x.id === ref.imageMarkerRef.id);

    if (markerIndex < 0) {
      return;
    }

    ref.markerRef.images[imageIndex].imageViewerImage.markers[markerIndex] = ref.imageMarkerRef;
  }

  private _handleImageMarkerDelete(ref: models.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.markerRef.images) {
      return;
    }

    const imageIndex = ref.markerRef.images
      .findIndex(x => x.imageViewerImage.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0 || !ref.markerRef.images[imageIndex].imageViewerImage.markers) {
      return;
    }

    const markerIndex = ref.markerRef.images[imageIndex].imageViewerImage.markers
      .findIndex(x => x.id === ref.imageMarkerRef.id);

    if (markerIndex < 0) {
      return;
    }

    ref.markerRef.images[imageIndex].imageViewerImage.markers.splice(markerIndex, 1);
  }

  //
  // DOM Helpers
  //

  private _setContainerGrabbedClass(): void {
    this._setContainerClass('is-grabbed');
  }

  private _removeContainerGrabbedClass(): void {
    this._removeContainerClass('is-grabbed');
  }

  private _setContainerClickableClass(): void {
    this._setContainerClass('is-clickable');
  }

  private _removeContainerClickableClass(): void {
    this._removeContainerClass('is-clickable');
  }

  private _setContainerClass(className: string): void {
    if (!this.planViewerKonvaContainerElementRef || !this.planViewerKonvaContainerElementRef.nativeElement) {
      return;
    }

    this._renderer.addClass(this.planViewerKonvaContainerElementRef.nativeElement, className);
  }

  private _removeContainerClass(className: string): void {
    if (!this.planViewerKonvaContainerElementRef || !this.planViewerKonvaContainerElementRef.nativeElement) {
      return;
    }

    this._renderer.removeClass(this.planViewerKonvaContainerElementRef.nativeElement, className);
  }

  private _createContextMenu(markerGroup: konva.Group, x: number, y: number): void {
    if (!markerGroup) {
      return;
    }

    const contextMenuBackdrop = this._renderer.createElement('div');

    this._renderer.addClass(contextMenuBackdrop, 'plan-viewer-context-menu-backdrop');

    const contextMenuElement = this._renderer.createElement('div');
    const contextMenuListElement = this._renderer.createElement('ul');

    const contextMenuItems = [
      {text: 'Edit', action: () => this._openMarkerDialog(markerGroup)},
      {text: 'Remove', action: () => this._removeMarker(markerGroup)},
    ];

    for (let i = 0, num = contextMenuItems.length; i < num; i++) {
      const item = contextMenuItems[i];

      const contextMenuItemElement = this._renderer.createElement('li');
      const contextMenuItemButtonElement = this._renderer.createElement('button');
      const contextMenuItemButtonText = this._renderer.createText(item.text);

      contextMenuItemButtonElement.type = 'button';
      contextMenuItemButtonElement.addEventListener('mousedown', (event: MouseEvent) => {
        if (event) {
          event.preventDefault();
        }

        item.action();
      });

      this._renderer.appendChild(contextMenuItemButtonElement, contextMenuItemButtonText);
      this._renderer.appendChild(contextMenuItemElement, contextMenuItemButtonElement);
      this._renderer.appendChild(contextMenuListElement, contextMenuItemElement);
    }

    this._renderer.addClass(contextMenuElement, 'plan-viewer-context-menu');

    this._renderer.setStyle(contextMenuElement, 'left', `${x || 0}px`);
    this._renderer.setStyle(contextMenuElement, 'top', `${y || 0}px`);

    this._renderer.appendChild(contextMenuElement, contextMenuListElement);
    this._renderer.appendChild(contextMenuBackdrop, contextMenuElement);

    this._renderer.appendChild(document.body, contextMenuBackdrop);

    contextMenuBackdrop.addEventListener('click', () => {
      contextMenuBackdrop.remove();
    });
  }
}
