import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { Subject } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { SelectItem } from '../../../../shared/models/select-item.model';

import { Tools } from '../../../../shared/tools';

import { PopupService } from '../../../infrastructure/services/popup.service';

import { PointOfInterestImportComponent } from '../point-of-interest-import/point-of-interest-import.component';
import { PointOfInterestUpsertComponent } from '../point-of-interest-upsert/point-of-interest-upsert.component';

import * as models from '../../../../shared/models/generated';

@Component({
  templateUrl: 'point-of-interest-list.component.html',
  styleUrls: ['point-of-interest-list.component.scss'],
})
export class PointOfInterestListComponent implements OnInit, OnDestroy {
  private static readonly _dataURL = `${environment.adminUrl}/points-of-interest`;

  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: CustomStore;

  pointOfInterestStatusDataSource: Array<SelectItem>;
  pointOfInterestKindDataSource: Array<SelectItem>;

  private readonly _popupService: PopupService;

  private readonly _destroy: Subject<void>;

  constructor(popupService: PopupService) {
    this._popupService = popupService;

    this._destroy = new Subject<void>();

    this.showUpsertPopup = this.showUpsertPopup.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = AspNetData
      .createStore({
        key: 'id',
        loadUrl: PointOfInterestListComponent._dataURL,
        deleteUrl: PointOfInterestListComponent._dataURL,
      });

    this.pointOfInterestStatusDataSource = Tools.EnumToArray(models.PointOfInterestStatus);
    this.pointOfInterestKindDataSource = Tools.EnumToArray(models.PointOfInterestKind);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  prepareToolbar($event) {
    if ($event.toolbarOptions) {
      $event.toolbarOptions.items = [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'importPointOfInterest',
          options: {
            icon: 'fa fa-upload',
            disabled: false,
            text: 'Import Points of Interest',
            onClick: () => this._showImportPopup(),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'createPointOfInterest',
          options: {
            icon: 'fa fa-plus',
            disabled: false,
            text: 'Create Point Of Interest',
            onClick: () => this._showUpsertPopup(),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        ...$event.toolbarOptions.items,
      ];
    }
  }

  showUpsertPopup(event: {row: {data: models.IPointOfInterestViewModel}}): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._showUpsertPopup(event.row.data);
  }

  private _showImportPopup(): void {
    this._popupService.show(PointOfInterestImportComponent, {
      title: 'Import Points Of Interest',
      width: 550,
      maxHeight: '90%',
      showCloseButton: false,
      closeOnOutsideClick: false,
    });
  }

  private _showUpsertPopup(model?: models.IPointOfInterestViewModel): void {
    this._popupService.show(PointOfInterestUpsertComponent, {
      title: `${model ? 'Edit' : 'Create'} Point Of Interest`,
      width: 550,
      maxHeight: '90%',
      showCloseButton: false,
      closeOnOutsideClick: false,
      injectableData: {
        model: model,
        reloadList: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      },
    });
  }
}
