export class DocumentViewerOptions {
  zoom: number;
  page: number;
  maxPage: number;
  previewMode: boolean;
  floatingControls: boolean;
  draftId: number;
  importTaskId: number;
  hashCode: number;

  constructor(draftId: number) {
    this.zoom = 100;
    this.page = 1;
    this.maxPage = 1;
    this.previewMode = false;
    this.floatingControls = true;
    this.draftId = draftId;
  }
}
