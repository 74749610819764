import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  DxLookupModule,
  DxToastModule,
  DxLoadPanelModule,
  DxDataGridModule,
  DxPopupModule,
  DxFileUploaderModule,
  DxButtonModule,
  DxFormModule,
} from 'devextreme-angular';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { NgProgressModule } from '@ngx-progressbar/core';

import { BuildingModule } from './modules/building/building.module';
import { BuildingUnitModule } from './modules/building-unit/building-unit.module';
import { BuildingUnitListingModule } from './modules/building-unit-listing/building-unit-listing.module';
import { CompanyModule } from './modules/company/company.module';
import { CompanyFeatureModule } from './modules/company-feature/company-feature.module';
import { DocumentMarkerModule } from './modules/document-marker/document-marker.module';
import { GlobalFeatureModule } from './modules/global-feature/global-feature.module';
import { ImportModule } from './modules/import/import.module';
import { InvitationModule } from './modules/invitation/invitation.module';
import { LeaseAmendmentModule } from './modules/lease-amendment/lease-amendment.module';
import { LeaseFloorPlanModule } from './modules/lease-floor-plan/lease-floor-plan.module';
import { LeaseTemplateModule } from './modules/lease-template/lease-template.module';
import { LeaseSignatureModule } from './modules/lease-signature/lease-signature.module';
import { TermModule } from './modules/term/term.module';
import { UserModule } from './modules/user/user.module';
import { PortfolioModule } from './modules/portfolio/portfolio.module';
import { LeaseAbstractModule } from './modules/lease-abstract/lease-abstract.module';
import { LeaseUserModule } from './modules/lease-user/lease-user.module';

import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService, ThumbnailsService } from './shared/services';
import { HttpClientInterceptor } from './shared/http-client-interceptor';
import { ProgressBarService } from './shared/services/progress-bar.service';
import { StateraClaimGroupService } from './shared/services/statera-claim-group.service';
import { ToastService } from './shared/services/toast.service';
import { CleanCopyService } from './shared/services/clean-copy.service';

import { LeaseSignersComponent } from './pages/lease-signers/lease-signers.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { StateraUserClaimsComponent } from './pages/statera-user-claims/statera-user-claims.component';
import { StateraClaimGroupsComponent } from './pages/statera-claim-groups/statera-claim-groups.component';
import { ThirdPartyLeasesComponent } from './pages/third-party-leases/third-party-leases.component';

import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BuildingAttachmentModule } from './modules/building-attachments/building-attachments.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    LeaseSignersComponent,
    CompaniesComponent,
    StateraUserClaimsComponent,
    StateraClaimGroupsComponent,
    ThirdPartyLeasesComponent,
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    AppRoutingModule,
    HttpClientModule,
    DxLookupModule,
    NgProgressModule,
    DxToastModule,
    DxLoadPanelModule,
    DxCheckBoxModule,
    ImportModule.forRoot(),
    UserModule.forRoot(),
    PortfolioModule.forRoot(),
    BuildingModule.forRoot(),
    BuildingUnitModule.forRoot(),
    BuildingUnitListingModule.forRoot(),
    CompanyModule.forRoot(),
    CompanyFeatureModule.forRoot(),
    DocumentMarkerModule.forRoot(),
    GlobalFeatureModule.forRoot(),
    InvitationModule.forRoot(),
    LeaseAbstractModule.forRoot(),
    LeaseAmendmentModule.forRoot(),
    LeaseFloorPlanModule.forRoot(),
    LeaseTemplateModule.forRoot(),
    LeaseSignatureModule.forRoot(),
    LeaseUserModule.forRoot(),
    TermModule.forRoot(),
    BuildingAttachmentModule.forRoot(),
    DxDataGridModule,
    DxPopupModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxFormModule,
    SharedModule,
  ],
  providers: [
    AuthService,
    ScreenService,
    AppInfoService,
    ThumbnailsService,
    ProgressBarService,
    ToastService,
    CleanCopyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    DatePipe,
    StateraClaimGroupService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
