<!-- Start: Commencement fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Commencement
  </div>
  <!-- Start: Commencement -->
  <div class="dx-field">
    <div class="dx-field-label"
         [class.text-danger]="form.submitted && commencementDate.invalid">
      Commencement date
    </div>
    <div class="dx-field-value">
      <dx-date-box type="date" name="commencement"
                   displayFormat="MMMM d, yyyy" dateSerializationFormat="yyyy-MM-dd" required
                   [isValid]="form.submitted ? commencementDate.valid : true"
                   [(ngModel)]="lease.commencementTerm.commencement"
                   (ngModelChange)="handleCommencementDateChange($event)"
                   #commencementDate="ngModel">
      </dx-date-box>
      <div class="my-1 invalid-feedback d-block"
           *ngIf="form.submitted && commencementDate.hasError('required')">
        Commencement date is required
      </div>
    </div>
  </div>
  <!-- End: Commencement -->
  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="commencement-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.commencementTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->
  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="Commencement"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->
  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.commencementTerm?.commencement?.toString() | date: 'MMMM d, y'"
                                [documentSection]="leasePreview?.commencementTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Commencement fieldset -->
<!-- Start: Expiration fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Expiration
  </div>
  <!-- Start: Expiration -->
  <div class="dx-field">
    <div class="dx-field-label"
         [class.text-danger]="form.submitted && expirationDate.invalid">
      Expiration date
    </div>
    <div class="dx-field-value">
      <dx-date-box type="date" name="expiration" displayFormat="MMMM d, yyyy"
                   dateSerializationFormat="yyyy-MM-dd" required
                   [isValid]="form.submitted ? expirationDate.valid : true"
                   [(ngModel)]="lease.expiration"
                   (ngModelChange)="handleExpirationDateChange($event)"
                   #expirationDate="ngModel">
      </dx-date-box>
      <div class="my-1 invalid-feedback d-block"
           *ngIf="form.submitted && expirationDate.hasError('required')">
        Expiration date is required
      </div>
    </div>
  </div>
  <!-- End: Expiration -->
  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="expiration-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.expirationDocumentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->
  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="Expiration"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->
  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.calculatedExpirationDate?.toString() | date: 'MMMM d, y'"
                                [documentSection]="leasePreview?.expirationDocumentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Expiration fieldset -->
<!-- Start: Term fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Lease Term
  </div>
  <!-- Start: Term -->
  <div class="dx-field">
    <div class="dx-field-label"
         [class.text-danger]="form.submitted && term.invalid">
      Length
    </div>
    <div class="dx-field-value">
      <dx-number-box appIgnoreScrollwheel name="term" format="#0 month(s)" required
                     min="0" max="9999999"
                     [isValid]="form.submitted ? term.valid : true"
                     [(ngModel)]="lease.term.termValue"
                     (ngModelChange)="handleTermChange($event)"
                     #term="ngModel">
      </dx-number-box>
      <div class="my-1 invalid-feedback d-block"
           *ngIf="form.submitted && term.hasError('required')">
        Length is required
      </div>
    </div>
  </div>
  <!-- End: Term -->
  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="term-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.term.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->
  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="Length"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->
  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.term?.detailedDisplayValue"
                                [documentSection]="leasePreview?.term?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Term fieldset -->

<!-- Start: Square footage fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Square footage
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label"
         [class.text-danger]="form.submitted && tenantSquareFootageType.invalid">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="tenant-square-footage-type" valueExpr="value" displayExpr="name" required
                     [(items)]="tenantSquareFootageTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.tenantSquareFootageTerm.tenantSquareFootageTermType"
                     #tenantSquareFootageType="ngModel">
      </dx-select-box>
      <div class="my-1 invalid-feedback d-block"
           *ngIf="form.submitted && tenantSquareFootageType.hasError('required')">
        Type is required
      </div>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.tenantSquareFootageTerm.tenantSquareFootageTermType === TenantSquareFootageType.Custom">
    <!-- Start: Size -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && tenantSquareFootageSize.invalid">
        Size
      </div>
      <div class="dx-field-value">
        <dx-number-box name="tenant-square-footage-size" format="#,##0 SF" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? tenantSquareFootageSize.valid : true"
                       [(ngModel)]="lease.tenantSquareFootageTerm.tenantSquareFootageCustomValue"
                       #tenantSquareFootageSize="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && tenantSquareFootageSize.hasError('required')">
          Size is required
        </div>
      </div>
    </div>
    <!-- End: Size -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="tenant-square-footage-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.tenantSquareFootageTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="SquareFootage"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->
  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.tenantSquareFootageTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.tenantSquareFootageTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Square footage fieldset -->
<!-- Start: Base rental rate fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Base rental rate
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="base-rental-rate-type" valueExpr="value" displayExpr="name"
                     [(items)]="baseRentalRateTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.baseRentalRateTerm.baseRentalRateType"
                     #baseRentalRateType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.baseRentalRateTerm.baseRentalRateType && lease.baseRentalRateTerm.baseRentalRateType !== BaseRentalRateType.None">
    <!-- Start: Rental rate -->
    <ng-container [ngSwitch]="lease.baseRentalRateTerm.baseRentalRateType">
      <!-- Start: Net -->
      <ng-container *ngSwitchCase="BaseRentalRateType.Net">
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && baseRentalRateRentalRate.invalid">
            Rental rate
          </div>
          <div class="dx-field-value">
            <dx-number-box name="base-rental-rate-net" format="$#,##0.00" required
                           min="0" max="9999999" step="0"
                           [isValid]="form.submitted ? baseRentalRateRentalRate.valid : true"
                           [(ngModel)]="lease.baseRentalRateTerm.baseRentalRateNetValue"
                           #baseRentalRateRentalRate="ngModel">
            </dx-number-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && baseRentalRateRentalRate.hasError('required')">
              Rental rate is required
            </div>
          </div>
        </div>
      </ng-container>
      <!-- End: Net -->
      <!-- Start: Gross -->
      <ng-container *ngSwitchCase="BaseRentalRateType.Gross">
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && baseRentalRateRentalRate.invalid">
            Rental rate
          </div>
          <div class="dx-field-value">
            <dx-number-box name="base-rental-rate-gross" format="$#,##0.00" required
                           min="0" max="9999999" step="0" [isValid]="form.submitted ? baseRentalRateRentalRate.valid : true"
                           [(ngModel)]="lease.baseRentalRateTerm.baseRentalRateGrossValue"
                           #baseRentalRateRentalRate="ngModel">
            </dx-number-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && baseRentalRateRentalRate.hasError('required')">
              Rental rate is required
            </div>
          </div>
        </div>
      </ng-container>
      <!-- End: Gross -->
      <!-- Start: Modified gross -->
      <ng-container *ngSwitchCase="BaseRentalRateType.BaseYear">
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && baseRentalRateRentalRate.invalid">
            Base rent
          </div>
          <div class="dx-field-value">
            <dx-number-box name="base-rental-rate-modified-gross" format="$#,##0.00" required
                           min="0" max="9999999" step="0"
                           [isValid]="form.submitted ? baseRentalRateRentalRate.valid : true"
                           [(ngModel)]="lease.baseRentalRateTerm.actualYearRate"
                           #baseRentalRateRentalRate="ngModel">
            </dx-number-box>
            <div class="my-1 invalid-feedback text-muted d-block"
                 *ngIf="!form.submitted || !baseRentalRateRentalRate.invalid">
              Don't forget to complete <b>Base year for expenses</b> in the section RE taxes and CAM expenses
            </div>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && baseRentalRateRentalRate.hasError('required')">
              Base rent is required
            </div>
          </div>
        </div>
      </ng-container>
      <!-- End: Modified gross -->
    </ng-container>
    <!-- End: Rental rate -->
    <!-- Start: Unit metrics -->
    <div class="dx-field">
      <div class="dx-field-label">
        Unit metrics
      </div>
      <div class="dx-field-value">
        <dx-select-box name="base-rental-rate-unit-metrics" valueExpr="value" displayExpr="name"
                       [(items)]="baseRentalRateUnitMetricsList"
                       [(ngModel)]="lease.baseRentalRateTerm.baseRentalRateUnitMetrics"
                       #baseRentalRateUnitMetrics="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Unit metrics -->
  </ng-container>

  <!-- Start: Custom table enabler -->
  <div class="dx-field">
    <div class="dx-field-label">
      Use custom table
    </div>
    <div class="dx-field-value">
      <dx-check-box name="base-rental-rate-use-custom-table" text="Enable custom table overloading"
                    [(ngModel)]="lease.baseRentalRateTerm.isCustomScheduleTableEnabled"
                    #baseRentalRateUseCustomTable="ngModel">
      </dx-check-box>
    </div>
  </div>
  <!-- End: Custom table enabler -->

  <ng-container *ngIf="lease.baseRentalRateTerm.isCustomScheduleTableEnabled">
    <div class="dx-field">
      <div class="dx-field-label">
        Custom table
      </div>
      <div class="dx-field-value">
        <dx-button type="button" text="Edit custom table"
                   (click)="showBaseRentalRateCustomTableEditor()">
        </dx-button>
      </div>
    </div>
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="base-rental-rate-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.baseRentalRateTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="base-rental-rate-document-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.baseRentalRateTerm.customDisplayValue"
                    #baseRentalRateDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="BaseRentalRate"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.baseRentalRateTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.baseRentalRateTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Base rental rate fieldset -->
<!-- Start: Free rent fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Free rent
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="free-rent-type" valueExpr="value" displayExpr="name"
                     [(items)]="freeRentTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.freeRentTerm.freeRentTermType"
                     #freeRentType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.freeRentTerm.freeRentTermType === FreeRentType.MonthsCount">
    <!-- Start: Number of months -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && freeRentMonthsCount.invalid">
        Number of months
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="free-rent-number-of-months" required format="#0 month(s)"
                       min="0" max="9999999"
                       [isValid]="form.submitted ? freeRentMonthsCount.valid : true"
                       [(ngModel)]="lease.freeRentTerm.freeRentMonthsCount"
                       #freeRentMonthsCount="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && freeRentMonthsCount.hasError('required')">
          Number of months is required
        </div>
      </div>
    </div>
    <!-- End: Number of months -->
  </ng-container>

  <ng-container *ngIf="lease.freeRentTerm.freeRentTermType === FreeRentType.SpecificSchedule">
    <!-- Start: Schedule -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && freeRentSpecificSchedule.invalid">
        Schedule
      </div>
      <div class="dx-field-value">
        <dx-text-area name="free-rent-specific-schedule" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? freeRentSpecificSchedule.valid : true"
                      [(ngModel)]="freeRentCustomSchedule"
                      (ngModelChange)="handleFreeRentCustomScheduleChange($event)"
                      #freeRentSpecificSchedule="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback text-muted d-block"
             *ngIf="!form.submitted || !freeRentSpecificSchedule.invalid">
          Please enter a list of specific months separated by commas
        </div>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && freeRentSpecificSchedule.hasError('required')">
          Schedule is required
        </div>
      </div>
    </div>
    <!-- End: Schedule -->
  </ng-container>

  <ng-container *ngIf="lease.freeRentTerm.freeRentTermType === FreeRentType.MonthsCount || lease.freeRentTerm.freeRentTermType === FreeRentType.SpecificSchedule">
    <!-- Start: Tax type -->
    <div class="dx-field">
      <div class="dx-field-label">
        Tax type
      </div>
      <div class="dx-field-value">
        <dx-select-box name="free-rent-tax-type" valueExpr="value" displayExpr="name"
                       [(items)]="freeRentTaxesTypesList"
                       [(ngModel)]="lease.freeRentTerm.freeRentTaxesType"
                       #freeRentTaxType="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Tax type -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="free-rent-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.freeRentTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="free-rent-document-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.freeRentTerm.customDisplayValue"
                    #freeRentDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="FreeRent"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.freeRentTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.freeRentTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Free rent fieldset -->
<!-- Start: Security deposit fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Security deposit
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="security-deposit-type" valueExpr="value" displayExpr="name"
                     [(items)]="securityDepositTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.securityDepositTerm.securityDepositTermType"
                     #securityDepositType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.securityDepositTerm.securityDepositTermType === SecurityDepositType.MonthsCount">
    <!-- Start: Number of months -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && securityDepositMonthsCount.invalid">
        Number of months
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="security-deposit-number-of-months" format="#0 month(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? securityDepositMonthsCount.valid : true"
                       [(ngModel)]="lease.securityDepositTerm.monthsCount"
                       #securityDepositMonthsCount="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && securityDepositMonthsCount.hasError('required')">
          Number of months is required
        </div>
      </div>
    </div>
    <!-- End: Number of months -->

    <!-- Start: Beginning or End of term months -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && securityDepositMonthsPeriod.invalid">
        Beginning or End of Term months
      </div>
      <div class="dx-field-value">
        <dx-select-box name="security-deposit-months-period" valueExpr="value" displayExpr="name"
                       [(items)]="securityDepositBeginningOrEndMonthsList" [showClearButton]="true"
                       [(ngModel)]="lease.securityDepositTerm.depositMonths"
                       #securityDepositMonthsPeriod="ngModel">
        </dx-select-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && securityDepositMonthsPeriod.hasError('required')">
          Months period is required
        </div>
      </div>
    </div>
    <!-- End: Beginning or End of term months -->
  </ng-container>

  <ng-container *ngIf="lease.securityDepositTerm.securityDepositTermType === SecurityDepositType.ReducingValue">
    <!-- Start: Value of reducing -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && securityDepositReducingValue.invalid">
        Value of reducing
      </div>
      <div class="dx-field-value">
        <dx-number-box name="security-deposit-reducing-value" format="$#,##0.00" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? securityDepositReducingValue.valid : true"
                       [(ngModel)]="lease.securityDepositTerm.securityDepositReducingValue"
                       #securityDepositReducingValue="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && securityDepositReducingValue.hasError('required')">
          Value of reducing is required
        </div>
      </div>
    </div>
    <!-- End: Value of reducing -->
  </ng-container>

  <ng-container *ngIf="lease.securityDepositTerm.securityDepositTermType === SecurityDepositType.LetterOfCredit">
    <!-- Start: Amount of letter of credit -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && securityDepositLetterOfCredit.invalid">
        Amount of letter of credit
      </div>
      <div class="dx-field-value">
        <dx-number-box name="security-deposit-letter-of-credit" format="$#,##0.00" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? securityDepositLetterOfCredit.valid : true"
                       [(ngModel)]="lease.securityDepositTerm.amountOfLetterCredit"
                       #securityDepositLetterOfCredit="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && securityDepositLetterOfCredit.hasError('required')">
          Amount of letter of credit is required
        </div>
      </div>
    </div>
    <!-- End: Amount of letter of credit -->
  </ng-container>

  <ng-container *ngIf="lease.securityDepositTerm.securityDepositTermType === SecurityDepositType.Guaranty">
    <!-- Start: Guaranty -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && securityDepositGuaranty.invalid">
        Guaranty
      </div>
      <div class="dx-field-value">
        <dx-select-box name="security-deposit-guaranty" valueExpr="value" displayExpr="name" required
                       [(items)]="securityDepositGuarantyTypesList" [showClearButton]="true"
                       [isValid]="form.submitted ? securityDepositGuaranty.valid : true"
                       [(ngModel)]="lease.securityDepositTerm.securityGuarantyOptionType"
                       #securityDepositGuaranty="ngModel">
        </dx-select-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && securityDepositGuaranty.hasError('required')">
          Guaranty is required
        </div>
      </div>
    </div>
    <!-- End: Guaranty -->
  </ng-container>

  <ng-container *ngIf="lease.securityDepositTerm.securityDepositTermType === SecurityDepositType.MoneyValue">
    <!-- Start: Deposit value -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && securityDepositDepositValue.invalid">
        Deposit value
      </div>
      <div class="dx-field-value">
        <dx-number-box name="security-deposit-deposit-value" format="$#,##0.00" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? securityDepositDepositValue.valid : true"
                       [(ngModel)]="lease.securityDepositTerm.securityDepositMoneyValue"
                       #securityDepositDepositValue="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && securityDepositDepositValue.hasError('required')">
          Deposit value is required
        </div>
      </div>
    </div>
    <!-- End: Deposit value -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="security-deposit-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.securityDepositTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="security-deposit-document-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.securityDepositTerm.customDisplayValue"
                    #securityDepositDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->
  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="SecurityDeposit"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->
  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.securityDepositTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.securityDepositTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Security deposit fieldset -->
<!-- Start: Building percentage fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Building percentage
  </div>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="building-percentage-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.buildingPercentageDocumentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="building-percentage-document-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.buildingPercentage"
                    #buildingPercentageDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->
  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="BuildingPercentage"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->
  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.buildingPercentage"
                                [documentSection]="leasePreview?.buildingPercentageDocumentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Security deposit fieldset -->
<!-- Start: Real estate taxes fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Real estate taxes and CAM expenses
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="real-estate-taxes-and-cam-expenses-type" valueExpr="value" displayExpr="name"
                     [(items)]="realEstateTaxesAndOpExpensesTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesTermType"
                     #realEstateTaxesCamExpensesType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesTermType === RealEstateTaxesAndOpExpensesType.PercentagePerYear">
    <!-- Start: Percentage cap -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && realEstateTaxesCamExpensesPercentageCap.invalid">
        Percentage cap
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="real-estate-taxes-and-cam-expenses-percentage-cap" format="#0.##'%'" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? realEstateTaxesCamExpensesPercentageCap.valid : true"
                       [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.percentagePerYear"
                       #realEstateTaxesCamExpensesPercentageCap="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && realEstateTaxesCamExpensesPercentageCap.hasError('required')">
          Percentage cap is required
        </div>
      </div>
    </div>
    <!-- End: Percentage cap -->
    <!-- Start: Cumulative type -->
    <div class="dx-field">
      <div class="dx-field-label">
        Cumulative type
      </div>
      <div class="dx-field-value">
        <dx-select-box name="real-estate-taxes-and-cam-expenses-cumulative-type"
                       valueExpr="value" displayExpr="name"
                       [(items)]="realEstateTaxesAndOpExpensesCumulativeTypesList"
                       [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesCumulativeType"
                       #realEstateTaxesCamExpensesCumulativeType="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Cumulative type -->
  </ng-container>

  <ng-container *ngIf="lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesTermType === RealEstateTaxesAndOpExpensesType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && realEstateTaxesCamExpensesCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="real-estate-taxes-and-cam-expenses-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? realEstateTaxesCamExpensesCustomLanguage.valid : true"
                      [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesText"
                      #realEstateTaxesCamExpensesCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && realEstateTaxesCamExpensesCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <ng-container *ngIf="lease.baseRentalRateTerm.baseRentalRateType === BaseRentalRateType.BaseYear">
    <!-- Start: Base year for expenses -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && realEstateTaxesCamExpensesBaseYearForExpenses.invalid">
        Base year for expenses
      </div>
      <div class="dx-field-value">
        <dx-date-box type="date" name="real-estate-taxes-and-cam-expenses-base-year-for-expenses"
                     dateSerializationFormat="yyyy-MM-dd" displayFormat="yyyy" required
                     [calendarOptions]="{ zoomLevel: 'decade', minZoomLevel: 'decade', maxZoomLevel: 'decade' }"
                     [isValid]="form.submitted ? realEstateTaxesCamExpensesBaseYearForExpenses.valid : true"
                     [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.baseYearForExpenses"
                     #realEstateTaxesCamExpensesBaseYearForExpenses="ngModel">
        </dx-date-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && commencementDate.hasError('required')">
          Base year for expenses is required
        </div>
      </div>
    </div>
    <!-- End: Base year for expenses -->
  </ng-container>

  <ng-container *ngIf="lease.baseRentalRateTerm.baseRentalRateType === BaseRentalRateType.BaseYear || lease.baseRentalRateTerm.baseRentalRateType === BaseRentalRateType.Net">
    <!-- Start: Real estate taxes type -->
    <div class="dx-field">
      <div class="dx-field-label">
        Real estate taxes type
      </div>
      <div class="dx-field-value">
        <dx-select-box name="real-estate-taxes-and-cam-expenses-re-taxes-type"
                       valueExpr="value" displayExpr="name"
                       [(items)]="realEstateTaxesAndOpExpensesReTaxesTypesList"
                       [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.estimatedReTaxesType"
                       #realEstateTaxesCamExpensesReTaxesType="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Real estate taxes type -->
    <!-- Start: Real estate taxes -->
    <div class="dx-field">
      <div class="dx-field-label">
        Real estate taxes
      </div>
      <div class="dx-field-value">
        <dx-number-box name="real-estate-taxes-and-cam-expenses-re-taxes" format="$#,##0.00"
                       min="0" max="9999999" step="0"
                       [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.estimatedReTaxes"
                       #realEstateTaxesCamExpensesReTaxes="ngModel">
        </dx-number-box>
      </div>
    </div>
    <!-- End: Real estate taxes -->
    <!-- Start: Operating expenses type -->
    <div class="dx-field">
      <div class="dx-field-label">
        Operating expenses type
      </div>
      <div class="dx-field-value">
        <dx-select-box name="real-estate-taxes-and-cam-expenses-op-expenses-type"
                       valueExpr="value" displayExpr="name"
                       [(items)]="realEstateTaxesAndOpExpensesOpExpensesTypesList"
                       [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.estimatedOpExType"
                       #realEstateTaxesCamExpensesOpExpensesType="ngModel">
        </dx-select-box>
      </div>
    </div>
    <!-- End: Operating expenses type -->
    <!-- Start: Operating expenses -->
    <div class="dx-field">
      <div class="dx-field-label">
        Operating expenses
      </div>
      <div class="dx-field-value">
        <dx-number-box name="real-estate-taxes-and-cam-expenses-op-expenses" format="$#,##0.00"
                       min="0" max="9999999" step="0"
                       [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.estimatedOpEx"
                       #realEstateTaxesCamExpensesOpExpenses="ngModel">
        </dx-number-box>
      </div>
    </div>
    <!-- End: Operating expenses -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section (Real Estate Taxes)
    </div>
    <div class="dx-field-value">
      <dx-text-area name="real-estate-taxes-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.realEstateTaxesDocumentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language (Real Estate Taxes)
    </div>
    <div class="dx-field-value">
      <dx-text-area name="real-estate-taxes-document-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCustomDisplayValue">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="RealEstateTaxes"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section (Operating Expenses)
    </div>
    <div class="dx-field-value">
      <dx-text-area name="operating-expenses-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.operatingExpensesDocumentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language (Operating Expenses)
    </div>
    <div class="dx-field-value">
      <dx-text-area name="operating-expenses-document-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.realEstateTaxesCamExpensesTerm.operatingExpensesCustomDisplayValue">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="OperatingExpenses"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.realEstateTaxesCamExpensesTerm?.detailedDisplayValue"
                                [documentSections]="[leasePreview?.realEstateTaxesCamExpensesTerm?.realEstateTaxesDocumentSection, leasePreview?.realEstateTaxesCamExpensesTerm?.operatingExpensesDocumentSection]">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Real estate taxes fieldset -->
<!-- Start: Rental rate escalation fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Rental rate escalation
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="rental-rate-escalation-type" valueExpr="value" displayExpr="name"
                     [(items)]="rentalRateEscalationTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.rentalRateAnnualEscalationTerm.escalationTermType"
                     #rentalRateEscalationType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.rentalRateAnnualEscalationTerm.escalationTermType === RentalRateEscalationType.FixedPercentagePerYear">
    <!-- Start: Percentage -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && rentalRateEscalationPercentage.invalid">
        Percentage
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="rental-rate-escalation-percentage" format="#0.##'%'" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? rentalRateEscalationPercentage.valid : true"
                       [(ngModel)]="lease.rentalRateAnnualEscalationTerm.escalationPercentagePerYear"
                       #rentalRateEscalationPercentage="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && rentalRateEscalationPercentage.hasError('required')">
          Percentage is required
        </div>
      </div>
    </div>
    <!-- End: Percentage -->
  </ng-container>

  <ng-container *ngIf="lease.rentalRateAnnualEscalationTerm.escalationTermType === RentalRateEscalationType.FixedAmountPsfPerYear">
    <!-- Start: Amount -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && rentalRateEscalationAmount.invalid">
        Amount
      </div>
      <div class="dx-field-value">
        <dx-number-box name="rental-rate-escalation-amount" format="$#,##0.00" required
                       min="0" max="9999999" step="0"
                       [isValid]="form.submitted ? rentalRateEscalationAmount.valid : true"
                       [(ngModel)]="lease.rentalRateAnnualEscalationTerm.escalationPsfValuePerYear"
                       #rentalRateEscalationAmount="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && rentalRateEscalationAmount.hasError('required')">
          Amount is required
        </div>
      </div>
    </div>
    <!-- End: Amount -->
  </ng-container>

  <ng-container *ngIf="lease.rentalRateAnnualEscalationTerm.escalationTermType === RentalRateEscalationType.Custom">
    <div class="dx-field">
      <div class="dx-field-label">
        Custom table
      </div>
      <div class="dx-field-value">
        <dx-button type="button" text="Edit custom table"
                   (click)="showRentalRateEscalationCustomTableEditor()">
        </dx-button>
      </div>
    </div>
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="rental-rate-escalation-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.rentalRateAnnualEscalationTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Document language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="rental-rate-escalation-document-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.rentalRateAnnualEscalationTerm.customDisplayValue"
                    #rentalRateEscalationDocumentLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document language -->

  <!-- Start: Term extraction result -->
  <app-import-form-term-extraction-result variant="RentalRateEscalation"
                                          [documentViewerOptions]="documentViewerOptions"
                                          [documentViewerOptionsChange]="documentViewerOptionsChange">
  </app-import-form-term-extraction-result>
  <!-- End: Term extraction result -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.rentalRateAnnualEscalationTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.rentalRateAnnualEscalationTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Rental rate escalation fieldset -->
