<div class="document-viewer-container"
     #documentViewerContainerElementRef>
  <!-- Start: Document Variant -->
  <div class="document-viewer-document"
       *ngIf="document && document.url; else documentNotFoundTpl">
    <pdf-viewer zoom-scale="page-height"
                [src]="document.url" [render-text]="false" [show-all]="true"
                [autoresize]="true" [original-size]="false" [stick-to-page]="true"
                [zoom]="documentViewerOptions?.zoom / 100" [page]="documentViewerOptions?.page"
                (after-load-complete)="handleDocumentLoadComplete($event)"
                (page-rendered)="handleDocumentPageRendered($event)"
                (pageChange)="handleDocumentPageChange($event)">
    </pdf-viewer>
  </div>
  <!-- End: Document Variant -->

  <ng-template #documentNotFoundTpl>
    <div class="document-viewer-document">
      <div class="document-viewer-document-not-found">
        <div class="document-viewer-document-not-found-centered">
          <p class="mb-3">
            Document not uploaded yet
          </p>

          <dx-button type="normal" text="Upload document"
                     (onClick)="showUploadDocumentDialog()">
          </dx-button>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="document-viewer-floating-controls"
       *ngIf="documentViewerOptions.floatingControls && document?.url">
    <div class="document-viewer-floating-controls-control">
      <dx-button type="normal" text="Change document"
                 [disabled]="isDocumentUploading$ | async"
                 (onClick)="showUploadDocumentDialog()">
      </dx-button>
    </div>
    <div class="document-viewer-floating-controls-control"
         *ngIf="isDocumentLoading$ | async">
      <div class="document-viewer-floating-controls-control-loading-state">
        Loading document...
      </div>
    </div>
    <div class="document-viewer-floating-controls-control"
         *ngIf="isDocumentUploading$ | async">
      <div class="document-viewer-floating-controls-control-loading-state">
        Uploading document...
      </div>
    </div>
    <div class="document-viewer-floating-controls-control"
         *ngIf="isTextProcessing$ | async">
      <div class="document-viewer-floating-controls-control-loading-state">
        Analyzing document...
      </div>
    </div>
  </div>
</div>
