import { Component, OnInit } from '@angular/core';
import {ILeaseTermConfiguration, LeaseTermType, SecurityDepositTermType, TerminationOptionType} from "../../../shared/models/generated";
import {Tools} from "../../../shared/tools";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../shared/services";
import {TermsService} from "../../../shared/services/terms.service";
import * as AspNetData from "devextreme-aspnet-data-nojquery";

@Component({
  selector: 'app-termination-option',
  templateUrl: './termination-option.component.html',
  styleUrls: ['./termination-option.component.scss']
})
export class TerminationOptionComponent implements OnInit {

  TerminationOptionType = TerminationOptionType;
  TerminationOptionTypes = Tools.EnumToArray(TerminationOptionType);

  dataSource: any;
  leases: any;
  leaseConfiguration:ILeaseTermConfiguration;
  leaseTerms: any[];
  url = `${environment.adminUrl}/leaseTerms`;
  get _window() {
    return window;
  }
  tools = Tools;

  constructor(private authService: AuthService, private termsService: TermsService) {}

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      loadParams:{
        leaseTermType: LeaseTermType.TerminationOption
      },
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };

        if(ajaxOptions.data.values){
          const values = JSON.parse(ajaxOptions.data.values);
          if(method === "insert"){
            values.leaseTermType = LeaseTermType.TerminationOption;
            values.leaseTermConfiguration = this.leaseConfiguration;
          }

          if(values.specificMonths){
            values.specificMonths = values.specificMonths.map(function (v) {
              return parseInt(v, 10);
            });
          }

          ajaxOptions.data.values = JSON.stringify(values);
        }
      }
    });

    this.dataSource.on('loaded', (data) => {
      this.leaseTerms = data;
    });

    this.leases = this.createDataSource('id', `${environment.adminUrl}/Leases`);

    this.termsService.getLeaseTermConfiguration(LeaseTermType.TerminationOption).subscribe(x => this.leaseConfiguration = x);
  }

  createDataSource(key: string, loadUrl: string) {
    return AspNetData.createStore({
      key: key,
      loadUrl: loadUrl,
      onBeforeSend: function(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });
  }

}
