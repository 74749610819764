import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { UserStatus, InvestorType, DocumentsMode } from 'src/app/shared/models/generated';
import { Tools } from 'src/app/shared/tools';

import { UserService } from '../../modules/user/services/user.service';

import * as models from '../../shared/models/generated';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  Tools = Tools;
  dataSource: any;
  url = `${environment.adminUrl}/user`;
  UserStatus = UserStatus;
  InvestorType = InvestorType;
  DocumentsMode = DocumentsMode;
  userStatusItems = Tools.EnumToArray(UserStatus);
  investorTypeItems = Tools.EnumToArray(InvestorType);
  documentsModeItems = Tools.EnumToArray(DocumentsMode);
  industryItems = [];
  roles = [];
  companyDataStore: CustomStore;
  cellCompanyDataStore: CustomStore;

  userId: number;

  isEmailVerificationMessageLoading: boolean;

  phoneNumberFieldEditorOptions: any;

  get _window() {
    return window;
  }

  private readonly _userService: UserService;
  private readonly _destroy$: Subject<void>;

  constructor(userService: UserService) {
    this._userService = userService;
    this._destroy$ = new Subject<void>();

    this.phoneNumberFieldEditorOptions = {
      mask: '+1 (X00) 000-0000',
      maskRules: { X: /[02-9]/ },
      useMaskedValue: true,
      maskInvalidMessage: 'The phone number must have a correct phone format'
    };
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${this.url}/Get`,
      updateUrl: `${this.url}/Put`,
      insertUrl: `${this.url}/Post`,
      deleteUrl: `${this.url}/user`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
        if (ajaxOptions.data.values) {
          const values = JSON.parse(ajaxOptions.data.values);
          delete values.Avatar;
          delete values.AvatarSource;
          delete values.User;
          delete values.SpaceUse;
          delete values.Industry;
          delete values.Company;
          ajaxOptions.data.values = JSON.stringify(values);
        }
      },
    });

    Tools.lookupDataSource('Industry')
      .on('loaded', data => {
        this.industryItems = data;
      })
      .load();

    Tools.lookupDataSource('Role')
      .on('loaded', data => {
        this.roles = data;
      })
      .load();

    this.companyDataStore = Tools.lookupDataSource('company');
    this.cellCompanyDataStore = Tools.lookupDataSource('company');
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onEditorPreparing(e): void {
    if (e.dataField === 'emailConfirmed' && e.parentType === 'dataRow') {
      e.editorOptions = {
        ...(e.editorOptions || {}),
        value: !!e.value,
      };
    }
    if (e.dataField === 'twoFactorEnabled' && e.parentType === 'dataRow') {
      e.editorOptions = {
        ...(e.editorOptions || {}),
        value: !!e.value,
      };
    }
  }

  onEditingStart(e): void {
    this.userId = e.key;
  }

  sendEmailVerificationMessage(userId: number): void {
    if (this.isEmailVerificationMessageLoading) {
      return;
    }

    this.isEmailVerificationMessageLoading = true;

    this._userService
      .sendEmailVerificationMessage(userId)
      .pipe(
        tap(() => {
          this.isEmailVerificationMessageLoading = false;
        }),
        catchError((err) => {
          this.isEmailVerificationMessageLoading = false;

          console.error(err);

          return err;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  twoFactorAuthPhoneNumberValidationCallback(e): boolean {
    if (e.data.twoFactorEnabled) {
      return !!e.value;
    }
    return true;
  }

  handleImpersonateClick(event: {row: {data: models.IUserViewModel}}): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    const user = event.row.data;

    const url = `${window.location.origin}${environment.adminUrl}/users/impersonate?userId=${user.id}`;

    window.open(url, '_blank');
  }
}
