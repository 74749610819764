<form name="form" novalidate
      #form="ngForm" (ngSubmit)="submit(form)">
  <dx-scroll-view width="100%" height="100%">
    <ng-container *ngIf="!areAllRequiredTermsValid()">
      <div class="wrapper">
        <div class="alert alert-danger">
          <p>
            Not all terms are filled in correctly, check the following:
          </p>

          <ul class="mb-0">
            <li *ngIf="!isCommencementTermValid()">
              Commencement term
            </li>
            <li *ngIf="!isLengthTermValid()">
              Term term
            </li>
            <li *ngIf="!isTenantSquareFootageValid()">
              Size term
            </li>
            <li *ngIf="!isBaseRentalRateTermValid()">
              Base Rental Rate term
            </li>
          </ul>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="areAllRequiredTermsValid()">
      <div class="wrapper pb-0">
        <div class="mx-n1 d-flex align-items-center justify-content-center">
          <div class="px-1">
            Starting Rate
          </div>
          <div class="px-1">
            <div class="green-block">
              {{getStartingRate()}}
            </div>
          </div>
        </div>
      </div>

      <div class="wrapper"
           [class.pb-0]="canCalculatePreview()">
        <h1 class="text-center">
          Escalations
        </h1>

        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
            <tr>
              <th></th>
              <th class="align-middle">
                Lease Month
              </th>
              <th class="align-middle">
                Increase Date
              </th>
              <th class="align-middle">
                Increase
              </th>
              <th class="align-middle">
                Repeat to End
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let customValue of customValues; let index = index;">
              <td>
                <a role="button" class="cursor-pointer text-danger d-block px-3" title="Remove"
                   (click)="removeCustomValue(index); calculatePreview()">
                  <i class="fa fa-minus"></i>
                </a>
              </td>
              <!-- Start: Lease Mo -->
              <td class="align-middle p-0">
                <dx-number-box appIgnoreScrollwheel name="leaseMonth_{{index}}"
                               min="1" [(ngModel)]="customValue.leaseMonth" #leaseMonth
                               (ngModelChange)="calculateIncreaseDate($event, index); reorderCustomValues(); calculatePreview()">
                </dx-number-box>
              </td>
              <!-- End: Lease Mo -->
              <!-- Start: Increase Date -->
              <td class="align-middle">
                {{customValue.increaseDate | date:'MM/dd/yyyy'}}
              </td>
              <!-- End: Increase Date -->
              <!-- Start: Increase -->
              <td class="increase align-middle p-0">
                <div class="increase-value"
                     [ngSwitch]="customValue.rentalRateAnnualEscalationTermCustomValueType">
                  <ng-container *ngSwitchCase="ValueType.FixedAmountPsfPerYear">
                    <dx-number-box name="increasePsfValue_{{index}}" format="$#0.00 PSF/Yr"
                                   min="0" step="0" [(ngModel)]="customValue.stepIncreasePsfValue" #increasePsfValue
                                   (ngModelChange)="calculatePreview()">
                    </dx-number-box>
                  </ng-container>
                  <ng-container *ngSwitchCase="ValueType.FixedPercentagePerYear">
                    <dx-number-box appIgnoreScrollwheel name="increasePercentage_{{index}}" format="#0.##%"
                                   min="0" [(ngModel)]="customValue.stepIncreasePercentage" #increasePercentage
                                   (ngModelChange)="calculatePreview()">
                    </dx-number-box>
                  </ng-container>
                </div>
                <div class="increase-type-picker">
                  <dx-select-box name="increaseType_{{index}}" placeholder=""
                                 displayExpr="text" valueExpr="value"
                                 [items]="increaseTypeOptions"
                                 [(value)]="customValue.rentalRateAnnualEscalationTermCustomValueType" #increaseType
                                 (valueChange)="calculatePreview()">
                  </dx-select-box>
                </div>
              </td>
              <!-- End: Increase -->
              <!-- Start: Repeat to End -->
              <td class="align-middle p-0">
                <dx-select-box name="repeatToEnd_{{index}}" placeholder=""
                               displayExpr="text" valueExpr="value"
                               [items]="repeatToEndOptions"
                               [(value)]="customValue.rentalRateAnnualEscalationTermCustomRepeatType" #repeatToEnd
                               (valueChange)="calculatePreview()">
                </dx-select-box>
              </td>
              <!-- End: Repeat to End -->
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="5">
                <div class="d-table">
                  <div class="d-flex flex-row mx-n1">
                    <div class="px-1">
                      <dx-button type="success" text="Add" icon="fa fa-plus"
                                 (click)="pushCustomValue()">
                      </dx-button>
                    </div>
                    <div class="px-1">
                      <dx-button type="normal" text="Remove All" icon="fa fa-minus"
                                 (click)="clearCustomValues()">
                      </dx-button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <ng-container *ngIf="canCalculatePreview()">
        <div class="wrapper">
          <h1 class="text-center">
            Results Preview
          </h1>

          <ng-container *ngIf="isPreviewCalculating; else previewLoaded">
            <p class="text-muted mb-0 text-center">
              Please wait, we are calculating preview...
            </p>
          </ng-container>

          <ng-template #previewLoaded>
            <table class="table mb-0">
              <thead>
              <tr>
                <th class="align-middle">
                  Lease Month
                </th>
                <th class="align-middle">
                  Date
                </th>
                <th class="align-middle">
                  {{getPreviewUnitMetrics()}}
                </th>
                <th class="align-middle">
                  Escalation (%)
                </th>
                <th class="align-middle">
                  Monthly Amount
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let customResult of customResults;">
                <td class="align-middle">
                  {{customResult.leaseMonth}}
                </td>
                <td class="align-middle">
                  {{customResult.date | date:'MM/dd/yyyy'}}
                </td>
                <td class="align-middle">
                  {{customResult.rsfValue | number : '1.2-2'}}
                </td>
                <td class="align-middle">
                  {{customResult.percentValue | percent: '1.0-2'}}
                </td>
                <td class="align-middle">
                  {{customResult.monthlyAmount | currency}}
                </td>
              </tr>
              </tbody>
            </table>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </dx-scroll-view>

  <ng-container *ngIf="areAllRequiredTermsValid()">
    <div class="footer text-right">
      <dx-button type="success" text="Save"
                 [useSubmitBehavior]="true">
      </dx-button>
      <dx-button type="normal" text="Cancel"
                 (click)="cancel()">
      </dx-button>
    </div>
  </ng-container>
</form>
