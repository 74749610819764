import * as ng from '@angular/core';
import { Component, Injector, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';

import { DocumentViewerOptions } from '../../models/document-viewer-options.model';

import { LeaseAbstractService } from '../../services/lease-abstract.service';
import { LeaseAbstractStore } from '../../services/lease-abstract.store';
import { PopupService } from '../../../infrastructure/services/popup.service';

import { ImportFormBuildingConditionsComponent } from '../import-form-building-conditions/import-form-building-conditions.component';
import { ImportFormLeaseOptionsComponent } from '../import-form-lease-options/import-form-lease-options.component';
import { ImportFormMainTermsComponent } from '../import-form-main-terms/import-form-main-terms.component';
import { ImportFormOtherLeaseRightsComponent } from '../import-form-other-lease-rights/import-form-other-lease-rights.component';
import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

interface TabComponentInstance {
  lease: models.ILeaseViewModel;
  leaseChange: ng.EventEmitter<models.ILeaseViewModel>;
  leasePreview: models.ILeaseViewModel;
  documentViewerOptions: DocumentViewerOptions;
  documentViewerOptionsChange: Subject<DocumentViewerOptions>;
}

interface TabItem {
  id: number;
  title: string;
  component: ng.Type<TabComponentInstance>;
}

@Component({
  selector: 'app-import-form',
  templateUrl: 'import-form.component.html',
  styleUrls: ['import-form.component.scss'],
})
export class ImportFormComponent implements ng.OnInit, ng.OnDestroy, ng.OnChanges, ng.AfterViewInit {
  private static readonly _importFormTabItems: Array<TabItem> = [
    {
      id: 0,
      title: 'Main terms',
      component: ImportFormMainTermsComponent,
    },
    {
      id: 1,
      title: 'Building conditions',
      component: ImportFormBuildingConditionsComponent,
    },
    {
      id: 2,
      title: 'Lease options',
      component: ImportFormLeaseOptionsComponent,
    },
    {
      id: 3,
      title: 'Other lease rights',
      component: ImportFormOtherLeaseRightsComponent,
    },
  ];

  @Input() lease: models.ILeaseViewModel;
  @Output() leaseChange: ng.EventEmitter<models.ILeaseViewModel>;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  @Output() done: ng.EventEmitter<void>;
  @Output() cancel: ng.EventEmitter<{ form: NgForm }>;

  @ViewChild(NgForm, {static: false}) form: NgForm;
  @ViewChild('tabContentContainer', {read: ng.ViewContainerRef, static: true}) tabContentContainer: ng.ViewContainerRef;

  readonly tabItems: Array<TabItem>;

  activeTabId: number;

  leasePreview: models.ILeaseViewModel;

  leasePreviewLoadingSubscription: Subscription;

  isLeaseAbstractImportDraftSaved: boolean;

  LeaseStatus: typeof models.LeaseStatus = models.LeaseStatus;
  LeaseActionType: typeof models.LeaseActionType = models.LeaseActionType;

  LeaseType: typeof models.LeaseType = models.LeaseType;

  isLoading$: BehaviorSubject<boolean>;

  isInterfaceLocked: boolean;

  private _currentComponentRef: ng.ComponentRef<TabComponentInstance>;

  private readonly _leaseAbstractService: LeaseAbstractService;
  private readonly _leaseAbstractStore: LeaseAbstractStore;
  private readonly _popupService: PopupService;
  private readonly _componentFactoryResolver: ng.ComponentFactoryResolver;
  private readonly _injector: ng.Injector;
  private readonly _changeDetectorRef: ng.ChangeDetectorRef;
  private readonly _destroy$: Subject<void>;

  constructor(
    leaseAbstractService: LeaseAbstractService,
    leaseAbstractStore: LeaseAbstractStore,
    popupService: PopupService,
    componentFactoryResolver: ng.ComponentFactoryResolver,
    injector: ng.Injector,
    changeDetectorRef: ng.ChangeDetectorRef,
  ) {
    this._leaseAbstractService = leaseAbstractService;
    this._leaseAbstractStore = leaseAbstractStore;
    this._popupService = popupService;
    this._componentFactoryResolver = componentFactoryResolver;
    this._injector = injector;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy$ = new Subject<void>();

    this.leaseChange = new ng.EventEmitter<models.ILeaseViewModel>();

    this.done = new ng.EventEmitter();
    this.cancel = new ng.EventEmitter<{ form: NgForm }>();

    this.tabItems = ImportFormComponent._importFormTabItems;

    this.isLoading$ = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
    this.lease = this.lease || <models.ILeaseViewModel>{};

    this._leaseAbstractStore
      .getIsInterfaceLocked()
      .pipe(
        tap(isLocked => this.isInterfaceLocked = isLocked),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._leaseAbstractStore
      .getLeaseAbstractImportDraft()
      .pipe(
        tap(leaseAbstractImportDraft => {
          if (!leaseAbstractImportDraft) {
            return;
          }

          this.lease = leaseAbstractImportDraft.lease;

          if (!this._currentComponentRef) {
            return;
          }

          this._currentComponentRef.instance.lease = this.lease;

          this._currentComponentRef.changeDetectorRef.markForCheck();
          this._currentComponentRef.changeDetectorRef.detectChanges();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.documentViewerOptionsChange
      .pipe(
        tap((options) => {
          if (!this._currentComponentRef) {
            return;
          }

          this.documentViewerOptions = options;

          this._currentComponentRef.instance.documentViewerOptions = this.documentViewerOptions;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.form) {
      this.form.resetForm();
    }

    this._destroy$.next();
    this._destroy$.complete();

    this._changeDetectorRef.detach();
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.documentViewerOptions &&
      changes.documentViewerOptions.currentValue
    ) {
      const {previewMode} = changes.documentViewerOptions.currentValue;

      const updateLeasePreview = (leasePreview: models.ILeaseViewModel): void => {
        this.leasePreview = leasePreview;

        if (this._currentComponentRef && this._currentComponentRef.instance) {
          this._currentComponentRef.instance.leasePreview = leasePreview;

          this._currentComponentRef.changeDetectorRef.markForCheck();
          this._currentComponentRef.changeDetectorRef.detectChanges();
        }
      };

      if (previewMode) {
        this._getLeaseDraftPreview(<models.ILeaseAbstractImportDraftViewModel>{leaseId: this.lease.id, lease: this.lease})
          .pipe(
            tap(leasePreview => updateLeasePreview(leasePreview)),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();

        this.leasePreviewLoadingSubscription = this.leaseChange
          .pipe(
            switchMap(lease => this._getLeaseDraftPreview(<models.ILeaseAbstractImportDraftViewModel>{leaseId: lease.id, lease: lease})),
            tap(leasePreview => updateLeasePreview(leasePreview)),
            takeUntil(this._destroy$),
          )
          .subscribe();
      } else {
        updateLeasePreview(null);

        if (this.leasePreviewLoadingSubscription) {
          this.leasePreviewLoadingSubscription.unsubscribe();
          this.leasePreviewLoadingSubscription = null;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.handleTabChange(ImportFormComponent._importFormTabItems[0]);
  }

  handleTabChange(tabItem: TabItem): void {
    if (!tabItem) {
      return;
    }

    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(tabItem.component);
    const viewContainerRef = this.tabContentContainer;

    viewContainerRef.clear();

    const injector = Injector.create({
      providers: [
        {
          provide: ControlContainer,
          useValue: this.form,
        },
        {
          provide: NgForm,
          useValue: this.form,
        },
      ],
      parent: this._injector,
    });

    const componentRef = viewContainerRef.createComponent(componentFactory, null, injector);

    if (componentRef.instance) {
      componentRef.instance.lease = this.lease;
      componentRef.instance.leaseChange = this.leaseChange;

      componentRef.instance.leasePreview = this.leasePreview;

      componentRef.instance.documentViewerOptions = this.documentViewerOptions;
      componentRef.instance.documentViewerOptionsChange = this.documentViewerOptionsChange;
    }

    this.activeTabId = tabItem.id;

    componentRef.changeDetectorRef.markForCheck();
    componentRef.changeDetectorRef.detectChanges();

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();

    this._currentComponentRef = componentRef;
  }

  updateLeaseAbstractImportDraft(): void {
    if (this.form.invalid) {
      return;
    }

    this.isLoading$.next(true);

    this._leaseAbstractService
      .updateLeaseAbstractImportDraft(<models.ILeaseAbstractImportDraftViewModel>{
        lease: this.lease,
        leaseId: this.lease.id,
      })
      .pipe(
        take(1),
        tap(() => {
          this.isLoading$.next(false);
        }),
        catchError(err => {
          this.isLoading$.next(false);

          return throwError(err);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.isLeaseAbstractImportDraftSaved = true;
      });
  }

  changeLeaseActionType(): void {
    if (!this.lease || !this.lease.id) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to abstract a lease?',
        acceptFn: () => {
          this.isLoading$.next(true);
          this._leaseAbstractService
            .changeLeaseActionType(this.lease.id, models.LeaseActionType.None)
            .pipe(
              take(1),
              tap(leaseAbstractImportDraft => {
                if (!leaseAbstractImportDraft || !leaseAbstractImportDraft.lease) {
                  return;
                }

                this.lease = leaseAbstractImportDraft.lease;
              }),
              tap(() => {
                this.isLoading$.next(false);
              }),
              catchError(err => {
                this.isLoading$.next(false);

                return throwError(err);
              }),
              takeUntil(this._destroy$),
            )
            .subscribe();
        },
      },
    });
  }

  publishLeaseAbstractImportDraft(): void {
    if (!this.lease || !this.lease.id) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to publish a lease?',
        acceptFn: () => {
          this.isLoading$.next(true);

          this._leaseAbstractService
            .updateLeaseAbstractImportDraft(<models.ILeaseAbstractImportDraftViewModel>{
              lease: this.lease,
              leaseId: this.lease.id,
            })
            .pipe(
              take(1),
              switchMap(() => this._leaseAbstractService.publishLeaseAbstractImportDraft(this.lease.id)),
              tap(leaseAbstractImportDraft => {
                if (!leaseAbstractImportDraft || !leaseAbstractImportDraft.lease) {
                  return;
                }

                this.lease = leaseAbstractImportDraft.lease;
              }),
              tap(() => {
                this.isLoading$.next(false);
              }),
              catchError(err => {
                this.isLoading$.next(false);

                return throwError(err);
              }),
              takeUntil(this._destroy$),
            )
            .subscribe(() => {
              this.isLeaseAbstractImportDraftSaved = true;
            });
        },
      },
    });
  }

  draftLeaseAbstractImportDraft(): void {
    if (!this.lease || !this.lease.id) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to draft a lease?',
        acceptFn: () => {
          this.isLoading$.next(true);

          this._leaseAbstractService
            .updateLeaseAbstractImportDraft(<models.ILeaseAbstractImportDraftViewModel>{
              lease: this.lease,
              leaseId: this.lease.id,
            })
            .pipe(
              take(1),
              switchMap(() => this._leaseAbstractService.draftLeaseAbstractImportDraft(this.lease.id)),
              tap(leaseAbstractImportDraft => {
                if (!leaseAbstractImportDraft || !leaseAbstractImportDraft.lease) {
                  return;
                }

                this.lease = leaseAbstractImportDraft.lease;
              }),
              tap(() => {
                this.isLoading$.next(false);
              }),
              catchError(err => {
                this.isLoading$.next(false);

                return throwError(err);
              }),
              takeUntil(this._destroy$),
            )
            .subscribe(() => {
              this.isLeaseAbstractImportDraftSaved = true;
            });
        },
      },
    });
  }

  private _getLeaseDraftPreview(leaseAbstractImportDraft: models.ILeaseAbstractImportDraftViewModel): Observable<models.ILeaseViewModel> {
    return this._leaseAbstractService
      .getLeaseDraftPreview(leaseAbstractImportDraft)
      .pipe(
        takeUntil(this._destroy$),
      );
  }
}
