import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './services/user.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    UserService,
  ],
})
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [
        UserService,
      ],
    };
  }
}
