<div id="point-of-interest-import">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form" novalidate
          (ngSubmit)="submit()"
          #form="ngForm">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Main properties
        </div>
        <!-- Start: Status -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && statusField.invalid">
            Status
          </div>
          <div class="dx-field-value">
            <dx-select-box name="status" required valueExpr="id" displayExpr="text"
                           [dataSource]="pointOfInterestStatusDataSource"
                           [(ngModel)]="importTaskPayload.status"
                           #statusField="ngModel">
              <div *dxTemplate="let item of 'item'">
                {{ item?.text }}
              </div>
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && statusField.hasError('required')">
              Status is required
            </div>
          </div>
        </div>
        <!-- End: Status -->
        <!-- Start: Kind -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && kindField.invalid">
            Kind
          </div>
          <div class="dx-field-value">
            <dx-select-box name="kind" required valueExpr="id" displayExpr="text"
                           [dataSource]="pointOfInterestKindDataSource"
                           [(ngModel)]="importTaskPayload.kind"
                           #kindField="ngModel">
              <div *dxTemplate="let item of 'item'">
                {{ item?.text }}
              </div>
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && kindField.hasError('required')">
              Kind is required
            </div>
          </div>
        </div>
        <!-- End: Kind -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: File upload -->
      <div class="dx-fieldset mt-2 mb-2">
        <div class="dx-fieldset-header">
          File
        </div>
        <ng-container>
          <div class="dx-field file-upload"
               [class.dx-field-disabled]="(isLoading | async) || importFile">
            <div class="dx-field-value file-control">
              <div class="file-control-icon">
                <i class="fa fa-paperclip"></i>
              </div>
              <div class="file-control-text">
                <div>
                  Attach
                  <strong>File</strong>
                </div>
                <div class="subscript">
                  .csv files are allowed
                </div>
              </div>
              <dx-file-uploader name="file" uploadMode="useForm"
                                accept="text/comma-separated-values,text/csv,application/csv"
                                [allowedFileExtensions]="['.csv']"
                                [multiple]="false" [showFileList]="false"
                                (onValueChanged)="handleUploaderFileChange($event)">
              </dx-file-uploader>
            </div>
          </div>
          <div>
            <ng-container *ngIf="importFile as file"
                          [ngTemplateOutlet]="filePreviewTemplate"
                          [ngTemplateOutletContext]="{ file: file, index: 0 }">
            </ng-container>
          </div>
        </ng-container>
        <ng-template #filePreviewTemplate
                     let-file="file"
                     let-index="index">
          <div class="d-flex flex-row flex-nowrap mw-100 my-2 align-items-center">
            <div class="pl-2 mw-0 flex-grow-1">
              <p class="mb-1 small font-weight-bold w-100 text-truncate"
                 [title]="file.name">
                {{file.name}}
              </p>
              <p class="mb-0"
                 *ngIf="index || index === 0">
                <a role="button" class="cursor-pointer font-weight-bold small text-danger"
                   [class.disabled]="isLoading | async"
                   (click)="removeImportFile()">
                  Remove
                </a>
              </p>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- End: File upload -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row mx-n1">
          <div class="px-1">
            <dx-button type="success"
                       text="Proceed"
                       [disabled]="(isLoading | async) || !importFile"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-1">
            <dx-button type="normal" text="Cancel"
                       [disabled]="isLoading | async"
                       (click)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>

<dx-load-panel [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false"
               [position]="{of: '#point-of-interest-import'}" [visible]="isLoading | async">
</dx-load-panel>
