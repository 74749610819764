import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import * as dx from 'devextreme-angular';

import { ImportTaskListComponent } from './components/import-task-list/import-task-list.component';

import { SortPipe } from '../../shared/pipes/sort.pipe';

import { ImportService } from './services/import.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    dx.DxDataGridModule,
  ],
  declarations: [
    ImportTaskListComponent,

    SortPipe,
  ],
  providers: [
    ImportService,
  ],
})
export class ImportModule {
  static forRoot(): ModuleWithProviders<ImportModule> {
    return {
      ngModule: ImportModule,
      providers: [
        ImportService,
      ],
    };
  }
}
