import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompanyService } from './services/company.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    CompanyService,
  ],
})
export class CompanyModule {
  static forRoot(): ModuleWithProviders<CompanyModule> {
    return {
      ngModule: CompanyModule,
      providers: [
        CompanyService,
      ],
    };
  }
}
