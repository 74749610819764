<div class="plan-viewer-editor">
  <ul>
    <li>
      <button type="button" title="Pan and Zoom"
              [class.is-active]="isDragEnabled"
              (click)="setIsDragEnabled(!isDragEnabled)">
        <i class="fa fa-hand-paper-o"></i>
      </button>
    </li>
    <!-- Start: Shapes -->
    <li *ngIf="mode === modes.Marker">
      <button type="button" title="Add circle marker"
              [class.is-active]="isDrawCircleEnabled"
              (click)="setIsDrawCircleEnabled(!isDrawCircleEnabled)">
        <i class="fa fa-circle-thin"></i>
      </button>
    </li>
    <li>
      <button type="button" title="Add rectangle marker"
              [class.is-active]="isDrawRectEnabled"
              (click)="setIsDrawRectEnabled(!isDrawRectEnabled);">
        <i class="fa fa-square-o"></i>
      </button>
    </li>
    <li *ngIf="mode === modes.Marker">
      <button type="button" title="Add cross marker"
              [class.is-active]="isDrawCrossEnabled"
              (click)="setIsDrawCrossEnabled(!isDrawCrossEnabled)">
        <i class="fa fa-times"></i>
      </button>
    </li>
    <li>
      <button type="button" title="Add polygon marker"
              [class.is-active]="isDrawPolygonEnabled"
              (click)="setIsDrawPolygonEnabled(!isDrawPolygonEnabled)">
        <i inlineSVG="assets/img/polygon.svg"></i>
      </button>
    </li>
    <!-- End: Shapes -->
    <!-- Start: Rotate -->
    <li>
      <button type="button" title="Rotate plan 90 degrees counter clockwise"
              (click)="rotateLeft()">
        <i class="fa fa-undo"></i>
      </button>
    </li>
    <li>
      <button type="button" title="Rotate plan 90 degrees clockwise"
              (click)="rotateRight()">
        <i class="fa fa-repeat"></i>
      </button>
    </li>
    <!-- End: Rotate -->
  </ul>
</div>
