import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Tools } from 'src/app/shared/tools';
import CustomStore from 'devextreme/data/custom_store';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';



@Component({
  selector: 'app-lease-signers',
  templateUrl: './lease-signers.component.html',
  styleUrls: ['./lease-signers.component.scss']
})
export class LeaseSignersComponent implements OnInit {
  Tools = Tools;
  dataSource: any;
  url = `${environment.adminUrl}/leaseSigner`;
  get _window() {
    return window;
  }
  leaseDataSource: CustomStore;

  constructor() { }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      onBeforeSend: function(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });

    this.leaseDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/leases/shortLeaseModels/`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });


  }

}
