<h2 class="content-block">Statera user claims</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [remoteOperations]="true"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onInitNewRow)="initNewRow($event)"
>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="50"></dxi-column>
  <dxi-column dataField="userId" cellTemplate="userFieldTemplate">
    <dxo-lookup [dataSource]="userDataStore" valueExpr="id" [displayExpr]="Tools.getDisplayExpression">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'userFieldTemplate'" class="lookup-user-field">
    <div>
      {{ item?.data?.user?.profile?.firstName  }} {{item?.data?.user?.profile?.lastName}}
    </div>
  </div>

  <dxi-column dataField="stateraClaimId" cellTemplate="stateraClaimTemplate">
    <dxo-lookup [dataSource]="stateraClaimDataSource" valueExpr="id" [displayExpr]="getClaimName"></dxo-lookup>
  </dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'stateraClaimTemplate'">
    <div>
      {{ getClaimName(item?.data?.stateraClaim) }}
    </div>
  </div>

  <dxi-column dataField="stateraClaimGroupId">
    <dxo-lookup [dataSource]="stateraClaimGroupDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>


  <dxi-column dataField="companyId" cellTemplate="companyFieldTemplate">
    <dxo-lookup [dataSource]="companyDataStore" valueExpr="id" [displayExpr]="Tools.getCompanyExpression">
    </dxo-lookup>
  </dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'companyFieldTemplate'">
    <div>
      {{ item?.data?.company?.name }}
    </div>
  </div>

  <dxi-column dataField="portfolioId">
    <dxo-lookup [dataSource]="portfoliosDataStore" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="buildingId" cellTemplate="buildingCellTemplate">
    <dxo-lookup [dataSource]="cellBuildingDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'buildingCellTemplate'" class="lookup-user-field">
    <img alt=""
         [src]="getBuildingPicture(item)"/>
    <div>
      {{ getBuildingName(item) }}
    </div>
  </div>

  <dxi-column dataField="leaseId" caption="Lease" cellTemplate="leaseFieldTemplate">
    <dxo-lookup [dataSource]="leaseDataSource" valueExpr="leaseId" [displayExpr]="Tools.shortLeaseNameExpression">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'leaseFieldTemplate'" class="lookup-user-field">
    <div>{{ Tools.leaseNameExpression(item.data.lease) }}</div>
  </div>

  <dxo-editing mode="popup" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup
      title="Add Statera User Claims"
      [maxWidth]="500"
      [showTitle]="true"
      [position]="{ my: 'center', at: 'center', of: _window }"
    >
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item itemType="group" caption="Main Options">
        <dxi-item dataField="userId" caption="User"
                  [editorOptions]="userEditorOptions"
        ></dxi-item>
        <dxi-item dataField="stateraClaimId" caption="User"
                  [editorOptions]="claimEditorOptions"
        ></dxi-item>
        <dxi-item dataField="stateraClaimGroupId" caption="User"
                  [editorOptions]="claimGroupEditorOptions"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Options for landlord" [visible]="showOptionsForLandlord">
        <dxi-item dataField="companyId" caption="Company"
                  [editorOptions]="companyEditorOptions"
                  #companyControl
        ></dxi-item>
        <dxi-item dataField="portfolioId" caption="Portfolio"
                  [editorOptions]="portfolioEditorOptions"
                  #portfolioControl
        ></dxi-item>
        <dxi-item dataField="buildingId" caption="Building"
                  [editorOptions]="buildingEditorOptions"
                  #buildingControl
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Option for all roles">
        <dxi-item dataField="leaseId" caption="Lease"
                  [editorOptions]="leaseEditorOptions"
                  #leaseControl
        >
        </dxi-item>
      </dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
