import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ImageViewerImageMarkerRef, ImageViewerImageRef } from '../../models/image-viewer-image.model';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';

@Component({
  templateUrl: 'image-viewer-marker-dialog.component.html',
  styleUrls: ['image-viewer-marker-dialog.component.scss'],
})
export class ImageViewerMarkerDialogComponent implements OnInit {
  @ViewChild('form', {static: false, read: NgForm}) form: NgForm;

  marker: ImageViewerImageMarkerRef;

  private readonly _popupRefService: PopupRefService;

  constructor(popupRefService: PopupRefService) {
    this._popupRefService = popupRefService;
  }

  ngOnInit(): void {
    this.marker = this.marker || new ImageViewerImageMarkerRef(new ImageViewerImageRef(), 0, 0);
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    this._popupRefService.outputData = {
      marker: this.marker,
    };

    this.close();
  }

  close(): void {
    if (!this._popupRefService) {
      return;
    }

    this._popupRefService.hide();
  }
}
