import { Injectable } from '@angular/core';
import { NgProgressComponent } from '@ngx-progressbar/core';

@Injectable()
export class ProgressBarService {
    public progressBar: NgProgressComponent;

    start() {
        if (this.progressBar) {
            this.progressBar.start();
        }
    }

    setValue(value: number) {
        if (this.progressBar) {
            this.progressBar.set(value);
        }
    }

    complete() {
        if (this.progressBar) {
            this.progressBar.complete();
        }
    }
}
