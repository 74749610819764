<h2 class="content-block">Rental Rate Escalation</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [masterDetail]="{ enabled: true, template: 'detail' }"
>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="70" dataType="number"></dxi-column>
  <dxi-column dataField="abstractValue" dataType="text"></dxi-column>
  <dxi-column dataField="escalationPercentagePerYear" dataType="number"></dxi-column>
  <dxi-column dataField="escalationPsfValuePerYear" dataType="number"></dxi-column>

  <dxi-column dataField="leaseId" cellTemplate="leaseFieldTemplate">
    <dxo-lookup [dataSource]="leases" valueExpr="id" [displayExpr]="tools.leaseNameExpression"></dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'leaseFieldTemplate'" class="lookup-user-field">
    <div>{{ tools.leaseNameExpression(item.data.lease) }}</div>
  </div>
  <dxi-column dataField="escalationTermType" dataType="number">
    <dxo-lookup [dataSource]="EscalationTermTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="rentalRateEscalationTaxType" dataType="number">
    <dxo-lookup [dataSource]="RentalRateEscalationTaxTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>

  <div *dxTemplate="let detail of 'detail'">
    <dx-data-grid
      [dataSource]="detail?.data?.rentalRateAnnualEscalationTermCustomValues"
      [showBorders]="true"
      [columnAutoWidth]="true"
      (onRowInserting)="onInsertingRentalRateAnnualEscalationTermCustomValue($event, detail.data.rentalRateAnnualEscalationTermCustomValues)"
      (onRowInserted)="onInsertedRentalRateAnnualEscalationTermCustomValue($event, detail?.data)"
      (onRowRemoved)="onRemovedRentalRateAnnualEscalationTermCustomValue($event, detail?.data)"
    >
      <dxi-column dataField="id" [width]="70" dataType="number"></dxi-column>
      <dxi-column dataField="escalationMonth" dataType="date"></dxi-column>
      <dxi-column dataField="escalationRentalRate" dataType="number"></dxi-column>
      <dxo-editing mode="popup" [allowDeleting]="true" [allowAdding]="true">
        <dxo-popup
          title="Add or edit term"
          [showTitle]="true"
          width="600"
          height="480"
          [position]="{ my: 'center', at: 'center', of: _window }"
        >
        </dxo-popup>
        <dxo-form [colCount]="1">
          <dxi-item dataField="escalationMonth" dataType="date"></dxi-item>
          <dxi-item dataField="escalationRentalRate" dataType="number"></dxi-item>
        </dxo-form>
      </dxo-editing>
    </dx-data-grid>
  </div>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup
      title="Add or edit term"
      [showTitle]="true"
      width="600"
      height="480"
      [position]="{ my: 'center', at: 'center', of: _window }"
    >
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="leaseId" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="abstractValue"></dxi-item>
      <dxi-item dataField="escalationTermType" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="rentalRateEscalationTaxType" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="escalationPercentagePerYear" dataType="number"></dxi-item>
      <dxi-item dataField="escalationPsfValuePerYear" dataType="number"></dxi-item>

    </dxo-form>
  </dxo-editing>
</dx-data-grid>

