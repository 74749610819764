<div id="point-of-interest-upsert">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form" novalidate
          (ngSubmit)="submit()"
          #form="ngForm">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Main properties
        </div>
        <!-- Start: Status -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && statusField.invalid">
            Status
          </div>
          <div class="dx-field-value">
            <dx-select-box name="status" required valueExpr="id" displayExpr="text"
                           [dataSource]="pointOfInterestStatusDataSource"
                           [(ngModel)]="model.status"
                           #statusField="ngModel">
              <div *dxTemplate="let item of 'item'">
                {{ item?.text }}
              </div>
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && statusField.hasError('required')">
              Status is required
            </div>
          </div>
        </div>
        <!-- End: Status -->
        <!-- Start: Kind -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && kindField.invalid">
            Kind
          </div>
          <div class="dx-field-value">
            <dx-select-box name="kind" required valueExpr="id" displayExpr="text"
                           [dataSource]="pointOfInterestKindDataSource"
                           [(ngModel)]="model.kind"
                           #kindField="ngModel">
              <div *dxTemplate="let item of 'item'">
                {{ item?.text }}
              </div>
            </dx-select-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && kindField.hasError('required')">
              Kind is required
            </div>
          </div>
        </div>
        <!-- End: Kind -->
        <!-- Start: Name -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && nameField.invalid">
            Name
          </div>
          <div class="dx-field-value">
            <dx-text-box name="name" required
                         [isValid]="form.submitted && !nameField.disabled ? nameField.valid : true"
                         [(ngModel)]="model.name"
                         #nameField="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && nameField.hasError('required')">
              Name is required
            </div>
          </div>
        </div>
        <!-- End: Name -->
        <!-- Start: Description -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && descriptionField.invalid">
            Description
          </div>
          <div class="dx-field-value">
            <dx-text-box name="description" required
                         [isValid]="form.submitted && !descriptionField.disabled ? descriptionField.valid : true"
                         [(ngModel)]="model.description"
                         #descriptionField="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && descriptionField.hasError('required')">
              Description is required
            </div>
          </div>
        </div>
        <!-- End: Description -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Geopoint
        </div>
        <!-- Start: Geopoint Latitude -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && geopointLatitudeField.invalid">
            Latitude
          </div>
          <div class="dx-field-value">
            <dx-text-box name="geopointLatitude" required
                         [isValid]="form.submitted && !geopointLatitudeField.disabled ? geopointLatitudeField.valid : true"
                         [(ngModel)]="model.geopointLatitude"
                         #geopointLatitudeField="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && geopointLatitudeField.hasError('required')">
              Latitude is required
            </div>
          </div>
        </div>
        <!-- End: Geopoint Latitude -->
        <!-- Start: Geopoint Longitude -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && geopointLongitudeField.invalid">
            Longitude
          </div>
          <div class="dx-field-value">
            <dx-text-box name="geopointLongitude" required
                         [isValid]="form.submitted && !geopointLongitudeField.disabled ? geopointLongitudeField.valid : true"
                         [(ngModel)]="model.geopointLongitude"
                         #geopointLongitudeField="ngModel">
            </dx-text-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && geopointLongitudeField.hasError('required')">
              Longitude is required
            </div>
          </div>
        </div>
        <!-- End: Geopoint Longitude -->
        <!-- Start: GeoJSON -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && geoJSONField.invalid">
            GeoJSON
          </div>
          <div class="dx-field-value">
            <dx-text-box name="geoJSON"
                         [isValid]="form.submitted && !geoJSONField.disabled ? geoJSONField.valid : true"
                         [(ngModel)]="model.geoJSON"
                         #geoJSONField="ngModel">
            </dx-text-box>
          </div>
        </div>
        <!-- End: GeoJSON -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row mx-n1">
          <div class="px-1">
            <dx-button type="success"
                       [text]="model.id ? 'Save' : 'Create'"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-1">
            <dx-button type="normal" text="Cancel"
                       (click)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>

<dx-load-panel [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false"
               [position]="{of: '#point-of-interest-upsert'}" [visible]="isLoading | async">
</dx-load-panel>
