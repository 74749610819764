import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { EditComponent } from './components/edit/edit.component';
import { ListComponent } from './components/list/list.component';
import { UploadComponent } from './components/upload/upload.component';

import { LeaseTemplateService } from './services/lease-template.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxDataGridModule,
    dx.DxButtonModule,
    dx.DxCheckBoxModule,
    dx.DxScrollViewModule,
    dx.DxFileUploaderModule,
    dx.DxLookupModule,
  ],
  declarations: [
    EditComponent,
    ListComponent,
    UploadComponent,
  ],
  providers: [
    LeaseTemplateService,
  ],
  exports: [
    EditComponent,
    ListComponent,
    UploadComponent,
  ],
  entryComponents: [
    EditComponent,
    UploadComponent,
  ],
})
export class LeaseTemplateModule {
  static forRoot(): ModuleWithProviders<LeaseTemplateModule> {
    return {
      ngModule: LeaseTemplateModule,
      providers: [
        LeaseTemplateService,
      ],
    };
  }
}
