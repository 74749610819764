import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

import CustomStore from 'devextreme/data/custom_store';

import * as vm from 'src/app/shared/models/generated';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  dataSource: any;
  url = `${environment.adminUrl}/quiz`;

  QuestionType: typeof vm.QuestionType = vm.QuestionType;
  AnswerType: typeof vm.AnswerType = vm.AnswerType;

  private readonly _httpClient: HttpClient;
  private readonly _datePipe: DatePipe;

  constructor(httpClient: HttpClient, datePipe: DatePipe) {
    this._httpClient = httpClient;
    this._datePipe = datePipe;
  }

  ngOnInit() {
    this.dataSource = new CustomStore({
      key: 'index',
      load: (options): Promise<any> => {
        let params = new HttpParams();

        if (options) {
          const dxParams = [
            'filter',
            'group',
            'groupSummary',
            'parentIds',
            'requireGroupCount',
            'requireTotalCount',
            'searchExpr',
            'searchOperation',
            'searchValue',
            'select',
            'sort',
            'skip',
            'take',
            'totalSummary',
            'userData',
          ];

          for (let i = 0, num = dxParams.length; i < num; i++) {
            const paramName = dxParams[i];
            if (paramName in options && !options[paramName]) {
              params = params.set(paramName, JSON.stringify(options[paramName]));
            }
          }
        }

        return this._httpClient
          .get<any>(this.url, {params: params})
          .pipe(
            map((resp) => {
              if (!resp) {
                return {};
              }

              return {
                data: resp.data
                  .map((x, i) => {
                    const modifiedModel = { ...x, index: i };

                    const latestAnswer = x.answers[x.answers.length - 1];
                    if (latestAnswer) {
                      modifiedModel.latestAnswerId = latestAnswer.id;
                    }

                    return modifiedModel;
                  })
                  .sort((a, b) => {
                    if (!a || !b || !a.latestAnswerId || !b.latestAnswerId) {
                      return 0;
                    }

                    return a.latestAnswerId - b.latestAnswerId;
                  }),
                totalCount: resp.totalCount,
                summary: resp.summary,
                groupCount: resp.groupCount
              };
            }),
          )
          .toPromise();
      },
    });
  }

  renderAnswer(answer) {
    if (
      answer.quizQuestion.questionType === vm.QuestionType.Text ||
      answer.quizQuestion.questionType === vm.QuestionType.YesNo
    ) {
      return answer.customAnswerText;
    } else if (answer.quizQuestion.questionType === vm.QuestionType.Choice) {
      return answer.selectedOptionText;
    } else if (answer.quizQuestion.questionType === vm.QuestionType.MultipleChoice
      || answer.quizQuestion.questionType === vm.QuestionType.MultipleChoiceMaxTwoItems) {
      return (JSON.parse(answer.customAnswerText) as vm.IQuizQuestionOptionViewModel[]).map(x => x.text);
    } else if (answer.quizQuestion.questionType === vm.QuestionType.File) {
      return answer.uploadedFileUrl;
    } else if (answer.quizQuestion.questionType === vm.QuestionType.Address) {
      if (answer.selectedAddress) {
        return answer.selectedAddress.displayString;
      }
      return '';
    } else if (answer.quizQuestion.questionType === vm.QuestionType.MultipleNumber) {
      const multipleNumber = JSON.parse(answer.customAnswerText);
      let result = '';
      for (const field in multipleNumber) {
        if (multipleNumber.hasOwnProperty(field)) {
          result += `${field}: ${multipleNumber[field]} `;
        }
      }
      return result;
    } else if (answer.quizQuestion.questionType === vm.QuestionType.Number
      || answer.quizQuestion.questionType === vm.QuestionType.SqftNumber
      || answer.quizQuestion.questionType === vm.QuestionType.NumberWithExpirationDate) {
      return answer.answerNumber;
    } else if (answer.quizQuestion.questionType === vm.QuestionType.Calendar) {
      return answer.answerDate;
    } else if (answer.quizQuestion.questionType === vm.QuestionType.Range) {
      const range = <vm.IQuizRangeAnswerViewModel>JSON.parse(answer.customAnswerText);
      return `From ${range.from} to ${range.to}`;
    }
  }

  downloadFinancialFile($event, file: vm.IFileViewModel): void {
    $event.preventDefault();
    window.open(file.url);
  }

  getQuestionText(question: vm.IQuizQuestionViewModel, lease: vm.ILeaseViewModel): string {
    if (question.questionType === vm.QuestionType.NumberWithExpirationDate) {
      let text = 'How long are you willing to extend the lease beyond the current expiration date';

      if (lease && lease.expiration) {
        text = `${text} of ${this._datePipe.transform(lease.expiration)}`;
      }
      text += '?';
      return text;
    }

    return question.text;
  }
}
