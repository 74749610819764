import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';
import { TermService } from '../../services/term.service';

import * as models from '../../../../shared/models/generated';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

import moment from 'moment';

interface ScheduleTableRow extends models.IBaseRentalRateCustomScheduleTableRow {
  current: boolean;
}

@Component({
  templateUrl: 'base-rental-rate-custom-table-editor.component.html',
  styleUrls: ['base-rental-rate-custom-table-editor.component.scss'],
})
export class BaseRentalRateCustomTableEditorComponent implements OnInit, OnDestroy {
  UnitMetrics: typeof models.BaseRentalRateUnitMetrics = models.BaseRentalRateUnitMetrics;

  unitMetrics: models.BaseRentalRateUnitMetrics;
  scheduleRows: Array<ScheduleTableRow>;

  previewChangeReason: string;

  readonly lease: models.ILeaseViewModel;

  readonly saveCustomScheduleTable: (rows: Array<models.IBaseRentalRateCustomScheduleTableRow>) => void;

  private readonly _popupService: PopupService;
  private readonly _popupRefService: PopupRefService;
  private readonly _termService: TermService;
  private readonly _destroy$: Subject<void>;

  constructor(popupService: PopupService, popupRefService: PopupRefService, termService: TermService) {
    this._popupService = popupService;
    this._popupRefService = popupRefService;
    this._termService = termService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    if (this.areAllRequiredTermsValid()) {
      if (
        this.lease &&
        this.lease.baseRentalRateTerm &&
        this.lease.baseRentalRateTerm.customScheduleTable
      ) {
        this.unitMetrics = this.lease.baseRentalRateTerm.baseRentalRateUnitMetrics;
        this.scheduleRows = this.convertToScheduleTableRow(this.lease.baseRentalRateTerm.customScheduleTable);

        // Check if something has changed
        this._requestPreview()
          .pipe(
            map(preview => {
              if (!preview || !this.scheduleRows) {
                return null;
              }

              if (this.unitMetrics !== preview.baseRentalRateUnitMetrics) {
                return 'Unit metrics changed';
              }

              if (preview.rows) {
                if (preview.rows.length !== this.scheduleRows.length) {
                  return 'Number of lines changed';
                }

                for (let i = 0, num = preview.rows.length; i < num; i++) {
                  const previewRow = preview.rows[i];
                  const scheduleRow = this.scheduleRows[i];

                  const isSomethingChanged = (
                    previewRow.start !== scheduleRow.start ||
                    previewRow.end !== scheduleRow.end ||
                    previewRow.baseRent !== scheduleRow.baseRent ||
                    previewRow.annualRent !== scheduleRow.annualRent ||
                    previewRow.monthlyRent !== scheduleRow.monthlyRent
                  );

                  if (isSomethingChanged) {
                    return 'One of the lines changed';
                  }
                }
              }

              return null;
            }),
            tap(changeReason => this.previewChangeReason = changeReason),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();
      } else {
        this.recalculateSchedule();
      }
    }

    this._popupRefService.onContentReady
      .pipe(
        tap((event) => {
          const contentElement = event.component.content();
          if (contentElement) {
            contentElement.style.padding = '0';
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  recalculateSchedule(): void {
    if (!this.areAllRequiredTermsValid()) {
      return;
    }

    this._requestPreview()
      .pipe(
        tap((schedule) => {
          this.unitMetrics = schedule.baseRentalRateUnitMetrics;
          this.scheduleRows = this.convertToScheduleTableRow(schedule.rows);
          this.previewChangeReason = null;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _requestPreview(): Observable<models.IBaseRentalScheduleViewModel> {
    if (!this.areAllRequiredTermsValid()) {
      return of(null);
    }

    return this._termService.getRentSchedulePreview(this.lease);
  }

  recalculateScheduleWithWarningDialog(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to recalculate the schedule table? All changes will be lost.',
        acceptFn: () => {
          this.recalculateSchedule();
        },
      },
    });
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    if (typeof this.saveCustomScheduleTable === 'function') {
      this.saveCustomScheduleTable([...this.scheduleRows]);
    }

    this._popupRefService.hide();
  }

  cancel(): void {
    this._popupRefService.hide();
  }

  areAllRequiredTermsValid(): boolean {
    if (!this.lease) {
      return false;
    }

    return (
      this.isCommencementTermValid() &&
      this.isLengthTermValid() &&
      this.isTenantSquareFootageValid() &&
      this.isBaseRentalRateTermValid()
    );
  }

  areAllOptionalTermsValid(): boolean {
    if (!this.lease) {
      return false;
    }

    return (
      this.isFreeRentTermValid() &&
      this.isRentalRateEscalationTermValid()
    );
  }

  isCommencementTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const commencementTerm = <models.ICommencementTermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.Commencement);

    if (!commencementTerm) {
      return false;
    }

    return !!commencementTerm.commencement;
  }

  isLengthTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const lengthTerm = <models.ITermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.Term);

    if (!lengthTerm) {
      return false;
    }

    return (
      lengthTerm.termType &&
      !!lengthTerm.termValue
    );
  }

  isTenantSquareFootageValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const tenantSquareFootage = <models.ITenantSquareFootageTermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.TenantSquareFootage);

    if (!tenantSquareFootage) {
      return false;
    }

    return (
      tenantSquareFootage.tenantSquareFootageTermType &&
      (
        (
          tenantSquareFootage.tenantSquareFootageTermType !== models.TenantSquareFootageTermType.Custom
        ) ||
        (
          tenantSquareFootage.tenantSquareFootageTermType === models.TenantSquareFootageTermType.Custom &&
          !!tenantSquareFootage.tenantSquareFootageCustomValue
        )
      )
    );
  }

  isBaseRentalRateTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const baseRentalRate = <models.IBaseRentalRateViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.BaseRentalRate);

    const realEstateTaxesAndCamExpenses = <models.IRealEstateTaxesCamExpensesViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.RealEstateTaxesCamExpenses);

    if (!baseRentalRate) {
      return false;
    }

    return (
      baseRentalRate.baseRentalRateUnitMetrics &&
      baseRentalRate.baseRentalRateType &&
      (
        (
          baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Net &&
          !!baseRentalRate.baseRentalRateNetValue
        ) ||
        (
          baseRentalRate.baseRentalRateType === models.BaseRentalRateType.Gross &&
          !!baseRentalRate.baseRentalRateGrossValue
        ) ||
        (
          baseRentalRate.baseRentalRateType === models.BaseRentalRateType.BaseYear &&
          !!baseRentalRate.actualYearRate &&
          (
            realEstateTaxesAndCamExpenses &&
            !!realEstateTaxesAndCamExpenses.baseYearForExpenses
          )
        )
      )
    );
  }

  isFreeRentTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const freeRentTerm = <models.IFreeRentTermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.FreeRent);

    if (!freeRentTerm) {
      return false;
    }

    return (
      freeRentTerm.freeRentTermType &&
      (
        (
          freeRentTerm.freeRentTermType === models.FreeRentTermType.MonthsCount &&
          !!freeRentTerm.freeRentMonthsCount &&
          !!freeRentTerm.freeRentTaxesType
        ) ||
        (
          freeRentTerm.freeRentTermType === models.FreeRentTermType.SpecificSchedule &&
          freeRentTerm.specificMonths &&
          !!freeRentTerm.specificMonths.length &&
          !!freeRentTerm.freeRentTaxesType
        )
      )
    );
  }

  isRentalRateEscalationTermValid(): boolean {
    if (!this.lease) {
      return false;
    }

    const rentalRateEscalationTerm = <models.IRentalRateAnnualEscalationTermViewModel>this._termService
      .getLeaseTerm(this.lease, models.LeaseTermType.RentalRateAnnualEscalation);

    if (!rentalRateEscalationTerm) {
      return false;
    }

    return (
      rentalRateEscalationTerm.escalationTermType &&
      (
        (
          rentalRateEscalationTerm.escalationTermType === models.EscalationTermType.FixedPercentagePerYear &&
          !!rentalRateEscalationTerm.escalationPercentagePerYear
        ) ||
        (
          rentalRateEscalationTerm.escalationTermType === models.EscalationTermType.FixedAmountPsfPerYear &&
          !!rentalRateEscalationTerm.escalationPsfValuePerYear
        ) ||
        (
          rentalRateEscalationTerm.escalationTermType === models.EscalationTermType.Custom &&
          rentalRateEscalationTerm.rentalRateAnnualEscalationTermCustomValues &&
          !!rentalRateEscalationTerm.rentalRateAnnualEscalationTermCustomValues.length
        )
      )
    );
  }

  convertToScheduleTableRow(from: models.IBaseRentalRateCustomScheduleTableRow[]): ScheduleTableRow[] {
    const now = moment();

    return from.map(row => {
      const current = now.isAfter(moment(row.start)) && now.isBefore(moment(row.end));
      return {...row, current};
    });
  }
}
