import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';
import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';
import { ImportFormSelectItem } from '../../models/import-form-select-item.model';
import { LeaseAbstractService } from '../../services/lease-abstract.service';
import { ImportComponent } from '../import/import.component';

@Component({
  templateUrl: 'import-document-viewer-options-edit-dialog.component.html',
  styleUrls: ['import-document-viewer-options-edit-dialog.component.scss'],
})
export class ImportDocumentViewerOptionsEditDialogComponent implements OnInit, OnDestroy {
  private static readonly _leaseTypes: Array<ImportFormSelectItem<models.LeaseType>> = [
    {
      name: 'None',
      value: models.LeaseType.None,
    },
    {
      name: 'Internal',
      value: models.LeaseType.Internal,
    },
    {
      name: '3rd Party Lease',
      value: models.LeaseType.ThirdPartyLease,
    },
  ];

  private static readonly _leaseMetricsTypes: Array<ImportFormSelectItem<models.BaseRentalRateUnitMetrics>> = [
    {
      name: 'PSF/YR',
      value: models.BaseRentalRateUnitMetrics.PsfPerYear,
    },
    {
      name: 'PSF/MO',
      value: models.BaseRentalRateUnitMetrics.PsfPerMonth,
    },
  ];

  @ViewChild(NgForm, {static: false}) form: NgForm;

  readonly lease: models.ILeaseViewModel;

  leaseAbstractDocument: File;
  LeaseType = models.LeaseType;

  leaseTypesDataSource: DataSource;
  landlordCompaniesDataSource: DataSource;
  tenantCompaniesDataSource: DataSource;
  buildingsDataSource: DataSource;
  buildingsUnitsDataSource: DataSource;
  abstractLeasesDataSource: DataSource;
  leaseMetricsTypesDataSource: DataSource;

  abstractLeases: Array<models.ILeaseViewModel>;

  isLoading$: BehaviorSubject<boolean>;

  private readonly _leaseAbstractService: LeaseAbstractService;
  private readonly _popupRefService: PopupRefService;
  private readonly _popupService: PopupService;
  private readonly _destroy$: Subject<void>;

  constructor(
    leaseAbstractService: LeaseAbstractService,
    popupRefService: PopupRefService,
    popupService: PopupService
  ) {
    this._leaseAbstractService = leaseAbstractService;
    this._popupRefService = popupRefService;
    this._popupService = popupService;
    this._destroy$ = new Subject<void>();

    this.isLoading$ = new BehaviorSubject<boolean>(true);
  }

  ngOnInit(): void {
    this.abstractLeases = [];

    if (!this.lease) {
      return;
    }

    this.leaseTypesDataSource = new DataSource({
      key: 'value',
      store: {
        type: 'array',
        data: ImportDocumentViewerOptionsEditDialogComponent._leaseTypes,
      },
    });

    this.leaseMetricsTypesDataSource = new DataSource({
      key: 'value',
      store: {
        type: 'array',
        data: ImportDocumentViewerOptionsEditDialogComponent._leaseMetricsTypes,
      },
    });

    let observers: Array<Observable<any>> = [
      this._leaseAbstractService.getLandlordCompaniesList(),
      this._leaseAbstractService.getTenantCompaniesList(),
    ];

    if (this.lease.leaseType !== models.LeaseType.ThirdPartyLease) {
      observers = [
        ...observers,
        this._leaseAbstractService.getLandlordCompanyBuildingsList(this.lease.landlordCompanyId),
      ];
    } else {
      observers = [
        ...observers,
        this._leaseAbstractService.get3rdPartyBuildingsList(),
      ];
    }

    if (this.lease.landlordCompanyId && this.lease.tenantCompanyId && this.lease.buildingId) {
      observers = [
        ...observers,
        this._leaseAbstractService.getAbstractLeasesList(this.lease)
      ];
    }

    forkJoin(observers)
      .pipe(
        tap(([landlordCompanies, tenantCompanies, buildings, abstractLeases]) => {
          this.landlordCompaniesDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: landlordCompanies,
            },
          });

          this.tenantCompaniesDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: tenantCompanies,
            },
          });

          this.buildingsDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: buildings,
            },
          });

          let buildingUnits = [];
          if (this.lease.buildingId) {
            const building = buildings.find(x => x.id === this.lease.buildingId);
            if (building) {
              buildingUnits = [...building.buildingUnits];
            }
          }

          this.buildingsUnitsDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: buildingUnits,
            },
          });

          this.abstractLeasesDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: abstractLeases,
            },
          });

          this.abstractLeases = abstractLeases;
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => this.isLoading$.next(false));
  }

  ngOnDestroy(): void {
    this.resetForm();
  }

  setupSearchableCompanyFields(company: models.ICompanyViewModel): Array<string> {
    return [
      company.id.toString(10),
      company.name,
    ];
  }

  setupSearchableBuildingFields(building: models.IBuildingViewModel): Array<string> {
    const searchableFields = [
      building.id.toString(10),
      building.name,
    ];

    if (building.address) {
      if (building.address.addressLine1) {
        searchableFields.push(building.address.addressLine1);
      }

      if (building.address.addressLine2) {
        searchableFields.push(building.address.addressLine2);
      }

      if (building.address.city) {
        searchableFields.push(building.address.city);
      }

      if (building.address.stateCode) {
        searchableFields.push(building.address.stateCode);
      }

      if (building.address.zipCode) {
        searchableFields.push(building.address.zipCode);
      }
    }

    return searchableFields;
  }

  setupSearchableBuildingUnitFields(buildingUnit: models.IBuildingUnitViewModel): Array<string> {
    return [
      buildingUnit.id.toString(10),
      buildingUnit.name,
    ];
  }

  setupSearchableAbstractLeaseFields(abstractLease: models.ILeaseViewModel): Array<string> {
    const searchableFields = [
      abstractLease.id.toString(10),
    ];

    if (abstractLease.landlordCompany && abstractLease.landlordCompany.name) {
      searchableFields.push(abstractLease.landlordCompany.name);
    }

    if (abstractLease.tenantCompany && abstractLease.tenantCompany.name) {
      searchableFields.push(abstractLease.tenantCompany.name);
    }

    if (abstractLease.building) {
      if (abstractLease.building.name) {
        searchableFields.push(abstractLease.building.name);
      }

      if (abstractLease.building.address) {
        if (abstractLease.building.address.addressLine1) {
          searchableFields.push(abstractLease.building.address.addressLine1);
        }

        if (abstractLease.building.address.addressLine2) {
          searchableFields.push(abstractLease.building.address.addressLine2);
        }

        if (abstractLease.building.address.city) {
          searchableFields.push(abstractLease.building.address.city);
        }

        if (abstractLease.building.address.stateCode) {
          searchableFields.push(abstractLease.building.address.stateCode);
        }

        if (abstractLease.building.address.zipCode) {
          searchableFields.push(abstractLease.building.address.zipCode);
        }
      }
    }

    if (abstractLease.buildingUnit && abstractLease.buildingUnit.name) {
      searchableFields.push(abstractLease.buildingUnit.name);
    }

    return searchableFields;
  }

  handleLeaseTypeChange(leaseType: models.LeaseType) {
    if (leaseType !== models.LeaseType.ThirdPartyLease) {
      return;
    }

    this.lease.landlordCompanyId = null;

    this._repopulateDataSources();
  }

  handleLandlordCompanyChange(landlordCompanyId: number): void {
    this.lease.buildingId = null;
    this.lease.buildingUnitId = null;
    this.lease.abstractLeaseId = null;

    this._repopulateDataSources();
  }

  handleTenantCompanyChange(tenantCompanyId: number): void {
    this.lease.abstractLeaseId = null;

    this._repopulateDataSources();
  }

  handleBuildingChange(buildingId: number): void {
    this.lease.buildingUnitId = null;
    this.lease.abstractLeaseId = null;

    this._repopulateDataSources();
  }

  handleBuildingUnitChange(buildingUnitId: number): void {
    this.lease.abstractLeaseId = null;

    this._repopulateDataSources();
  }

  handleAbstractLeaseChange(abstractLeaseId: number): void {
    if (!this.abstractLeases) {
      return;
    }

    const abstractLease = this.abstractLeases.find(x => x.id === abstractLeaseId);
    if (!abstractLease) {
      return;
    }

    this.lease.amendmentNumber = abstractLease.amendmentNumber + 1;
  }

  private _repopulateDataSources(): void {
    let observers: Array<Observable<any>> = [];

    if (!this.lease) {
      return;
    }

    if (this.lease.leaseType !== models.LeaseType.ThirdPartyLease) {
      observers = [
        ...observers,
        this._leaseAbstractService.getLandlordCompanyBuildingsList(this.lease.landlordCompanyId),
      ];
    } else {
      observers = [
        ...observers,
        this._leaseAbstractService.get3rdPartyBuildingsList(),
      ];
    }

    if (this.lease.landlordCompanyId && this.lease.tenantCompanyId && this.lease.buildingId) {
      observers = [
        ...observers,
        this._leaseAbstractService.getAbstractLeasesList(this.lease)
      ];
    }

    this.isLoading$.next(true);

    forkJoin(observers)
      .pipe(
        tap(([buildings, abstractLeases]) => {
          this.buildingsDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: buildings,
            },
          });

          let buildingUnits = [];
          if (this.lease.buildingId) {
            const building = buildings.find(x => x.id === this.lease.buildingId);
            if (building) {
              buildingUnits = [...building.buildingUnits];
            }
          }

          this.buildingsUnitsDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: buildingUnits,
            },
          });

          this.abstractLeasesDataSource = new DataSource({
            key: 'id',
            store: {
              type: 'array',
              data: abstractLeases,
            },
          });

          this.abstractLeases = abstractLeases;
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => this.isLoading$.next(false));
  }

  getLeaseName(lease: models.ILeaseViewModel): string {
    if (!lease) {
      return null;
    }

    const leaseNameSegments = [
      lease.building && lease.building.name,
      lease.buildingUnit && lease.buildingUnit.name,
    ];

    const tenantLandlordRelationSegments = [
      lease.landlordCompany && lease.landlordCompany.name,
      lease.tenantCompany && lease.tenantCompany.name,
    ];

    const addressAndCompanyNames = leaseNameSegments
      .concat(
        tenantLandlordRelationSegments
          .filter(Boolean)
          .join(' - ')
      )
      .filter(Boolean)
      .join(', ');

    return `Lease #${lease.id}: ${addressAndCompanyNames}`;
  }

  updateLeaseOptions(): void {
    if (this.form && this.form.invalid) {
      return;
    }

    if (!this.lease || !this.lease.id) {
      return;
    }

    const lease = <models.ILeaseViewModel>{
      ...this.lease,
      tenantCompany: null,
      landlordCompany: null,
      building: null,
      buildingUnit: null,
      abstractLease: null,
    };

    this.isLoading$.next(true);

    this._leaseAbstractService
      .updateLeaseAbstractImportDraft(<models.ILeaseAbstractImportDraftViewModel>{
        lease: lease,
        leaseId: lease.id,
      })
      .pipe(
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => {
        this.isLoading$.next(false);
        this._popupRefService.hide();
      });
  }

  resetForm(): void {
    if (!this.form) {
      return;
    }

    this.form.resetForm();
  }

  cancel(): void {
    if (this.form && this.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  isLandlordCompanyRequired(lease: models.ILeaseViewModel) {
    return lease && lease.leaseType === models.LeaseType.Internal;
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel?',
        acceptFn: () => {
          this._popupRefService.hide();
        },
      },
    });
  }
}
