<div>
  <p class="mb-4 text-center">
    {{message}}
  </p>

  <!-- Start: Buttonset -->
  <div class="d-flex flex-row mx-n1">
    <div class="px-1">
      <dx-button type="danger" text="Yes"
                 (click)="accept()">
      </dx-button>
    </div>
    <div class="px-1">
      <dx-button type="normal" text="No"
                 (click)="decline()">
      </dx-button>
    </div>
  </div>
  <!-- End: Buttonset -->
</div>
