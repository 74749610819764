<div id="import-mapping-popup">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form" novalidate
          (ngSubmit)="submit()" #form="ngForm">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset"
           *ngIf="importFileError">
        <div class="alert alert-danger">
          {{importFileError}}
        </div>
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Main properties
        </div>
        <!-- Start: Name -->
        <div class="dx-field">
          <div class="dx-field-label">
            Name
          </div>
          <div class="dx-field-value">
            <dx-select-box name="name" id="name"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.name" #name="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Name -->
        <!-- Start: Description -->
        <div class="dx-field">
          <div class="dx-field-label">
            Description
          </div>
          <div class="dx-field-value">
            <dx-select-box name="description" id="description"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.description" #name="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Description -->
        <!-- Start: Latitude -->
        <div class="dx-field">
          <div class="dx-field-label">
            Geopoint Latitude
          </div>
          <div class="dx-field-value">
            <dx-select-box name="geopointLatitude" id="geopointLatitude"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.geopointLatitude" #name="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Latitude -->
        <!-- Start: Longitude -->
        <div class="dx-field">
          <div class="dx-field-label">
            Geopoint Longitude
          </div>
          <div class="dx-field-value">
            <dx-select-box name="geopointLongitude" id="geopointLongitude"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.geopointLongitude" #name="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Longitude -->
        <!-- Start: GeoJSON -->
        <div class="dx-field">
          <div class="dx-field-label">
            GeoJSON
          </div>
          <div class="dx-field-value">
            <dx-select-box name="geoJSON" id="geoJSON"
                           [items]="importFileHeaders" valueExpr="value" displayExpr="name" [showClearButton]="true"
                           (onValueChanged)="handleSelectBoxChange($event)"
                           [(ngModel)]="fieldMappings.geoJSON" #name="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: GeoJSON -->
      </div>
      <!-- End: Fieldset -->

      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row mx-n1">
          <div class="px-1">
            <dx-button type="success" text="Import"
                       [disabled]="isLoading | async"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-1">
            <dx-button type="normal" text="Cancel"
                       [disabled]="isLoading | async"
                       (click)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>
