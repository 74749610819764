import { Component, OnInit } from '@angular/core';
import {
  EscalationTermType,
  HvacTermType,
  ILeaseTermConfiguration,
  LeaseTermType, RentalRateEscalationTaxType
} from "../../../shared/models/generated";
import {Tools} from "../../../shared/tools";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../shared/services";
import {TermsService} from "../../../shared/services/terms.service";
import * as AspNetData from "devextreme-aspnet-data-nojquery";

@Component({
  selector: 'app-rental-rate-annual-escalation',
  templateUrl: './rental-rate-annual-escalation.component.html',
  styleUrls: ['./rental-rate-annual-escalation.component.scss']
})
export class RentalRateAnnualEscalationComponent implements OnInit {

  EscalationTermType = EscalationTermType;
  EscalationTermTypes = Tools.EnumToArray(EscalationTermType);

  RentalRateEscalationTaxType = RentalRateEscalationTaxType;
  RentalRateEscalationTaxTypes = Tools.EnumToArray(RentalRateEscalationTaxType);

  dataSource: any;
  leases: any;
  leaseConfiguration:ILeaseTermConfiguration;
  leaseTerms: any[];
  url = `${environment.adminUrl}/leaseTerms`;
  get _window() {
    return window;
  }
  tools = Tools;

  constructor(private authService: AuthService, private termsService: TermsService) {}

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      loadParams:{
        leaseTermType: LeaseTermType.RentalRateAnnualEscalation
      },
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };

        if(ajaxOptions.data.values){
          const values = JSON.parse(ajaxOptions.data.values);
          if(method === "insert"){
            values.leaseTermType = LeaseTermType.RentalRateAnnualEscalation;
            values.leaseTermConfiguration = this.leaseConfiguration;
          }

          ajaxOptions.data.values = JSON.stringify(values);
        }
      }
    });

    this.dataSource.on('loaded', (data) => {
      this.leaseTerms = data;
    });

    this.leases = this.createDataSource('id', `${environment.adminUrl}/Leases`);

    this.termsService.getLeaseTermConfiguration(LeaseTermType.RentalRateAnnualEscalation).subscribe(x => this.leaseConfiguration = x);
  }

  createDataSource(key: string, loadUrl: string) {
    return AspNetData.createStore({
      key: key,
      loadUrl: loadUrl,
      onBeforeSend: function(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });
  }

  onInsertingRentalRateAnnualEscalationTermCustomValue($event,rentalRateAnnualEscalationTermCustomValues ){
    let index = 1;
    if(rentalRateAnnualEscalationTermCustomValues && rentalRateAnnualEscalationTermCustomValues.length){
      index = rentalRateAnnualEscalationTermCustomValues[rentalRateAnnualEscalationTermCustomValues.length - 1].id + 1;
    }
    $event.data.id = index;
  }

  onInsertedRentalRateAnnualEscalationTermCustomValue($event, rentalRateAnnualEscalation){

    this.dataSource.update(rentalRateAnnualEscalation.id, rentalRateAnnualEscalation);
  }

  onRemovedRentalRateAnnualEscalationTermCustomValue($event, rentalRateAnnualEscalation){
    this.dataSource.update(rentalRateAnnualEscalation.id, rentalRateAnnualEscalation);
  }
}
