import { Component, OnInit, ViewChild } from '@angular/core';
import DevExpress from 'devextreme';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import moment from 'moment';

import { environment } from 'src/environments/environment';

import { Tools } from 'src/app/shared/tools';

import { SelectItem } from '../../../../shared/models/select-item.model';
import * as models from 'src/app/shared/models/generated';

@Component({
  selector: 'app-building-unit-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class BuildingUnitListComponent implements OnInit {
  @ViewChild('portfolioControl', {read: DxiItemComponent, static: false}) portfolioControl: DxiItemComponent;
  @ViewChild('buildingControl', {read: DxiItemComponent, static: false}) buildingControl: DxiItemComponent;

  companyId: number;
  portfolioId: number;
  buildingId: number;

  dataSource: DevExpress.data.CustomStore;
  companyDataSource: DevExpress.data.CustomStore;
  portfolioDataSource: DevExpress.data.CustomStore;
  buildingDataSource: DevExpress.data.CustomStore;

  companyControlOptions: any;
  portfolioControlOptions: any;
  buildingControlOptions: any;

  tools: typeof Tools;
  sprinklerSystems: SelectItem[];

  get _window() {
    return window;
  }

  constructor() {
    this.tools = Tools;
    this.sprinklerSystems = Tools.EnumToArray(models.SprinklerSystem);
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/BuildingUnit`,
      insertUrl: `${environment.adminUrl}/BuildingUnit`,
      updateUrl: `${environment.adminUrl}/BuildingUnit`,
      deleteUrl: `${environment.adminUrl}/BuildingUnit`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
        if (ajaxOptions.data.values) {
          this.prepareSave(ajaxOptions);
        }
      },
    });

    this.companyDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/company?companyType=LandlordCompany&withDeleted=true`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      }
    });

    this.portfolioDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/portfolio?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.companyId) {
          ajaxOptions.data.companyId = this.companyId;
        }
      },
    });

    this.buildingDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/building?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.portfolioId) {
          ajaxOptions.data.id = this.portfolioId;
        }
      },
    });

    this.companyControlOptions = {
      dataSource: this.companyDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select company',
      valueExpr: 'id',
      value: this.companyId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.companyId = event.value;
        this.portfolioId = null;
        this.buildingId = null;
        if (this.portfolioControl && this.portfolioControl.instance) {
          const dataSource = this.portfolioControl.instance.getDataSource();
          dataSource.load();
          this.portfolioControl.disabled = false;
        }
        return event.value;
      },
    };

    this.portfolioControlOptions = {
      dataSource: this.portfolioDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select portfolio',
      valueExpr: 'id',
      value: this.portfolioId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.portfolioId = event.value;
        this.buildingId = null;
        if (this.buildingControl && this.buildingControl.instance) {
          const dataSource = this.buildingControl.instance.getDataSource();
          dataSource.load();
          this.buildingControl.disabled = false;
        }
        return event.value;
      },
    };

    this.buildingControlOptions = {
      dataSource: this.buildingDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select building',
      valueExpr: 'id',
      value: this.buildingId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.buildingId = event.value;
        return event.value;
      },
    };
  }

  prepareSave(ajaxOptions) {
    if (!ajaxOptions || !ajaxOptions.data || !ajaxOptions.data.values) {
      return;
    }

    const values = JSON.parse(ajaxOptions.data.values) as models.IBuildingUnitViewModel;
    delete values.building;
    values.buildingId = this.buildingId;
    ajaxOptions.data.values = JSON.stringify(values);
  }

  initNewRow(e) {
    e.data.createdOn = moment().startOf('day').format('YYYY-MM-DD');

    this.companyId = null;
    this.portfolioId = null;
    this.buildingId = null;

    if (this.portfolioControl && this.portfolioControl.instance) {
      this.portfolioControl.disabled = true;
    }
    if (this.buildingControl && this.buildingControl.instance) {
      this.buildingControl.disabled = true;
    }
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'building.portfolio.companyId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.companyControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'building.portfolio.id') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.portfolioControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'buildingId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.buildingControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }
  }

  onEditingStart(e) {
    const buildingUnit = e.data as models.IBuildingUnit;

    this.companyId = buildingUnit.building?.portfolio?.companyId;
    this.portfolioId = buildingUnit.building?.portfolio?.id;
    this.buildingId = buildingUnit.buildingId;

    if (this.portfolioControl && this.portfolioControl.instance) {
      this.portfolioControl.disabled = false;
    }
    if (this.buildingControl && this.buildingControl.instance) {
      this.buildingControl.disabled = false;
    }
  }

  onRowPrepared(e): void {
    if (e.rowType === 'data' && e.data && e.data.deletedOn && e.rowElement) {
      e.rowElement.classList.add('is-deleted');
    }
  }

  getBuildingPicture(item) {
    if (item && item.data && item.data.building && item.data.building.pictures) {
      return Tools.buildingPictureThumbnail(item.data) || Tools.noImageUrl;
    } else if (item && item.pictures) {
      return Tools.buildingPictureThumbnail(item) || Tools.noImageUrl;
    }
    return Tools.noImageUrl;
  }

  getBuildingName(item) {
    if (item && item.data && item.data.building) {
      return item.data.building.name;
    } else if (item) {
      return item.name;
    }
    return '';
  }

  isEditButtonVisible(e): boolean {
    return e && e.row && e.row.data && !e.row.data.deletedOn;
  }
}
