import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SkipSelf } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';
import { DocumentViewerOptions } from '../../models/document-viewer-options.model';
import { ImportFormSelectItem } from '../../models/import-form-select-item.model';

//
// NOTE: ImportFormLeaseOptionsComponent component is used as nested form.
//       Before using, please make sure the ControlContainer is provided.
//

@Component({
  templateUrl: 'import-form-lease-options.component.html',
  styleUrls: ['import-form-lease-options.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class ImportFormLeaseOptionsComponent implements OnInit, OnDestroy {
  private static readonly _renewalOptionTypes: Array<ImportFormSelectItem<models.RenewalOptionTermType>> = [
    {
      name: 'Per current lease',
      value: models.RenewalOptionTermType.PerCurrentLease,
    },
    {
      name: 'None',
      value: models.RenewalOptionTermType.None,
    },
    {
      name: 'Tenant shall have renewal option',
      value: models.RenewalOptionTermType.RenewalOptionValues,
    },
    {
      name:
        'Tenant shall have renewal option (extended)',
      value: models.RenewalOptionTermType.RenewalOptionValuesExtended,
    },
    {
      name: 'Custom',
      value: models.RenewalOptionTermType.Custom,
    },
  ];

  private static readonly _terminationOptionTypes: Array<ImportFormSelectItem<models.TerminationOptionType>> = [
    {
      name: 'None',
      value: models.TerminationOptionType.NoTerminationOption,
    },
    {
      name: 'Tenant shall have right to terminate the lease',
      value: models.TerminationOptionType.RightToTerminateLease,
    },
    {
      name: 'Custom',
      value: models.TerminationOptionType.Custom,
    },
  ];

  private static readonly _terminationOptionFeeTypes: Array<ImportFormSelectItem<models.TerminationOptionFeeType>> = [
    {
      name: 'Amortization rate',
      value: models.TerminationOptionFeeType.AmortizationRate,
    },
    {
      name: 'Flat fee',
      value: models.TerminationOptionFeeType.DollarValue,
    }
  ];

  private static readonly _expansionOptionTypes: Array<ImportFormSelectItem<models.ExpansionOptionTermType>> = [
    {
      name: 'Per current lease',
      value: models.ExpansionOptionTermType.PerCurrentLease,
    },
    {
      name: 'None',
      value: models.ExpansionOptionTermType.None,
    },
    {
      name: 'Right of first refusal',
      value: models.ExpansionOptionTermType.RightOfFirstRefusal,
    },
    {
      name: 'Right of first offer',
      value: models.ExpansionOptionTermType.RightOfFirstOffer,
    },
    {
      name: 'Custom',
      value: models.ExpansionOptionTermType.Custom,
    },
  ];

  private static readonly _assignmentOrSublettingTypes: Array<ImportFormSelectItem<models.AssignmentTermType>> = [
    {
      name: 'Per current lease',
      value: models.AssignmentTermType.PerCurrentLease,
    },
    {
      name: 'Tenant shall have the right',
      value: models.AssignmentTermType.TenantShallHaveTheRight,
    },
    {
      name: 'Custom',
      value: models.AssignmentTermType.Custom,
    },
  ];

  @Input() lease: models.ILeaseViewModel;
  @Output() leaseChange: EventEmitter<models.ILeaseViewModel>;

  @Input() leasePreview: models.ILeaseViewModel;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  RenewalOptionType: typeof models.RenewalOptionTermType = models.RenewalOptionTermType;
  TerminationOptionType: typeof models.TerminationOptionType = models.TerminationOptionType;
  TerminationOptionFeeType: typeof models.TerminationOptionFeeType = models.TerminationOptionFeeType;
  ExpansionOptionType: typeof models.ExpansionOptionTermType = models.ExpansionOptionTermType;
  AssignmentOrSublettingType: typeof models.AssignmentTermType = models.AssignmentTermType;

  renewalOptionTypesList: Array<ImportFormSelectItem<models.RenewalOptionTermType>>;
  terminationOptionTypesList: Array<ImportFormSelectItem<models.TerminationOptionType>>;
  terminationOptionFeeTypesList: Array<ImportFormSelectItem<models.TerminationOptionFeeType>>;
  expansionOptionTypesList: Array<ImportFormSelectItem<models.ExpansionOptionTermType>>;
  assignmentOrSublettingTypesList: Array<ImportFormSelectItem<models.AssignmentTermType>>;

  readonly form: NgForm;

  private readonly _destroy$: Subject<void>;

  constructor(form: NgForm) {
    this.form = form;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.lease = this._prepareLeaseModel();

    if (!this.leaseChange) {
      this.leaseChange = new EventEmitter<models.ILeaseViewModel>();
    }

    this._prepareDropDownOptions();

    // Reloads the dropdown options whenever the form model changes
    this.form
      .valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.leaseChange.next(this.lease);

          this._prepareDropDownOptions();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _prepareLeaseModel(): models.ILeaseViewModel {
    const lease = this.lease || <models.ILeaseViewModel>{};

    if (!this.lease.terminationOptionTerm || !this.lease.terminationOptionTerm.terminationOptionFeeType) {
      if (!this.lease.terminationOptionTerm) {
        this.lease.terminationOptionTerm = <models.ITerminationOptionTermViewModel>{};
      }

      this.lease.terminationOptionTerm.terminationOptionFeeType = models.TerminationOptionFeeType.AmortizationRate;
    }

    return lease;
  }

  private _prepareDropDownOptions(): void {
    if (!this.lease) {
      return;
    }

    this.renewalOptionTypesList = ImportFormLeaseOptionsComponent._renewalOptionTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.RenewalOptionTermType.PerCurrentLease
        );

        return option;
      });

    this.terminationOptionTypesList = ImportFormLeaseOptionsComponent._terminationOptionTypes;
    this.terminationOptionFeeTypesList = ImportFormLeaseOptionsComponent._terminationOptionFeeTypes;

    this.expansionOptionTypesList = ImportFormLeaseOptionsComponent._expansionOptionTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.ExpansionOptionTermType.PerCurrentLease
        );

        return option;
      });

    this.assignmentOrSublettingTypesList = ImportFormLeaseOptionsComponent._assignmentOrSublettingTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.AssignmentTermType.PerCurrentLease
        );

        return option;
      });
  }
}
