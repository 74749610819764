import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

@Injectable()
export class LeaseAmendmentService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  updateLeaseAmendment(leaseAmendment: models.ILeaseAmendmentFile): Observable<models.ILeaseAmendmentFile> {
    return this._httpClient
      .put<models.ILeaseAmendmentFile>(`/admin/lease-amendments`, leaseAmendment);
  }

  remapLeaseAmendment(leaseAmendment: models.ILeaseAmendmentFile): Observable<models.ILeaseAmendmentFile> {
    return this._httpClient
      .post<models.ILeaseAmendmentFile>(`/admin/lease-amendments/remap`, leaseAmendment);
  }
}
