<div id="sitePlan" class="site-plan">
  <!-- Start: Header -->
  <div class="header mb-1">
    <a role="button" class="close-cross cursor-pointer"
       (click)="close()">
      <i class="fa fa-times">
      </i>
    </a>
  </div>
  <!-- End: Header -->
  <!-- Start: Site Plan container -->
  <div class="plan-container">
    <!-- Start: Site Plan viewer -->
    <div class="plan-viewer"
         #sitePlanContainerElement>
      <ng-container *ngIf="sitePlanImage">
        <app-plan-viewer [mode]="anchorsTabIndex"
                         [image]="sitePlanImage" [scale]="planImageScale"
                         [allowChangeMarkers]="true" [isDragEnabled]="true"
                         [width]="sitePlanContainerElement.offsetWidth" [height]="sitePlanContainerElement.offsetHeight"
                         [markerColorChange]="markerColorChange$" [markerCreationEnd]="markerCreationEnd$"
                         [selectedMarkerId]="selectedAnchorId" [suggestions]="getSuggestions()"
                         (scaleChange)="handleScaleChange($event)"
                         (selectedMarkerIdChange)="handlePlanMarkerSelect($event)"
                         (markerCreated)="handlePlanMarkerCreate($event)"
                         (markerChanged)="handlePlanMarkerChange($event)"
                         (markerDeleted)="handlePlanMarkerDelete($event)"
                         (imageMarkerCreated)="handleImageMarkerCreate($event)"
                         (imageMarkerChanged)="handleImageMarkerChange($event)"
                         (imageMarkerDeleted)="handleImageMarkerDelete($event)">
        </app-plan-viewer>
      </ng-container>
    </div>
    <!-- End: Site Plan viewer -->
    <!-- Start: Right side container -->
    <div class="right-side-container ml-4">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset mt-0">
        <div class="dx-fieldset-header">
          Site Plan
        </div>
        <!-- Start: Site Plan -->
        <div class="dx-field">
          <div class="dx-field-value w-100">
            <dx-select-box name="selectedPlan" displayExpr="name"
                           [items]="plans" [(value)]="selectedPlan"
                           #planSelect>
              <div *dxTemplate="let item of 'item'">
                {{ item?.name }}
              </div>
            </dx-select-box>
          </div>
        </div>
        <!-- End: Site Plan -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset my-0">
        <ul class="tabs">
          <li *ngFor="let tab of anchorsTabItems; let index = index;"
              [class.active]="tab.id === anchorsTabIndex">
            <a href="javascript:void(0)" role="button"
               (click)="handleTabChange(tab)">
              {{tab.title}}
            </a>
          </li>
        </ul>
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Accordion -->
      <dx-scroll-view width="100%" height="100%">
        <div class="b-card">
          <div class="d-flex align-items-center justify-content-center pt-3"
               *ngIf="!popupContainerExpanded || !selectedPlan">
            <dx-load-indicator
              id="small-indicator"
              height="20"
              width="20"
            ></dx-load-indicator>
          </div>
          <div class="tab-pad pt-3 px-0"
               *ngIf="!(!popupContainerExpanded || !selectedPlan)">
            <dx-accordion class="b-collapse"
                          [collapsible]="true" [animationDuration]="300"
                          [focusStateEnabled]="false" [selectedIndex]="-1"
                          (onSelectionChanged)="handleAccordionSelectionChanged($event)"
                          #unitMarkerAccordion>
              <ng-container *ngIf="anchorsTabIndex === 0 && selectedPlan">
                <ng-container *ngFor="let anchor of unitAnchors">
                  <ng-container *ngIf="anchor.buildingUnit">
                    <dxi-item [title]="anchor.buildingUnit.name">
                      <ul class="unit-properties">
                        <!-- Start: Unit size -->
                        <li *ngIf="anchor.buildingUnit.size !== null">
                          <div class="b-fancy-item">
                            <div class="b-fancy-item-title">
                              Size
                            </div>
                            <div class="b-fancy-item-body">
                              {{anchor.buildingUnit.size | safeNumber}} SF
                            </div>
                          </div>
                        </li>
                        <!-- End: Unit size -->
                        <!-- Start: Unit office size -->
                        <li *ngIf="anchor.buildingUnit.totalOfficeArea !== null">
                          <div class="b-fancy-item">
                            <div class="b-fancy-item-title">
                              Office Size
                            </div>
                            <div class="b-fancy-item-body">
                              {{anchor.buildingUnit.totalOfficeArea | safeNumber}} SF
                            </div>
                          </div>
                        </li>
                        <!-- End: Unit office size -->
                        <!-- Start: Unit loading -->
                        <li *ngIf="anchor.buildingUnit.totalDocksInterior !== null ||
                           anchor.buildingUnit.totalDocksExterior !== null">
                          <div class="b-fancy-item">
                            <div class="b-fancy-item-title">
                              Loading
                            </div>
                            <div class="b-fancy-item-body">
                              <ng-container *ngIf="anchor.buildingUnit.totalDocksInterior &&
                                           !anchor.buildingUnit.totalDocksExterior">
                                {{ anchor.buildingUnit.totalDocksInterior | safeNumber }} Interior
                              </ng-container>
                              <ng-container *ngIf="!anchor.buildingUnit.totalDocksInterior &&
                                           anchor.buildingUnit.totalDocksExterior">
                                {{ anchor.buildingUnit.totalDocksExterior | safeNumber }} Exterior
                              </ng-container>
                              <ng-container *ngIf="anchor.buildingUnit.totalDocksInterior &&
                                           anchor.buildingUnit.totalDocksExterior">
                                {{ anchor.buildingUnit.totalDocksInterior | safeNumber }} Interior /
                                {{ anchor.buildingUnit.totalDocksExterior | safeNumber }} Exterior
                              </ng-container>
                            </div>
                          </div>
                        </li>
                        <!-- End: Unit loading -->
                        <!-- Start: Unit power -->
                        <li *ngIf="anchor.buildingUnit.amps !== null ||
                           anchor.buildingUnit.volts !== null ||
                           anchor.buildingUnit.phase !== null">
                          <div class="b-fancy-item">
                            <div class="b-fancy-item-title">
                              Power
                            </div>
                            <div class="b-fancy-item-body">
                              <ng-container *ngIf="anchor.buildingUnit.amps !== null">
                                {{anchor.buildingUnit.amps}} A,
                              </ng-container>
                              <ng-container *ngIf="anchor.buildingUnit.volts !== null">
                                {{anchor.buildingUnit.volts}} V,
                              </ng-container>
                              <ng-container *ngIf="anchor.buildingUnit.phase !== null">
                                {{anchor.buildingUnit.phase}} P
                              </ng-container>
                            </div>
                          </div>
                        </li>
                        <!-- End: Unit power -->
                        <!-- Start: Unit columns -->
                        <li *ngIf="anchor.buildingUnit.columnSpacing !== null">
                          <div class="b-fancy-item">
                            <div class="b-fancy-item-title">
                              Columns
                            </div>
                            <div class="b-fancy-item-body">
                              {{anchor.buildingUnit.columnSpacing}}
                            </div>
                          </div>
                        </li>
                        <!-- End: Unit columns -->
                        <!-- Start: Unit clear height -->
                        <li *ngIf="anchor.buildingUnit.clearHeight !== null">
                          <div class="b-fancy-item">
                            <div class="b-fancy-item-title">
                              Clear Height
                            </div>
                            <div class="b-fancy-item-body">
                              {{ anchor.buildingUnit.clearHeight }}' clear
                            </div>
                          </div>
                        </li>
                        <!-- End: Unit clear height -->
                      </ul>
                    </dxi-item>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="anchorsTabIndex === 1 && selectedPlan">
                <ng-container *ngFor="let anchor of markerAnchors">
                  <dxi-item [title]="anchor.name">
                    <i>{{anchor.description}}</i><br/><br/>
                    Area: {{buildingAreas[anchor.buildingArea]}}
                  </dxi-item>
                </ng-container>
              </ng-container>
            </dx-accordion>
          </div>
        </div>
      </dx-scroll-view>
      <!-- End: Accordion -->
    </div>
    <!-- End: Right side container -->
  </div>
  <!-- End: Site Plan container -->
  <!-- Start: Footer -->
  <div class="footer">
    <p *ngIf="planImageScale">
      Zoom: {{planImageScale | number: '1.0-0'}}%
    </p>
    <dx-button text="Confirm" type="success"
               (onClick)="close()">
    </dx-button>
  </div>
  <!-- End: Footer -->
</div>
