import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';

import { RoleNames } from '../../../../shared/models/roles';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';
import { UserService } from '../../../user/services/user.service';
import { LeaseAmendmentService } from '../../services/lease-amendment.service';
import {
  EditEversignLeaseAmendmentDialogComponent
} from '../edit-eversign-lease-amendment-dialog/edit-eversign-lease-amendment-dialog.component';

@Component({
  templateUrl: 'edit-lease-amendment-dialog.component.html',
  styleUrls: ['edit-lease-amendment-dialog.component.scss'],
})
export class EditLeaseAmendmentDialogComponent implements OnInit, OnDestroy {
  leaseAmendment: models.ILeaseAmendmentFile;

  landlordsList$: Observable<Array<models.IProfileViewModel>>;
  tenantsList$: Observable<Array<models.IProfileViewModel>>;

  userTypes = [
    {
      name: 'Internal',
      value: false,
    },
    {
      name: 'External',
      value: true,
    },
  ];

  readonly reloadLeaseAmendmentList: () => Promise<void>;

  private readonly _leaseAmendmentService: LeaseAmendmentService;
  private readonly _userService: UserService;
  private readonly _popupService: PopupService;
  private readonly _popupRefService: PopupRefService;
  private readonly _destroy: Subject<void>;

  constructor(
    leaseAmendmentService: LeaseAmendmentService,
    userService: UserService,
    popupService: PopupService,
    popupRefService: PopupRefService
  ) {
    this._leaseAmendmentService = leaseAmendmentService;
    this._userService = userService;
    this._popupService = popupService;
    this._popupRefService = popupRefService;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.leaseAmendment = this.leaseAmendment || <models.ILeaseAmendmentFile>{};

    this.landlordsList$ = this._userService.getUsersByRoleName(RoleNames.Landlord);
    this.tenantsList$ = this._userService.getUsersByRoleName(RoleNames.Tenant);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  setupSearchableUserFields(profile: models.IProfileViewModel): Array<string> {
    const searchableFields = [
      profile.firstName,
      profile.lastName,
    ];

    if (profile.company && profile.company.name) {
      searchableFields.push(profile.company.name);
    }

    return searchableFields;
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._leaseAmendmentService
      .updateLeaseAmendment({...this.leaseAmendment})
      .pipe(
        tap(generatedLeaseAmendment => {
          this._popupService.show(EditEversignLeaseAmendmentDialogComponent, {
            title: 'Prepare clean copy',
            width: 1100,
            maxHeight: '90%',
            showCloseButton: true,
            closeOnOutsideClick: false,
            injectableData: {
              leaseAmendment: generatedLeaseAmendment,
              reloadLeaseAmendmentList: async () => await this.reloadLeaseAmendmentList(),
            },
          });
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  cancel(): void {
    this._popupRefService.hide();
  }
}
