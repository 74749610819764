import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import CustomStore from 'devextreme/data/custom_store';

import * as models from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

import { Tools } from '../../../../shared/tools';
import { PopupService } from '../../../infrastructure/services/popup.service';

import { EditLeaseSignatureDialogComponent } from '../edit-lease-signature-dialog/edit-lease-signature-dialog.component';

@Component({
  templateUrl: 'lease-signature-list.component.html',
  styleUrls: ['lease-signature-list.component.scss'],
})
export class LeaseSignatureListComponent implements OnInit {
  private static readonly _leaseSignatureListUrl = `${environment.adminUrl}/lease-signatures`;
  private static readonly _usersUrl = `${environment.adminUrl}/User/Get`;
  private static readonly _leasesUrl = `${environment.adminUrl}/Leases/shortLeaseModels`;

  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: CustomStore;
  userDataSource: CustomStore;
  leaseDataSource: CustomStore;

  Tools = Tools;

  private readonly _popupService: PopupService;

  constructor(popupService: PopupService) {
    this._popupService = popupService;

    this.calculateIsSignedByLandlord = this.calculateIsSignedByLandlord.bind(this);
    this.calculateIsSignedByTenant = this.calculateIsSignedByTenant.bind(this);
    this.editLeaseSignature = this.editLeaseSignature.bind(this);
  }

  ngOnInit(): void {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: LeaseSignatureListComponent._leaseSignatureListUrl,
      updateUrl: LeaseSignatureListComponent._leaseSignatureListUrl,
    });

    this.userDataSource = AspNetData.createStore({
      loadUrl: LeaseSignatureListComponent._usersUrl,
    });

    this.leaseDataSource = AspNetData.createStore({
      loadUrl: LeaseSignatureListComponent._leasesUrl,
    });
  }

  calculateIsSignedByLandlord(leaseSignature: models.ILeaseSignature): boolean {
    return leaseSignature && !!leaseSignature.landlordSignDate;
  }

  calculateIsSignedByTenant(leaseSignature: models.ILeaseSignature): boolean {
    return leaseSignature && !!leaseSignature.tenantSignDate;
  }

  editLeaseSignature(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._editLeaseSignature(event.row.data);
  }

  downloadLeaseSignatureDocument(event): void {
    if (!event || !event.row || !event.row.data || !event.row.data.document || !event.row.data.document.url) {
      return;
    }

    window.open(event.row.data.document.url);
  }

  canShowDownload(event): boolean {
    return event && event.row && event.row.data && event.row.data.document && event.row.data.document.url;
  }

  private _editLeaseSignature(leaseSignature: models.ILeaseSignature): void {
    this._popupService.show(EditLeaseSignatureDialogComponent, {
      title: 'Edit signature',
      width: 750,
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      injectableData: {
        leaseSignature: leaseSignature,
        reloadLeaseSignatureList: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      }
    });
  }
}
