import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from '../../../shared/models/generated';

@Injectable()
export class UserService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  /**
   * Requests and returns the list of users with the specified role name
   * @method UserService.getUsersByRoleName
   * @returns The list of users
   */
  getUsersByRoleName(roleName: string): Observable<Array<models.IProfileViewModel>> {
    const params = new HttpParams().set('roleName', roleName);

    return this._httpClient
      .get<Array<models.IProfileViewModel>>(`/admin/users/with-role-name`, {params});
  }

  /**
   * Sends email verification message to the specified user
   * @method UserService.sendEmailVerificationMessage
   * @param userId User identifier
   * @returns Observable object
   */
  sendEmailVerificationMessage(userId: number): Observable<void> {
    let params = new HttpParams();

    if (userId) {
      params = params.set('userId', userId.toString(10));
    }

    return this._httpClient
      .get<void>(`/admin/users/send-email-verification-message`, {params});
  }
}
