<h2 class="content-block">Projects</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              [remoteOperations]="true" [masterDetail]="{ enabled: true, template: 'detail' }"
              (onEditorPreparing)="onEditorPreparing($event)" (onRowExpanding)="onRowExpanding($event)"
              (onRowPrepared)="onRowPrepared($event)">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="70" dataType="number"></dxi-column>
  <dxi-column dataField="name"></dxi-column>

  <dxi-column dataField="projectTypeId">
    <dxo-lookup [dataSource]="projectTypes" valueExpr="id" displayExpr="name"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="leaseId" cellTemplate="leaseFieldTemplate">
    <dxo-lookup [dataSource]="leases" valueExpr="leaseId" [displayExpr]="tools.shortLeaseNameExpression"></dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'leaseFieldTemplate'" class="lookup-user-field">
    <div>{{ tools.leaseNameExpression(item.data.lease) }}</div>
  </div>

  <dxi-column dataField="projectStateId">
    <dxo-lookup [dataSource]="renewalProjectTemplateItemsDataSource" valueExpr="id" displayExpr="name"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="projectStatus" dataType="number" caption="Project Status">
    <dxo-lookup [dataSource]="ProjectStatuses" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="projectRenewalRejectionStatus" dataType="number" caption="Project Renewal Rejection Status">
    <dxo-lookup [dataSource]="ProjectRenewalRejectionStatuses" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="startDate" dataType="date"></dxi-column>
  <dxi-column dataField="endDate" dataType="date"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit">
    </dxi-button>
    <dxi-button name="delete"
                [visible]="isEditButtonVisible">
    </dxi-button>
  </dxi-column>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup
      title="Add or edit project"
      [showTitle]="true"
      width="600"
      height="480"
      [position]="{ my: 'center', at: 'center', of: _window }"
    >
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="name"></dxi-item>
      <dxi-item dataField="projectTypeId" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="leaseId" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="projectStateId" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="projectStatus" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="projectRenewalRejectionStatus" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ showClearButton: true }"></dxi-item>
    </dxo-form>
  </dxo-editing>

  <div *dxTemplate="let item of 'detail'" class="flex-container">
    <dx-chart
      [dataSource]="item.data.milestones"
      [rotated]="true"
      (onInitialized)="chartInitialized($event)"
      [customizePoint]="customizePoint"
      style=" font-family: 'Montserrat' !important "
    >
      <dxo-legend [visible]="false"></dxo-legend>
      <dxi-series type="stackedbar" argumentField="name" valueField="start" color="transparent"></dxi-series>
      <dxi-series
        name="gantt"
        type="stackedbar"
        argumentField="name"
        valueField="duration"
        color="#1f3ce0"
        [tag]="item.data.projectStateId"
      ></dxi-series>
      <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"> </dxo-tooltip>
    </dx-chart>
  </div>
</dx-data-grid>
