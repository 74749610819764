<h2 class="content-block">Site Plans</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  (onToolbarPreparing)="prepareToolbar($event)"
  #dxDataGrid
>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup> </dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="70" dataType="number"></dxi-column>

  <dxi-column
    dataField="picture"
    title="Picture"
    [width]="80"
    cellTemplate="pictureCellTemplate"
  ></dxi-column>
  <div *dxTemplate="let item of 'pictureCellTemplate'" class="site-plan-cell">
    <img [src]="item.value?.thumbnailUrl" />
  </div>

  <dxi-column dataField="name"></dxi-column>

  <dxi-column dataField="buildingId" caption="Building" cellTemplate="buildingCellTemplate">
    <dxo-lookup [dataSource]="buildingsDataSource" valueExpr="id" displayExpr="name" itemTemplate="buildingTemplate">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'buildingCellTemplate'" class="building-cell">
    <img src="{{ Tools.buildingPictureThumbnail(item.data?.building) || Tools.noImageUrl }}" />
    <div class="building-cell-label">{{ item.data.building?.name }}</div>
  </div>

  <dxi-column dataField="building.portfolio.id" caption="Portfolio">
    <dxo-lookup [dataSource]="portfolioItems" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit-custom" text="Edit"
                [onClick]="showPlanUpsertPopup">
    </dxi-button>
    <dxi-button name="edit-custom" text="Manage"
                [onClick]="showPlanManagePopup">
    </dxi-button>
    <dxi-button name="delete">
    </dxi-button>
  </dxi-column>

  <dxo-editing mode="popup" [allowDeleting]="true">
  </dxo-editing>
</dx-data-grid>
