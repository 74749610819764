import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { environment } from 'src/environments/environment';

import { SelectItem } from './models/select-item.model';

import * as models from './models/generated';

export class Tools {
  static noAvatarUrl = 'assets/img/avatar.png';
  static noImageUrl = 'assets/img/nofoto.png';

  static StringIsNumber = value => isNaN(Number(value)) === false;

  static EnumToArray(theEnum): SelectItem[] {
    return Object.keys(theEnum)
      .filter(this.StringIsNumber)
      .map((key: any) => {
        return {
          id: Number(key),
          text: theEnum[key].replace(/_/g, ' ')
        } as SelectItem;
      });
  }

  static SelectItemsToDictionary(items: SelectItem[]): any[] {
    const result = [];
    for (const item of items) {
      result[item.id] = item.text;
    }
    return result;
  }

  static isNumeric(val: any): boolean {
    return !(val instanceof Array) && (val - parseFloat(val) + 1) >= 0;
  }

  static lookupDataSource(controllerName: string, options: {key?: string, onLoaded?: (result: Array<any>) => void} = {}) {
    const defaultOptions = {
      key: 'id',
      onLoaded: result => {},
    };

    options = {...defaultOptions, ...options};

    return AspNetData.createStore({
      key: options.key,
      loadUrl: `${environment.adminUrl}/${controllerName}`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
      onLoaded: result => options.onLoaded(result),
    });
  }

  static lookupUserDataSource(role) {
    return AspNetData.createStore({
      key: 'userId',
      loadUrl: `${environment.adminUrl}/users/${role}`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      }
    });
  }

  static lookupUserTemplate(data, cls) {
    return data
      ? `<div class='${cls}'>
        <img src='${data.avatar ? data.avatar.url : Tools.noAvatarUrl}' />
        <div>${data.firstName} ${data.lastName}</div>
      </div>`
      : '';
  }

  static getCompanyExpression(item) {
    if (!item) {
      return null;
    }
    return item.name;
  }

  static getDisplayExpression(item) {
    return item && item.firstName + ' ' + item.lastName;
  }

  static getCompanyDisplayExpression(item) {
    return item && item.name;
  }

  static getUserDisplayName(item, type) {
    if (item && item.data && item.data[type] && item.data[type].profile) {
      return `${item.data[type].profile.firstName} ${item.data[type].profile.lastName}`;
    } else if (item.firstName || item.lastName) {
      return `${item.firstName} ${item.lastName}`;
    }
    return '';
  }

  static getCompanyDisplayName(item, type) {
    if (item && item.data && item.data[type]) {
      return item.data[type].name;
    }

    return '';
  }

  static getUserAvatar(item, type) {
    if (item && item.avatar) {
      return item.avatar.url;
    } else if (
      item &&
      item.data &&
      item.data[type] &&
      item.data[type].profile &&
      item.data[type].profile.avatar
    ) {
      return item.data[type].profile.avatar.url;
    }
    return this.noAvatarUrl;
  }

  static fakeTemplate(data) {
    return JSON.stringify(data);
  }

  static portfolioNameExpression(portfolio: models.IPortfolio): string {
    if (!portfolio) {
      return '';
    }
    return `portfolio id: ${portfolio.id}, name: ${portfolio.name}`;
  }

  static buildingNameExpression(building: models.IBuilding) {
    if (!building) {
      return '';
    }
    const address = building.address ? building.address.name || building.address.addressLine1 : '-';
    return `building id: ${building.id}, name: ${building.name}, address: ${address}`;
  }

  static buildingPictureThumbnail(building: models.IBuilding | models.IBuildingViewModel): string {
    if (!building || !building.attachments) {
      return Tools.noImageUrl;
    }
    // @ts-ignore
    const pictureAttachments = building.attachments.filter(x => x.buildingAttachmentType === models.BuildingAttachmentType.Picture);
    if (!pictureAttachments || !pictureAttachments[0] || !pictureAttachments[0].file || !pictureAttachments[0].file.thumbnailUrl) {
      return Tools.noImageUrl;
    }

    return pictureAttachments[0].file.thumbnailUrl || Tools.noImageUrl;
  }

  static buildingUnitPictureThumbnail(buildingUnit: models.IBuildingUnit | models.IBuildingUnitViewModel): string {
    if (!buildingUnit || !buildingUnit.attachments) {
      return Tools.noImageUrl;
    }
    // @ts-ignore
    const pictureAttachments = buildingUnit.attachments.filter(x => x.buildingAttachmentType === models.BuildingAttachmentType.Picture);
    if (!pictureAttachments || !pictureAttachments[0] || !pictureAttachments[0].file || !pictureAttachments[0].file.thumbnailUrl) {
      return Tools.noImageUrl;
    }

    return pictureAttachments[0].file.thumbnailUrl || Tools.noImageUrl;
  }

  static leaseNameExpression(item: models.ILease) {
    const expressionParts = [];

    if (item && item.id) {
      expressionParts.push(`lease id: ${item.id}`);
    }

    if (item && item.building && item.building.name) {
      expressionParts.push(item.building.name);
    }

    if (item && item.buildingUnit && item.buildingUnit.name) {
      expressionParts.push(item.buildingUnit.name);
    }

    const landlordName =
      item && item.landlordCompany && item.landlordCompany.name
        ? `${item.landlordCompany.name}`
        : '';

    const tenantName =
      item && item.tenantCompany && item.tenantCompany.name
        ? `${item.tenantCompany.name}`
        : '';

    if (landlordName && tenantName) {
      expressionParts.push([landlordName, tenantName].join(' - '));
    }

    return expressionParts.join(', ');
  }

  static shortLeaseNameExpression(item: models.IShortLeaseViewModel) {
    const leaseId = item ? `lease id: ${item.leaseId}` : '';
    const buildingName = item && item.buildingName || '';

    const landlordName =
      item && item.landlordCompanyName || '';

    const tenantName =
      item && item.tenantCompanyName || '';

    return leaseId || buildingName || landlordName || tenantName
      ? `${leaseId}, ${buildingName}, ${landlordName} - ${tenantName}`
      : leaseId;
  }

  static leaseVmNameExpression(item: models.ILeaseViewModel): string {
    if (!item) {
      return null;
    }

    const leaseNameSegments = [
      item.id && `lease id: ${item.id}`,
      item.building && item.building.name,
    ];

    const tenantLandlordRelationSegments = [
      item.landlordCompany && item.landlordCompany.name,
      item.tenantCompany && item.tenantCompany.name,
    ];

    return leaseNameSegments
      .concat(
        tenantLandlordRelationSegments
          .filter(Boolean)
          .join(' - ')
      )
      .filter(Boolean)
      .join(', ');
  }

  static downloadFile(fileUrl: string): void {
    const iframe = document.createElement('iframe');
    if (!iframe) {
      return;
    }

    iframe.style.visibility = 'hidden';
    iframe.style.display = 'none';

    iframe.src = fileUrl;

    document.body.append(iframe);
  }
}
