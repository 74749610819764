<h2 class="content-block">Market Rental Rate</h2>

<dx-data-grid class="dx-card wide-card" [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true" [remoteOperations]="true">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup> </dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="marketId" caption="SubMarket" [editorOptions]="marketEditorOptions">
    <dxo-lookup [dataSource]="subMarkets" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="squareFootageRangeId" caption="Square Footage" [editorOptions]="rangeEditorOptions">
    <dxo-lookup [dataSource]="ranges" valueExpr="id" [displayExpr]="buildRangeDisplayName"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="avgRate" dataType="number" caption="Avg Rate" [editorOptions]="{ min: 0, format: '$ #,##0.##' }"></dxi-column>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup title="Add or edit Market Rental Rate" [showTitle]="true" width="95%" [maxWidth]="600" [position]="{ my: 'center', at: 'center', of: _window }">
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="marketId" isRequired="true"></dxi-item>
      <dxi-item dataField="squareFootageRangeId" isRequired="true"></dxi-item>

      <dxi-item dataField="avgRate" [isRequired]="true"></dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
