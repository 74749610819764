import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

import { environment } from '../../../../environments/environment';

@Injectable()
export class InvitationService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  inviteExistingUser(userInvitation: models.IUserInvitationViewModel): Observable<void> {
    return this._httpClient.post<void>(`${environment.adminUrl}/invitation/invite-existing-user`, userInvitation);
  }
}
