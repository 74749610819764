<h2 class="content-block">Companies</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  (onInitNewRow)="initNewRow($event)"
  (onEditingStart)="editingStart($event)"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="50"></dxi-column>
  <dxi-column
    dataField="logo"
    title="Picture"
    [width]="80"
    cellTemplate="pictureCellTemplate"
  ></dxi-column>
  <div *dxTemplate="let item of 'pictureCellTemplate'" class="company-logo-cell">
    <img *ngIf="item.value && item.value.thumbnailUrl" [src]="item.value?.thumbnailUrl" />
  </div>
  <dxi-column dataField="name"></dxi-column>
  <dxi-column dataField="companyType">
    <dxo-lookup [dataSource]="companyTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="phone"></dxi-column>
  <dxi-column dataField="homeMarket"></dxi-column>
  <dxi-column dataField="otherMarkets"></dxi-column>
  <dxi-column dataField="webSite"></dxi-column>
  <dxi-column dataField="address"></dxi-column>
  <dxi-column dataField="industryId" dataType="number"  caption="Industry">
    <dxo-lookup [dataSource]="industryItems" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="primaryAddress.addressLine1"></dxi-column>
  <dxi-column dataField="primaryAddress.addressLine2"></dxi-column>
  <dxi-column dataField="primaryAddress.zipCode"></dxi-column>
  <dxi-column dataField="primaryAddress.city"></dxi-column>
  <dxi-column dataField="primaryAddress.countryCode">
    <dxo-lookup [dataSource]="countries" valueExpr="countryCode" displayExpr="name"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="primaryAddress.stateCode">
    <dxo-lookup [dataSource]="states" valueExpr="stateCode" displayExpr="name"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="otherAddress.addressLine1"></dxi-column>
  <dxi-column dataField="otherAddress.addressLine2"></dxi-column>
  <dxi-column dataField="otherAddress.zipCode"></dxi-column>
  <dxi-column dataField="otherAddress.city"></dxi-column>
  <dxi-column dataField="otherAddress.countryCode">
    <dxo-lookup [dataSource]="countries" valueExpr="countryCode" displayExpr="name"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="otherAddress.stateCode">
    <dxo-lookup [dataSource]="states" valueExpr="stateCode" displayExpr="name"> </dxo-lookup>
  </dxi-column>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup
      title="Add Company"
      [showTitle]="true"
      [position]="{ my: 'center', at: 'center', of: _window }"
      width="70%" height="90%"
    >
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item itemType="tabbed" [tabPanelOptions]="{ deferRendering: false }">
        <dxi-tab title="Main properties">
          <dxi-item itemType="group" caption="Main properties">
            <dxi-item dataField="name" isRequired="true"></dxi-item>
            <dxi-item dataField="companyType" isRequired="true"></dxi-item>
            <dxi-item dataField="phone" [editorOptions]="phoneNumberFieldEditorOptions"></dxi-item>
            <dxi-item dataField="homeMarket"></dxi-item>
            <dxi-item dataField="otherMarkets"></dxi-item>
            <dxi-item dataField="webSite"></dxi-item>
            <dxi-item dataField="address"></dxi-item>
            <dxi-item dataField="industryId" [editorOptions]="{ showClearButton: true }"></dxi-item>
          </dxi-item>
          <dxi-item itemType="group" caption="Picture">
            <dxi-item [template]="uploaderTemplate"></dxi-item>
            <div *dxTemplate="let item of 'uploaderTemplate'" class="d-flex flex-row">
              <img [src]="currentPicture.thumbnailUrl" width="64" height="64" />
              <div class="pl-3">
                <dx-button text="Upload picture" (onClick)="popupVisible = true"></dx-button>
              </div>
            </div>
          </dxi-item>
          <dxi-item dataField="logo" [editorOptions]="pictureEditorOptions">
            <dxo-label [visible]="false"></dxo-label>
          </dxi-item>
        </dxi-tab>
        <dxi-tab title="Address">
          <dxi-item dataField="primaryAddress.addressLine1" isRequired="true"></dxi-item>
          <dxi-item dataField="primaryAddress.addressLine2"></dxi-item>
          <dxi-item dataField="primaryAddress.zipCode" isRequired="true" [editorOptions]="{  maxLength: 8  }"></dxi-item>
          <dxi-item dataField="primaryAddress.city" isRequired="true"></dxi-item>
          <dxi-item dataField="primaryAddress.countryCode" isRequired="true" [editorOptions]="{ showClearButton: true }"></dxi-item>
          <dxi-item dataField="primaryAddress.stateCode" isRequired="true" [editorOptions]="{ showClearButton: true }"></dxi-item>
        </dxi-tab>
        <dxi-tab title="Other Address">
          <dxi-item dataField="otherAddress.addressLine1"></dxi-item>
          <dxi-item dataField="otherAddress.addressLine2"></dxi-item>
          <dxi-item dataField="otherAddress.zipCode" [editorOptions]="{  maxLength: 8  }"></dxi-item>
          <dxi-item dataField="otherAddress.city"></dxi-item>
          <dxi-item dataField="otherAddress.countryCode" [editorOptions]="{ showClearButton: true }"></dxi-item>
          <dxi-item dataField="otherAddress.stateCode" [editorOptions]="{ showClearButton: true }"></dxi-item>
        </dxi-tab>
      </dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>

<dx-popup
  class="popup"
  [width]="400"
  [height]="250"
  [showTitle]="true"
  title="Upload picture"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupVisible"
>
  <div *dxTemplate="let data of 'content'">
    <dx-file-uploader
      #fileUploader
      id="file"
      name="file"
      [uploadUrl]="uploadUrl"
      (onUploaded)="onUploaded($event)"
    >
    </dx-file-uploader>
  </div>
</dx-popup>

