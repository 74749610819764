import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';
import { IProfileViewModel } from 'src/app/shared/models/generated';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  profile = {} as IProfileViewModel;

  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.profileService.load().subscribe(x => this.profile = x);
  }

  onSubmit() {
    this.profileService.saveName(this.profile).subscribe(() => {
      this.authService.startupInfo.displayName = `${this.profile.firstName} ${this.profile.lastName}`.trim();
      this.toast.success('Your profile was successfully saved');
    });
  }

  getAvatarUrl(): string {
    return this.authService?.startupInfo?.avatarUrl ?? this.authService?.noAvatarUrl;
  }
}
