import { Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { AuthService, AppInfoService } from '../../services';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { ILoginViewModel } from '../../models/generated';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { DxFormModule } from 'devextreme-angular';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  private readonly _appInfo: AppInfoService;
  private readonly _authService: AuthService;
  private readonly _router: Router;

  @ViewChild('signInForm', {static: false}) signInForm: ElementRef;

  signInUrl = `${environment.adminUrl}/authentication/signin`;
  submitted = false;
  vm = <ILoginViewModel>{};

  errorMessage: string;

  constructor(appInfo: AppInfoService, authService: AuthService, router: Router) {
    this._appInfo = appInfo;
    this._authService = authService;
    this._router = router;
  }

  ngOnInit(): void {
    this._authService
      .infoLoadComplete
      .subscribe(() => {
        this._router.navigate(['/']);
      });
  }

  onLoginFormSubmit() {
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    this.errorMessage = null;

    const subscription = this._authService
      .logIn(this.vm)
      .subscribe(
        () => {
          this.signInForm.nativeElement.submit();
        },
        (err) => {
          this.errorMessage = err.error || err.message || err;
        }
      )
      .add(() => {
        this.submitted = false;
        subscription.unsubscribe();
      });
  }
}

@NgModule({
  imports: [
    FormsModule,
    DxFormModule,
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
})
export class LoginFormModule {
}
