import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as models from '../../../shared/models/generated';

@Injectable()
export class LeaseTemplateService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  uploadLeaseTemplate(landlordCompanyId: number, template: File): Observable<models.ILeaseTemplateViewModel> {
    const body = new FormData();

    body.append('landlordCompanyId', landlordCompanyId.toString(10));
    body.append('template', template);

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this._httpClient
      .post<models.ILeaseTemplateViewModel>(`/admin/lease-templates`, body, {headers})
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
