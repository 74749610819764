import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SkipSelf } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';

import { PopupService } from '../../../infrastructure/services/popup.service';

import { BaseRentalRateCustomTableEditorComponent } from '../../../term/components/base-rental-rate-custom-table-editor/base-rental-rate-custom-table-editor.component';
import { RentalRateEscalationCustomTableEditorComponent } from '../../../term/components/rental-rate-escalation-custom-table-editor/rental-rate-escalation-custom-table-editor.component';
import { DocumentViewerOptions } from '../../models/document-viewer-options.model';

import { ImportFormSelectItem } from '../../models/import-form-select-item.model';
import { LeaseAbstractStore } from '../../services/lease-abstract.store';

//
// NOTE: ImportFormMainTermsComponent component is used as nested form.
//       Before using, please make sure the ControlContainer is provided.
//

@Component({
  templateUrl: 'import-form-main-terms.component.html',
  styleUrls: ['import-form-main-terms.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class ImportFormMainTermsComponent implements OnInit, OnDestroy {
  private static readonly _tenantSquareFootageTypes: Array<ImportFormSelectItem<models.TenantSquareFootageTermType>> = [
    {
      name: 'Existing',
      value: models.TenantSquareFootageTermType.ExistingSquareFootage,
    },
    {
      name: 'Custom',
      value: models.TenantSquareFootageTermType.Custom,
    }
  ];

  private static readonly _baseRentalRateTypes: Array<ImportFormSelectItem<models.BaseRentalRateType>> = [
    {
      name: 'None',
      value: models.BaseRentalRateType.None,
    },
    {
      name: 'Net',
      value: models.BaseRentalRateType.Net,
    },
    {
      name: 'Gross',
      value: models.BaseRentalRateType.Gross,
    },
    {
      name: 'Modified gross',
      value: models.BaseRentalRateType.BaseYear,
    },
  ];

  private static readonly _baseRentalRateUnitMetrics: Array<ImportFormSelectItem<models.BaseRentalRateUnitMetrics>> = [
    {
      name: 'PSF/Yr',
      value: models.BaseRentalRateUnitMetrics.PsfPerYear,
    },
    {
      name: 'PSF/Mo',
      value: models.BaseRentalRateUnitMetrics.PsfPerMonth,
    },
  ];

  private static readonly _freeRentTypes: Array<ImportFormSelectItem<models.FreeRentTermType>> = [
    {
      name: 'None',
      value: models.FreeRentTermType.None,
    },
    {
      name: 'Months at beginning of commencement date',
      value: models.FreeRentTermType.MonthsCount,
    },
    {
      name: 'Specific schedule',
      value: models.FreeRentTermType.SpecificSchedule,
    },
  ];

  private static readonly _freeRentTaxesTypes: Array<ImportFormSelectItem<models.FreeRentTaxesType>> = [
    {
      name: 'Net',
      value: models.FreeRentTaxesType.Net,
    },
    {
      name: 'Gross',
      value: models.FreeRentTaxesType.Gross,
    },
  ];

  private static readonly _securityDepositTypes: Array<ImportFormSelectItem<models.SecurityDepositTermType>> = [
    {
      name: 'Per current lease',
      value: models.SecurityDepositTermType.PerCurrentLease,
    },
    {
      name: 'Months number per base rental rate',
      value: models.SecurityDepositTermType.MonthsCount,
    },
    {
      name: 'Reduce security deposit',
      value: models.SecurityDepositTermType.ReducingValue,
    },
    {
      name: 'Letter of credit',
      value: models.SecurityDepositTermType.LetterOfCredit,
    },
    {
      name: 'Guaranty',
      value: models.SecurityDepositTermType.Guaranty,
    },
    {
      name: 'Custom',
      value: models.SecurityDepositTermType.MoneyValue,
    },
  ];

  private static readonly _securityDepositBeginningOrEndMonthsList: Array<ImportFormSelectItem<models.SecurityDepositMonths>> = [
    {
      name: 'Beginning of Term',
      value: models.SecurityDepositMonths.BeginningOfTerm,
    },
    {
      name: 'End of Term',
      value: models.SecurityDepositMonths.EndOfTerm,
    },
  ];

  private static readonly _securityDepositGuarantyTypes: Array<ImportFormSelectItem<models.SecurityGuarantyOptionType>> = [
    {
      name: 'Personal',
      value: models.SecurityGuarantyOptionType.Personal,
    },
    {
      name: 'Corporate',
      value: models.SecurityGuarantyOptionType.Corporate,
    },
  ];

  private static readonly _realEstateTaxesAndCamExpensesTypes: Array<ImportFormSelectItem<models.RealEstateTaxesCamExpensesTermType>> = [
    {
      name: 'Per current lease',
      value: models.RealEstateTaxesCamExpensesTermType.PerCurrentLease,
    },
    {
      name: 'Limited percentage per year',
      value: models.RealEstateTaxesCamExpensesTermType.PercentagePerYear,
    },
    {
      name: 'Retained by tenant',
      value: models.RealEstateTaxesCamExpensesTermType.RetainingByTenant,
    },
    {
      name: 'Tenant pay its pro-rata',
      value: models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfExpenses,
    },
    {
      name: 'All taxes & op. expenses are included in gross rental rate',
      value: models.RealEstateTaxesCamExpensesTermType.ReOpExIncludedInGross,
    },
    {
      name: 'Custom',
      value: models.RealEstateTaxesCamExpensesTermType.Custom,
    },
  ];

  private static readonly _realEstateTaxesAndOpExpensesCumulativeTypes:
    Array<ImportFormSelectItem<models.RealEstateTaxesCamExpensesCumulativeType>> = [
    {
      name: 'Cumulative',
      value: models.RealEstateTaxesCamExpensesCumulativeType.Cumulative,
    },
    {
      name: 'Non-cumulative',
      value: models.RealEstateTaxesCamExpensesCumulativeType.NonCumulative,
    },
  ];

  private static readonly _realEstateTaxesAndOpExpensesReTaxesTypes: Array<ImportFormSelectItem<models.RealEstateTaxesType>> = [
    {
      name: 'Estimate',
      value: models.RealEstateTaxesType.Estimate,
    },
    {
      name: 'Stop',
      value: models.RealEstateTaxesType.Stop,
    },
  ];

  private static readonly _realEstateTaxesAndOpExpensesOpExpensesTypes: Array<ImportFormSelectItem<models.OperatingExpensesType>> = [
    {
      name: 'Estimate',
      value: models.OperatingExpensesType.Estimate,
    },
    {
      name: 'Stop',
      value: models.OperatingExpensesType.Stop,
    },
  ];

  private static readonly _rentalRateEscalationTypes: Array<ImportFormSelectItem<models.EscalationTermType>> = [
    {
      name: 'None',
      value: models.EscalationTermType.None,
    },
    {
      name: 'Fixed percentage per year',
      value: models.EscalationTermType.FixedPercentagePerYear,
    },
    {
      name: 'Fixed amount per year',
      value: models.EscalationTermType.FixedAmountPsfPerYear,
    },
    {
      name: 'Custom',
      value: models.EscalationTermType.Custom,
    },
  ];

  @Input() lease: models.ILeaseViewModel;
  @Output() leaseChange: EventEmitter<models.ILeaseViewModel>;

  @Input() leasePreview: models.ILeaseViewModel;

  @Input() documentViewerOptions: DocumentViewerOptions;
  @Input() documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  TenantSquareFootageType: typeof models.TenantSquareFootageTermType = models.TenantSquareFootageTermType;
  BaseRentalRateType: typeof models.BaseRentalRateType = models.BaseRentalRateType;
  FreeRentType: typeof models.FreeRentTermType = models.FreeRentTermType;
  SecurityDepositType: typeof models.SecurityDepositTermType = models.SecurityDepositTermType;
  RealEstateTaxesAndOpExpensesType: typeof models.RealEstateTaxesCamExpensesTermType = models.RealEstateTaxesCamExpensesTermType;
  RentalRateEscalationType: typeof models.EscalationTermType = models.EscalationTermType;

  tenantSquareFootageTypesList: Array<ImportFormSelectItem<models.TenantSquareFootageTermType>>;
  baseRentalRateTypesList: Array<ImportFormSelectItem<models.BaseRentalRateType>>;
  baseRentalRateUnitMetricsList: Array<ImportFormSelectItem<models.BaseRentalRateUnitMetrics>>;
  freeRentTypesList: Array<ImportFormSelectItem<models.FreeRentTermType>>;
  freeRentTaxesTypesList: Array<ImportFormSelectItem<models.FreeRentTaxesType>>;
  securityDepositTypesList: Array<ImportFormSelectItem<models.SecurityDepositTermType>>;
  securityDepositBeginningOrEndMonthsList: Array<ImportFormSelectItem<models.SecurityDepositMonths>>;
  securityDepositGuarantyTypesList: Array<ImportFormSelectItem<models.SecurityGuarantyOptionType>>;
  realEstateTaxesAndOpExpensesTypesList: Array<ImportFormSelectItem<models.RealEstateTaxesCamExpensesTermType>>;
  realEstateTaxesAndOpExpensesCumulativeTypesList: Array<ImportFormSelectItem<models.RealEstateTaxesCamExpensesCumulativeType>>;
  realEstateTaxesAndOpExpensesReTaxesTypesList: Array<ImportFormSelectItem<models.RealEstateTaxesType>>;
  realEstateTaxesAndOpExpensesOpExpensesTypesList: Array<ImportFormSelectItem<models.OperatingExpensesType>>;
  rentalRateEscalationTypesList: Array<ImportFormSelectItem<models.EscalationTermType>>;

  freeRentCustomSchedule: string;

  readonly form: NgForm;

  private readonly _popupService: PopupService;
  private readonly _destroy$: Subject<void>;

  constructor(form: NgForm, popupService: PopupService) {
    this.form = form;

    this._popupService = popupService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.lease = this._prepareLeaseModel();

    if (!this.leaseChange) {
      this.leaseChange = new EventEmitter<models.ILeaseViewModel>();
    }

    this._prepareDropDownOptions();

    // Reloads the dropdown options whenever the form model changes
    this.form
      .valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.leaseChange.next(this.lease);

          this._prepareDropDownOptions();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  handleCommencementDateChange(commencementDate: string | Date): void {
    if (!this.lease || !this.lease.expiration) {
      return;
    }

    if (!this.lease.term) {
      this.lease.term = <models.ITermViewModel>{};
    }

    if (!commencementDate) {
      this.lease.term.termValue = null;
      return;
    }

    const commencement = moment(commencementDate).startOf('day');
    const expiration = moment(this.lease.expiration).startOf('day').add(1, 'day');

    this.lease.term.termValue = expiration.diff(commencement, 'months');
  }

  handleExpirationDateChange(expirationDate: string | Date): void {
    if (!this.lease || !this.lease.commencementTerm || !this.lease.commencementTerm.commencement) {
      return;
    }

    if (!this.lease.term) {
      this.lease.term = <models.ITermViewModel>{};
    }

    if (!expirationDate) {
      this.lease.term.termValue = null;
      return;
    }

    const commencement = moment(this.lease.commencementTerm.commencement).startOf('day');
    const expiration = moment(expirationDate).startOf('day').add(1, 'day');

    this.lease.term.termValue = expiration.diff(commencement, 'months');
  }

  handleTermChange(term: number): void {
    if (!this.lease || !this.lease.commencementTerm || !this.lease.commencementTerm.commencement) {
      return;
    }

    if (!term) {
      this.lease.expiration = null;
      return;
    }

    const commencement = moment(this.lease.commencementTerm.commencement).startOf('day');

    this.lease.expiration = commencement.add(term, 'months').add(-1, 'day').format('YYYY-MM-DD');
  }

  handleFreeRentCustomScheduleChange(str: string): void {
    if (!this.lease) {
      return;
    }

    if (!str) {
      this.lease.freeRentTerm.specificMonths = [];
      return;
    }

    this.lease.freeRentTerm.specificMonths = str
      .split(',')
      .map(x => parseInt(x.trim(), 10));
  }

  showBaseRentalRateCustomTableEditor(): void {
    this._popupService.show(BaseRentalRateCustomTableEditorComponent, {
      title: 'Edit Base Rental Rate Custom Table',
      width: 750,
      height: 'auto',
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      containerClassNames: ['base-rental-rate-custom-table'],
      injectableData: {
        lease: this.lease,
        saveCustomScheduleTable: (rows: Array<models.IBaseRentalRateCustomScheduleTableRow>): void => {
          if (!this.lease) {
            return;
          }

          if (!this.lease.baseRentalRateTerm) {
            this.lease.baseRentalRateTerm = <models.IBaseRentalRateViewModel>{};
          }

          this.lease.baseRentalRateTerm.customScheduleTable = rows;
        },
      },
    });
  }

  showRentalRateEscalationCustomTableEditor(): void {
    this._popupService.show(RentalRateEscalationCustomTableEditorComponent, {
      title: 'Edit Rental Rate Escalation Custom Table',
      width: 750,
      height: 'auto',
      maxHeight: '90%',
      showCloseButton: true,
      closeOnOutsideClick: false,
      containerClassNames: ['edit-rental-rate-escalation-custom-table'],
      injectableData: {
        lease: this.lease,
        saveCustomValues: (customValues: Array<models.IRentalRateAnnualEscalationTermCustomValue>): void => {
          if (!this.lease) {
            return;
          }

          if (!this.lease.rentalRateAnnualEscalationTerm) {
            this.lease.rentalRateAnnualEscalationTerm = <models.IRentalRateAnnualEscalationTermViewModel>{};
          }

          this.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomValues = customValues;
        },
        saveCustomResults: (customResults: Array<models.IRentalRateAnnualEscalationTermCustomResult>): void => {
          if (!this.lease) {
            return;
          }

          if (!this.lease.rentalRateAnnualEscalationTerm) {
            this.lease.rentalRateAnnualEscalationTerm = <models.IRentalRateAnnualEscalationTermViewModel>{};
          }

          this.lease.rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomResults = customResults;
        },
      },
    });
  }

  private _prepareLeaseModel(): models.ILeaseViewModel {
    const lease = this.lease || <models.ILeaseViewModel>{};

    if (!lease.tenantSquareFootageTerm || !lease.tenantSquareFootageTerm.tenantSquareFootageTermType) {
      if (!lease.tenantSquareFootageTerm) {
        lease.tenantSquareFootageTerm = <models.ITenantSquareFootageTermViewModel>{};
      }

      lease.tenantSquareFootageTerm.tenantSquareFootageTermType = models.TenantSquareFootageTermType.Custom;

      if (lease.abstractLeaseId) {
        lease.tenantSquareFootageTerm.tenantSquareFootageTermType = models.TenantSquareFootageTermType.ExistingSquareFootage;
      }
    }

    if (!lease.baseRentalRateTerm || !lease.baseRentalRateTerm.baseRentalRateUnitMetrics) {
      if (!lease.baseRentalRateTerm) {
        lease.baseRentalRateTerm = <models.IBaseRentalRateViewModel>{};
      }

      lease.baseRentalRateTerm.baseRentalRateUnitMetrics = models.BaseRentalRateUnitMetrics.PsfPerYear;
    }

    if (!lease.freeRentTerm || !lease.freeRentTerm.freeRentTaxesType) {
      if (!lease.freeRentTerm) {
        lease.freeRentTerm = <models.IFreeRentTermViewModel>{};
      }

      lease.freeRentTerm.freeRentTaxesType = models.FreeRentTaxesType.Net;
    }

    if (!lease.securityDepositTerm || !lease.securityDepositTerm.securityDepositTermType) {
      if (!lease.securityDepositTerm) {
        lease.securityDepositTerm = <models.ISecurityDepositTermViewModel>{};
      }

      if (lease.abstractLeaseId) {
        lease.securityDepositTerm.securityDepositTermType = models.SecurityDepositTermType.PerCurrentLease;
      }
    }

    if (!lease.realEstateTaxesCamExpensesTerm || !lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesTermType) {
      if (!lease.realEstateTaxesCamExpensesTerm) {
        lease.realEstateTaxesCamExpensesTerm = <models.IRealEstateTaxesCamExpensesViewModel>{};
      }

      if (lease.abstractLeaseId) {
        lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesTermType = models.RealEstateTaxesCamExpensesTermType.PerCurrentLease;
      }
    }

    if (!lease.realEstateTaxesCamExpensesTerm || !lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesCumulativeType) {
      if (!lease.realEstateTaxesCamExpensesTerm) {
        lease.realEstateTaxesCamExpensesTerm = <models.IRealEstateTaxesCamExpensesViewModel>{};
      }

      lease.realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesCumulativeType = models.RealEstateTaxesCamExpensesCumulativeType
        .Cumulative;
    }

    if (!lease.realEstateTaxesCamExpensesTerm || !lease.realEstateTaxesCamExpensesTerm.estimatedReTaxes) {
      if (!lease.realEstateTaxesCamExpensesTerm) {
        lease.realEstateTaxesCamExpensesTerm = <models.IRealEstateTaxesCamExpensesViewModel>{};
      }

      lease.realEstateTaxesCamExpensesTerm.estimatedReTaxesType = models.RealEstateTaxesType.Estimate;
    }

    if (!lease.realEstateTaxesCamExpensesTerm || !lease.realEstateTaxesCamExpensesTerm.estimatedOpExType) {
      if (!lease.realEstateTaxesCamExpensesTerm) {
        lease.realEstateTaxesCamExpensesTerm = <models.IRealEstateTaxesCamExpensesViewModel>{};
      }

      lease.realEstateTaxesCamExpensesTerm.estimatedOpExType = models.OperatingExpensesType.Estimate;
    }

    if (lease && lease.freeRentTerm && lease.freeRentTerm.specificMonths) {
      this.freeRentCustomSchedule = lease.freeRentTerm.specificMonths.join(', ');
    }

    return lease;
  }

  private _prepareDropDownOptions(): void {
    if (!this.lease) {
      return;
    }

    this.tenantSquareFootageTypesList = ImportFormMainTermsComponent._tenantSquareFootageTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.TenantSquareFootageTermType.ExistingSquareFootage
        );

        return option;
      });

    this.baseRentalRateTypesList = ImportFormMainTermsComponent._baseRentalRateTypes;
    this.baseRentalRateUnitMetricsList = ImportFormMainTermsComponent._baseRentalRateUnitMetrics;

    this.freeRentTypesList = ImportFormMainTermsComponent._freeRentTypes;
    this.freeRentTaxesTypesList = ImportFormMainTermsComponent._freeRentTaxesTypes;

    this.securityDepositTypesList = ImportFormMainTermsComponent._securityDepositTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.SecurityDepositTermType.PerCurrentLease
        );

        return option;
      });

    this.securityDepositBeginningOrEndMonthsList = ImportFormMainTermsComponent._securityDepositBeginningOrEndMonthsList;
    this.securityDepositGuarantyTypesList = ImportFormMainTermsComponent._securityDepositGuarantyTypes;

    this.realEstateTaxesAndOpExpensesTypesList = ImportFormMainTermsComponent._realEstateTaxesAndCamExpensesTypes
      .map(option => {
        option.disabled = (
          !this.lease.abstractLeaseId &&
          option.value === models.RealEstateTaxesCamExpensesTermType.PerCurrentLease
        );

        if (this.lease && this.lease.baseRentalRateTerm) {
          const {baseRentalRateType} = this.lease.baseRentalRateTerm;

          if (
            (!baseRentalRateType || baseRentalRateType === models.BaseRentalRateType.Gross) &&
            (
              option.value === models.RealEstateTaxesCamExpensesTermType.PercentagePerYear ||
              option.value === models.RealEstateTaxesCamExpensesTermType.RetainingByTenant
            )
          ) {
            option.disabled = true;
          }

          if (
            baseRentalRateType !== models.BaseRentalRateType.Gross &&
            option.value === models.RealEstateTaxesCamExpensesTermType.ReOpExIncludedInGross
          ) {
            option.disabled = true;
          }

          if (
            baseRentalRateType !== models.BaseRentalRateType.BaseYear &&
            option.value === models.RealEstateTaxesCamExpensesTermType.TenantPayItsProRataShareOfExpenses
          ) {
            option.disabled = true;
          }
        }

        return option;
      });

    this.realEstateTaxesAndOpExpensesCumulativeTypesList = ImportFormMainTermsComponent._realEstateTaxesAndOpExpensesCumulativeTypes;
    this.realEstateTaxesAndOpExpensesReTaxesTypesList = ImportFormMainTermsComponent._realEstateTaxesAndOpExpensesReTaxesTypes;
    this.realEstateTaxesAndOpExpensesOpExpensesTypesList = ImportFormMainTermsComponent._realEstateTaxesAndOpExpensesOpExpensesTypes;

    this.rentalRateEscalationTypesList = ImportFormMainTermsComponent._rentalRateEscalationTypes;
  }
}
