import { ComponentRef, ViewRef } from '@angular/core';

export class ContentRefService {
  nodes: Array<any>;
  viewRef?: ViewRef;
  componentRef?: ComponentRef<any>;

  constructor(nodes: Array<any>, viewRef?: ViewRef, componentRef?: ComponentRef<any>) {
    this.nodes = nodes;
    this.viewRef = viewRef;
    this.componentRef = componentRef;
  }
}
