<div id="lease-abstract-popup">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form px-4" novalidate
          (ngSubmit)="createLeaseAbstractImportDraft()" #form="ngForm">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Main properties
        </div>
        <!-- Start: Lease Type lookup -->
        <!-- Start: Lease Type  -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && leaseType.invalid">
            Type
          </div>
          <div class="dx-field-value">
            <dx-select-box name="tenant-improvements-type" valueExpr="value" displayExpr="name" required
                           [(items)]="leaseTypeList" [showClearButton]="true"
                           [(ngModel)]="lease.leaseType"
                           [isValid]="form.submitted ? leaseType.valid : true"
                           (onValueChanged)="handleLeaseTypeChange($event)"
                           #leaseType="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Lease Type -->
        <!-- Start: Colábo Settings  -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && leaseType.invalid">
            Colábo Settings
          </div>
          <div class="dx-field-value">
            <dx-select-box name="tenant-improvements-type" valueExpr="value" displayExpr="name" required
                           [(items)]="leaseMetrics" [showClearButton]="true"
                           [(ngModel)]="lease.leaseMetrics"
                           [isValid]="form.submitted ? leaseType.valid : true"
                           #leaseType="ngModel">
            </dx-select-box>
          </div>
        </div>
        <!-- End: Colábo Settings -->
        <!-- Start: Landlord company lookup -->
        <div *ngIf="lease.leaseType === LeaseType.Internal" class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && landlordCompany.invalid">
            Landlord company
          </div>
          <div class="dx-field-value">
            <dx-lookup name="landlordCompany" [required]="isLandlordCompanyRequired(lease)"
                       noDataText="No landlords found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="landlordCompaniesList$ | async"
                       [isValid]="form.submitted ? landlordCompany.valid : true"
                       [searchExpr]="setupSearchableCompanyFields"
                       [(ngModel)]="lease.landlordCompanyId" #landlordCompany="ngModel"
                       (onValueChanged)="handleLandlordCompanyChange($event)">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let company of 'field'">
                <ng-container *ngIf="company">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img width="20" height="20" style="object-fit: cover"
                           [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                           [alt]="company.name" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{company.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!company">
                    <span class="text-muted">
                      Select landlord company
                    </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let company of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="40" height="40" style="object-fit: cover"
                         [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                         [alt]="company.name" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && landlordCompany.hasError('required')">
              Landlord company is required
            </div>
          </div>
        </div>
        <!-- End: Landlord company lookup -->
        <!-- Start: Tenant company lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && tenantCompany.invalid">
            Tenant company
          </div>
          <div class="dx-field-value">
            <dx-lookup name="tenantCompany" required
                       noDataText="No tenants found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="tenantCompaniesList$ | async"
                       [isValid]="form.submitted ? tenantCompany.valid : true"
                       [searchExpr]="setupSearchableCompanyFields"
                       [(ngModel)]="lease.tenantCompanyId" #tenantCompany="ngModel"
                       (onValueChanged)="handleTenantCompanyChange($event)">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let company of 'field'">
                <ng-container *ngIf="company">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img width="20" height="20" style="object-fit: cover"
                           [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                           [alt]="company.name" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{company.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!company">
                    <span class="text-muted">
                      Select tenant company
                    </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let company of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="40" height="40" style="object-fit: cover"
                         [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                         [alt]="company.name" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && tenantCompany.hasError('required')">
              Tenant company is required
            </div>
          </div>
        </div>
        <!-- End: Tenant company lookup -->
        <!-- Start: Building lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && building.invalid">
            Building
          </div>
          <div class="dx-field-value">
            <dx-lookup name="building" required
                       noDataText="No buildings found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="buildingsList"
                       [isValid]="form.submitted && !building.disabled ? building.valid : true"
                       [searchExpr]="setupSearchableBuildingFields"
                       [(ngModel)]="lease.buildingId" #building="ngModel"
                       (onValueChanged)="handleBuildingChange($event)"
                       [disabled]="!lease.landlordCompanyId && lease.leaseType === LeaseType.Internal">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let buildingProfile of 'field'">
                <ng-container *ngIf="buildingProfile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img width="20" height="20"
                           [src]="buildingProfile.picture && buildingProfile.picture.thumbnailUrl ? buildingProfile.picture.thumbnailUrl : 'assets/img/nofoto.png'"
                           [alt]="buildingProfile.name" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{buildingProfile.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!buildingProfile">
                    <span class="text-muted">
                      Select building
                    </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let buildingProfile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="40" height="40"
                         [src]="buildingProfile.picture && buildingProfile.picture.thumbnailUrl ? buildingProfile.picture.thumbnailUrl : 'assets/img/nofoto.png'"
                         [alt]="buildingProfile.name" />
                  </div>
                  <div class="lookup-field-summary px-1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{buildingProfile.name}}
                      </p>
                      <p class="mb-0 small w-100 text-truncate"
                         *ngIf="buildingProfile.address && buildingProfile.address.displayString">
                        {{buildingProfile.address.displayString}}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && building.hasError('required')">
              Building is required
            </div>
          </div>
        </div>
        <!-- End: Building lookup -->
        <!-- Start: Building unit lookup -->
        <div class="dx-field">
          <div class="dx-field-label">
            Building unit
          </div>
          <div class="dx-field-value">
            <dx-lookup name="buildingUnit"
                       noDataText="No building units found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="buildingUnitsList"
                       [searchExpr]="setupSearchableBuildingUnitFields"
                       [isValid]="form.submitted && !buildingUnit.disabled ? buildingUnit.valid : true"
                       [(ngModel)]="lease.buildingUnitId" #buildingUnit="ngModel"
                       [disabled]="!lease.buildingId"
                       (onValueChanged)="handleBuildingUnitChange($event)">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let buildingUnitProfile of 'field'">
                <ng-container *ngIf="buildingUnitProfile">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{buildingUnitProfile.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!buildingUnitProfile">
                    <span class="text-muted">
                      Select building unit
                    </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let buildingUnitProfile of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{buildingUnitProfile.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
          </div>
        </div>
        <!-- End: Building unit lookup -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Abstract properties
        </div>
        <!-- Start: Abstract lease lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && abstractLease.invalid">
            Abstract lease
          </div>
          <div class="dx-field-value">
            <dx-lookup name="abstractLease"
                       noDataText="No abstract leases found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                       [showClearButton]="true" [showPopupTitle]="false"
                       [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="abstractLeasesList"
                       [searchExpr]="setupSearchableAbstractLeaseFields"
                       [isValid]="form.submitted && !abstractLease.disabled ? abstractLease.valid : true"
                       [(ngModel)]="lease.abstractLeaseId" #abstractLease="ngModel"
                       [disabled]="!lease.landlordCompanyId || !lease.tenantCompanyId || !lease.buildingId"
                       (onSelectionChanged)="handleAbstractLeaseChange($event)">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let lease of 'field'">
                <ng-container *ngIf="lease">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{getLeaseName(lease)}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!lease">
                  <span class="text-muted">
                    Select abstract lease
                  </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let lease of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{getLeaseName(lease)}}
                    </p>
                    <p class="mb-0 small w-100 text-truncate">
                      {{lease.amendmentNumber === 0 ? 'Origin' : 'Amendment ' + lease.amendmentNumber}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
          </div>
        </div>
        <!-- End: Abstract lease lookup -->
        <!-- Start: Amendment number -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && amendmentNumber.invalid">
            Amendment number
          </div>
          <div class="dx-field-value">
            <dx-number-box appIgnoreScrollwheel name="amendmentNumber" required
                           min="0" max="9999999"
                           [isValid]="form.submitted && !amendmentNumber.disabled ? amendmentNumber.valid : true"
                           [(ngModel)]="lease.amendmentNumber" #amendmentNumber="ngModel">
            </dx-number-box>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && amendmentNumber.hasError('required')">
              Amendment number is required
            </div>
          </div>
        </div>
        <!-- End: Amendment number -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Lease abstract document
        </div>
        <!-- Start: File uploader -->
        <div class="dx-field">
          <!-- Start: File uploader control -->
          <div class="file-control"
               *ngIf="!leaseAbstractDocument">
            <dx-file-uploader name="file" accept="application/pdf" uploadMode="useForm"
                              [multiple]="false" [showFileList]="false" [allowedFileExtensions]="['.pdf']"
                              (onValueChanged)="handleFileChange($event)">
            </dx-file-uploader>
          </div>
          <!-- End: File uploader control -->
          <!-- Start: File preview -->
          <div class="d-flex flex-row align-items-center flex-nowrap mx-n1 mt-1"
               *ngIf="leaseAbstractDocument">
            <div class="px-1">
              <dx-button stylingMode="outlined" type="danger" icon="fa fa-times"
                         (onClick)="removeLeaseAbstractDocument()">
              </dx-button>
            </div>
            <div class="px-1">
              <p class="mb-0">
                {{leaseAbstractDocument.name}}
              </p>
              <p class="mb-0 invalid-feedback d-block"
                 *ngIf="leaseAbstractDocument.type !== 'application/pdf'">
                File type is not allowed
              </p>
            </div>
          </div>
          <!-- End: File preview -->
        </div>
        <!-- End: File uploader -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row align-items-center mx-n2">
          <div class="px-2">
            <div class="d-flex flex-row mx-n1">
              <div class="px-1">
                <dx-button type="success" text="Upload"
                           [useSubmitBehavior]="true">
                </dx-button>
              </div>
              <div class="px-1">
                <dx-button type="normal" text="Cancel"
                           (click)="cancel()">
                </dx-button>
              </div>
            </div>
          </div>
          <div class="px-2">
            <dx-check-box name="shouldUploadAnother" text="Upload another"
                          [(ngModel)]="shouldUploadAnother">
            </dx-check-box>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>

<dx-load-panel [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false"
               [position]="{of: '#lease-abstract-popup'}" [visible]="isLoading$ | async">
</dx-load-panel>
