import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';
import { RoleNames } from '../../../../shared/models/roles';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { UserService } from '../../../user/services/user.service';
import { LeaseSignatureService } from '../../services/lease-signature.service';

@Component({
  templateUrl: 'edit-lease-signature-dialog.component.html',
  styleUrls: ['edit-lease-signature-dialog.component.scss'],
})
export class EditLeaseSignatureDialogComponent implements OnInit, OnDestroy {
  leaseSignature: models.ILeaseSignature;

  landlordsList$: Observable<Array<models.IProfileViewModel>>;
  tenantsList$: Observable<Array<models.IProfileViewModel>>;

  userTypes = [
    {
      name: 'Internal',
      value: false,
    },
    {
      name: 'External',
      value: true,
    },
  ];

  readonly reloadLeaseSignatureList: () => Promise<void>;

  private readonly _leaseSignatureService: LeaseSignatureService;
  private readonly _userService: UserService;
  private readonly _popupRefService: PopupRefService;
  private readonly _destroy: Subject<void>;

  constructor(leaseSignatureService: LeaseSignatureService, userService: UserService, popupRefService: PopupRefService) {
    this._leaseSignatureService = leaseSignatureService;
    this._userService = userService;
    this._popupRefService = popupRefService;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.leaseSignature = this.leaseSignature || <models.ILeaseSignature>{};

    this.landlordsList$ = this._userService.getUsersByRoleName(RoleNames.Landlord);
    this.tenantsList$ = this._userService.getUsersByRoleName(RoleNames.Tenant);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  setupSearchableUserFields(profile: models.IProfileViewModel): Array<string> {
    const searchableFields = [
      profile.firstName,
      profile.lastName,
    ];

    if (profile.company && profile.company.name) {
      searchableFields.push(profile.company.name);
    }

    return searchableFields;
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._leaseSignatureService
      .updateLeaseSignature({...this.leaseSignature})
      .pipe(
        tap(() => {
          this._popupRefService.hide();

          this.reloadLeaseSignatureList();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  cancel(): void {
    this._popupRefService.hide();
  }
}
