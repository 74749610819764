import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { LeaseAmendmentService } from './services/lease-amendment.service';

import { EditEversignLeaseAmendmentDialogComponent } from './components/edit-eversign-lease-amendment-dialog/edit-eversign-lease-amendment-dialog.component';
import { EditLeaseAmendmentDialogComponent } from './components/edit-lease-amendment-dialog/edit-lease-amendment-dialog.component';
import { LeaseAmendmentListComponent } from './components/lease-amendment-list/lease-amendment-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxButtonModule,
    dx.DxLookupModule,
    dx.DxTextBoxModule,
    dx.DxDropDownBoxModule,
    dx.DxDataGridModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
  ],
  declarations: [
    EditEversignLeaseAmendmentDialogComponent,
    EditLeaseAmendmentDialogComponent,
    LeaseAmendmentListComponent,
  ],
  providers: [
    LeaseAmendmentService,
  ],
  entryComponents: [
    EditEversignLeaseAmendmentDialogComponent,
    EditLeaseAmendmentDialogComponent,
  ],
})
export class LeaseAmendmentModule {
  static forRoot(): ModuleWithProviders<LeaseAmendmentModule> {
    return {
      ngModule: LeaseAmendmentModule,
      providers: [
        LeaseAmendmentService,
      ],
    };
  }
}
