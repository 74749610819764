import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import * as dx from 'devextreme-angular';

import { VariableDirective } from './directives/variable.directive';

import { ComponentLoaderFactoryService } from './services/component-loader-factory.service';
import { PopupService } from './services/popup.service';

import { PopupContainerComponent } from './components/popup-container/popup-container.component';
import { NoticeComponent } from './components/notice/notice.component';

import { SafeNumberPipe } from './pipes/safe-number.pipe';

@NgModule({
  imports: [
    CommonModule,

    dx.DxPopupModule,
    dx.DxButtonModule,
  ],
  declarations: [
    VariableDirective,
    PopupContainerComponent,
    NoticeComponent,
    SafeNumberPipe,
  ],
  providers: [
    ComponentLoaderFactoryService,
    DecimalPipe,
    PopupService,
  ],
  entryComponents: [
    PopupContainerComponent,
    NoticeComponent,
  ],
  exports: [
    VariableDirective,
    SafeNumberPipe,
  ],
})
export class InfrastructureModule {}
