import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, empty, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from './services';
import { ToastService } from './services/toast.service';
import { ProgressBarService } from './services/progress-bar.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private progressBarService: ProgressBarService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressBarService.start();

    return next.handle(this.authService.isAuthenticated ? req.clone({ withCredentials: true }) : req).pipe(
      catchError((error: any) => {
        if (error.status === 401) {
          if (this.authService.isAuthenticated) {
            this.authService.logOut();
          }
          return empty();
        } else {
          var message = error.error ? error.error : error.statusText ? error.statusText : error.message;
          this.toastService.error(message);
          window.scrollTo(0, 0);
          return throwError(error);
        }
      }),
      finalize(() => {
        this.progressBarService.complete();
      })
    );
  }
}
