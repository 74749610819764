<h2 class="content-block">Leases</h2>

<dx-data-grid id="gridComponent" class="dx-card wide-card"
              [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              [masterDetail]="{ enabled: true, template: 'detail' }" [remoteOperations]="true" [wordWrapEnabled]="true"
              (onToolbarPreparing)="prepareToolbar($event)" (onRowPrepared)="onRowPrepared($event)"
              #dxDataGrid>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup> </dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="70" dataType="number">
  </dxi-column>

  <dxi-column dataField="abstractLeaseId" cellTemplate="abstractLeaseCellTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'abstractLeaseCellTemplate'">
    <ng-container *ngIf="item?.data?.abstractLeaseId as abstractLeaseId">
      {{abstractLeaseId}}
    </ng-container>
  </div>

  <dxi-column dataField="leaseStatus" dataType="number">
    <dxo-lookup [dataSource]="cellLeaseStatusDataSource" valueExpr="id" displayExpr="text"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="leaseType" dataType="number">
    <dxo-lookup [dataSource]="cellLeaseTypeDataSource" valueExpr="id" displayExpr="text"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="landlordCompanyId" cellTemplate="landlordCompanyFieldTemplate">
    <dxo-lookup [dataSource]="cellCompanyDataStore" valueExpr="id" [displayExpr]="Tools.getCompanyExpression">
    </dxo-lookup>
  </dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'landlordCompanyFieldTemplate'">
    <div>
      {{ Tools.getCompanyDisplayName(item, 'landlordCompany') }}
    </div>
  </div>

  <dxi-column dataField="tenantCompanyId" cellTemplate="tenantCompanyFieldTemplate">
    <dxo-lookup [dataSource]="cellCompanyDataStore" valueExpr="id" [displayExpr]="Tools.getCompanyExpression">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'tenantCompanyFieldTemplate'" class="lookup-user-field">
    <div>
      {{ Tools.getCompanyDisplayName(item, 'tenantCompany')  }}
    </div>
  </div>

  <dxi-column dataField="buildingId" cellTemplate="buildingCellTemplate">
    <dxo-lookup [dataSource]="cellBuildingDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'buildingCellTemplate'" class="lookup-user-field">
    <img alt=""
         [src]="getBuildingPicture(item)" />
    <div>
      {{ getBuildingName(item) }}
    </div>
  </div>

  <dxi-column dataField="buildingUnitId" cellTemplate="buildingUnitIdCellTemplate">
    <dxo-lookup [dataSource]="cellBuildingUnitDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'buildingUnitIdCellTemplate'">
    {{ item?.data?.buildingUnit?.name }}
  </div>

  <dxi-column dataField="amendmentNumber" dataType="number"></dxi-column>

  <dxi-column dataField="landArea" dataType="number"></dxi-column>
  <dxi-column dataField="commencementTerm.commencement" dataType="date" caption="Commencement">
  </dxi-column>
  <dxi-column dataField="commencementTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>
  <dxi-column dataField="expiration" dataType="date">
  </dxi-column>
  <dxi-column dataField="term.termValue" dataType="number">
  </dxi-column>
  <dxi-column dataField="term.termType" dataType="number" caption="Term Type">
    <dxo-lookup [dataSource]="termTypes" valueExpr="id" displayExpr="text">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="term.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="spaceUseId" dataType="number" caption="Space Use">
    <dxo-lookup [dataSource]="spaceUseItems" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="tenantSquareFootageTerm.tenantSquareFootageTermType" dataType="number"
              caption="Size Type">
    <dxo-lookup [dataSource]="tenantSquareFootageTermTypes" valueExpr="id" displayExpr="text">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="tenantSquareFootageTerm.tenantSquareFootageCustomValue" dataType="number"
              caption="Size Custom Value">
  </dxi-column>
  <dxi-column dataField="tenantSquareFootageTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="buildingPercentage"></dxi-column>
  <dxi-column dataField="createdOn" dataType="date"></dxi-column>

  <dxi-column dataField="tenantMaintenanceTerm.tenantMaintenance" caption="Tenant Maintenance"></dxi-column>
  <dxi-column dataField="tenantMaintenanceTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="selfHelpTerm.selfHelp" caption="Self Help"></dxi-column>
  <dxi-column dataField="selfHelpTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="assignmentTerm.assignment" caption="Assignment"></dxi-column>
  <dxi-column dataField="assignmentTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="renewalOptionTerm.renewalOption" caption="Renewal Option"></dxi-column>
  <dxi-column dataField="renewalOptionTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="expansionOptionTerm.expansionOption" caption="Expansion Option"></dxi-column>
  <dxi-column dataField="expansionOptionTerm.expansionDate" dataType="date" caption="Expansion Date"></dxi-column>
  <dxi-column dataField="expansionOptionTerm.customDisplayValue" dataType="text"
              caption="Expansion Custom Display Value">
  </dxi-column>
  <dxi-column dataField="expansionOptionTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="estoppelCertificateTerm.estoppelCertificate" caption="Estoppel Certificate"></dxi-column>
  <dxi-column dataField="estoppelCertificateTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="landlordMaintenanceTerm.landlordMaintenance" caption="Landlord Maintenance"></dxi-column>
  <dxi-column dataField="landlordMaintenanceTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="baseRentalRateTerm.baseRentalRateNetValue" dataType="number" caption="Base Rental Rate Net Value"></dxi-column>
  <dxi-column dataField="baseRentalRateTerm.baseRentalRateGrossValue" dataType="number" caption="Base Rental Rate Gross Value"></dxi-column>
  <dxi-column dataField="baseRentalRateTerm.actualYearRate" dataType="number"></dxi-column>
  <dxi-column dataField="baseRentalRateTerm.baseRentalRateType" dataType="number" caption="Base Rental Rate Type">
    <dxo-lookup [dataSource]="baseRentalRateTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="baseRentalRateTerm.baseRentalRateTypeOriginal" dataType="number" caption="Base Rental Rate Type Original">
    <dxo-lookup [dataSource]="baseRentalRateTypesOriginal" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="baseRentalRateTerm.baseRentalRateUnitMetrics" caption="Base Rental Rate Unit Metrics">
    <dxo-lookup [dataSource]="baseRentalRateUnitMetricsDataSource" valueExpr="value" displayExpr="label"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="baseRentalRateTerm.isCustomScheduleTableEnabled" dataType="boolean" caption="Base Rental Rate Enable Custom Schedule Table">
  </dxi-column>
  <dxi-column dataField="baseRentalRateTerm.customScheduleTable" [visible]="false">
  </dxi-column>
  <dxi-column dataField="baseRentalRateTerm.customDisplayValue" dataType="text"
              caption="Base Rental Rate Custom Display Value">
  </dxi-column>
  <dxi-column dataField="baseRentalRateTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="renewalOptionTerm.renewalDate" dataType="date" caption="Renewal Option Notice Date"></dxi-column>
  <dxi-column dataField="renewalOptionTerm.renewalOptionsCount" dataType="number" caption="Renewal Option Count of Options"></dxi-column>
  <dxi-column dataField="renewalOptionTerm.renewalOptionYearsCount" dataType="number" caption="Renewal Option Count of Years"></dxi-column>
  <dxi-column dataField="renewalOptionTerm.renewalOptionCustomText" dataType="text" caption="Renewal Option Custom Text"></dxi-column>
  <dxi-column dataField="renewalOptionTerm.renewalOptionTermType" dataType="number" caption="Renewal Option Type">
    <dxo-lookup [dataSource]="RenewalOptionTermTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="renewalOptionTerm.customDisplayValue" dataType="text"
              caption="Renewal Custom Display Value">
  </dxi-column>
  <dxi-column dataField="renewalOptionTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="freeRentTerm.freeRentMonthsCount" dataType="number" caption="Free Rent Months Count"></dxi-column>
  <dxi-column dataField="freeRentTerm.specificMonths" caption="Free Rent Specific Months"></dxi-column>
  <dxi-column dataField="freeRentTerm.freeRentTermType" dataType="number" caption="Free Rent Type">
    <dxo-lookup [dataSource]="freeRentTermTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="freeRentTerm.freeRentTaxesType" dataType="number" caption="Free Rent Taxes Type">
    <dxo-lookup [dataSource]="freeRentTaxesTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="freeRentTerm.customDisplayValue" dataType="text"
              caption="Free Rent Custom Display Value">
  </dxi-column>
  <dxi-column dataField="freeRentTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="securityDepositTerm.monthsCount" dataType="number" caption="Security Deposit Months Count"></dxi-column>
  <dxi-column dataField="securityDepositTerm.securityDepositMoneyValue" dataType="number" caption="Security Deposit Money Value"></dxi-column>
  <dxi-column dataField="securityDepositTerm.securityDepositReducingValue" dataType="number" caption="Security Deposit Reducing Value"></dxi-column>
  <dxi-column dataField="securityDepositTerm.securityDepositTermType" dataType="number" caption="Security Deposit Type">
    <dxo-lookup [dataSource]="SecurityDepositTermTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="securityDepositTerm.securityGuarantyOptionType" dataType="number" caption="Security Deposit Guaranty Option Type">
    <dxo-lookup [dataSource]="SecurityGuarantyOptionTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="securityDepositTerm.amountOfLetterCredit" dataType="number" caption="Amount Of Credit"></dxi-column>
  <dxi-column dataField="securityDepositTerm.customDisplayValue" dataType="text"
              caption="Security Deposit Custom Display Value">
  </dxi-column>
  <dxi-column dataField="securityDepositTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="terminationOptionTerm.terminationOption" caption="Termination Option"></dxi-column>
  <dxi-column dataField="terminationOptionTerm.terminationDate" dataType="date" caption="Termination Date"></dxi-column>
  <dxi-column dataField="terminationOptionTerm.terminationMonth" dataType="number" ></dxi-column>
  <dxi-column dataField="terminationOptionTerm.amortizationRate" dataType="number"></dxi-column>
  <dxi-column dataField="terminationOptionTerm.howManyMonthsToNotice" dataType="number"></dxi-column>
  <dxi-column dataField="terminationOptionTerm.terminationOptionCustomText" dataType="text" caption="Termination Option Custom Text"></dxi-column>
  <dxi-column dataField="terminationOptionTerm.terminationOptionType" dataType="number" caption="Termination Option Type">
    <dxo-lookup [dataSource]="TerminationOptionTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="terminationOptionTerm.customDisplayValue" dataType="text"
              caption="Termination Custom Display Value">
  </dxi-column>
  <dxi-column dataField="terminationOptionTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="hvacTerm.hvacMoneyValue" dataType="number" caption="Hvac Money Value"></dxi-column>
  <dxi-column dataField="hvacTerm.hvacPercentValue" dataType="number"  caption="Hvac Percent Value"></dxi-column>
  <dxi-column dataField="hvacTerm.hvacTermType" dataType="number"  caption="Hvac Type">
    <dxo-lookup [dataSource]="HvacTermTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="hvacTerm.customDisplayValue" dataType="text" caption="Hvac Custom Display Value"></dxi-column>
  <dxi-column dataField="hvacTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="realEstateTaxesCamExpensesTerm.estimatedReTaxes" dataType="number"></dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.baseYearForExpenses" dataType="date"></dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.estimatedReTaxesType">
    <dxo-lookup [dataSource]="RealEstateTaxesTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.estimatedOpEx" dataType="number"></dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.estimatedOpExType">
    <dxo-lookup [dataSource]="OperatingExpensesTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.percentagePerYear" dataType="number"></dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesText" dataType="text"  caption="Real Estate Taxes Cam Expenses Text"></dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesTermType" dataType="number" caption="Real Estate Taxes Cam Expenses Type">
    <dxo-lookup [dataSource]="RealEstateTaxesCamExpensesTermTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.realEstateTaxesCamExpensesCumulativeType" dataType="number"  caption="Real Estate Taxes Cam Expenses Cumulative Type">
    <dxo-lookup [dataSource]="RealEstateTaxesCamExpensesCumulativeTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.customDisplayValue" dataType="text"
              caption="Real Estate Taxes Cam Expenses Custom Display Value">
  </dxi-column>
  <dxi-column dataField="realEstateTaxesCamExpensesTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="rentalRateAnnualEscalationTerm.escalationPercentagePerYear" dataType="number" caption="Rental Rate Escalation Percentage Per Year"></dxi-column>
  <dxi-column dataField="rentalRateAnnualEscalationTerm.escalationPsfValuePerYear" dataType="number" caption="Rental Rate Escalation Psf Value Per Year"></dxi-column>
  <dxi-column dataField="rentalRateAnnualEscalationTerm.escalationTermType" dataType="number" caption="Rental Rate Escalation Type">
    <dxo-lookup [dataSource]="EscalationTermTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="rentalRateAnnualEscalationTerm.rentalRateEscalationTaxType" dataType="number" caption="Rental Rate Escalation Tax Type">
    <dxo-lookup [dataSource]="RentalRateEscalationTaxTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="rentalRateAnnualEscalationTerm.customDisplayValue" dataType="text"
              caption="Rental Rate Escalation Custom Display Value">
  </dxi-column>
  <dxi-column dataField="rentalRateAnnualEscalationTerm.rentalRateAnnualEscalationTermCustomValues" [visible]="false">
  </dxi-column>
  <dxi-column dataField="rentalRateAnnualEscalationTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="tenantImprovementsTerm.tenantImprovementsValue" dataType="number" caption="Tenant Improvements Value"></dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.tenantImprovementsAmortized" dataType="number" caption="Tenant Improvements Amortized"></dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.tenantImprovementsAmortizedMonthsCount" dataType="number" caption="Tenant Improvements Amortized Years Count"></dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.tenantImprovementsPercentageRate" dataType="number" caption="Tenant Improvements Percentage Rate"></dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.tenantImprovementsCustom" dataType="text" caption="Tenant Improvements Custom"></dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.tenantImprovementsOptionDescription" dataType="text" caption="Tenant Improvements Turn Key"></dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.tenantImprovementsType" dataType="number" caption="Tenant Improvements Type">
    <dxo-lookup [dataSource]="TenantImprovementsTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.contractorTypeForTenantImprovementsWork" dataType="number" caption="Contractor type for Tenant Improvements work">
    <dxo-lookup [dataSource]="ContractorTypesForTenantImprovementsWork" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.warrantyDurationInMonthsForLandlordContractors" dataType="number" caption="Warranty Duration In Months"></dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.warrantyDurationTypeForLandlordsContractors" dataType="number" caption="Contractor type for Tenant Improvements work">
    <dxo-lookup [dataSource]="WarrantyDurationTypesForLandlordsContractors" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.customDisplayValue" dataType="text"
              caption="Tenant Improvements Custom Display Value">
  </dxi-column>
  <dxi-column dataField="tenantImprovementsTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="holdoverProvisionTerm.holdoverProvisionType" dataType="number" caption="Holdover Provision Term Type">
    <dxo-lookup [dataSource]="HoldoverProvisionTypes" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="holdoverProvisionTerm.text" dataType="text" caption="Holdover Provision Text"></dxi-column>
  <dxi-column dataField="holdoverProvisionTerm.landlordHoldoverRentForFirstDaysInPercent" dataType="number" caption="Holdover Provision Rent For First Days"></dxi-column>
  <dxi-column dataField="holdoverProvisionTerm.landlordHoldoverRentForRemainingDaysInPercent" dataType="number" caption="Holdover Provision Rent"></dxi-column>
  <dxi-column dataField="holdoverProvisionTerm.countOfFirstLandlordHoldoverRentDays" dataType="number" caption="Count of Days"></dxi-column>
  <dxi-column dataField="holdoverProvisionTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="insuranceTerm.text" dataType="text" caption="Insurance Text"></dxi-column>
  <dxi-column dataField="insuranceTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="forceMajeureTerm.text" dataType="text" caption="Force Majeure Text"></dxi-column>
  <dxi-column dataField="forceMajeureTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="parkingTerm.text" dataType="text" caption="Parking Text"></dxi-column>
  <dxi-column dataField="parkingTerm.isElectedForNegotiation" dataType="boolean" caption="Is Elected for Negotiation"
              [visible]="false">
  </dxi-column>

  <dxi-column dataField="leaseFiles" [visible]="false"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit-custom" text="Edit"
                [visible]="isEditButtonVisible" [onClick]="showLeaseEditDialog">
    </dxi-button>
    <dxi-button name="copy-custom" text="Copy"
                [visible]="isEditButtonVisible" [onClick]="copyLeaseAndShowLeaseEditDialog">
    </dxi-button>
    <dxi-button name="delete"
                [visible]="isDeleteButtonVisible">
    </dxi-button>
  </dxi-column>

  <div *dxTemplate="let item of 'detail'" class="flex-container">
    <div>
      <div class="details-title">Picture</div>
      <img width="200" alt=""
           [src]="getBuildingPicture(item?.data?.building)" />
    </div>
    <div>
      <div class="details-title">Files</div>
      <div *ngFor="let file of item?.data?.leaseFiles">
        <a href="{{ file?.file?.url }}">{{ file?.file?.name }}</a>
      </div>
    </div>
  </div>

  <dxo-editing mode="popup" [allowDeleting]="true">
  </dxo-editing>
</dx-data-grid>
