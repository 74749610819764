<h2 class="content-block">Points Of Interest</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  (onToolbarPreparing)="prepareToolbar($event)"
  #dxDataGrid
>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup> </dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="70" dataType="number"></dxi-column>

  <dxi-column dataField="status">
    <dxo-lookup [dataSource]="pointOfInterestStatusDataSource" valueExpr="id" displayExpr="text"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="kind">
    <dxo-lookup [dataSource]="pointOfInterestKindDataSource" valueExpr="id" displayExpr="text"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="name"></dxi-column>

  <dxi-column dataField="geopointLatitude"></dxi-column>

  <dxi-column dataField="geopointLongitude"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit-custom" text="Edit"
                [onClick]="showUpsertPopup">
    </dxi-button>
    <dxi-button name="delete">
    </dxi-button>
  </dxi-column>

  <dxo-editing mode="popup" [allowDeleting]="true">
  </dxo-editing>
</dx-data-grid>
