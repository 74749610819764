import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { EditLeaseUserDialogComponent } from './components/edit-lease-user-dialog/edit-lease-user-dialog.component';
import { LeaseUserListComponent } from './components/lease-user-list/lease-user-list.component';

import { LeaseUserService } from './services/lease-user.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxButtonModule,
    dx.DxLookupModule,
    dx.DxTextBoxModule,
    dx.DxDropDownBoxModule,
    dx.DxDataGridModule,
    dx.DxScrollViewModule
  ],
  declarations: [
    EditLeaseUserDialogComponent,
    LeaseUserListComponent,
  ],
  providers: [
    LeaseUserService,
  ],
  entryComponents: [
    EditLeaseUserDialogComponent,
  ],
})
export class LeaseUserModule {
  static forRoot(): ModuleWithProviders<LeaseUserModule> {
    return {
      ngModule: LeaseUserModule,
      providers: [
        LeaseUserService,
      ],
    };
  }
}
