import { Injectable } from '@angular/core';
import {
  IAvatarViewModel,
  ILeaseTermConfiguration,
  IProfileViewModel,
  IStartupInfoViewModel,
  LeaseTermType
} from "../models/generated";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(private httpClient: HttpClient) { }

  getLeaseTermConfiguration(leaseTermType: LeaseTermType): Observable<ILeaseTermConfiguration> {
    return this.httpClient.get<ILeaseTermConfiguration>(`${environment.adminUrl}/leaseTerms/LeaseTermConfiguration?leaseTermType=${leaseTermType}`);
  }

  getLeaseTermConfigurations(): Observable<ILeaseTermConfiguration[]> {
    return this.httpClient.get<ILeaseTermConfiguration[]>(`${environment.adminUrl}/leaseTerms/LeaseTermConfigurations`);
  }
}
