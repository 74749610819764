import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import * as vm from 'src/app/shared/models/generated';
import { AuthService } from 'src/app/shared/services';
import DevExpress from 'devextreme/bundles/dx.all';

@Component({
  selector: 'app-market-rental-rates',
  templateUrl: './market-rental-rates.component.html',
  styleUrls: ['./market-rental-rates.component.scss']
})
export class MarketRentalRatesComponent implements OnInit {

  environment = environment;
  url = `${environment.adminUrl}/MarketRentalRate`;
  rangesUrl = `${environment.adminUrl}/SquareFootageRange`;

  dataSource = AspNetData.createStore({
    key: ['areaId', 'squareFootageRangeId'],
    loadUrl: this.url,
    insertUrl: this.url,
    updateUrl: this.url,
    deleteUrl: this.url,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.xhrFields = { withCredentials: true };
      if (ajaxOptions.data.values) {
        this.prepareSave(ajaxOptions);
      }
    }
  });

  subMarkets: vm.IMarket[];
  marketEditorOptions: any;

  ranges: vm.ISquareFootageRange[];
  rangeEditorOptions = {
    dataSource: this.createRangesDataSource(),
    valueExpr: 'id',
    displayExpr: this.buildRangeDisplayName,
    showClearButton: true
  };

  get _window() {
    return window;
  }

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.addressPropertiesCompletes$.subscribe(info => {
      if (info) {
        this.subMarkets = info.subMarkets;
        this.marketEditorOptions = {
          dataSource: this.subMarkets,
          valueExpr: 'id',
          displayExpr: 'name',
          showClearButton: true
        };
      }
    });

    this.createRangesDataSource()
      .on('loaded', data => {
        this.ranges = data;
      })
      .load();
  }

  prepareSave(ajaxOptions) {
    const values = JSON.parse(ajaxOptions.data.values);

    ajaxOptions.data.values = JSON.stringify(values);
  }

  buildRangeDisplayName(data: vm.ISquareFootageRange): string {
    let displayName = '';

    if (data) {
      displayName = data.minValue.toString();
      if (data.maxValue) {
        displayName += '-' + data.maxValue.toString();
      } else {
        displayName += '+';
      }
    }

    return displayName;
  }

  private createRangesDataSource(): DevExpress.data.CustomStore {
    return AspNetData.createStore({
      key: 'id',
      loadUrl: this.rangesUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });
  }
}
