import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IStateraClaimGroup } from '../models/generated';

@Injectable({
  providedIn: 'root'
})
export class StateraClaimGroupService {

  constructor(private httpClient: HttpClient) { }

  getStateraClaimGroups(): Observable<{ data: Array<IStateraClaimGroup> }> {
    return this.httpClient.get<{ data: Array<IStateraClaimGroup> }>(`${environment.adminUrl}/stateraClaimGroup`);
  }
}
