import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { LeaseAbstractService } from '../../services/lease-abstract.service';
import { LeaseAbstractStore } from '../../services/lease-abstract.store';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';

import { DocumentViewerOptions } from '../../models/document-viewer-options.model';

import * as models from '../../../../shared/models/generated';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

@Component({
  templateUrl: 'import.component.html',
  styleUrls: ['import.component.scss'],
})
export class ImportComponent implements OnInit, OnDestroy {
  readonly leaseAbstractImportDraftId: number;
  readonly reloadLeases: () => Promise<unknown>;

  documentViewerOptions: DocumentViewerOptions;
  documentViewerOptionsChange: Subject<DocumentViewerOptions>;

  leaseAbstractImportDraft$: Observable<models.ILeaseAbstractImportDraftViewModel>;

  private readonly _leaseAbstractService: LeaseAbstractService;
  private readonly _leaseAbstractStore: LeaseAbstractStore;
  private readonly _popupRefService: PopupRefService;
  private readonly _popupService: PopupService;
  private readonly _destroy: Subject<void>;

  constructor(
    leaseAbstractService: LeaseAbstractService,
    leaseAbstractStore: LeaseAbstractStore,
    popupRefService: PopupRefService,
    popupService: PopupService,
  ) {
    this._leaseAbstractService = leaseAbstractService;
    this._leaseAbstractStore = leaseAbstractStore;
    this._popupRefService = popupRefService;
    this._popupService = popupService;
    this._destroy = new Subject<void>();

    this.reloadLeaseAbstractImportDraft = this.reloadLeaseAbstractImportDraft.bind(this);
  }

  ngOnInit(): void {
    this.documentViewerOptions = new DocumentViewerOptions(this.leaseAbstractImportDraftId);
    this.documentViewerOptionsChange = new Subject<DocumentViewerOptions>();

    this.documentViewerOptionsChange
      .pipe(
        tap(options => this.documentViewerOptions = options),
        takeUntil(this._destroy),
      )
      .subscribe();

    this._setupLeaseAbstractImportDraft();
    this._setupPopupConfig();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();

    this._leaseAbstractStore.clear();
  }

  done(): void {
    this.reloadLeases();
  }

  cancel(event: {form: NgForm}): void {
    this.reloadLeases();

    if (event.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  reloadLeaseAbstractImportDraft(): void {
    this._setupLeaseAbstractImportDraft();
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel the lease abstract import process?',
        acceptFn: () => {
          this._popupRefService.hide();
        },
      },
    });
  }

  private _setupLeaseAbstractImportDraft(): void {
    if (!this.leaseAbstractImportDraftId) {
      this.leaseAbstractImportDraft$ = of(<models.ILeaseAbstractImportDraftViewModel>{});
      return;
    }

    this.leaseAbstractImportDraft$ = this._leaseAbstractStore.getLeaseAbstractImportDraft();

    this._leaseAbstractService
      .getLeaseAbstractImportDraft(this.leaseAbstractImportDraftId)
      .pipe(
        tap(leaseAbstractImportDraft => this._leaseAbstractStore
          .storeLeaseAbstractImportDraft(leaseAbstractImportDraft),
        ),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  private _setupPopupConfig(): void {
    const subscription = this._popupRefService.onContentReady.subscribe((event) => {
      const contentElement = event.component.content();
      if (contentElement) {
        contentElement.style.padding = '0';
        contentElement.style.height = '100%';
      }

      subscription.unsubscribe();
    });
  }
}
