<div class="import-form-term-preview"
     *ngIf="leaseTermText">
  <div class="import-form-term-preview-title">
    Term preview
  </div>
  <div class="import-form-term-preview-body">
    <p>
      {{leaseTermText}}
    </p>
    <p *ngIf="documentSection">
      {{documentSection}}
    </p>
    <p *ngIf="documentSections && documentSections.length">
      Sections: {{documentSections.join(', ')}}
    </p>
  </div>
</div>
