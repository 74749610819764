import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { LeaseFloorPlanListComponent } from './components/list/list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfrastructureModule,

    dx.DxAccordionModule,
    dx.DxButtonModule,
    dx.DxDataGridModule,
    dx.DxFileUploaderModule,
    dx.DxLoadIndicatorModule,
    dx.DxLoadPanelModule,
    dx.DxLookupModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
    dx.DxTabsModule,
    dx.DxTextBoxModule,
  ],
  declarations: [
    LeaseFloorPlanListComponent,
  ],
  exports: [
  ],
  providers: [
  ],
  entryComponents: [
  ],
})
export class LeaseFloorPlanModule {
  static forRoot(): ModuleWithProviders<LeaseFloorPlanModule> {
    return {
      ngModule: LeaseFloorPlanModule,
      providers: [
      ],
    };
  }
}
