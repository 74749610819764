import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {
  BaseRentalRateType,
  IBaseRentalRateViewModel, ILeaseTermConfiguration,
  LeaseTermType
} from "../../../shared/models/generated";
import {Tools} from "../../../shared/tools";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import {AuthService} from "../../../shared/services";
import {TermsService} from "../../../shared/services/terms.service";

@Component({
  selector: 'app-base-rental-rate',
  templateUrl: './base-rental-rate.component.html',
  styleUrls: ['./base-rental-rate.component.scss']
})
export class BaseRentalRateComponent implements OnInit {

  BaseRentalRateType = BaseRentalRateType;
  baseRentalRateTypes = Tools.EnumToArray(BaseRentalRateType);
  dataSource: any;
  leases: any;
  leaseConfiguration:ILeaseTermConfiguration;
  url = `${environment.adminUrl}/leaseTerms`;
  get _window() {
    return window;
  }
  tools = Tools;

  constructor(private authService: AuthService, private termsService: TermsService) {}

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      loadParams:{
        leaseTermType: LeaseTermType.BaseRentalRate
      },
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };

        if(ajaxOptions.data.values){
          if(method === "insert"){
            const values = JSON.parse(ajaxOptions.data.values);
            values.leaseTermType = LeaseTermType.BaseRentalRate;
            values.leaseTermConfiguration = this.leaseConfiguration;
            ajaxOptions.data.values = JSON.stringify(values);
          }
        }
      }
    });

    this.dataSource.on('loaded', (data: IBaseRentalRateViewModel[]) => {

    });

    this.leases = this.createDataSource('id', `${environment.adminUrl}/Leases`);

    this.termsService.getLeaseTermConfiguration(LeaseTermType.BaseRentalRate).subscribe(x => this.leaseConfiguration = x);
  }

  createDataSource(key: string, loadUrl: string) {
    return AspNetData.createStore({
      key: key,
      loadUrl: loadUrl,
      onBeforeSend: function(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });
  }

}
