import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import * as models from '../../../shared/models/generated';

@Injectable({
  providedIn: 'root',
})
export class PlanAnchorImageMarkerService {
  private _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  /**
   * Creates plan anchor image marker
   * @method PlanAnchorImageMarkerService.createPlanAnchorImageMarker
   * @param planAnchorImageMarker Plan anchor image marker view model
   * @returns Created plan anchor image marker
   */
  createPlanAnchorImageMarker(
    planAnchorImageMarker: models.IPlanAnchorImageMarkerViewModel,
  ): Observable<models.IPlanAnchorImageMarkerViewModel> {
    return this._httpClient
      .post<models.IPlanAnchorImageMarkerViewModel>(
        `${environment.adminUrl}/plananchorimagemarker`,
        planAnchorImageMarker,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Updates plan anchor image marker
   * @method PlanAnchorImageMarkerService.updatePlanAnchorImageMarker
   * @param planAnchorImageMarker Plan anchor image marker view model
   * @returns Updated plan anchor image marker
   */
  updatePlanAnchorImageMarker(
    planAnchorImageMarker: models.IPlanAnchorImageMarkerViewModel,
  ): Observable<models.IPlanAnchorImageMarkerViewModel> {
    return this._httpClient
      .put<models.IPlanAnchorImageMarkerViewModel>(
        `${environment.adminUrl}/plananchorimagemarker/${planAnchorImageMarker.id}`,
        planAnchorImageMarker,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Deletes plan anchor image marker
   * @method PlanAnchorImageMarkerService.deletePlanAnchorImageMarker
   * @param planAnchorImageMarker Plan anchor image marker view model
   * @returns void
   */
  deletePlanAnchorImageMarker(
    planAnchorImageMarker: models.IPlanAnchorImageMarkerViewModel,
  ): Observable<void> {
    return this._httpClient
      .delete<void>(
        `${environment.adminUrl}/plananchorimagemarker/${planAnchorImageMarker.id}`
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
