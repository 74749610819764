import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { alert } from 'devextreme/ui/dialog';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Tools } from '../../../../shared/tools';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { LeaseUserService } from '../../services/lease-user.service';

import * as models from '../../../../shared/models/generated';

import { environment } from '../../../../../environments/environment';

@Component({
  templateUrl: 'edit-lease-user-dialog.component.html',
  styleUrls: ['edit-lease-user-dialog.component.scss'],
})
export class EditLeaseUserDialogComponent implements OnInit, OnDestroy {
  private static readonly _usersUrl = `${environment.adminUrl}/User/Get`;
  private static readonly _leasesUrl = `${environment.adminUrl}/Leases/shortLeaseModels?desc=true`;
  private static readonly _companiesUrl = `${environment.adminUrl}/Company`;
  private static readonly _claimsUrl = `${environment.adminUrl}/stateraClaim`;
  private static readonly _claimGroupsUrl = `${environment.adminUrl}/stateraClaimGroup`;

  userDataSource: CustomStore;
  leaseDataSource: CustomStore;
  companyDataSource: CustomStore;
  claimDataSource: CustomStore;
  claimGroupDataSource: CustomStore;
  leaseUserRolesDataSource: DataSource;

  leaseUser: Partial<models.ILeaseUserViewModel & { claimIds: Array<number>, claimGroupIds: Array<number> }>;

  Tools = Tools;

  readonly reloadUsersList: () => Promise<unknown>;

  private readonly _leaseUserService: LeaseUserService;
  private readonly _popupRefService: PopupRefService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _destroy$: Subject<void>;

  constructor(
    leaseUserService: LeaseUserService,
    popupRefService: PopupRefService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this._leaseUserService = leaseUserService;
    this._popupRefService = popupRefService;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    const isLeaseUserInjected = !!this.leaseUser;
    if (!isLeaseUserInjected) {
      console.error('Lease user is required');
      return;
    }

    this.userDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: EditLeaseUserDialogComponent._usersUrl,
    });

    this.leaseDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: EditLeaseUserDialogComponent._leasesUrl,
    });

    this.companyDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${EditLeaseUserDialogComponent._companiesUrl}?leaseId=${this.leaseUser.leaseId}`,
    });

    this.claimDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: EditLeaseUserDialogComponent._claimsUrl,
    });

    this.claimGroupDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: EditLeaseUserDialogComponent._claimGroupsUrl,
    });

    this.leaseUserRolesDataSource = new DataSource({
      key: 'id',
      store: Tools.EnumToArray(models.LeaseUserRole),
    });

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  claimDisplayExpression(claim: models.IStateraClaim): string {
    if (!claim) {
      return '';
    }

    const claimType = models.StateraClaimTypeAsEnum[claim.claimType];
    const claimValue = models.StateraClaimValueAsEnum[claim.claimValue];

    return [claimType, claimValue]
      .filter(Boolean)
      .join(': ');
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._leaseUserService
      .updateLeaseUser({...this.leaseUser})
      .pipe(
        tap(() => {
          this._popupRefService.hide();

          if (this.reloadUsersList) {
            this.reloadUsersList();
          }
        }),
        catchError(error => {
          if (error) {
            alert(error.error, 'Error');
          }

          return of(error);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  cancel(): void {
    this._popupRefService.hide();
  }
}
