import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CachedImageRef } from '../models/image-cache.model';

@Injectable()
export class ImageCacheService {
  private readonly _imageCache: {[url: string]: Observable<CachedImageRef>};

  constructor() {
    this._imageCache = {};
  }

  getCachedImage(url: string): Observable<CachedImageRef> {
    if (!this._imageCache[url]) {
      this._imageCache[url] = this._cacheImage(url);
    }

    return this._imageCache[url];
  }

  private _cacheImage(url: string): Observable<CachedImageRef> {
    const imageElement: HTMLImageElement = document.createElement('img');
    const subject = new ReplaySubject<CachedImageRef>(1);

    let imageSource = url;

    imageElement.src = imageSource;

    imageElement.onload = () => {
      if (imageSource.substring(0, 10) !== 'data:image') {
        const canvasElement: HTMLCanvasElement = document.createElement('canvas');
        const canvasContext: CanvasRenderingContext2D = canvasElement.getContext('2d');

        canvasElement.width = imageElement.naturalWidth;
        canvasElement.height = imageElement.naturalHeight;

        canvasContext.drawImage(imageElement, 0, 0);

        imageSource = canvasElement.toDataURL('image/jpeg');
      }

      const cachedImageRef = <CachedImageRef>{
        url: url,
        dataUrl: imageSource,
        element: imageElement,
        width: imageElement.naturalWidth,
        height: imageElement.naturalHeight,
      };

      subject.next(cachedImageRef);
      subject.complete();
    };

    imageElement.onerror = (err) => {
      subject.error(err);
    };

    return subject;
  }
}
