import { Component, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Croppie from 'croppie';
import { CroppieDirective } from 'angular-croppie-module';
import { DxFileUploaderComponent, DxButtonComponent } from 'devextreme-angular';
import { CropData } from 'croppie';
import { AuthService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import * as vm from 'src/app/shared/models/generated';
declare let $: any;

@Component({
  selector: 'app-avatar-editor',
  templateUrl: './avatar-editor.component.html',
  styleUrls: ['./avatar-editor.component.scss']
})
export class AvatarEditorComponent {

  @ViewChild('croppie', { static: false }) croppieDirective: CroppieDirective;
  @ViewChild('buttonSelect', { static: false }) buttonSelect: DxButtonComponent;
  @ViewChild('buttonSave', { static: false }) buttonSave: DxButtonComponent;
  @ViewChild('buttonCancel', { static: false }) buttonCancel: DxButtonComponent;
  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;

  uploadUrl = environment.apiUrl + '/storage/Upload'
  croppieMaxWidth = 640;
  croppieMaxHeight = 480;
  sourceUrl = this.authService.noAvatarSourceUrl;

  get ratio(): number {
    return this.croppieMaxHeight / this.croppieMaxWidth;
  }

  get viewportSize(): number {
    return Math.min(this.croppieWidth, this.croppieHeight) / 2;
  }

  get croppieWidth(): number {
    const width =
      window.innerWidth < window.innerHeight
        ? window.innerWidth > this.croppieMaxWidth
          ? this.croppieMaxWidth
          : window.innerWidth
        : this.croppieHeight / this.ratio;
    return width < this.croppieMaxWidth ? width : this.croppieMaxWidth;
  }

  get croppieHeight(): number {
    const height =
      window.innerWidth < window.innerHeight ? this.croppieWidth * this.ratio : window.innerHeight;
    return height < this.croppieMaxHeight ? height : this.croppieMaxHeight;
  }

  imageChanged = false;

  public croppieOptions: Croppie.CroppieOptions = {
    boundary: {
      width: this.croppieWidth - 80,
      height: this.croppieHeight - (window.innerWidth > window.innerHeight ? 170 : 0)
    },
    viewport: {
      width: this.viewportSize,
      height: this.viewportSize,
      type: 'circle'
    },
    showZoomer: true,
    enableOrientation: true,
    enforceBoundary: true
  };

  toolbarItems = [{
    location: 'before',
    widget: 'dxButton',
    options: {
      text: 'Select file',
      onClick: (e) => {
        e.event.stopPropagation();
        $((this.fileUploader.instance as any)._selectButton.element()).trigger('click');
      }
    }
  }, {
    location: 'after',
    widget: 'dxButton',
    options: {
      text: 'Save',
      onClick: () => {
        var options = this.croppieDirective.croppie.get() as any;

        var canSave =
          this.sourceUrl !== this.authService.noAvatarSourceUrl &&
          (!this.isCropDataEqueal(
            options,
            this.authService.startupInfo.avatarOptions
          ) || this.imageChanged);

        if (canSave) {
          this.profileService.saveAvatar({
            avatarOptions: options,
            sourceImageUrl: this.sourceUrl
          } as vm.IAvatarViewModel)
            .subscribe(info => {
              this.authService.startupInfo = info;
              this.toast.success('The avatar has been successfuly saved');
            });
        } else {
          this.toast.info('Please select new image or change selection region');
        }
      }
    }
  }];

  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private profileService: ProfileService
  ) { }

  public ngAfterViewInit() {
    if (this.croppieDirective) {
      if (this.authService.startupInfo.avatarSourceUrl) {
        this.croppieDirective.croppie
          .bind(Object.assign(this.authService.startupInfo.avatarOptions, { url: this.authService.startupInfo.avatarSourceUrl }));
        this.sourceUrl = this.authService.startupInfo.avatarSourceUrl;
      } else {
        this.croppieDirective.croppie.bind({ url: this.authService.noAvatarSourceUrl });
      }
    }
  }

  onUploaded(e) {
    var file = JSON.parse(e.request.responseText) as vm.IFileViewModel;
    this.sourceUrl = file.url;
    this.croppieDirective.croppie.bind({ url: this.sourceUrl });
    this.imageChanged = true;
  }

  isCropDataEqueal(one: CropData, two: CropData) {
    return (
      one && two &&
      one.orientation === two.orientation &&
      one.zoom == two.zoom &&
      one.points[0] * 1 === two.points[0] &&
      one.points[1] * 1 === two.points[1] &&
      one.points[2] * 1 === two.points[2] &&
      one.points[3] * 1 === two.points[3]
    );
  }
}
