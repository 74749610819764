import { Component, Input, ViewChild } from '@angular/core';

import * as ng from '@angular/core';

import * as models from '../../models/image-viewer-image.model';

import { ImageViewerImageStandardComponent } from '../image-viewer-image-standard/image-viewer-image-standard.component';
import { ImageViewerImagePanoramaComponent } from '../image-viewer-image-panorama/image-viewer-image-panorama.component';

@Component({
  selector: 'app-image-viewer-image',
  templateUrl: 'image-viewer-image.component.html',
  styleUrls: ['image-viewer-image.component.scss'],
})
export class ImageViewerImageComponent implements ng.OnInit, ng.OnChanges {
  @ViewChild('imageViewerImageStandardComponent', {static: false}) imageViewerImageStandardComponent: ImageViewerImageStandardComponent;
  @ViewChild('imageViewerImagePanoramaComponent', {static: false}) imageViewerImagePanoramaComponent: ImageViewerImagePanoramaComponent;

  @Input() imageRef: models.ImageViewerImageRef;

  @Input() width: number;
  @Input() height: number;
  @Input() minWidth: number;
  @Input() minHeight: number;

  @Input() isPreviewMode: boolean;

  @Input() allowChangeMarkers: boolean;

  @Input() markerCreated$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerChanged$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Input() markerDeleted$: ng.EventEmitter<models.ImageViewerImageMarkerRef>;

  ImageKind: typeof models.ImageViewerImageKind;

  constructor() {
    this.ImageKind = models.ImageViewerImageKind;
  }

  ngOnInit(): void {
    this.width = this.width || null;
    this.height = this.height || null;
    this.minWidth = this.minWidth || null;
    this.minHeight = this.minHeight || null;
    this.isPreviewMode = this.isPreviewMode || false;

    this.markerCreated$ = this.markerCreated$ || new ng.EventEmitter<models.ImageViewerImageMarkerRef>();
    this.markerChanged$ = this.markerChanged$ || new ng.EventEmitter<models.ImageViewerImageMarkerRef>();
    this.markerDeleted$ = this.markerDeleted$ || new ng.EventEmitter<models.ImageViewerImageMarkerRef>();
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      this.imageViewerImagePanoramaComponent &&
      (
        (changes.width && changes.width.previousValue !== changes.width.currentValue) ||
        (changes.height && changes.height.previousValue !== changes.height.currentValue)
      )
    ) {
      this.imageViewerImagePanoramaComponent.resize();
    }
  }
}
