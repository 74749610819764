import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { EditLeaseSignatureDialogComponent } from './components/edit-lease-signature-dialog/edit-lease-signature-dialog.component';
import { LeaseSignatureListComponent } from './components/lease-signature-list/lease-signature-list.component';

import { LeaseSignatureService } from './services/lease-signature.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxButtonModule,
    dx.DxLookupModule,
    dx.DxTextBoxModule,
    dx.DxDropDownBoxModule,
    dx.DxDataGridModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
  ],
  declarations: [
    EditLeaseSignatureDialogComponent,
    LeaseSignatureListComponent,
  ],
  providers: [
    LeaseSignatureService,
  ],
  entryComponents: [
    EditLeaseSignatureDialogComponent,
  ],
})
export class LeaseSignatureModule {
  static forRoot(): ModuleWithProviders<LeaseSignatureModule> {
    return {
      ngModule: LeaseSignatureModule,
      providers: [
        LeaseSignatureService,
      ],
    };
  }
}
