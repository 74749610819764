import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared/tools';
import { CleanCopyService } from '../../shared/services/clean-copy.service';
import DevExpress from 'devextreme/bundles/dx.all';
import { DxDataGridComponent } from 'devextreme-angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clean-copy',
  templateUrl: './clean-copy.component.html',
  styleUrls: ['./clean-copy.component.scss'],
})
export class CleanCopyComponent implements OnInit, AfterViewInit {
  private _cleanCopyService: CleanCopyService;
  private _activatedRoute: ActivatedRoute;

  tools = Tools;

  @ViewChild('dataGrid', {static: false}) dataGrid: DxDataGridComponent;
  @ViewChild('eversignContainerElementRef', {static: true}) eversignContainerElementRef: ElementRef;

  dataSource: DevExpress.data.CustomStore;
  leasesDataSource: DevExpress.data.CustomStore;
  selectedDocId: number;
  selectedDocPreparationUrl: string;
  isPopupVisible: boolean;
  isLoading = false;

  constructor(cleanCopyService: CleanCopyService, activatedRoute: ActivatedRoute) {
    this._cleanCopyService = cleanCopyService;
    this._activatedRoute = activatedRoute;

    this.onPrepareClick = this.onPrepareClick.bind(this);
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: ['id'],
      loadUrl: `${environment.adminUrl}/cleanCopy`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });

    this.leasesDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/leases/shortLeaseModels/`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._activatedRoute.queryParams.subscribe(params => {
        const leaseAmendmentId = params['leaseAmendmentId'];
        if (leaseAmendmentId) {
          this.prepareDoc(leaseAmendmentId);
        }
      });
    });
  }

  onPrepareClick(e) {
    this.prepareDoc(e.row.data.id);

    e.event.preventDefault();
  }

  onPopupShown() {
    if (!this.selectedDocPreparationUrl || !this.eversignContainerElementRef || !this.eversignContainerElementRef.nativeElement) {
      return;
    }

    const containerChildren = this.eversignContainerElementRef.nativeElement.children;
    for (let i = 0, num = containerChildren.length; i < num; i++) {
      if (!containerChildren[i] || typeof containerChildren[i].remove !== 'function') {
        continue;
      }

      containerChildren[i].remove();
    }

    setTimeout(() => {
      (<any>window).eversignEmbeddedRequesting.open({
        url: this.selectedDocPreparationUrl,
        containerID: 'eversignContainer',
        events: {
          loaded: () => {

          },
          submit: () => {
            this.isLoading = true;
            this._cleanCopyService.movePreparedAmendmentToNextStep(this.selectedDocId).subscribe(() => {
              this.isPopupVisible = false;
              this.dataGrid.instance.refresh();
              this.isLoading = false;
            }, () => {
              this.isLoading = false;
            });
          },
          error: () => {

          },
        },
      });
    }, 500);
  }

  private prepareDoc(leaseAmendmentId: number): void {
    this.isLoading = true;
    this._cleanCopyService.getPreparationUrl(leaseAmendmentId).subscribe(url => {
        this.selectedDocId = leaseAmendmentId;
        this.selectedDocPreparationUrl = url;
        this.isPopupVisible = true;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      });
  }
}
