import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import * as models from '../../../shared/models/generated';

@Injectable({
  providedIn: 'root',
})
export class PointOfInterestService {
  private static readonly _controllerURI: string = 'points-of-interest';

  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  /**
   * Creates point of interest
   * @method PointOfInterestService.createPointOfInterest
   * @param model Point of interest view model
   * @returns Created point of interest
   */
  createPointOfInterest(model: models.IPointOfInterestViewModel): Observable<models.IPointOfInterestViewModel> {
    return this._httpClient
      .post<models.IPointOfInterestViewModel>(
        `${environment.adminUrl}/${PointOfInterestService._controllerURI}`,
        model,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Requests and returns the list of point of interest
   * @method PointOfInterestService.getPointOfInterestList
   * @returns The list of point of interest
   */
  getPointOfInterestList(): Observable<{ data: Array<models.IPointOfInterestViewModel> }> {
    return this._httpClient
      .get<{ data: Array<models.IPointOfInterestViewModel> }>(
        `${environment.adminUrl}/${PointOfInterestService._controllerURI}`,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  /**
   * Updates point of interest
   * @method PointOfInterestService.updatePointOfInterest
   * @param model Point of interest view model
   * @returns Updated point of interest
   */
  updatePointOfInterest(model: models.IPointOfInterestViewModel): Observable<models.IPointOfInterestViewModel> {
    return this._httpClient
      .put<models.IPointOfInterestViewModel>(
        `${environment.adminUrl}/${PointOfInterestService._controllerURI}/${model.id}`,
        model,
      )
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
