<h2 class="content-block">OnBoarding</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              [masterDetail]="{ enabled: true, template: 'details' }">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="company.id" [width]="70" dataType="number"></dxi-column>

  <dxi-column dataField="company.name"></dxi-column>

  <dxi-column dataField="leaseId"></dxi-column>

  <div class="flex-container"
       *dxTemplate="let detail of 'details'">
    <div class="onboarding-table-wrapper">
      <div class="table-responsive">
        <table class="onboarding-table">
          <thead *ngIf="detail.data.answers">
          <tr>
            <th>
              Question
            </th>
            <th>
              Answer
            </th>
          </tr>
          </thead>
          <tbody *ngIf="detail.data.answers">
          <tr *ngFor="let answer of detail.data.answers">
            <td>
              <a [routerLink]="">{{ getQuestionText(answer.quizQuestion, detail.data.leaseViewModel)  }}</a>
            </td>
            <td>
              <a *ngIf="answer.quizQuestion.questionType === QuestionType.File" [href]="renderAnswer(answer)">File</a>
              <span *ngIf="
                        answer.quizQuestion.questionType !== QuestionType.File &&
                        answer.quizQuestion.questionType !== QuestionType.MultipleChoice
                        && answer.quizQuestion.questionType !== QuestionType.MultipleChoiceMaxTwoItems
                      ">
                {{ renderAnswer(answer) }}
              </span>
              <ul
                *ngIf="answer.quizQuestion.questionType === QuestionType.MultipleChoice
                || answer.quizQuestion.questionType === QuestionType.MultipleChoiceMaxTwoItems
                ">
                <li *ngFor="let option of renderAnswer(answer)">{{ option }}</li>
              </ul>

              <div *ngIf="answer.quizQuestion.questionType === QuestionType.FinancialDocuments && answer.quizAnswerFiles && answer.quizAnswerFiles.length > 0">
                <ul>
                  <li *ngFor="let file of answer.quizAnswerFiles; let index = index;">
                    <a href="javascript:void(0);"
                       (click)="downloadFinancialFile($event, file)">
                      {{file.name}}
                    </a>
                  </li>
                </ul>
              </div>

              <span
                *ngIf="answer.quizQuestion.questionType === QuestionType.FinancialDocuments && (!answer.quizAnswerFiles || answer.quizAnswerFiles.length == 0)">
                No File
              </span>

              <ng-container *ngIf="answer.quizQuestion.questionType === QuestionType.LeaseDocuments">
                <span *ngIf="answer.questionOptionViewModel?.answerType === AnswerType.RequestFromLandlord">
                  Requested From Landlord
                </span>

                <span *ngIf="answer.questionOptionViewModel?.answerType === AnswerType.DocumentsOnFile">
                  Document(s) on file
                </span>

                <div *ngIf="answer.quizAnswerFiles && answer.quizAnswerFiles.length > 0">
                  <ul>
                    <li *ngFor="let file of answer.quizAnswerFiles; let index = index;">
                      <a href="javascript:void(0);"
                         (click)="downloadFinancialFile($event, file)">
                        {{file.name}}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</dx-data-grid>
