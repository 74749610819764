<h2 class="content-block">Clean Copy</h2>

<dx-load-panel message="Please wait..."
               [(visible)]="isLoading">
</dx-load-panel>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              #dataGrid>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup> </dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="leaseId" caption="Lease" cellTemplate="leaseFieldTemplate">
    <dxo-lookup [dataSource]="leasesDataSource" valueExpr="leaseId" [displayExpr]="tools.shortLeaseNameExpression"></dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'leaseFieldTemplate'" class="lookup-user-field">
    <div>{{ tools.leaseNameExpression(item.data.lease) }}</div>
  </div>

  <dxi-column dataField="buildingUnitId" caption="Building Unit" cellTemplate="buildingUnitFieldTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'buildingUnitFieldTemplate'">
    <div>{{ item.data.lease.buildingUnit?.name }}</div>
  </div>

  <dxi-column dataField="cleanCopyId" caption="Clean Copy" cellTemplate="cleanCopyFieldTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'cleanCopyFieldTemplate'">
    <div>{{ item.data.versionedFile.pdfFileVersion.name }}</div>
  </div>

  <dxi-column type="buttons" [width]="110">
    <dxi-button text="Prepare" [onClick]="onPrepareClick"></dxi-button>
  </dxi-column>

  <dxo-editing mode="popup" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false">
  </dxo-editing>
</dx-data-grid>

<dx-popup title="Prepare Clean Copy" height="95%" width="1100"
          [showTitle]="true" [showCloseButton]="true" [dragEnabled]="false"
          [(visible)]="isPopupVisible"
          (onShown)="onPopupShown()">
  <div id="eversignContainer" class="popup-body" style="text-align: center"
       #eversignContainerElementRef>
  </div>
</dx-popup>
