import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    IProfileViewModel,
    IAvatarViewModel,
    IStartupInfoViewModel
} from '../models/generated';

@Injectable()
export class ProfileService {
    primaryAddressName = 'HQ Address';
    otherAddressName = 'Other Address';
    emptyProfile: IProfileViewModel = { } as IProfileViewModel;

    constructor(private httpClient: HttpClient) { }

    save(model: IProfileViewModel): Observable<IProfileViewModel> {
        return this.httpClient.post<IProfileViewModel>(`${environment.apiUrl}/profile/Save`, model);
    }

    saveName(model: IProfileViewModel): Observable<void> {
        return this.httpClient.post<void>(`${environment.apiUrl}/profile/SaveName`, model);
    }

    load(): Observable<IProfileViewModel> {
        return this.httpClient.get<IProfileViewModel>(`${environment.apiUrl}/profile`);
    }

    saveAvatar(model: IAvatarViewModel): Observable<IStartupInfoViewModel> {
        return this.httpClient.post<IStartupInfoViewModel>(`${environment.apiUrl}/profile/SaveAvatar`, model);
    }
}
