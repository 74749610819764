<!-- Start: Space Use fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Use
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="space-use-type" valueExpr="value" displayExpr="name"
                     [(items)]="spaceUseTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.spaceUseTerm.spaceUseType"
                     #spaceUseType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.spaceUseTerm.spaceUseType === SpaceUseType.Custom">
    <!-- Start: Use Types -->
    <div class="dx-field">
      <div class="dx-field-label">
        Options
      </div>
      <div class="dx-field-value">
        <dx-tag-box name="space-use-types" displayExpr="text" required
                    [(dataSource)]="spaceUseOptions" [showClearButton]="true"
                    [(ngModel)]="spaceUseItems" (onCustomItemCreating)="addCustomUse($event)" [acceptCustomValue]="true"
                    (onValueChanged)="spaceUseChange($event)" applyValueMode="useButtons"
                    #spaceUseValues="ngModel">
        </dx-tag-box>
      </div>
    </div>
    <!-- End: Use Types -->
  </ng-container>

  <ng-container *ngIf="lease.spaceUseTerm.spaceUseType === SpaceUseType.Custom">
    <!-- Start: Document language -->
    <div class="dx-field">
      <div class="dx-field-label">
        Document language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="space-use-document-language"
                      [autoResizeEnabled]="true"
                      [(ngModel)]="lease.spaceUseTerm.customDisplayValue"
                      #spaceUseDocumentLanguage="ngModel">
        </dx-text-area>
      </div>
    </div>
    <!-- End: Document language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="space-use-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.spaceUseTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.spaceUseTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.spaceUseTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Space Use fieldset -->

<!-- Start: Self-help fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Self-help
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="self-help-type" valueExpr="value" displayExpr="name"
                     [(items)]="selfHelpTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.selfHelpTerm.selfHelpTermType"
                     #selfHelpType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.selfHelpTerm.selfHelpTermType === SelfHelpType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && selfHelpCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="self-help-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? selfHelpCustomLanguage.valid : true"
                      [(ngModel)]="lease.selfHelpTerm.selfHelp"
                      #selfHelpCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && selfHelpCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="self-help-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.selfHelpTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.selfHelpTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.selfHelpTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Self-help fieldset -->
<!-- Start: Estoppel certificate fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Estoppel certificate
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="estoppel-certificate-type" valueExpr="value" displayExpr="name"
                     [(items)]="estoppelCertificateTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.estoppelCertificateTerm.estoppelCertificateTermType"
                     #estoppelCertificateType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.estoppelCertificateTerm.estoppelCertificateTermType === EstoppelCertificateType.TenantExecuteAndProvideEstoppelCertificateAfterLandlordRequest">
    <!-- Start: Tenant deadline -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && estoppelCertificateTenantDeadline.invalid">
        Tenant deadline in days
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="estoppel-certificate-tenant-deadline" format="#0 day(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? estoppelCertificateTenantDeadline.valid : true"
                       [(ngModel)]="lease.estoppelCertificateTerm.tenantDeadlineAfterLandlordRequestInDays"
                       #estoppelCertificateTenantDeadline="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && estoppelCertificateTenantDeadline.hasError('required')">
          Tenant deadline is required
        </div>
      </div>
    </div>
    <!-- End: Tenant deadline -->
  </ng-container>

  <ng-container *ngIf="lease.estoppelCertificateTerm.estoppelCertificateTermType === EstoppelCertificateType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && estoppelCertificateCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="estoppel-certificate-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? estoppelCertificateCustomLanguage.valid : true"
                      [(ngModel)]="lease.estoppelCertificateTerm.estoppelCertificate"
                      #estoppelCertificateCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && estoppelCertificateCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="estoppel-certificate-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.estoppelCertificateTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.estoppelCertificateTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.estoppelCertificateTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Estoppel certificate fieldset -->
<!-- Start: Insurance fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Insurance
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="insurance-type" valueExpr="value" displayExpr="name"
                     [(items)]="insuranceTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.insuranceTerm.insuranceTermType"
                     #insuranceType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.insuranceTerm.insuranceTermType === InsuranceType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && insuranceCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="insurance-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? insuranceCustomLanguage.valid : true"
                      [(ngModel)]="lease.insuranceTerm.text"
                      #insuranceCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && insuranceCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="insurance-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.insuranceTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.insuranceTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.insuranceTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Insurance fieldset -->
<!-- Start: Holdover provision fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Holdover provision
  </div>
  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="holdover-provision-type" valueExpr="value" displayExpr="name"
                     [(items)]="holdoverProvisionTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.holdoverProvisionTerm.holdoverProvisionType"
                     #holdoverProvisionType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.holdoverProvisionTerm.holdoverProvisionType === HoldoverProvisionType.MonthlyRentPriorToHoldover">
    <!-- Start: Rent -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && holdoverProvisionRent.invalid">
        Rent
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="holdover-provision-rent" format="#0.##'%'" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? holdoverProvisionRent.valid : true"
                       [(ngModel)]="lease.holdoverProvisionTerm.landlordHoldoverRentInPercent"
                       #holdoverProvisionRent="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && holdoverProvisionRent.hasError('required')">
          Rent is required
        </div>
      </div>
    </div>
    <!-- End: Rent -->
  </ng-container>

  <ng-container *ngIf="lease.holdoverProvisionTerm.holdoverProvisionType === HoldoverProvisionType.TenantRemainsFollowingEndTerm">
    <!-- Start: Number of days -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && holdoverProvisionNumberOfDays.invalid">
        Number of days
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="holdover-provision-number-of-days" format="#0 day(s)" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? holdoverProvisionNumberOfDays.valid : true"
                       [(ngModel)]="lease.holdoverProvisionTerm.countOfFirstLandlordHoldoverRentDays"
                       #holdoverProvisionNumberOfDays="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && holdoverProvisionNumberOfDays.hasError('required')">
          Number of days is required
        </div>
      </div>
    </div>
    <!-- End: Number of days -->
    <!-- Start: Rent for first day -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && holdoverProvisionRentForFirstDay.invalid">
        Rent for first day
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="holdover-provision-rent-for-first-day" format="#0.##'%'" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? holdoverProvisionRentForFirstDay.valid : true"
                       [(ngModel)]="lease.holdoverProvisionTerm.landlordHoldoverRentForFirstDaysInPercent"
                       #holdoverProvisionRentForFirstDay="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && holdoverProvisionRentForFirstDay.hasError('required')">
          Rent for first day is required
        </div>
      </div>
    </div>
    <!-- End: Rent for first day -->
    <!-- Start: Landlord holdover rent -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && holdoverProvisionLandlordHoldoverRent.invalid">
        Landlord holdover rent
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="holdover-provision-landlord-holdover-rent" format="#0.##'%'" required
                       min="0" max="9999999"
                       [isValid]="form.submitted ? holdoverProvisionLandlordHoldoverRent.valid : true"
                       [(ngModel)]="lease.holdoverProvisionTerm.landlordHoldoverRentForRemainingDaysInPercent"
                       #holdoverProvisionLandlordHoldoverRent="ngModel">
        </dx-number-box>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && holdoverProvisionLandlordHoldoverRent.hasError('required')">
          Landlord holdover rent is required
        </div>
      </div>
    </div>
    <!-- End: Landlord holdover rent -->
  </ng-container>

  <ng-container *ngIf="lease.holdoverProvisionTerm.holdoverProvisionType === HoldoverProvisionType.Custom">
    <!-- Start: Custom language -->
    <div class="dx-field">
      <div class="dx-field-label"
           [class.text-danger]="form.submitted && holdoverProvisionCustomLanguage.invalid">
        Custom language
      </div>
      <div class="dx-field-value">
        <dx-text-area name="holdover-provision-custom-language" required
                      [autoResizeEnabled]="true"
                      [isValid]="form.submitted ? holdoverProvisionCustomLanguage.valid : true"
                      [(ngModel)]="lease.holdoverProvisionTerm.text"
                      #holdoverProvisionCustomLanguage="ngModel">
        </dx-text-area>
        <div class="my-1 invalid-feedback d-block"
             *ngIf="form.submitted && holdoverProvisionCustomLanguage.hasError('required')">
          Custom language is required
        </div>
      </div>
    </div>
    <!-- End: Custom language -->
  </ng-container>

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="holdover-provision-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.holdoverProvisionTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.holdoverProvisionTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.holdoverProvisionTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Holdover provision fieldset -->
<!-- Start: Force majeure fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Force majeure
  </div>

  <!-- Start: Custom language -->
  <div class="dx-field">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="force-majeure-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.forceMajeureTerm.text"
                    #forceMajeureCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="force-majeure-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.forceMajeureTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.forceMajeureTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.forceMajeureTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Force majeure fieldset -->

<!-- Start: Parking fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Parking
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="parking-type" valueExpr="value" displayExpr="name"
                     [(items)]="parkingTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.parkingTerm.parkingType"
                     #parkingType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.parkingTerm.parkingType === ParkingType.TenantRequested">
    <div class="dx-field">
      <div class="dx-field-label">
        Unreserved Parking Stalls Count
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="unreserved-parking-stalls-count" format="#0 stall(s)" required
                       min="0" max="999"
                       [(ngModel)]="lease.parkingTerm.unreservedParkingStallsCount"
                       #unreservedParkingStallsCount="ngModel">
        </dx-number-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">
        Reserved Parking Stalls Count
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="reserved-parking-stalls-count" format="#0 stall(s)" required
                       min="0" max="999"
                       [(ngModel)]="lease.parkingTerm.reservedParkingStallsCount"
                       #reservedParkingStallsCount="ngModel">
        </dx-number-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="lease.parkingTerm.parkingType === ParkingType.TenantRequested">
      <div class="dx-field-label">
        Unreserved Trailer Drops Count
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="parking-stalls-count" format="#0 drop(s)" required
                       min="0" max="999"
                       [(ngModel)]="lease.parkingTerm.unreservedTrailerDropsCount"
                       #unreservedTrailerDropsCount="ngModel">
        </dx-number-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="lease.parkingTerm.parkingType === ParkingType.TenantRequested">
      <div class="dx-field-label">
        Reserved Trailer Drops Count
      </div>
      <div class="dx-field-value">
        <dx-number-box appIgnoreScrollwheel name="parking-stalls-count" format="#0 drop(s)" required
                       min="0" max="999"
                       [(ngModel)]="lease.parkingTerm.reservedTrailerDropsCount"
                       #reservedTrailerDropsCount="ngModel">
        </dx-number-box>
      </div>
    </div>
  </ng-container>

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.parkingTerm.parkingType === ParkingType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="parking-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.parkingTerm.customDisplayValue"
                    #parkingCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="parking-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.parkingTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.parkingTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.parkingTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Parking fieldset -->

<!-- Start: Utilities fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Utilities
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="utilities-type" valueExpr="value" displayExpr="name"
                     [(items)]="utilitiesTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.utilitiesTerm.utilitiesType"
                     #utilitiesType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <!-- Start: Tenant Metered Utilities -->
  <div class="dx-field" *ngIf="lease.utilitiesTerm.utilitiesType === UtilitiesType.LandlordPaysExcept">
    <div class="dx-field-label">
      Tenant Metered Utilities
    </div>
    <div class="dx-field-value">
      <dx-tag-box name="tenant-metered-utilities" displayExpr="text" required
                  [(dataSource)]="tenantMeteredOptions" [showClearButton]="true"
                  [(ngModel)]="tenantMeteredItems" (onValueChanged)="utilitiesChange($event)"
                  applyValueMode="useButtons"
                  #tenantMeteredUtilities="ngModel">
      </dx-tag-box>
    </div>
  </div>
  <!-- End: Tenant Metered Utilities -->

  <ng-container *ngIf="lease.utilitiesTerm.utilitiesType &&
                        (lease.utilitiesTerm.utilitiesType === UtilitiesType.LandlordPays ||
                         lease.utilitiesTerm.utilitiesType === UtilitiesType.LandlordPaysExcept)">
    <div class="dx-field">
      <div class="dx-field-value">
        <dx-check-box name="utilities-green-lease-checkbox" text="Green Lease Option 🌿"
                      [(ngModel)]="lease.utilitiesTerm.hasGreenLeaseOption">
        </dx-check-box>
      </div>
    </div>

    <div class="dx-field" *ngIf="lease.utilitiesTerm.hasGreenLeaseOption">
      <div class="dx-field-value">
        <dx-select-box name="utilities-type" valueExpr="value" displayExpr="name" required
                       [(items)]="utilitiesGreenLeaseOptionsList" [showClearButton]="true"
                       [(ngModel)]="lease.utilitiesTerm.greenLeaseOption"
                       #utilitiesGreenLeaseOption="ngModel">
        </dx-select-box>
      </div>
    </div>
  </ng-container>

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.utilitiesTerm.utilitiesType === UtilitiesType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="utilities-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.utilitiesTerm.customDisplayValue"
                    #utilitiesCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="utilities-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.utilitiesTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.utilitiesTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.utilitiesTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Utilities fieldset -->

<!-- Start: Signage fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Signage
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="signage-type" valueExpr="value" displayExpr="name"
                     [(items)]="signageTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.signageTerm.signageType"
                     #signageType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.signageTerm.signageType === SignageType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="signage-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.signageTerm.customDisplayValue"
                    #signageCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="signage-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.signageTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.signageTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.signageTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Signage fieldset -->

<!-- Start: Non-Disturbance fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Non-Disturbance
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="non-disturbance-type" valueExpr="value" displayExpr="name"
                     [(items)]="nonDisturbanceTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.nonDisturbanceTerm.nonDisturbanceTermType"
                     #nonDisturbanceType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.nonDisturbanceTerm.nonDisturbanceTermType === NonDisturbanceTermType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="non-disturbance-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.nonDisturbanceTerm.customDisplayValue"
                    #nonDisturbanceCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="non-disturbance-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.nonDisturbanceTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.nonDisturbanceTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.nonDisturbanceTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Non-Disturbance fieldset -->

<!-- Start: Rail fieldset -->
<div class="dx-fieldset">
  <div class="dx-fieldset-header">
    Rail
  </div>

  <!-- Start: Type -->
  <div class="dx-field">
    <div class="dx-field-label">
      Type
    </div>
    <div class="dx-field-value">
      <dx-select-box name="rail-type" valueExpr="value" displayExpr="name"
                     [(items)]="railTypesList" [showClearButton]="true"
                     [(ngModel)]="lease.railTerm.railType"
                     #railType="ngModel">
      </dx-select-box>
    </div>
  </div>
  <!-- End: Type -->

  <ng-container *ngIf="lease.railTerm.railType === RailTermType.RailProvidedBy">
    <!-- Start: Rail Provider -->
    <div class="dx-field" >
      <div class="dx-field-label">
        Rail Provider
      </div>
      <div class="dx-field-value">
        <dx-text-area name="rail-provider"
                      [autoResizeEnabled]="true"
                      [(ngModel)]="lease.railTerm.railProvider"
                      #railProvider="ngModel">
        </dx-text-area>
      </div>
    </div>
    <!-- End: Rail Provider -->
  </ng-container>

  <!-- Start: Sole use -->
  <div class="dx-field"
       *ngIf="
         lease.railTerm.railType === RailTermType.IndustryTrackAgreementPossible ||
         lease.railTerm.railType === RailTermType.LandlordResponsibility ||
         lease.railTerm.railType === RailTermType.TenantResponsibility ||
         lease.railTerm.railType === RailTermType.RailProvidedBy ||
         lease.railTerm.railType === RailTermType.Inactive
      ">
    <div class="dx-field-value">
      <dx-check-box name="rail-sole-use-checkbox" text="Sole Use"
                    [(ngModel)]="lease.railTerm.soleUse">
      </dx-check-box>
    </div>
  </div>
  <!-- End: Sole use -->

  <!-- Start: Custom language -->
  <div class="dx-field" *ngIf="lease.railTerm.railType === RailTermType.Custom">
    <div class="dx-field-label">
      Custom language
    </div>
    <div class="dx-field-value">
      <dx-text-area name="rail-custom-language"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.railTerm.customDisplayValue"
                    #railCustomLanguage="ngModel">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Custom language -->

  <!-- Start: Document section -->
  <div class="dx-field">
    <div class="dx-field-label">
      Document section
    </div>
    <div class="dx-field-value">
      <dx-text-area name="rail-document-section"
                    [autoResizeEnabled]="true"
                    [(ngModel)]="lease.railTerm.documentSection">
      </dx-text-area>
    </div>
  </div>
  <!-- End: Document section -->

  <!-- Start: Term preview -->
  <app-import-form-term-preview *ngIf="leasePreview"
                                [leaseTermText]="leasePreview?.railTerm?.detailedDisplayValue"
                                [documentSection]="leasePreview?.railTerm?.documentSection">
  </app-import-form-term-preview>
  <!-- End: Term preview -->
</div>
<!-- End: Rail fieldset -->
