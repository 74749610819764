import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Tools } from 'src/app/shared/tools';
import { IStateraClaim, StateraClaimTypeAsEnum, StateraClaimValueAsEnum } from '../../shared/models/generated';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import {RoleNames} from '../../shared/models/roles';

@Component({
  selector: 'app-statera-user-claims',
  templateUrl: './statera-user-claims.component.html',
  styleUrls: ['./statera-user-claims.component.scss']
})
export class StateraUserClaimsComponent implements OnInit {
  @ViewChild('portfolioControl', {read: DxiItemComponent, static: false}) portfolioControl: DxiItemComponent;
  @ViewChild('buildingControl', {read: DxiItemComponent, static: false}) buildingControl: DxiItemComponent;
  @ViewChild('companyControl', {read: DxiItemComponent, static: false}) companyControl: DxiItemComponent;
  @ViewChild('leaseControl', {read: DxiItemComponent, static: false}) leaseControl: DxiItemComponent;

  Tools = Tools;
  dataSource: any;
  url = `${environment.adminUrl}/stateraUserClaim`;
  showOptionsForLandlord = true;

  get _window() {
    return window;
  }

  companyId: number;
  portfolioId: number;
  buildingId: number;
  userId: number;
  leaseId: number;

  companyDataStore: CustomStore;
  portfoliosDataStore: CustomStore;
  leaseDataSource: CustomStore;
  cellBuildingDataSource: CustomStore;
  stateraClaimDataSource: CustomStore;
  stateraClaimGroupDataSource: CustomStore;
  userDataStore: CustomStore;

  defaultEditorOptions = {showClearButton: true};
  userEditorOptions: any = {...this.defaultEditorOptions};
  companyEditorOptions: any = {...this.defaultEditorOptions};
  portfolioEditorOptions: any = {...this.defaultEditorOptions};
  leaseEditorOptions: any = {...this.defaultEditorOptions};
  buildingEditorOptions: any = {...this.defaultEditorOptions};
  claimEditorOptions: any = {...this.defaultEditorOptions};
  claimGroupEditorOptions: any = {...this.defaultEditorOptions};
  users: any[];

  constructor() {
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      }
    });

    this.companyDataStore = Tools.lookupDataSource('company');
    this.companyEditorOptions = {
      ...this.defaultEditorOptions,
      dataSource: this.companyDataStore,
      valueExpr: 'id',
      searchExpr: ['id', 'name'],
      value: this.companyId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.companyId = event.value;
        this.portfolioId = null;
        this.buildingId = null;
        if (this.portfolioControl && this.portfolioControl.instance) {
          const dataSource = this.portfolioControl.instance.getDataSource();
          dataSource.load();
          this.portfolioControl.disabled = false;
        }
        return event.value;
      },
    };

    this.portfoliosDataStore = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/portfolio?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.companyId) {
          ajaxOptions.data.companyId = this.companyId;
        }
      },
    });
    this.portfolioEditorOptions = {
      ...this.defaultEditorOptions,
      dataSource: this.portfoliosDataStore,
      valueExpr: 'id',
      searchExpr: ['id', 'name'],
      value: this.portfolioId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.portfolioId = event.value;
        this.buildingId = null;
        if (this.buildingControl && this.buildingControl.instance) {
          const dataSource = this.buildingControl.instance.getDataSource();
          dataSource.load();
          this.buildingControl.disabled = false;
        }
        return event.value;
      },
    };

    this.leaseDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/leases/shortLeaseModels?desc=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });
    this.leaseEditorOptions = {
      ...this.defaultEditorOptions,
      dataSource: this.leaseDataSource,
      value: this.leaseId,
      valueExpr: 'id',
      searchExpr: ['id', 'buildingName'],
    };

    this.cellBuildingDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/building?withDeleted=false`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.portfolioId) {
          ajaxOptions.data.id = this.portfolioId;
        }
      },
    });
    this.buildingEditorOptions = {
      ...this.defaultEditorOptions,
      dataSource: this.cellBuildingDataSource,
      valueExpr: 'id',
      searchExpr: ['id', 'name'],
      value: this.buildingId,
    };

    this.userDataStore = Tools.lookupDataSource('user/Get')
      .on('loaded', data => {
        this.users = data;
      });
    this.userEditorOptions = {
      ...this.defaultEditorOptions,
      dataSource: this.userDataStore,
      valueExpr: 'id',
      searchExpr: ['id', 'firstName', 'lastName'],
      value: this.userId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.userId = event.value;
        this.buildingId = null;
        this.portfolioId = null;
        this.companyId = null;
        this.leaseId = null;
        this.userDataStore.byKey(event.value).then((user: any) => {
          if (user && user.role === RoleNames.Landlord) {
            this.showOptionsForLandlord = true;
            if (this.companyControl && this.companyControl.instance) {
              const dataSource = this.companyControl.instance.getDataSource();
              dataSource.load();
              this.companyControl.disabled = false;
            }
          } else {
            this.showOptionsForLandlord = false;
            this.companyControl.disabled = true;
          }
        });
        return event.value;
      },
    };

    this.stateraClaimDataSource = Tools.lookupDataSource('stateraClaim');
    this.stateraClaimGroupDataSource = Tools.lookupDataSource('stateraClaimGroup');
  }

  getBuildingPicture(item) {
    if (item && item.data && item.data.building) {
      return Tools.buildingPictureThumbnail(item.data.building) || Tools.noImageUrl;
    }

    if (item && item.picture) {
      return Tools.buildingPictureThumbnail(item) || Tools.noImageUrl;
    }

    return Tools.noImageUrl;
  }

  getBuildingName(item) {
    if (item && item.data && item.data.building) {
      return item.data.building.name;
    } else if (item) {
      return item.name;
    }
    return '';
  }

  getClaimName(item: IStateraClaim) {
    if (!item) {
      return '';
    }

    let result = StateraClaimTypeAsEnum[item.claimType];

    if (item.claimValue) {
      result += ' ' + StateraClaimValueAsEnum[item.claimValue];
    }

    return result;
  }

  initNewRow(e) {
    this.companyId = null;
    this.portfolioId = null;
    this.buildingId = null;

    if (this.portfolioControl && this.portfolioControl.instance) {
      this.portfolioControl.disabled = true;
    }
    if (this.buildingControl && this.buildingControl.instance) {
      this.buildingControl.disabled = true;
    }
    if (this.companyControl && this.companyControl.instance) {
      this.companyControl.disabled = true;
    }
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'companyId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.companyEditorOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        if (previousOnValueChanged) {
          await previousOnValueChanged(event);
        }
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'portfolioId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.portfolioEditorOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        if (previousOnValueChanged) {
          await previousOnValueChanged(event);
        }
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'buildingId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.buildingEditorOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        if (previousOnValueChanged) {
          await previousOnValueChanged(event);
        }
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'userId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.userEditorOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        if (previousOnValueChanged) {
          await previousOnValueChanged(event);
        }
      };
    }
  }
}
