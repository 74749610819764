import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()
export class CleanCopyService {
  _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  getPreparationUrl(leaseAmendmentId: number): Observable<string> {
    return this._httpClient.post<string>(`${environment.adminUrl}/cleanCopy/GetPreparationUrl/${leaseAmendmentId}`, null);
  }

  movePreparedAmendmentToNextStep(leaseAmendmentId: number): Observable<void> {
    return this._httpClient.post<void>(`${environment.adminUrl}/cleanCopy/MovePreparedAmendmentToNextStep/${leaseAmendmentId}`, null);
  }
}
