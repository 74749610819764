<div class="content-block">
  <h2>
    Lease Users
  </h2>

  <dx-data-grid class="dx-card wide-card"
                [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true"
                [columnHidingEnabled]="true" [masterDetail]="{ enabled: false }" [remoteOperations]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                #dxDataGrid>

    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
    <dxo-paging [pageSize]="15"></dxo-paging>

    <dxo-editing mode="row"
                 [allowDeleting]="true">
    </dxo-editing>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder></dxo-filter-builder>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxi-column dataField="userId" caption="User" cellTemplate="userFieldTemplate">
      <dxo-lookup valueExpr="id"
                  [dataSource]="userDataSource" [displayExpr]="Tools.getDisplayExpression">
      </dxo-lookup>
    </dxi-column>

    <div class="lookup-user-field"
         *dxTemplate="let item of 'userFieldTemplate'">
      <img alt=""
           [src]="getUserAvatar(item)" />
      <div>
        {{ getUserDisplayName(item) }}
      </div>
    </div>

    <dxi-column dataField="companyId" caption="Company">
      <dxo-lookup valueExpr="id" displayExpr="name"
                  [dataSource]="companyDataSource">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="portfolioId" caption="Portfolio">
      <dxo-lookup valueExpr="id"
                  [dataSource]="portfolioDataSource" [displayExpr]="Tools.portfolioNameExpression">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="buildingId" caption="Building">
      <dxo-lookup valueExpr="id"
                  [dataSource]="buildingDataSource" [displayExpr]="Tools.buildingNameExpression">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="leaseId" caption="Lease">
      <dxo-lookup valueExpr="leaseId"
                  [dataSource]="leaseDataSource" [displayExpr]="Tools.shortLeaseNameExpression">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="leaseUserInfo" caption="Role" cellTemplate="roleFieldTemplate">
    </dxi-column>

    <div class="lookup-user-field text-left"
         *dxTemplate="let item of 'roleFieldTemplate'">
      <div>
        {{getRole(item.data)}}
      </div>
    </div>

    <dxi-column dataField="claims" caption="Claims" cellTemplate="claimsFieldTemplate">
    </dxi-column>

    <div class="lookup-user-field text-left"
         *dxTemplate="let item of 'claimsFieldTemplate'">
      <div *ngIf="item.data?.claims && item.data?.claims?.length; else noClaims">
        {{getClaimsList(item.data?.claims)}}
      </div>
      <ng-template #noClaims>
        <div>
          –
        </div>
      </ng-template>
    </div>

    <dxi-column dataField="isInvitationAccepted" caption="Is Invitation Accepted" dataType="boolean">
    </dxi-column>

    <dxi-column type="buttons">
      <dxi-button text="Accept"
                  [visible]="isAcceptButtonVisible"
                  [onClick]="acceptInvitation">
      </dxi-button>
      <dxi-button text="Edit"
                  [onClick]="editLeaseUser">
      </dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

  </dx-data-grid>
</div>
