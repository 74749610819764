export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Company Management',
    icon: 'group',
    expanded: true,
    items: [
      {
        text: 'Companies',
        path: '/companies'
      },
      {
        text: 'Company Features',
        path: '/company-features',
      },
    ],
  },
  {
    text: 'User Management',
    icon: 'user',
    expanded: true,
    items: [
      {
        text: 'Users',
        path: '/users'
      },
      {
        text: 'User Invitation Requests',
        path: '/user-invitation-requests',
      },
      {
        text: 'User Claims',
        path: '/statera-user-claims'
      },
      {
        text: 'Claim Groups',
        path: '/statera-claim-groups'
      },
    ],
  },
  {
    text: 'Portfolio Management',
    icon: 'product',
    expanded: true,
    items: [
      {
        text: 'Portfolios',
        path: '/portfolios'
      },
      {
        text: 'Portfolio Invitations',
        path: '/portfolio-invitations'
      },
      {
        text: 'Portfolio Invitation Users',
        path: '/portfolio-invitation-users'
      },
      {
        text: 'Buildings',
        path: '/buildings'
      },
      {
        text: 'Building Attachments',
        path: '/building-attachments'
      },
      {
        text: 'Building Units',
        path: 'pages/building-units'
      },
      {
        text: 'Building Unit Listings',
        path: 'building-unit-listings'
      },
      {
        text: 'Floor Plans',
        path: '/floor-plans'
      },
      {
        text: 'Site Plans',
        path: '/site-plans'
      },
      {
        text: 'Points Of Interest',
        path: '/points-of-interest'
      },
    ],
  },
  {
    text: 'Lease Management',
    icon: 'doc',
    expanded: true,
    items: [
      {
        text: 'Leases',
        path: '/leases'
      },
      {
        text: 'Lease Templates',
        path: '/lease-templates',
      },
      {
        text: 'Lease Users',
        path: '/lease-users',
      },
      {
        text: 'Lease Floor Plans',
        path: '/lease-floor-plans'
      },
      {
        text: 'Projects',
        path: '/projects'
      },
      {
        text: 'Onboarding',
        path: '/onboarding'
      },
      {
        text: 'Clean Copy',
        path: '/clean-copy'
      },
      {
        text: 'Market Rental Rates',
        path: '/market-rental-rates'
      },
      {
        text: 'Lease Signers',
        path: '/lease-signers',
      },
      {
        text: 'LOI Signatures',
        path: '/lease-signatures',
      },
      {
        text: 'Amendment Signatures',
        path: '/lease-amendments',
      },
      {
        text: '3rd Party Leases',
        path: '/3rd-party-leases',
      },
    ]
  },
  {
    text: 'System Management',
    expanded: false,
    icon: 'preferences',
    items: [
      {
        text: 'Global Features',
        icon: 'favorites',
        path: '/global-features',
      },
      {
        text: 'Tasks',
        icon: 'event',
        items: [
          {
            text: 'Import Tasks',
            path: '/import-tasks',
          },
        ],
      },
      {
        text: 'Dictionaries',
        icon: 'datafield',
        items: [
          {
            text: 'Countries',
            path: '/countries'
          },
          {
            text: 'States',
            path: '/states'
          },
          {
            text: 'Markets',
            path: '/markets'
          },
          {
            text: 'SubMarkets',
            path: '/submarkets'
          },
          {
            text: 'Counties',
            path: '/counties'
          },
          {
            text: 'User roles',
            path: '/userroles'
          }
        ]
      },
      {
        text: 'Tools',
        icon: 'box',
        items: [
          {
            text: 'Thumbnails',
            path: '/thumbnails'
          }
        ]
      }
    ]
  },
];
