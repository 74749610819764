import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IFile } from '../models/generated';
import { environment } from 'src/environments/environment';

@Injectable()
export class ThumbnailsService {
    
    constructor(private httpClient: HttpClient) { }

    getImagesWithoutThumbnails(): Observable<IFile[]> { 
        return this.httpClient.get<IFile[]>(`${environment.adminUrl}/thumbnails/GetImages`);
    }

    generate(ids: number[]): Observable<number[]> {
        return this.httpClient.post<number[]>(`${environment.adminUrl}/thumbnails/Generate`, ids);
    }
}
