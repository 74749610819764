import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as models from '../../../shared/models/generated';

@Injectable()
export class PortfolioService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  getLandlordPortfolios(landlordId: number): Observable<Array<models.IPortfolioViewModel>> {
    let params = new HttpParams();
    if (landlordId) {
      params = params.set('landlordId', landlordId.toString(10));
    }

    return this._httpClient
      .get<Array<models.IPortfolioViewModel>>('/admin/Portfolio/landlord-portfolios', {params});
  }

  /**
   * Requests and returns the list portfolios
   * @method SitePlanService.getPortfolioList
   * @returns The list of portfolios
   */
  getPortfolioList(): Observable<{ data: Array<models.IPortfolioViewModel> }> {
    return this._httpClient
      .get<{ data: Array<models.IPortfolioViewModel> }>(`/admin/portfolio`)
      .pipe(
        catchError(err => this._catchError(err)),
      );
  }

  private _catchError(err: Error): Observable<never> {
    console.error('An error occured:', err);

    return throwError(err);
  }
}
