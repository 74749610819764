import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Tools } from 'src/app/shared/tools';

@Component({
  selector: 'app-portfolios',
  templateUrl: './portfolios.component.html',
  styleUrls: ['./portfolios.component.scss']
})
export class PortfoliosComponent implements OnInit {
  Tools = Tools;
  dataSource: any;
  url = `${environment.adminUrl}/portfolio`;
  get _window() {
    return window;
  }
  companyDataStore: CustomStore;
  cellCompanyDataStore: CustomStore;

  constructor() {}

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      insertUrl: this.url,
      updateUrl: this.url,
      deleteUrl: this.url,
      onBeforeSend: function(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true };
      }
    });
    Tools
      .lookupDataSource('Company')
      .on('loaded', data => { this.companyDataStore = data; })
      .load();
    this.cellCompanyDataStore = Tools.lookupDataSource('company');
  }
}
