<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">

    <!-- Start: Company select -->
    <div class="dx-fieldset"
         *ngIf="isCreate">
      <div class="dx-fieldset-header mb-1">
        Company
      </div>

      <p class="text-black-50">If feature flags have already been created for the selected company, they will be overridden</p>

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-lookup name="company" required
                     noDataText="No companies found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="companyDataSource"
                     [isValid]="form.submitted ? company.valid : true"
                     [(ngModel)]="companyFeature.companyId"
                     #company="ngModel">
            <ng-container *dxTemplate="let company of 'field'">
              <ng-container *ngIf="company; else noCompany">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noCompany>
                <span class="text-muted">
                  Select company
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let company of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && company.hasError('required')">
            Company is required
          </div>
        </div>
      </div>
    </div>
    <!-- Start: Company select -->
    <!-- Start: Features -->
    <div class="dx-fieldset">
      <div class="dx-fieldset-header mb-1">
        Features
      </div>

      <p class="text-black-50">If you want to enable a feature for a company but leave it disabled globally then you need "Danger zone"</p>

      <table class="table">
        <thead>
        <tr>
          <th>Flag</th>
          <th>Feature</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let feature of features; let index = index">
          <td>
            <dx-check-box [name]="'flag-' + index"
                          [(ngModel)]="companyFeature[feature.model]">
            </dx-check-box>
          </td>
          <td class="text-nowrap">{{feature.name}}</td>
          <td>{{feature.description}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- End: Features -->
    <!-- Start: Danger zone -->
    <div class="dx-fieldset">
      <div class="dx-fieldset-header mb-1 text-danger">
        Danger zone
      </div>

      <p class="text-danger-50">Be aware this is very dangerous 😈</p>

      <table class="table">
        <thead>
        <tr>
          <th>Flag</th>
          <th>Feature</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <dx-check-box name="ignore-globals"
                          [(ngModel)]="companyFeature.isIgnoresGlobals">
            </dx-check-box>
          </td>
          <td class="text-nowrap">
            Ignore globals
          </td>
          <td>
            If enabled, global feature toggle will be ignored and the feature
            will be enabled/disabled depending on the flag above
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- End: Danger zone -->

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%"
                     [text]="isCreate ? 'Create' : 'Update'"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="cancel()">
          </dx-button>
        </div>
      </div>
    </div>

  </form>
</dx-scroll-view>
