<h2 class="content-block">Avatar</h2>

<div class="dx-card content-block responsive-paddings">
  <div class="avatar-toolbar">
    <div class="avatar-toolbar-container">
      <dx-toolbar [items]="toolbarItems"></dx-toolbar>
    </div>
  </div>
  <img #croppie="croppie" [croppieOptions]="croppieOptions" />
</div>

<dx-file-uploader
  #fileUploader
  id="file"
  name="file"
  [uploadUrl]="uploadUrl"
  (onUploaded)="onUploaded($event)"
  [visible]="false"
>
</dx-file-uploader>
