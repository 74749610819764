<div class="content-block">
  <h2>
    Amendment Signatures
  </h2>

  <dx-data-grid class="dx-card wide-card"
                [dataSource]="dataSource" [showBorders]="true" [columnAutoWidth]="true"
                [columnHidingEnabled]="true" [masterDetail]="{ enabled: false }" [remoteOperations]="true"
                #dxDataGrid>

    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
    <dxo-paging [pageSize]="15"></dxo-paging>

    <dxo-editing mode="row"
                 [allowUpdating]="true" [allowDeleting]="false">
    </dxo-editing>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder></dxo-filter-builder>
    <dxo-filter-builder-popup></dxo-filter-builder-popup>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxi-column dataField="id" [width]="70" dataType="number">
    </dxi-column>

    <dxi-column dataField="leaseId" caption="Lease">
      <dxo-lookup valueExpr="leaseId"
                  [dataSource]="leaseDataSource" [displayExpr]="Tools.shortLeaseNameExpression">
      </dxo-lookup>
    </dxi-column>

    <dxi-column caption="Landlord side" cellTemplate="landlordSideFieldTemplate">
    </dxi-column>

    <div class="lookup-user-field text-left"
         *dxTemplate="let item of 'landlordSideFieldTemplate'">
      <div *ngIf="item?.data?.landlordEmail || item?.data?.landlord; else noLandlordTemplate">
        <ng-container *ngIf="item.data.isExternalLandlordEmail; else internalLandlordTemplate">
          <p class="mb-0">
            {{item.data.landlordName}}
          </p>
          <p class="mb-0 text-muted">
            {{item.data.landlordEmail}}
          </p>
        </ng-container>
        <ng-template #internalLandlordTemplate>
          <p class="mb-0">
            <ng-container *ngIf="item.data.landlord?.profile; else noProfile">
              {{item.data.landlord?.profile?.firstName}}
              {{item.data.landlord?.profile?.lastName}}
            </ng-container>
            <ng-template #noProfile>
              {{item.data.landlordName}}
            </ng-template>
          </p>
          <p class="mb-0 text-muted">
            <ng-container *ngIf="item.data.landlord?.email; else noEmail">
              {{item.data.landlord?.email}}
            </ng-container>
            <ng-template #noEmail>
              {{item.data.landlordEmail}}
            </ng-template>
          </p>
        </ng-template>
      </div>
      <ng-template #noLandlordTemplate>
        <div>
          –
        </div>
      </ng-template>
    </div>

    <dxi-column caption="Tenant side" cellTemplate="tenantSideFieldTemplate">
    </dxi-column>

    <div class="lookup-user-field text-left"
         *dxTemplate="let item of 'tenantSideFieldTemplate'">
      <div *ngIf="item?.data?.tenantEmail || item?.data?.tenant; else noTenantTemplate">
        <ng-container *ngIf="item.data.isExternalTenantEmail; else internalTenantTemplate">
          <p class="mb-0">
            {{item.data.tenantName}}
          </p>
          <p class="mb-0 text-muted">
            {{item.data.tenantEmail}}
          </p>
        </ng-container>
        <ng-template #internalTenantTemplate>
          <p class="mb-0">
            <ng-container *ngIf="item.data.tenant?.profile; else noProfile">
              {{item.data.tenant?.profile?.firstName}}
              {{item.data.tenant?.profile?.lastName}}
            </ng-container>
            <ng-template #noProfile>
              {{item.data.tenantName}}
            </ng-template>
          </p>
          <p class="mb-0 text-muted">
            <ng-container *ngIf="item.data.tenant?.email; else noEmail">
              {{item.data.tenant?.email}}
            </ng-container>
            <ng-template #noEmail>
              {{item.data.tenantEmail}}
            </ng-template>
          </p>
        </ng-template>
      </div>
      <ng-template #noTenantTemplate>
        <div>
          –
        </div>
      </ng-template>
    </div>

    <dxi-column caption="Signed by Landlord" dataType="boolean"
                [calculateCellValue]="calculateIsSignedByLandlord">
    </dxi-column>

    <dxi-column caption="Signed by Tenant" dataType="boolean"
                [calculateCellValue]="calculateIsSignedByTenant">
    </dxi-column>

    <dxi-column type="buttons">
      <dxi-button text="Download"
                  [onClick]="downloadLeaseSignatureDocument" [visible]="canShowDownload">
      </dxi-button>
      <dxi-button name="edit"
                  [onClick]="editLeaseSignature">
      </dxi-button>
    </dxi-column>

  </dx-data-grid>
</div>
