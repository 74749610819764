import { Component, Input, Output } from '@angular/core';

import * as ng from '@angular/core';

import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import * as models from '../../models/image-viewer-image.model';

import { ImageCacheService } from '../../services/image-cache.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: 'image-viewer.component.html',
  styleUrls: ['image-viewer.component.scss'],
})
export class ImageViewerComponent implements ng.OnInit, ng.AfterViewInit, ng.OnChanges {
  @Input() images: Array<models.ImageViewerImage>;

  @Input() minWidth: number;
  @Input() minHeight: number;

  @Input() container: Element;

  @Input() enableArrowNavigation: boolean;
  @Input() enablePreviewNavigation: boolean;
  @Input() enableZoom = true;

  @Input() activeImageIndex: number;
  @Input() isPreviewMode: boolean;
  @Input() allowChangeMarkers: boolean;

  @Output() imageLoaded: ng.EventEmitter<models.ImageViewerImageRef>;
  @Output() zoomClicked: ng.EventEmitter<models.ImageViewerImageRef>;

  @Output() markerCreated: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Output() markerChanged: ng.EventEmitter<models.ImageViewerImageMarkerRef>;
  @Output() markerDeleted: ng.EventEmitter<models.ImageViewerImageMarkerRef>;

  containerSize: {width: number, height: number};

  private _imageCache: models.ImageViewerImageCache;

  private readonly _renderer: ng.Renderer2;
  private readonly _changeDetectorRef: ng.ChangeDetectorRef;
  private readonly _ngZone: ng.NgZone;
  private readonly _imageCacheService: ImageCacheService;

  private readonly _destroy$: Subject<void>;
  private readonly _isRendered$: ReplaySubject<boolean>;

  constructor(
    renderer: ng.Renderer2,
    changeDetectorRef: ng.ChangeDetectorRef,
    ngZone: ng.NgZone,
    imageCacheService: ImageCacheService,
  ) {
    this._renderer = renderer;
    this._changeDetectorRef = changeDetectorRef;
    this._ngZone = ngZone;
    this._imageCacheService = imageCacheService;

    this._destroy$ = new Subject<void>();
    this._isRendered$ = new ReplaySubject<boolean>(1);

    this._imageCache = new models.ImageViewerImageCache();

    this.imageLoaded = new ng.EventEmitter<models.ImageViewerImageRef>();
    this.zoomClicked = new ng.EventEmitter<models.ImageViewerImageRef>();

    this.markerCreated = new ng.EventEmitter<models.ImageViewerImageMarkerRef>(true);
    this.markerChanged = new ng.EventEmitter<models.ImageViewerImageMarkerRef>(true);
    this.markerDeleted = new ng.EventEmitter<models.ImageViewerImageMarkerRef>(true);
  }

  ngOnInit(): void {
    this.minWidth = this.minWidth || -1;
    this.minHeight = this.minHeight || -1;
    this.enableArrowNavigation = this.enableArrowNavigation || false;
    this.enablePreviewNavigation = this.enablePreviewNavigation || false;
    this.activeImageIndex = this.activeImageIndex || 0;
    this.isPreviewMode = this.isPreviewMode || false;
    this.containerSize = {width: 0, height: 0};

    this._isRendered$
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.setupContainerSize())
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this._isRendered$.next(true);
  }

  ngOnChanges(changes: ng.SimpleChanges): void {
    // clear image cache if list of images changed
    if (changes && changes.images) {
      if (changes.images.isFirstChange()) {
        this._restoreImageCache(false /** resetActiveImageIndex */);
        return;
      }

      const nextImages = changes.images.currentValue;
      const currImages = changes.images.previousValue;

      if (!nextImages || !currImages) {
        return;
      }

      if (currImages.length !== nextImages.length) {
        this._restoreImageCache();
        return;
      }

      const nextImageUrls = nextImages.map(x => x.imageUrl);
      const prevImageUrls = currImages.map(x => x.imageUrl);
      for (let i = 0, num = prevImageUrls.length; i < num; i++) {
        if (!nextImageUrls.includes(prevImageUrls[i])) {
          this._restoreImageCache();
          return;
        }
      }
    }
  }

  hasNextImage(): boolean {
    return this.activeImageIndex + 1 < this.images.length;
  }

  hasPreviousImage(): boolean {
    return 0 <= this.activeImageIndex - 1;
  }

  nextImage(): void {
    if (this.hasNextImage()) {
      this.activeImageIndex++;
    }
  }

  previousImage(): void {
    if (this.hasPreviousImage()) {
      this.activeImageIndex--;
    }
  }

  selectImage(index: number): void {
    if (index < 0 || this.images.length <= index) {
      return;
    }

    this.activeImageIndex = index;
  }

  getCachedImageRefs$(): Array<Observable<models.ImageViewerImageRef>> {
    const imageRefs$: Array<Observable<models.ImageViewerImageRef>> = [];

    const indices = Object.keys(this._imageCache);
    for (let i = 0, num = indices.length; i < num; i++) {
      imageRefs$[i] = this._imageCache[i];
    }

    return imageRefs$;
  }

  getCachedImageRef$(index: number): Observable<models.ImageViewerImageRef> {
    if (!this._imageCache[index]) {
      this._imageCache[index] = this._getImageRef$(index)
        .pipe(
          take(1),
          tap(x => this.imageLoaded.emit(x)),
        );
    }

    return this._imageCache[index];
  }

  private _restoreImageCache(resetActiveImageIndex: boolean = true): void {
    this._imageCache = {};

    if (resetActiveImageIndex) {
      this.activeImageIndex = 0;
    }

    for (let i = 0, num = this.images.length; i < num; i++) {
      if (this._imageCache[i]) {
        continue;
      }

      const imageRef$ = this._getImageRef$(i)
        .pipe(
          take(1),
          tap(x => this.imageLoaded.emit(x)),
        );

      imageRef$.subscribe();

      this._imageCache[i] = imageRef$;
    }
  }

  setupContainerSize(): void {
    if (!this.container) {
      return;
    }

    const clientRect = this.container.getBoundingClientRect();
    if (!clientRect) {
      return;
    }

    this.containerSize = {
      width: clientRect.width,
      height: clientRect.height,
    };

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  private _getImage(index: number): models.ImageViewerImage {
    if (index < 0 || this.images.length <= index || !this.images[index]) {
      return null;
    }

    return this.images[index];
  }

  private _getImageRef$(index: number): Observable<models.ImageViewerImageRef> {
    const image = this._getImage(index);
    if (!image || !image.imageUrl) {
      return of(null);
    }

    return this._imageCacheService
      .getCachedImage(image.imageUrl)
      .pipe(
        map((cachedImage) => {
          const imageRef = <models.ImageViewerImageRef>{
            id: image.id,
            index: index,
            imageKind: image.imageKind,
            imageElement: cachedImage.element,
            imageDataUrl: cachedImage.dataUrl,
            width: cachedImage.width,
            height: cachedImage.height,
            markers: [],
          };

          if (image.markers) {
            imageRef.markers = image.markers.map(x => {
              return {
                ...x,
                imageRef: imageRef,
              };
            });
          }

          return imageRef;
        }),
      );
  }
}
