import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';

import * as dx from 'devextreme-angular';

import { ImageViewerModule } from '../image-viewer/image-viewer.module';

import { PlanViewerMarkerService } from './services/plan-viewer-marker.service';
import { PlanViewerMarkerSortService } from './services/plan-viewer-marker-sort.service';
import { PlanViewerMarkerPopoverService } from './services/plan-viewer-marker-popover.service';

import { PlanViewerComponent } from './components/plan-viewer/plan-viewer.component';
import { PlanViewerEditorComponent } from './components/plan-viewer-editor/plan-viewer-editor.component';
import { PlanViewerMarkerDialogComponent } from './components/plan-viewer-marker-dialog/plan-viewer-marker-dialog.component';
import { PlanViewerMarkerPopoverComponent } from './components/plan-viewer-marker-popover/plan-viewer-marker-popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    InlineSVGModule,
    ImageViewerModule,

    dx.DxButtonModule,
    dx.DxFileUploaderModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
    dx.DxTextAreaModule,
  ],
  declarations: [
    PlanViewerComponent,
    PlanViewerEditorComponent,
    PlanViewerMarkerDialogComponent,
    PlanViewerMarkerPopoverComponent,
  ],
  exports: [
    PlanViewerComponent,
  ],
  providers: [
    PlanViewerMarkerService,
    PlanViewerMarkerSortService,
    PlanViewerMarkerPopoverService,
  ],
  entryComponents: [
    PlanViewerMarkerDialogComponent,
    PlanViewerMarkerPopoverComponent,
  ],
})
export class PlanViewerModule {
  static forRoot(): ModuleWithProviders<PlanViewerModule> {
    return {
      ngModule: PlanViewerModule,
      providers: [
        PlanViewerMarkerService,
        PlanViewerMarkerSortService,
        PlanViewerMarkerPopoverService,
      ],
    };
  }
}
