import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { LeaseTemplateService } from '../../services/lease-template.service';
import { CompanyService } from '../../../company/services/company.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';

import * as models from '../../../../shared/models/generated';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

@Component({
  templateUrl: 'upload.component.html',
  styleUrls: ['upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm, {static: false}) form: NgForm;
  @ViewChild(DxFileUploaderComponent, {static: false}) fileUploader: DxFileUploaderComponent;

  readonly landlordCompaniesList: Observable<Array<models.ICompanyViewModel>>;

  template: File;

  landlordCompanyId: number;

  readonly reloadList: () => Promise<unknown>;

  private readonly _leaseTemplateService: LeaseTemplateService;
  private readonly _companyService: CompanyService;
  private readonly _popupRefService: PopupRefService;
  private readonly _popupService: PopupService;
  private readonly _destroy: Subject<void>;

  constructor(
    leaseTemplateService: LeaseTemplateService,
    companyService: CompanyService,
    popupRefService: PopupRefService,
    popupService: PopupService
  ) {
    this._leaseTemplateService = leaseTemplateService;
    this._companyService = companyService;
    this._popupRefService = popupRefService;
    this._popupService = popupService;
    this._destroy = new Subject<void>();

    this.landlordCompaniesList = this._companyService
      .getCompaniesList(models.CompanyType.LandlordCompany)
      .pipe(
        map(x => x.data),
      );
  }

  ngOnInit(): void {
    this._setupPopupConfig();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  setupSearchableCompanyFields(company: models.ICompanyViewModel): Array<string> {
    return [
      company.id.toString(10),
      company.name,
    ];
  }

  handleFileChange(event: { value: Array<File>, previousValue: Array<File> }): void {
    if (event.value && event.value instanceof Array && 0 < event.value.length) {
      this.template = event.value.shift();
      return;
    }

    this.template = null;
  }

  removeTemplate(): void {
    this.template = null;

    if (this.fileUploader && this.fileUploader.instance) {
      this.fileUploader.instance.reset();
    }
  }

  createLeaseTemplate(): void {
    if (this.form && this.form.invalid) {
      return;
    }

    this._leaseTemplateService
      .uploadLeaseTemplate(this.landlordCompanyId, this.template)
      .pipe(
        tap(leaseTemplate => {
          this.removeTemplate();

          this.reloadList();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  cancel(): void {
    if (this.form && this.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel the lease document marking process?',
        acceptFn: () => this._popupRefService.hide(),
      },
    });
  }

  private _setupPopupConfig(): void {
    const subscription = this._popupRefService.onContentReady.subscribe((event) => {
      const contentElement = event.component.content();
      if (contentElement) {
        contentElement.style.padding = '0';
      }

      subscription.unsubscribe();
    });
  }
}
