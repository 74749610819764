import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { BuildingAttachmentListComponent } from './components/list/list.component';
import {SafeHtmlPipe} from '../../shared/pipes/safe-html.pipe';
import {YouTubePlayerModule} from '@angular/youtube-player';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfrastructureModule,

    dx.DxPopupModule,
    dx.DxAccordionModule,
    dx.DxButtonModule,
    dx.DxDataGridModule,
    dx.DxFileUploaderModule,
    dx.DxLoadIndicatorModule,
    dx.DxLoadPanelModule,
    dx.DxLookupModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
    dx.DxTabsModule,
    dx.DxTextBoxModule,
    YouTubePlayerModule,
  ],
  declarations: [
    BuildingAttachmentListComponent,
    SafeHtmlPipe
  ],
  exports: [
  ],
  providers: [
  ],
  entryComponents: [
  ],
})
export class BuildingAttachmentModule {
  static forRoot(): ModuleWithProviders<BuildingAttachmentModule> {
    return {
      ngModule: BuildingAttachmentModule,
      providers: [
      ],
    };
  }
}
