import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of, ReplaySubject } from 'rxjs';

import heic2any from 'heic2any';

import { Tools } from '../../../../shared/tools';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';

import * as models from '../../../../shared/models/generated';

import { PlanViewerMarkerImageRef, PlanViewerMarkerRef, PlanViewerMode } from '../../models/plan-viewer.model';

@Component({
  templateUrl: 'plan-viewer-marker-dialog.component.html',
  styleUrls: ['plan-viewer-marker-dialog.component.scss'],
})
export class PlanViewerMarkerDialogComponent implements OnInit {
  private static readonly _newTitleOption = 'New Title';

  @ViewChild('form', {static: false, read: NgForm}) form: NgForm;

  mode: PlanViewerMode;
  marker: PlanViewerMarkerRef;

  suggestions: Array<{ id?: number, name: string }>;
  selectedSuggestion: { id?: number, name: string };

  images: Array<{ file: File, previewUrl: Observable<SafeUrl> }>;

  areas: Array<{ id: number, text: string }>;

  modes: typeof PlanViewerMode;

  isCustomTitleControlVisible: boolean;

  private readonly _renderer: Renderer2;
  private readonly _domSanitizer: DomSanitizer;
  private readonly _popupRefService: PopupRefService;

  constructor(renderer: Renderer2, domSanitizer: DomSanitizer, popupRefService: PopupRefService) {
    this._renderer = renderer;
    this._domSanitizer = domSanitizer;
    this._popupRefService = popupRefService;
  }

  ngOnInit(): void {
    this.marker = this.marker || new PlanViewerMarkerRef();
    this.images = new Array<{ file: File, previewUrl: Observable<string> }>();
    this.areas = Tools.EnumToArray(models.BuildingAreaType);
    this.modes = PlanViewerMode;
    this.suggestions = this.suggestions || [];

    this.isCustomTitleControlVisible = false;
    switch (this.mode) {
      case PlanViewerMode.Marker:
        this.isCustomTitleControlVisible = 0 === this.suggestions.length;

        if (this.suggestions.length) {
          this.suggestions.push({ name: PlanViewerMarkerDialogComponent._newTitleOption });
        }

        this.selectedSuggestion = this.suggestions
          .find(x => x.name === this.marker.title);

        break;
      case PlanViewerMode.Unit:
        this.selectedSuggestion = this.suggestions
          .find(x => x.id === this.marker.buildingUnitId);

        break;
    }
  }

  suggestionsDisplayExpr(item?: { id?: number, name: string }): string {
    return item && item.name;
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    const marker = {
      ...this.marker,
      buildingUnitId: undefined,
      images: this.images.map(x => {
        return <PlanViewerMarkerImageRef>{
          file: x.file,
        };
      }),
    };

    if (this.mode === PlanViewerMode.Unit) {
      marker.buildingUnitId = this.selectedSuggestion.id;
    }

    this._popupRefService.outputData = {
      marker: marker,
    };

    this.close();
  }

  close(): void {
    if (!this._popupRefService) {
      return;
    }

    this._popupRefService.hide();
  }

  handleFilesChange(event: { value: Array<File>, previousValue: Array<File> }): void {
    if (!event || !event.value || !event.value.length) {
      return;
    }

    const file = event.value[0];
    if (!file || !this._isImageFile(file)) {
      return;
    }

    this.images.push({
      file: file,
      previewUrl: this.getImagePreviewUrl$(file),
    });
  }

  fileNameWithoutExtension(filename: string): string {
    if (!filename) {
      return '';
    }

    const extension = this._getFileExtension(filename);
    return filename.replace(`.${extension}`, '');
  }

  getImagePreviewUrl$(image: File): Observable<SafeUrl> {
    if (!image) {
      return of(null);
    }

    const subject = new ReplaySubject<SafeUrl>(1);

    const fileExtension = this._getFileExtension(image.name);
    if (!fileExtension) {
      return of(null);
    }

    if (fileExtension.toLowerCase() !== 'heic') {
      const fileReader = new FileReader();

      fileReader.onload = (event: ProgressEvent) => {
        if (event.loaded !== event.total) {
          return;
        }

        const previewDataUrl = <string>fileReader.result;

        subject.next(this._domSanitizer.bypassSecurityTrustUrl(previewDataUrl));
        subject.complete();
      };

      fileReader.onerror = (err) => {
        subject.error(err);
      };

      fileReader.readAsDataURL(image);
    } else {
      heic2any({blob: image, quality: 0.5, toType: 'image/jpeg'})
        .then((conversionResult) => {
          const previewDataUrl = URL.createObjectURL(conversionResult);

          subject.next(this._domSanitizer.bypassSecurityTrustUrl(previewDataUrl));
          subject.complete();
        })
        .catch((err) => {
          subject.error(err);
        });
    }

    return subject;
  }

  removeImage(index: number): void {
    if (!this.images || (index < 0 || this.images.length <= index)) {
      return;
    }

    this.images.splice(index, 1);
  }

  handleTitleSelectBoxChange(suggestion: { id?: number, name: string }): void {
    if (PlanViewerMarkerDialogComponent._newTitleOption !== suggestion.name) {
      this.marker.title = suggestion.name;
      return;
    }

    this.isCustomTitleControlVisible = true;
  }

  private _isImageFile(file: File): boolean {
    if (!file || !file.type) {
      return false;
    }

    return file.type.startsWith('image/');
  }

  private _getFileExtension(filename: string): string {
    const filenameSegments = filename.split('.');
    return filenameSegments.pop();
  }
}
