import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

@Injectable()
export class TermService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  getRentSchedulePreview(lease: models.ILeaseViewModel): Observable<models.IBaseRentalScheduleViewModel> {
    return this._httpClient
      .post<models.IBaseRentalScheduleViewModel>(`/admin/leases/calculate-rent-schedule-preview`, lease);
  }

  getRentalRateEscalationPreview(lease: models.ILeaseViewModel): Observable<Array<models.IRentalRateAnnualEscalationTermCustomResult>> {
    return this._httpClient
      .post<Array<models.IRentalRateAnnualEscalationTermCustomResult>>(`/admin/leases/calculate-rental-rate-escalation-preview`, lease);
  }

  getLeaseTermTypeByTermName(termName: string): models.LeaseTermType {
    switch (termName) {
      case 'commencementTerm':
        return models.LeaseTermType.Commencement;

      case 'term':
        return models.LeaseTermType.Term;

      case 'landlordMaintenanceTerm':
        return models.LeaseTermType.LandlordMaintenance;

      case 'tenantMaintenanceTerm':
        return models.LeaseTermType.TenantMaintenance;

      case 'selfHelpTerm':
        return models.LeaseTermType.SelfHelp;

      case 'assignmentTerm':
        return models.LeaseTermType.Assignment;

      case 'expansionOptionTerm':
        return models.LeaseTermType.ExpansionOption;

      case 'estoppelCertificateTerm':
        return models.LeaseTermType.EstoppelCertificate;

      case 'baseRentalRateTerm':
        return models.LeaseTermType.BaseRentalRate;

      case 'tenantSquareFootageTerm':
        return models.LeaseTermType.TenantSquareFootage;

      case 'freeRentTerm':
        return models.LeaseTermType.FreeRent;

      case 'securityDepositTerm':
        return models.LeaseTermType.SecurityDeposit;

      case 'realEstateTaxesCamExpensesTerm':
        return models.LeaseTermType.RealEstateTaxesCamExpenses;

      case 'rentalRateAnnualEscalationTerm':
        return models.LeaseTermType.RentalRateAnnualEscalation;

      case 'tenantImprovementsTerm':
        return models.LeaseTermType.TenantImprovements;

      case 'hvacTerm':
        return models.LeaseTermType.Hvac;

      case 'renewalOptionTerm':
        return models.LeaseTermType.RenewalOption;

      case 'terminationOptionTerm':
        return models.LeaseTermType.TerminationOption;

      case 'insuranceTerm':
        return models.LeaseTermType.Insurance;

      case 'holdoverProvisionTerm':
        return models.LeaseTermType.HoldoverProvision;

      case 'forceMajeureTerm':
        return models.LeaseTermType.ForceMajeure;

      case 'parkingTerm':
        return models.LeaseTermType.Parking;

      default:
        return null;
    }
  }

  getLeaseTerm(lease: models.ILease, leaseTermType: models.LeaseTermType): models.ILeaseTerm;
  getLeaseTerm(lease: models.ILeaseViewModel, leaseTermType: models.LeaseTermType): models.ILeaseTermViewModel;

  getLeaseTerm(
    lease: models.ILease | models.ILeaseViewModel,
    leaseTermType: models.LeaseTermType
  ): models.ILeaseTerm | models.ILeaseTermViewModel {
    if (!lease) {
      return null;
    }

    switch (leaseTermType) {
      case models.LeaseTermType.Commencement:
        return lease.commencementTerm;

      case models.LeaseTermType.Term:
        return lease.term;

      case models.LeaseTermType.LandlordMaintenance:
        return lease.landlordMaintenanceTerm;

      case models.LeaseTermType.TenantMaintenance:
        return lease.tenantMaintenanceTerm;

      case models.LeaseTermType.SelfHelp:
        return lease.selfHelpTerm;

      case models.LeaseTermType.Assignment:
        return lease.assignmentTerm;

      case models.LeaseTermType.ExpansionOption:
        return lease.expansionOptionTerm;

      case models.LeaseTermType.EstoppelCertificate:
        return lease.estoppelCertificateTerm;

      case models.LeaseTermType.BaseRentalRate:
        return lease.baseRentalRateTerm;

      case models.LeaseTermType.TenantSquareFootage:
        return lease.tenantSquareFootageTerm;

      case models.LeaseTermType.FreeRent:
        return lease.freeRentTerm;

      case models.LeaseTermType.SecurityDeposit:
        return lease.securityDepositTerm;

      case models.LeaseTermType.RealEstateTaxesCamExpenses:
        return lease.realEstateTaxesCamExpensesTerm;

      case models.LeaseTermType.RentalRateAnnualEscalation:
        return lease.rentalRateAnnualEscalationTerm;

      case models.LeaseTermType.TenantImprovements:
        return lease.tenantImprovementsTerm;

      case models.LeaseTermType.Hvac:
        return lease.hvacTerm;

      case models.LeaseTermType.RenewalOption:
        return lease.renewalOptionTerm;

      case models.LeaseTermType.TerminationOption:
        return lease.terminationOptionTerm;

      case models.LeaseTermType.Insurance:
        return lease.insuranceTerm;

      case models.LeaseTermType.HoldoverProvision:
        return lease.holdoverProvisionTerm;

      case models.LeaseTermType.ForceMajeure:
        return lease.forceMajeureTerm;

      case models.LeaseTermType.Parking:
        return lease.parkingTerm;

      default:
        return null;
    }
  }
}
