import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DxFileUploaderComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { BehaviorSubject, forkJoin, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { Tools } from 'src/app/shared/tools';

import { BuildingService } from '../../../building/services/building.service';
import { PopupService } from '../../../infrastructure/services/popup.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { ImageViewerRefService } from '../../../image-viewer/services/image-viewer-ref.service';
import { ImageViewerService } from '../../../image-viewer/services/image-viewer.service';
import { PortfolioService } from '../../../portfolio/services/portfolio.service';
import { PlanService } from '../../services/plan.service';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

import * as models from '../../../../shared/models/generated';
import * as imageViewerModels from '../../../image-viewer/models/image-viewer-image.model';

@Component({
  templateUrl: 'floor-plan-upsert.component.html',
  styleUrls: ['floor-plan-upsert.component.scss'],
})
export class FloorPlanUpsertComponent implements OnInit, OnDestroy {
  @Input() reloadFloorPlans: () => Promise<unknown>;

  @ViewChild(NgForm, {static: false}) form: NgForm;
  @ViewChild(DxFileUploaderComponent, {static: false}) fileUploader: DxFileUploaderComponent;

  portfolioId: number;

  floorPlan: models.IPlanViewModel;

  uploadedPlanPictures: Array<models.IFileViewModel>;

  portfolioDataSource: DataSource;
  buildingDataSource: DataSource;
  buildingUnitDataSource: DataSource;

  isUploading: BehaviorSubject<boolean>;
  isLoading: BehaviorSubject<boolean>;

  Tools = Tools;

  private _imageViewerRef: ImageViewerRefService;

  private readonly _portfolioService: PortfolioService;
  private readonly _buildingService: BuildingService;
  private readonly _planService: PlanService;
  private readonly _popupRefService: PopupRefService;
  private readonly _popupService: PopupService;
  private readonly _imageViewerService: ImageViewerService;
  private readonly _sanitizer: DomSanitizer;
  private readonly _destroy$: Subject<void>;

  constructor(
    portfolioService: PortfolioService,
    buildingService: BuildingService,
    planService: PlanService,
    popupRefService: PopupRefService,
    popupService: PopupService,
    imageViewerService: ImageViewerService,
    sanitizer: DomSanitizer,
  ) {
    this._portfolioService = portfolioService;
    this._buildingService = buildingService;
    this._planService = planService;
    this._popupRefService = popupRefService;
    this._imageViewerService = imageViewerService;
    this._popupService = popupService;
    this._sanitizer = sanitizer;
    this._destroy$ = new Subject<void>();

    this.uploadedPlanPictures = [];

    this.portfolioDataSource = new DataSource({
      key: 'id',
      store: [],
    });
    this.buildingDataSource = new DataSource({
      key: 'id',
      store: [],
    });
    this.buildingUnitDataSource = new DataSource({
      key: 'id',
      store: [],
    });

    this.isUploading = new BehaviorSubject<boolean>(false);
    this.isLoading = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
    this._portfolioService
      .getPortfolioList()
      .pipe(
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe((portfolioList) => {
        this.portfolioDataSource = new DataSource({
          key: 'id',
          store: portfolioList.data,
        });
      });

    if (!this.floorPlan) {
      this.floorPlan = <models.IPlanViewModel>{
        name: '',
        buildingId: null,
        buildingUnitId: null,
      };
    } else {
      this.portfolioId = this.floorPlan.building.portfolioId;

      this.isLoading.next(true);

      this._loadBuildingsByPortfolioId(this.portfolioId);
      this._loadBuildingUnitsByBuildingId(this.floorPlan.building.id);
    }
  }

  ngOnDestroy(): void {
    this.resetForm();
  }

  setupSearchablePortfolioFields(portfolio: models.IPortfolioViewModel): Array<string> {
    return [
      portfolio.id.toString(10),
      portfolio.name,
    ];
  }

  setupSearchableBuildingFields(building: models.IBuildingViewModel): Array<string> {
    const searchableFields = [
      building.id.toString(10),
      building.name,
    ];

    if (building.address) {
      if (building.address.addressLine1) {
        searchableFields.push(building.address.addressLine1);
      }

      if (building.address.addressLine2) {
        searchableFields.push(building.address.addressLine2);
      }

      if (building.address.city) {
        searchableFields.push(building.address.city);
      }

      if (building.address.stateCode) {
        searchableFields.push(building.address.stateCode);
      }

      if (building.address.zipCode) {
        searchableFields.push(building.address.zipCode);
      }
    }

    return searchableFields;
  }

  setupSearchableBuildingUnitFields(buildingUnit: models.IBuildingUnitViewModel): Array<string> {
    return [
      buildingUnit.id.toString(10),
      buildingUnit.name,
    ];
  }

  handleZoomClick(file: models.IFileViewModel): void {
    if (!file) {
      return;
    }

    this._showImageViewer(file);
  }

  handlePortfolioChange(portfolioId?: number): void {
    if (!portfolioId) {
      return;
    }

    this.floorPlan.buildingId = null;
    this.floorPlan.buildingUnitId = null;

    this.buildingDataSource = new DataSource({
      key: 'id',
      store: [],
    });
    this.buildingUnitDataSource = new DataSource({
      key: 'id',
      store: [],
    });

    this.isLoading.next(true);

    this._loadBuildingsByPortfolioId(portfolioId);
  }

  handleBuildingChange(buildingId): void {
    if (!buildingId) {
      return;
    }

    this.floorPlan.buildingUnitId = null;

    this.buildingUnitDataSource = new DataSource({
      key: 'id',
      store: [],
    });

    this.isLoading.next(true);

    this._loadBuildingUnitsByBuildingId(buildingId);
  }

  handleUploaderFilesChange(event: { value: Array<File>, previousValue: Array<File> }): void {
    if (!event || !event.value || !event.value.length) {
      return;
    }

    const file = event.value[0];

    this.isUploading.next(true);

    this._planService
      .uploadPlanPictures(file)
      .subscribe(pictures => {
        this.isUploading.next(false);

        this.uploadedPlanPictures = [
          ...this.uploadedPlanPictures,
          ...pictures,
        ];

        if (this.fileUploader && this.fileUploader.instance) {
          this.fileUploader.instance.reset();
        }
      });
  }

  deleteUploadedPlanPicture(index: number): void {
    if (!this.uploadedPlanPictures || (index < 0 || this.uploadedPlanPictures.length <= index)) {
      return;
    }

    const deletedPictures = this.uploadedPlanPictures.splice(index, 1);

    this._planService
      .deletePlanPicture(deletedPictures[0].id)
      .subscribe();

    if (this.fileUploader && this.fileUploader.instance) {
      this.fileUploader.instance.reset();
    }
  }

  createPlan(): void {
    if (this.form && this.form.invalid) {
      return;
    }

    this.isLoading.next(true);

    if (!this.floorPlan.id) {
      const observables = this.uploadedPlanPictures
        .map(picture =>
          this._planService
            .createPlan(<models.IPlanViewModel>{
              name: this.floorPlan.name,
              kind: models.PlanKind.Floor,
              buildingId: this.floorPlan.buildingId,
              buildingUnitId: this.floorPlan.buildingUnitId,
              pictureId: picture.id,
            })
        );

      forkJoin(observables)
        .pipe(
          take(1),
          tap(() => {
            this.isLoading.next(false);
          }),
          catchError(err => {
            this.isLoading.next(false);

            return throwError(err);
          }),
          takeUntil(this._destroy$),
        )
        .subscribe(plan => {
          this.reloadFloorPlans();

          this._popupRefService.hide();
        });
    } else {
      this._planService
        .updatePlan(<models.IPlanViewModel>{
          id: this.floorPlan.id,
          name: this.floorPlan.name,
          kind: models.PlanKind.Floor,
          buildingId: this.floorPlan.buildingId,
          buildingUnitId: this.floorPlan.buildingUnitId,
          pictureId: this.floorPlan.pictureId,
        })
        .pipe(
          take(1),
          tap(() => {
            this.isLoading.next(false);
          }),
          catchError(err => {
            this.isLoading.next(false);

            return throwError(err);
          }),
          takeUntil(this._destroy$),
        )
        .subscribe(plan => {
          this.reloadFloorPlans();

          this._popupRefService.hide();
        });
    }
  }

  resetForm(): void {
    if (this.form) {
      this.form.resetForm();
    }

    this.uploadedPlanPictures = [];

    if (this.fileUploader && this.fileUploader.instance) {
      this.fileUploader.instance.reset();
    }
  }

  cancel(): void {
    if (this.form && this.form.pristine && !this.uploadedPlanPictures.length) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  private _loadBuildingsByPortfolioId(portfolioId: number): void {
    this._buildingService
      .getBuildingListByPortfolioId(portfolioId)
      .pipe(
        take(1),
        tap(buildingsList => {
          this.buildingDataSource = new DataSource({
            key: 'id',
            store: buildingsList.data,
          });
        }),
        tap(() => {
          this.isLoading.next(false);
        }),
        catchError(err => {
          this.isLoading.next(false);

          return throwError(err);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _loadBuildingUnitsByBuildingId(buildingId: number): void {
    this._buildingService
      .getBuildingUnitsListByBuildingId(buildingId)
      .pipe(
        take(1),
        tap(buildingUnitsList => {
          this.buildingUnitDataSource = new DataSource({
            key: 'id',
            store: buildingUnitsList.data,
          });
        }),
        tap(() => {
          this.isLoading.next(false);
        }),
        catchError(err => {
          this.isLoading.next(false);

          return throwError(err);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _showImageViewer(file: models.IFileViewModel): void {
    if (this._imageViewerRef) {
      return;
    }

    const image = {
      id: file.id,
      imageUrl: file.url,
      imageKind: imageViewerModels.ImageViewerImageKind.Standard,
    };

    this._imageViewerRef = this._imageViewerService.show(
      [image],
      {
        width: '95%',
        height: '95%',
        maxWidth: 1800,
        closeOnOutsideClick: false,
        showCloseButton: true,
        title: 'Preview',
        activeIndex: 0,
        allowChangeMarkers: false,
        enableArrowNavigation: false,
      },
    );

    this._imageViewerRef.onHiding
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this._imageViewerRef = null;
        }),
      )
      .subscribe();
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel unsaved changes?',
        acceptFn: () => {
          const observables = this.uploadedPlanPictures
            .map(picture =>
              this._planService.deletePlanPicture(picture.id)
            );

          forkJoin(observables)
            .pipe(
              take(1),
            )
            .subscribe();

          this._popupRefService.hide();
        },
      },
    });
  }
}
