import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { Tools } from '../../../../shared/tools';

import { PopupService } from '../../../infrastructure/services/popup.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PointOfInterestService } from '../../services/point-of-interest.service';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

import * as models from '../../../../shared/models/generated';

@Component({
  templateUrl: 'point-of-interest-upsert.component.html',
  styleUrls: ['point-of-interest-upsert.component.scss'],
})
export class PointOfInterestUpsertComponent implements OnInit, OnDestroy {
  @Input() reloadList: () => Promise<unknown>;

  @ViewChild(NgForm, {static: false}) form: NgForm;

  model: models.IPointOfInterestViewModel;

  pointOfInterestStatusDataSource: DataSource;
  pointOfInterestKindDataSource: DataSource;

  isLoading: BehaviorSubject<boolean>;

  private readonly _popupService: PopupService;
  private readonly _popupRefService: PopupRefService;
  private readonly _pointOfInterestService: PointOfInterestService;

  private readonly _destroy: Subject<void>;

  constructor(
    popupService: PopupService,
    popupRefService: PopupRefService,
    pointOfInterestService: PointOfInterestService,
  ) {
    this._popupService = popupService;
    this._popupRefService = popupRefService;
    this._pointOfInterestService = pointOfInterestService;

    this._destroy = new Subject<void>();

    this.isLoading = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
    if (!this.model) {
      this.model = <models.IPointOfInterestViewModel>{
        kind: null,
        status: models.PointOfInterestStatus.Draft,
        name: '',
        description: '',
        geopointLatitude: null,
        geopointLongitude: null,
        geoJSON: '',
      };
    }

    this.pointOfInterestStatusDataSource = new DataSource({
      key: 'id',
      store: Tools.EnumToArray(models.PointOfInterestStatus),
    });

    this.pointOfInterestKindDataSource = new DataSource({
      key: 'id',
      store: Tools.EnumToArray(models.PointOfInterestKind),
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  submit(): void {
    if (this.form && this.form.invalid) {
      return;
    }

    this.isLoading.next(true);

    if (!this.model.id) {
      this._pointOfInterestService
        .createPointOfInterest(<models.IPointOfInterestViewModel>{
          ...this.model
        })
        .pipe(
          take(1),
          tap(() => {
            this.isLoading.next(false);
          }),
          catchError(err => {
            this.isLoading.next(false);

            return throwError(err);
          }),
          takeUntil(this._destroy),
        )
        .subscribe(plan => {
          this.reloadList();

          this._popupRefService.hide();
        });
    } else {
      this._pointOfInterestService
        .updatePointOfInterest(<models.IPointOfInterestViewModel>{
          ...this.model
        })
        .pipe(
          take(1),
          tap(() => {
            this.isLoading.next(false);
          }),
          catchError(err => {
            this.isLoading.next(false);

            return throwError(err);
          }),
          takeUntil(this._destroy),
        )
        .subscribe(plan => {
          this.reloadList();

          this._popupRefService.hide();
        });
    }
  }

  cancel(): void {
    if (this.form && this.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel?',
        acceptFn: () => {
          this._popupRefService.hide();
        },
      },
    });
  }
}
