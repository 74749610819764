import { ChangeDetectorRef, Component, HostListener, NgZone, ViewChild, ViewRef } from '@angular/core';

import * as ng from '@angular/core';

import { DxPopupComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ImageViewerRefService } from '../../services/image-viewer-ref.service';

import { ImageViewerOptions } from '../../models/image-viewer-options.model';
import { ImageViewerImage, ImageViewerImageMarkerRef } from '../../models/image-viewer-image.model';

import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

@Component({
  templateUrl: 'image-viewer-container.component.html',
  styleUrls: ['image-viewer-container.component.scss'],
})
export class ImageViewerContainerComponent implements ng.OnInit, ng.OnDestroy {
  @ViewChild('dxPopupComponent', {static: false}) dxPopupComponent: DxPopupComponent;
  @ViewChild(ImageViewerComponent, {static: false}) imageViewerComponent: ImageViewerComponent;

  private readonly _destroy$: Subject<void>;

  readonly onShowing: ng.EventEmitter<any>;
  readonly onHiding: ng.EventEmitter<any>;
  readonly onShown: ng.EventEmitter<any>;
  readonly onHidden: ng.EventEmitter<any>;
  readonly onContentReady: ng.EventEmitter<any>;

  readonly onMarkerCreated: ng.EventEmitter<ImageViewerImageMarkerRef>;
  readonly onMarkerChanged: ng.EventEmitter<ImageViewerImageMarkerRef>;
  readonly onMarkerDeleted: ng.EventEmitter<ImageViewerImageMarkerRef>;

  readonly config: ImageViewerOptions;

  isVisible: boolean;
  isReady: boolean;

  injectableData: {
    images: Array<ImageViewerImage>,
    activeIndex: number,
    allowChangeMarkers: boolean,
    enableArrowNavigation: boolean,
    enableZoom?: boolean
  };

  private readonly _ngZone: NgZone;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _imageViewerRefService: ImageViewerRefService;

  constructor(
    ngZone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    imageViewerRefService: ImageViewerRefService,
    config: ImageViewerOptions,
  ) {
    this._ngZone = ngZone;
    this._changeDetectorRef = changeDetectorRef;
    this._imageViewerRefService = imageViewerRefService;

    this._destroy$ = new Subject<void>();

    this.onShowing = new ng.EventEmitter<any>();
    this.onHiding = new ng.EventEmitter<any>();
    this.onShown = new ng.EventEmitter<any>();
    this.onHidden = new ng.EventEmitter<any>();
    this.onContentReady = new ng.EventEmitter<any>();

    this.onMarkerCreated = new ng.EventEmitter<ImageViewerImageMarkerRef>();
    this.onMarkerChanged = new ng.EventEmitter<ImageViewerImageMarkerRef>();
    this.onMarkerDeleted = new ng.EventEmitter<ImageViewerImageMarkerRef>();

    this.config = config;
  }

  ngOnInit(): void {
    this.isReady = false;

    this._subscribeOnDxEvents();

    // Fire sub-events
    this._firePopupRefEvent(this.onShowing, this._imageViewerRefService.onShowing);
    this._firePopupRefEvent(this.onHiding, this._imageViewerRefService.onHiding);
    this._firePopupRefEvent(this.onShown, this._imageViewerRefService.onShown);
    this._firePopupRefEvent(this.onHidden, this._imageViewerRefService.onHidden);
    this._firePopupRefEvent(this.onContentReady, this._imageViewerRefService.onContentReady);

    // Fire marker events
    this._firePopupRefEvent(this.onMarkerCreated, this._imageViewerRefService.onMarkerCreated);
    this._firePopupRefEvent(this.onMarkerChanged, this._imageViewerRefService.onMarkerChanged);
    this._firePopupRefEvent(this.onMarkerDeleted, this._imageViewerRefService.onMarkerDeleted);

    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if ((<ViewRef>this._changeDetectorRef).destroyed) {
          return;
        }

        this.isVisible = true;

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._changeDetectorRef.detach();
  }

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  private _subscribeOnDxEvents(): void {
    this.onHidden
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.config.imageViewerService.hide()),
      )
      .subscribe();

    this.onShown
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this.isReady = true;
        })
      )
      .subscribe();
  }

  @HostListener('window:resize', ['$event'])
  private _handleResize(event?: Event): void {
    if (this.imageViewerComponent) {
      this.imageViewerComponent.setupContainerSize();
    }
  }

  private _firePopupRefEvent(from: ng.EventEmitter<any>, to: ng.EventEmitter<any>): void {
    from.pipe(takeUntil(this._destroy$)).subscribe((event) => to.emit(event));
  }
}
