<h2 class="content-block">Buildings</h2>

<dx-data-grid class="dx-card wide-card" [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true" [remoteOperations]="true"
  (onEditorPreparing)="onEditorPreparing($event)" (onEditingStart)="editingStart($event)" (onInitNewRow)="initNewRow($event)" (onRowPrepared)="onRowPrepared($event)"
  (onToolbarPreparing)="prepareToolbar($event)"
  #dxDataGrid>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup> </dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="70" dataType="number"></dxi-column>
  <dxi-column dataField="picture" title="Picture" [width]="70" cellTemplate="buildingPictureCellTemplate"></dxi-column>
  <div *dxTemplate="let item of 'buildingPictureCellTemplate'" class="avatar-image text-center">
    <img height="30"
         width="40"
         [src]="Tools.buildingPictureThumbnail(item?.data) || Tools.noImageUrl" />
  </div>

  <dxi-column dataField="address" title="Address" cellTemplate="addressCellTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'addressCellTemplate'">
    {{item.value?.addressLine1}} {{item.value?.addressLine2}} {{item.value?.city}}, {{item.value?.stateCode}} {{item.value?.zipCode}}
  </div>

  <dxi-column dataField="name"></dxi-column>

  <dxi-column dataField="portfolio.company.id" caption="Landlord Company">
    <dxo-lookup [dataSource]="landlordCompaniesDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="portfolioId" caption="Portfolio">
    <dxo-lookup [dataSource]="portfoliosDataSource" valueExpr="id" displayExpr="name"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="totalBuildingSize" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0 square feet', placeholder: '0 square feet' }"></dxi-column>
  <dxi-column dataField="builtYear" caption="Year Built" editorType="dxNumberBox" [editorOptions]="{ min: 1000 }"></dxi-column>
  <dxi-column dataField="renovatedDate" dataType="date" editorType="dxDateBox" [editorOptions]="{ useMaskBehavior: true, displayFormat: '\'Year\': yyyy' }"></dxi-column>
  <dxi-column dataField="clearHeight" editorType="dxNumberBox" [editorOptions]="{ min: 0, max: 999, format: '#0.## feet', placeholder: '0.0 feet' }"></dxi-column>
  <dxi-column dataField="totalOfficeArea" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.### SF', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="totalDocksInterior" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="totalDocksExterior" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="buildingClass">
    <dxo-lookup [dataSource]="buildingClasses" valueExpr="id" displayExpr="text">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="buildingType">
    <dxo-lookup [dataSource]="buildingTypes" valueExpr="id" displayExpr="text">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="constructionTypeId">
    <dxo-lookup [dataSource]="constructionTypeDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="assetsTypeId">
    <dxo-lookup [dataSource]="assetsTypeDataSource" grouped="true" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="mostCurrentRealEstateTaxesPSF" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.##', placeholder: '0.0' }"></dxi-column>
  <dxi-column dataField="mostCurrentRealEstateTaxesTotal" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.##', placeholder: '0.0' }"></dxi-column>
  <dxi-column dataField="operatingExpenses" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.##', placeholder: '0.0' }"></dxi-column>
  <dxi-column dataField="totalLandArea" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.## acres', placeholder: '0.0 acres' }"></dxi-column>
  <dxi-column dataField="columnSpacing" editorType="dxTextBox" [editorOptions]="{ mask: '#0\' x #0\'', useMaskedValue: true }"></dxi-column>
  <dxi-column dataField="totalParkingSpaces" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>
  <dxi-column dataField="mostRecentInteriorConstructionOrUpdateYear" editorType="dxNumberBox" [editorOptions]="{ min: 1000 }"></dxi-column>
  <dxi-column dataField="mostRecentExteriorConstructionOrUpdateYear" editorType="dxNumberBox" [editorOptions]="{ min: 1000 }"></dxi-column>
  <dxi-column dataField="mostRecentInteriorConstructionOrUpdateText" editorType="dxTextBox"></dxi-column>
  <dxi-column dataField="mostRecentExteriorConstructionOrUpdateText" editorType="dxTextBox"></dxi-column>
  <dxi-column dataField="railLine"></dxi-column>
  <dxi-column dataField="pinNumber" editorType="dxTextBox" [editorOptions]="{ useMaskBehavior: true, mask: '##-##-###-###-####' }"></dxi-column>
  <dxi-column dataField="greenCertificates"></dxi-column>

  <dxi-column dataField="geopointLatitude" editorType="dxNumberBox" caption="Latitude"></dxi-column>
  <dxi-column dataField="geopointLongitude" editorType="dxNumberBox" caption="Longitude"></dxi-column>

  <dxi-column dataField="marketId" caption="Market">
    <dxo-lookup [dataSource]="marketsDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="subMarketId" caption="SubMarket">
    <dxo-lookup [dataSource]="subMarketsDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="amps" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.##', placeholder: '0' }"></dxi-column>

  <dxi-column dataField="volts" editorType="dxTextBox"></dxi-column>

  <dxi-column dataField="phase" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>

  <dxi-column dataField="sprinklerSystem">
    <dxo-lookup [dataSource]="sprinklerSystems" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="utilityCosts" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0.##', placeholder: '0' }"></dxi-column>

  <dxi-column dataField="utilityCostsText" editorType="dxTextBox"></dxi-column>

  <dxi-column dataField="stories" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>

  <dxi-column dataField="driveInDoors" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>

  <dxi-column dataField="trailerParking" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '#0', placeholder: '0' }"></dxi-column>


  <dxi-column dataField="roofTypeCondition" editorType="dxTextBox"></dxi-column>

  <dxi-column dataField="mostRecentSalePrice" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: '$#0', placeholder: '0' }"></dxi-column>

  <dxi-column dataField="saleYear" caption="Most Recent Sale Year" editorType="dxNumberBox" [editorOptions]="{ min: 1000 }"></dxi-column>

  <dxi-column dataField="address" [visible]="false"></dxi-column>

  <dxi-column dataField="fund"></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit">
    </dxi-button>
    <dxi-button name="delete"
                [visible]="isEditButtonVisible">
    </dxi-button>
  </dxi-column>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup title="Add or edit building" [showTitle]="true" width="95%" [maxWidth]="600" [position]="{ my: 'center', at: 'center', of: _window }">
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item itemType="tabbed" [tabPanelOptions]="{ deferRendering: false, showNavButtons: true }">
        <dxi-tab title="Main properties">
          <dxi-item itemType="group" caption="Market">
            <dxi-item dataField="marketId" isRequired="true"
                      editorType="dxSelectBox" [editorOptions]="marketEditorOptions">
            </dxi-item>
            <dxi-item dataField="subMarketId"
                      editorType="dxSelectBox" [editorOptions]="subMarketEditorOptions"
                      #subMarketSelectBox>
            </dxi-item>
          </dxi-item>
          <dxi-item itemType="group" caption="Main properties">
            <dxi-item dataField="portfolioId" isRequired="true"></dxi-item>
            <dxi-item dataField="name" isRequired="true"></dxi-item>
            <dxi-item dataField="totalBuildingSize"></dxi-item>
            <dxi-item dataField="builtYear"></dxi-item>
            <dxi-item dataField="buildingClass" [editorOptions]="{ showClearButton: true }"></dxi-item>
            <dxi-item dataField="buildingType" [editorOptions]="{ showClearButton: true }"></dxi-item>
            <dxi-item dataField="amps"></dxi-item>
            <dxi-item dataField="greenCertificates"></dxi-item>
            <dxi-item dataField="volts"></dxi-item>
            <dxi-item dataField="phase"></dxi-item>
            <dxi-item dataField="constructionTypeId" caption="Construction Type"></dxi-item>
            <dxi-item dataField="assetsTypeId" caption="Assets Type"></dxi-item>
            <dxi-item dataField="fund"></dxi-item>

          </dxi-item>
          <dxi-item itemType="group" caption="Geopoint">
            <dxi-item dataField="geopointLatitude"></dxi-item>
            <dxi-item dataField="geopointLongitude"></dxi-item>
            <dxi-item>
              <div class="dx-item-content dx-box-item-content">
                <div class="dx-datagrid-edit-form-item dx-first-col dx-last-col dx-field-item dx-field-item-tab dx-col-0 dx-field-item-optional dx-flex-layout dx-label-h-align">
                  <label class="dx-field-item-label dx-field-item-label-location-left">
                    <span class="dx-field-item-label-content">
                      <span class="dx-field-item-label-text">
                        &nbsp;
                      </span>
                    </span>
                  </label>
                  <div class="dx-field-item-content dx-field-item-content-location-right">
                    <a role="button" href="javascript:void(0)"
                       (click)="getLatAndLongByAddress()">
                      Get lat & long by address
                    </a>
                  </div>
                </div>
              </div>
            </dxi-item>
          </dxi-item>
        </dxi-tab>
        <dxi-tab title="Additional properties 1">
          <dxi-item dataField="clearHeight"></dxi-item>
          <dxi-item dataField="totalOfficeArea"></dxi-item>
          <dxi-item dataField="renovatedDate"></dxi-item>
          <dxi-item dataField="totalLandArea"></dxi-item>
          <dxi-item dataField="columnSpacing"></dxi-item>
          <dxi-item dataField="totalParkingSpaces"></dxi-item>
          <dxi-item dataField="totalDocksInterior" editorType="dxNumberBox"></dxi-item>
          <dxi-item dataField="totalDocksExterior" editorType="dxNumberBox"></dxi-item>
          <dxi-item dataField="sprinklerSystem" [editorOptions]="{ showClearButton: true }"></dxi-item>
          <dxi-item dataField="utilityCosts"></dxi-item>
          <dxi-item dataField="utilityCostsText"></dxi-item>
          <dxi-item dataField="stories" editorType="dxNumberBox"></dxi-item>
          <dxi-item dataField="driveInDoors" editorType="dxNumberBox"></dxi-item>
          <dxi-item dataField="trailerParking" editorType="dxNumberBox"></dxi-item>
        </dxi-tab>
        <dxi-tab title="Additional properties 2">
          <dxi-item dataField="mostCurrentRealEstateTaxesPSF"></dxi-item>
          <dxi-item dataField="mostCurrentRealEstateTaxesTotal"></dxi-item>
          <dxi-item dataField="operatingExpenses"></dxi-item>
          <dxi-item dataField="mostRecentInteriorConstructionOrUpdateYear"></dxi-item>
          <dxi-item dataField="mostRecentExteriorConstructionOrUpdateYear"></dxi-item>
          <dxi-item dataField="mostRecentInteriorConstructionOrUpdateText"></dxi-item>
          <dxi-item dataField="mostRecentExteriorConstructionOrUpdateText"></dxi-item>
          <dxi-item dataField="railLine"></dxi-item>
          <dxi-item dataField="pinNumber"></dxi-item>
          <dxi-item dataField="roofTypeCondition"></dxi-item>
          <dxi-item dataField="mostRecentSalePrice"></dxi-item>
          <dxi-item dataField="saleYear"></dxi-item>
        </dxi-tab>
        <dxi-tab title="Address">
          <dxi-item dataField="address" [editorOptions]="addressEditorOptions">
            <dxo-label [visible]="false"></dxo-label>
          </dxi-item>
          <dxi-item itemType="group" caption="Address" [colCount]="1" itemTemplate="addressFormTemplate">
            <div *dxTemplate="let item of 'addressFormTemplate'">
              <dx-form [colCount]="1" [formData]="currentAddress"
                       (onInitialized)="addressFormInitialized()"
                       (onFieldDataChanged)="addressFormFieldDataChanged()">
                <dxi-item dataField="addressLine1" [editorOptions]="addressLine1EditorOptions"></dxi-item>
                <dxi-item dataField="addressLine2" [editorOptions]="addressLine2EditorOptions"></dxi-item>
                <dxi-item dataField="zipCode" [editorOptions]="zipCodeEditorOptions"></dxi-item>
                <dxi-item dataField="city" [editorOptions]="cityEditorOptions"></dxi-item>
                <dxi-item dataField="countryCode" editorType="dxSelectBox" [editorOptions]="countryEditorOptions"></dxi-item>
                <dxi-item dataField="stateCode" editorType="dxSelectBox" [editorOptions]="stateEditorOptions"></dxi-item>
                <dxi-item dataField="countyName" editorType="dxSelectBox" [editorOptions]="countyEditorOptions"></dxi-item>
              </dx-form>
            </div>
          </dxi-item>
        </dxi-tab>
      </dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
