import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IAddressPropertiesViewModel, ILoginViewModel, IStartupInfoViewModel } from 'src/app/shared/models/generated';
import { share } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  isAuthenticated = false;
  public logoutForm: any;
  signingOut = false;
  appStarting = true;
  public noAvatarSourceUrl = 'assets/img/user.png';
  public noAvatarUrl = 'assets/img/avatar.png';
  public startupInfo = {
    isPending: true,
    avatarOptions: {},
    lists: {
      projectTypes: []
    }
  } as IStartupInfoViewModel;

  addressProperties = <IAddressPropertiesViewModel> {
    states: [],
    countries: [],
    counties: [],
    markets: [],
    subMarkets: [],
  };

  public infoLoadComplete: BehaviorSubject<IStartupInfoViewModel> = new BehaviorSubject<
    IStartupInfoViewModel
  >(null);

  public addressPropertiesCompletes$: BehaviorSubject<IAddressPropertiesViewModel> = new BehaviorSubject<
    IAddressPropertiesViewModel
    >(null);

  constructor(private router: Router, private httpClient: HttpClient) {
    if (localStorage['justAuthenticated']) {
      localStorage['justAuthenticated'] = '';
      this.isAuthenticated = true;
    }
  }

  logIn(model: ILoginViewModel): any {
    const result = this.httpClient
      .post(`${environment.adminUrl}/authentication/CheckUser`, model)
      .pipe(share());
    result.subscribe(() => {
      localStorage['justAuthenticated'] = true;
    });
    return result;
  }

  logOut() {
    if (this.logoutForm) {
      this.signingOut = true;
      this.logoutForm.submit();
    }
    this.isAuthenticated = false;
  }

  get isLoggedIn() {
    return this.isAuthenticated;
  }

  getStartupInfo() {
    this.httpClient
      .get(`${environment.apiUrl}/app/Info`, { withCredentials: true })
      .pipe(share())
      .subscribe(
        data => {
          this.startupInfo = data as IStartupInfoViewModel;
          this.isAuthenticated = true;
          this.infoLoadComplete.next(this.startupInfo);
        },
        error => {
          this.isAuthenticated = false;
        }
      )
      .add(() => {
        this.appStarting = false;
        if (!this.isAuthenticated) {
          this.router.navigate(['/signin-user']);
        }
      });

    this.httpClient
      .get(`${environment.apiUrl}/app/AddressProperties`, { withCredentials: true })
      .pipe(share())
      .subscribe(
        data => {
          this.addressProperties = data as IAddressPropertiesViewModel;
          this.addressPropertiesCompletes$.next(this.addressProperties);
        }
      );
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authService.appStarting) {
      // dev mode. TODO: create server response header
      return true;
    }

    const isLoggedIn = this.authService.isLoggedIn;

    const isLoginForm = route.routeConfig.path === 'signin-user';

    if (isLoggedIn && isLoginForm) {
      this.router.navigate(['/']);
      return false;
    }

    if (!isLoggedIn && !isLoginForm) {
      this.router.navigate(['/signin-user']);
    }

    return isLoggedIn || isLoginForm;
  }
}
