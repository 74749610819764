import { Component, OnInit, ViewChild } from '@angular/core';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DevExpress from 'devextreme';
import moment from 'moment';

import { environment } from 'src/environments/environment';

import { Tools } from 'src/app/shared/tools';

import { SelectItem } from '../../../../shared/models/select-item.model';
import * as models from 'src/app/shared/models/generated';

@Component({
  selector: 'app-building-unit-listing-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class BuildingUnitListingListComponent implements OnInit {
  @ViewChild('portfolioControl', {read: DxiItemComponent, static: false}) portfolioControl: DxiItemComponent;
  @ViewChild('buildingControl', {read: DxiItemComponent, static: false}) buildingControl: DxiItemComponent;
  @ViewChild('buildingUnitControl', {read: DxiItemComponent, static: false}) buildingUnitControl: DxiItemComponent;

  companyId: number;
  portfolioId: number;
  buildingId: number;
  buildingUnitId: number;

  dataSource: DevExpress.data.CustomStore;
  companyDataSource: DevExpress.data.CustomStore;
  portfolioDataSource: DevExpress.data.CustomStore;
  buildingDataSource: DevExpress.data.CustomStore;
  buildingUnitDataSource: DevExpress.data.CustomStore;

  companyControlOptions: any;
  portfolioControlOptions: any;
  buildingControlOptions: any;
  buildingUnitControlOptions: any;

  tools: typeof Tools;
  priceTypeDataSource: SelectItem[];
  priceUnitMetricsDataSource: Array<{ label: string, value: models.BuildingUnitListingPriceUnitMetrics }>;

  get _window() {
    return window;
  }

  constructor() {
    this.tools = Tools;
    this.priceTypeDataSource = Tools.EnumToArray(models.BuildingUnitListingPriceType);
    this.priceUnitMetricsDataSource = [
      {
        label: 'PSF/Yr',
        value: models.BuildingUnitListingPriceUnitMetrics.PsfPerYear,
      },
      {
        label: 'PSF/Mo',
        value: models.BuildingUnitListingPriceUnitMetrics.PsfPerMonth,
      }
    ];
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/BuildingUnitListing`,
      insertUrl: `${environment.adminUrl}/BuildingUnitListing`,
      updateUrl: `${environment.adminUrl}/BuildingUnitListing`,
      deleteUrl: `${environment.adminUrl}/BuildingUnitListing`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};
        if (ajaxOptions.data.values) {
          this.prepareSave(ajaxOptions);
        }
      },
    });

    this.companyDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/company?companyType=LandlordCompany&withDeleted=true`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      }
    });

    this.portfolioDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/portfolio?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.companyId) {
          ajaxOptions.data.companyId = this.companyId;
        }
      },
    });

    this.buildingDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/building?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.portfolioId) {
          ajaxOptions.data.id = this.portfolioId;
        }
      },
    });

    this.buildingUnitDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/buildingUnit?withDeleted=true`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = {withCredentials: true};

        if (this.buildingId) {
          ajaxOptions.data.buildingId = this.buildingId;
        }
      },
    });

    this.companyControlOptions = {
      dataSource: this.companyDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select company',
      valueExpr: 'id',
      value: this.companyId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.companyId = event.value;
        this.portfolioId = null;
        this.buildingId = null;
        this.buildingUnitId = null;
        if (this.portfolioControl && this.portfolioControl.instance) {
          const dataSource = this.portfolioControl.instance.getDataSource();
          dataSource.load();
          this.portfolioControl.disabled = false;
        }
        return event.value;
      },
    };

    this.portfolioControlOptions = {
      dataSource: this.portfolioDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select portfolio',
      valueExpr: 'id',
      value: this.portfolioId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.portfolioId = event.value;
        this.buildingId = null;
        this.buildingUnitId = null;
        if (this.buildingControl && this.buildingControl.instance) {
          const dataSource = this.buildingControl.instance.getDataSource();
          dataSource.load();
          this.buildingControl.disabled = false;
        }
        return event.value;
      },
    };

    this.buildingControlOptions = {
      dataSource: this.buildingDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select building',
      valueExpr: 'id',
      value: this.buildingId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.buildingId = event.value;
        this.buildingUnitId = null;
        if (this.buildingUnitControl && this.buildingUnitControl.instance) {
          const dataSource = this.buildingUnitControl.instance.getDataSource();
          dataSource.load();
          this.buildingUnitControl.disabled = false;
        }
        return event.value;
      },
    };

    this.buildingUnitControlOptions = {
      dataSource: this.buildingUnitDataSource,
      closeOnOutsideClick: true,
      showPopupTitle: false,
      showClearButton: true,
      placeholder: 'Select building unit',
      valueExpr: 'id',
      value: this.buildingUnitId,
      onValueChanged: (event: {value: number, previousValue: number}) => {
        this.buildingUnitId = event.value;
        return event.value;
      },
    };
  }

  prepareSave(ajaxOptions) {
    if (!ajaxOptions || !ajaxOptions.data || !ajaxOptions.data.values) {
      return;
    }

    const values = JSON.parse(ajaxOptions.data.values) as models.IBuildingUnitListingViewModel;
    delete values.buildingUnit;
    values.buildingUnitId = this.buildingUnitId;
    ajaxOptions.data.values = JSON.stringify(values);
  }

  initNewRow(e) {
    e.data.createdOn = moment().startOf('day').format('YYYY-MM-DD');

    this.companyId = null;
    this.portfolioId = null;
    this.buildingId = null;
    this.buildingUnitId = null;

    if (this.portfolioControl && this.portfolioControl.instance) {
      this.portfolioControl.disabled = true;
    }
    if (this.buildingControl && this.buildingControl.instance) {
      this.buildingControl.disabled = true;
    }
    if (this.buildingUnitControl && this.buildingUnitControl.instance) {
      this.buildingUnitControl.disabled = true;
    }
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'buildingUnit.building.portfolio.companyId') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.companyControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'buildingUnit.building.portfolio.id') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.portfolioControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'buildingUnit.building.id') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.buildingControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }

    if (e.parentType === 'dataRow' && e.dataField === 'buildingUnit.id') {
      e.editorName = 'dxLookup';

      const previousOnValueChanged = this.buildingUnitControlOptions.onValueChanged;
      e.editorOptions.onValueChanged = async (event: {value: number, previousValue: number}) => {
        if (event.value === event.previousValue) {
          return;
        }

        e.setValue(event.value);
        await previousOnValueChanged(event);
      };
    }
  }

  onEditingStart(e) {
    const listing = e.data as models.IBuildingUnitListing;

    this.companyId = listing.buildingUnit?.building?.portfolio?.companyId;
    this.portfolioId = listing.buildingUnit?.building?.portfolio?.id;
    this.buildingId = listing.buildingUnit?.building?.id;
    this.buildingUnitId = listing.buildingUnit?.id;

    if (this.portfolioControl && this.portfolioControl.instance) {
      this.portfolioControl.disabled = false;
    }
    if (this.buildingControl && this.buildingControl.instance) {
      this.buildingControl.disabled = false;
    }
    if (this.buildingUnitControl && this.buildingUnitControl.instance) {
      this.buildingUnitControl.disabled = false;
    }
  }

  onRowPrepared(e): void {
    if (e.rowType === 'data' && e.data && e.data.deletedOn && e.rowElement) {
      e.rowElement.classList.add('is-deleted');
    }
  }

  getBuildingPicture(item) {
    return Tools.buildingPictureThumbnail(item?.data?.buildingUnit?.building) || Tools.noImageUrl;
  }

  getBuildingName(item) {
    return Tools.buildingNameExpression(item?.data?.buildingUnit?.building);
  }

  getBuildingUnitPicture(item) {
    if (item && item.data && item.data.buildingUnit && item.data.buildingUnit.pictures) {
      return Tools.buildingUnitPictureThumbnail(item.data.buildingUnit) || Tools.noImageUrl;
    } else if (item && item.pictures) {
      return Tools.buildingUnitPictureThumbnail(item) || Tools.noImageUrl;
    }
    return Tools.noImageUrl;
  }

  getBuildingUnitName(item) {
    if (item && item.data && item.data.buildingUnit) {
      return item.data.buildingUnit.name;
    } else if (item) {
      return item.name;
    }
    return '';
  }

  isEditButtonVisible(e): boolean {
    return e && e.row && e.row.data && !e.row.data.deletedOn;
  }
}
