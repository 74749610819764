<h2 class="content-block">Lease Floor Plans</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="leaseFloorPlanDataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              (onEditorPreparing)="onEditorPreparing($event)">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true">
  </dxo-pager>

  <dxo-paging [pageSize]="15">
  </dxo-paging>

  <dxo-filter-row [visible]="true">
  </dxo-filter-row>

  <dxo-filter-panel [visible]="true">
  </dxo-filter-panel>

  <dxo-filter-builder>
  </dxo-filter-builder>

  <dxo-filter-builder-popup>
  </dxo-filter-builder-popup>

  <dxo-search-panel [visible]="true">
  </dxo-search-panel>

  <dxo-group-panel [visible]="true">
  </dxo-group-panel>

  <dxi-column dataField="leaseId" caption="Lease" cellTemplate="leaseFieldTemplate">
    <dxo-lookup [dataSource]="leaseDataSource" valueExpr="leaseId" [displayExpr]="tools.leaseNameExpression">
    </dxo-lookup>
  </dxi-column>

  <div *dxTemplate="let item of 'leaseFieldTemplate'" class="lookup-user-field">
    <div>{{tools.leaseNameExpression(item.data.lease)}}</div>
  </div>

  <dxi-column dataField="planId" caption="Plan" cellTemplate="floorPlanFieldTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'floorPlanFieldTemplate'">
    <div>{{ item.data.plan.name }}</div>
  </div>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup title="Add or edit Lease Floor Plan" width="95%"
               [showTitle]="true" [maxWidth]="600" [height]="300"
               [position]="{ my: 'center', at: 'center', of: getWindow() }">
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="leaseId" [editorOptions]="leaseControlOptions">
      </dxi-item>
      <dxi-item dataField="planId" [editorOptions]="floorPlanControlOptions" #floorPlan>
      </dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>

