import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import * as models from '../../../../shared/models/generated';

import { LeaseAmendmentService } from '../../services/lease-amendment.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';

@Component({
  templateUrl: 'edit-eversign-lease-amendment-dialog.component.html',
  styleUrls: ['edit-eversign-lease-amendment-dialog.component.scss'],
})
export class EditEversignLeaseAmendmentDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('eversignContainerElementRef', {static: false}) eversignContainerElementRef: ElementRef;

  leaseAmendment: models.ILeaseAmendmentFile;

  readonly reloadLeaseAmendmentList: () => Promise<void>;

  private readonly _leaseAmendmentService: LeaseAmendmentService;
  private readonly _popupRefService: PopupRefService;
  private readonly _destroy: Subject<void>;

  constructor(leaseAmendmentService: LeaseAmendmentService, popupRefService: PopupRefService) {
    this._leaseAmendmentService = leaseAmendmentService;
    this._popupRefService = popupRefService;
    this._destroy = new Subject<void>();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngAfterViewInit(): void {
    if (
      !this.eversignContainerElementRef ||
      !this.eversignContainerElementRef.nativeElement ||
      !this.leaseAmendment ||
      !this.leaseAmendment.prepareUrl
    ) {
      return;
    }

    const containerChildren = this.eversignContainerElementRef.nativeElement.children;
    for (let i = 0, num = containerChildren.length; i < num; i++) {
      if (!containerChildren[i] || typeof containerChildren[i].remove !== 'function') {
        continue;
      }

      containerChildren[i].remove();
    }

    (<any>window).eversignEmbeddedRequesting.open({
      url: this.leaseAmendment.prepareUrl,
      containerID: this.eversignContainerElementRef.nativeElement.id,
      events: {
        submit: () => {
          this._leaseAmendmentService
            .remapLeaseAmendment(this.leaseAmendment)
            .pipe(
              tap(() => {
                this._popupRefService.hideAll();

                this.reloadLeaseAmendmentList();
              }),
              takeUntil(this._destroy),
            )
            .subscribe();
        },
      },
    });
  }
}
