<dx-scroll-view width="100%" height="100%">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <!-- Start: User select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          User
        </div>
        <div class="dx-field-value">
          <dx-lookup name="userId" required
                     noDataText="No users found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [cleanSearchOnOpening]="true" [dataSource]="userDataSource"
                     [isValid]="form.submitted ? userId.valid : true" [searchExpr]="searchableUserFields"
                     [(ngModel)]="userInvitation.userId" #userId="ngModel">
            <ng-container *dxTemplate="let profile of 'field'">
              <ng-container *ngIf="profile; else noUser">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img class="rounded-circle" width="20" height="20"
                         [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                         [alt]="profile.firstName + ' ' + profile.lastName" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{profile.firstName}}
                      {{profile.lastName}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noUser>
                <span class="text-muted">
                  Select user
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let profile of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-img px-1">
                  <img class="rounded-circle" width="40" height="40"
                       [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                       [alt]="profile.firstName + ' ' + profile.lastName" />
                </div>
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{profile.firstName}}
                      {{profile.lastName}}
                    </p>
                    <p class="mb-0 small w-100 text-truncate"
                       *ngIf="profile.company && profile.company.name">
                      {{profile.company.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && userId.hasError('required')">
            User is required
          </div>
        </div>
      </div>
    </div>
    <!-- End: User select -->
    <!-- Start: Lease select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Lease
        </div>
        <div class="dx-field-value">
          <dx-lookup name="leaseId"
                     noDataText="No leases found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="leaseDataSource"
                     [isValid]="form.submitted ? leaseId.valid : true"
                     [(ngModel)]="userInvitation.leaseId"
                     (ngModelChange)="handleLeaseChange($event)"
                     #leaseId="ngModel">
            <ng-container *dxTemplate="let lease of 'field'">
              <ng-container *ngIf="lease; else noLease">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{Tools.shortLeaseNameExpression(lease)}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noLease>
                <span class="text-muted">
                  Select lease
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let lease of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{Tools.shortLeaseNameExpression(lease)}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
        </div>
      </div>
    </div>
    <!-- End: Lease select -->
    <!-- Start: Portfolio select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Portfolio
        </div>
        <div class="dx-field-value">
          <dx-lookup name="portfolioId"
                     noDataText="No portfolios found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="portfoliosDataSource"
                     [isValid]="form.submitted ? portfolioId.valid : true"
                     [(ngModel)]="userInvitation.portfolioId"
                     (ngModelChange)="handlePortfolioChange($event)"
                     #portfolioId="ngModel">
            <ng-container *dxTemplate="let portfolio of 'field'">
              <ng-container *ngIf="portfolio; else noPortfolio">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{portfolio.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noPortfolio>
                <span class="text-muted">
                  Select portfolio
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let portfolio of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{portfolio.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
        </div>
      </div>
    </div>
    <!-- End: Portfolio select -->
    <!-- Start: Building select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Building
        </div>
        <div class="dx-field-value">
          <dx-lookup name="buildingId"
                     noDataText="No buildings found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="buildingsDataSource"
                     [isValid]="form.submitted ? buildingId.valid : true"
                     [(ngModel)]="userInvitation.buildingId"
                     (ngModelChange)="handleBuildingChange($event)"
                     #buildingId="ngModel">
            <ng-container *dxTemplate="let building of 'field'">
              <ng-container *ngIf="building; else noBuilding">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{building.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noBuilding>
                <span class="text-muted">
                  Select building
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let building of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{building.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
        </div>
      </div>
    </div>
    <!-- End: Building select -->
    <!-- Start: Role select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Role
        </div>
        <div class="dx-field-value">
          <dx-lookup name="leaseUserRole" required
                     noDataText="No roles found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="leaseUserRolesDataSource"
                     [isValid]="form.submitted ? leaseUserRole.valid : true"
                     [(ngModel)]="userInvitation.leaseUserRole"
                     (ngModelChange)="handleRoleChange($event)"
                     #leaseUserRole="ngModel">
            <ng-container *dxTemplate="let role of 'field'">
              <ng-container *ngIf="role; else noRole">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{role.text}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noRole>
                <span class="text-muted">
                  Select role
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let role of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{role.text}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && leaseUserRole.hasError('required')">
            Role is required
          </div>
        </div>
      </div>
    </div>
    <!-- Start: Role select -->
    <!-- Start: Invited by select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Invited by
        </div>
        <div class="dx-field-value">
          <dx-lookup name="invitedByUserId" required
                     noDataText="No users found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [cleanSearchOnOpening]="true" [dataSource]="userDataSource"
                     [isValid]="form.submitted ? invitedByUserId.valid : true" [searchExpr]="searchableUserFields"
                     [(ngModel)]="userInvitation.invitedByUserId" #invitedByUserId="ngModel">
            <ng-container *dxTemplate="let profile of 'field'">
              <ng-container *ngIf="profile; else noUser">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img class="rounded-circle" width="20" height="20"
                         [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                         [alt]="profile.firstName + ' ' + profile.lastName" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{profile.firstName}}
                      {{profile.lastName}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noUser>
                <span class="text-muted">
                  Select user
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let profile of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-img px-1">
                  <img class="rounded-circle" width="40" height="40"
                       [src]="profile.avatar && profile.avatar.url ? profile.avatar.url : 'assets/img/avatar.png'"
                       [alt]="profile.firstName + ' ' + profile.lastName" />
                </div>
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{profile.firstName}}
                      {{profile.lastName}}
                    </p>
                    <p class="mb-0 small w-100 text-truncate"
                       *ngIf="profile.company && profile.company.name">
                      {{profile.company.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && invitedByUserId.hasError('required')">
            User is required
          </div>
        </div>
      </div>
    </div>
    <!-- End: Invited by select -->
    <!-- Start: Related to select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          To work with
        </div>
        <div class="dx-field-value">
          <dx-lookup name="relatedToCompanyId" required
                     noDataText="No companies found matching your criteria"
                     fieldTemplate="field" itemTemplate="listItem" valueExpr="id"
                     [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                     [searchEnabled]="false" [cleanSearchOnOpening]="true" [dataSource]="companyDataSource"
                     [isValid]="form.submitted ? relatedToCompanyId.valid : true"
                     [(ngModel)]="userInvitation.relatedToCompanyId" #relatedToCompanyId="ngModel">
            <ng-container *dxTemplate="let company of 'field'">
              <ng-container *ngIf="company; else noCompany">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="20" height="20" style="object-fit: cover"
                         [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                         [alt]="company.name" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-template #noCompany>
                <span class="text-muted">
                  Select company
                </span>
              </ng-template>
            </ng-container>

            <ng-container *dxTemplate="let company of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-img px-1">
                  <img width="40" height="40" style="object-fit: cover"
                       [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                       [alt]="company.name" />
                </div>
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-lookup>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && relatedToCompanyId.hasError('required')">
            Company is required
          </div>
        </div>
      </div>
    </div>
    <!-- End: Related to select -->
    <!-- Start: Claims select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Claims
        </div>
        <div class="dx-field-value">
          <dx-drop-down-box placeholder="Select claims" displayExpr="id"
                            [dataSource]="claimDataSource" [showClearButton]="true"
                            [(value)]="userInvitation.claimIds">
            <div *dxTemplate="let data of 'content'">
              <dx-data-grid [dataSource]="claimDataSource"
                            [selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true"
                            [paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: false }"
                            [scrolling]="{ mode: 'virtual' }" [height]="345"
                            (onSelectionChanged)="claimSelectionChanged($event)">
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>

                <dxi-column dataField="id" dataType="number" [width]="75"></dxi-column>
                <dxi-column dataField="claimType" caption="Claim" cellTemplate="claimTypeTemplate" dataType="number"></dxi-column>

                <div *dxTemplate="let item of 'claimTypeTemplate';">
                  {{claimDisplayExpression(item.data)}}
                </div>
              </dx-data-grid>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </div>
    <!-- End: Claims select -->
    <!-- Start: Claim groups select -->
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">
          Claim groups
        </div>
        <div class="dx-field-value">
          <dx-drop-down-box placeholder="Select claim groups" displayExpr="name"
                            [dataSource]="claimGroupDataSource" [showClearButton]="true"
                            [(value)]="userInvitation.claimGroupIds">
            <div *dxTemplate="let data of 'content'">
              <dx-data-grid [dataSource]="claimGroupDataSource"
                            [selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true"
                            [paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: false }"
                            [scrolling]="{ mode: 'virtual' }" [height]="345"
                            (onSelectionChanged)="claimGroupSelectionChanged($event)">
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>

                <dxi-column dataField="id" dataType="number" [width]="75"></dxi-column>
                <dxi-column dataField="name" dataType="text"></dxi-column>
              </dx-data-grid>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </div>
    <!-- End: Claim groups select -->

    <div class="dx-fieldset">
      <div class="d-flex flex-row mx-n2">
        <div class="px-2 w-50">
          <dx-button type="success" width="100%" text="Invite"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="px-2 w-50">
          <dx-button type="normal" text="Cancel" width="100%"
                     (onClick)="cancel()">
          </dx-button>
        </div>
      </div>
    </div>

  </form>
</dx-scroll-view>

