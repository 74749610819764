<h2 class="content-block">Building Unit Listings</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              (onEditorPreparing)="onEditorPreparing($event)" (onEditingStart)="onEditingStart($event)"
              (onInitNewRow)="initNewRow($event)" (onRowPrepared)="onRowPrepared($event)">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" dataType="number"
              [width]="70">
  </dxi-column>

  <dxi-column caption="Building"
              dataField="buildingUnit.building.id"
              cellTemplate="buildingCellTemplate">
    <dxo-lookup valueExpr="buildingUnit.buildingId"
                [displayExpr]="tools.buildingNameExpression"
                itemTemplate="buildingTemplate"
                [dataSource]="buildingDataSource">
    </dxo-lookup>
  </dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'buildingCellTemplate'">
    <img alt=""
         [src]="getBuildingPicture(item)" />
    <div>{{ getBuildingName(item) }}</div>
  </div>

  <dxi-column caption="Unit"
              dataField="buildingUnit.id"
              cellTemplate="buildingUnitCellTemplate">
    <dxo-lookup valueExpr="id" displayExpr="name"
                itemTemplate="buildingUnitTemplate"
                [dataSource]="buildingUnitDataSource">
    </dxo-lookup>
  </dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'buildingUnitCellTemplate'">
    <img alt=""
         [src]="getBuildingUnitPicture(item)" />
    <div>{{ getBuildingUnitName(item) }}</div>
  </div>

  <dxi-column dataField="buildingUnit.building.portfolio.id" caption="Portfolio">
    <dxo-lookup [dataSource]="portfolioDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="buildingUnit.building.portfolio.companyId" caption="Company">
    <dxo-lookup [dataSource]="companyDataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="availableOn" dataType="date" caption="Available On" ></dxi-column>
  <dxi-column dataField="sizeMin" dataType="number" caption="Size Min"
              [editorOptions]="{ min: 0, format: '#0 square feet', placeholder: '0 square feet' }">
  </dxi-column>
  <dxi-column dataField="sizeMax" dataType="number" caption="Size Max"
              [editorOptions]="{ min: 0, format: '#0 square feet', placeholder: '0 square feet' }">
  </dxi-column>
  <dxi-column dataField="priceType" dataType="number" caption="Price Type">
    <dxo-lookup [dataSource]="priceTypeDataSource" valueExpr="id" displayExpr="text"> </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="priceMin" dataType="number" caption="Price Min"></dxi-column>
  <dxi-column dataField="priceMax" dataType="number" caption="Price Max"></dxi-column>
  <dxi-column dataField="priceUnitMetrics" caption="Price Unit Metrics">
    <dxo-lookup [dataSource]="priceUnitMetricsDataSource" valueExpr="value" displayExpr="label"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="notes">
  </dxi-column>

  <dxi-column dataField="createdOn" dataType="date" caption="Created On" ></dxi-column>

  <dxi-column type="buttons">
    <dxi-button name="edit">
    </dxi-button>
    <dxi-button name="delete"
                [visible]="isEditButtonVisible">
    </dxi-button>
  </dxi-column>

  <dxo-editing mode="popup"
               [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup title="Add or edit Building Unit Listing" width="95%"
               [showTitle]="true" [maxWidth]="600" [height]="450"
               [position]="{ my: 'center', at: 'center', of: _window }">
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="buildingUnit.building.portfolio.companyId" [editorOptions]="companyControlOptions" isRequired="true"></dxi-item>
      <dxi-item dataField="buildingUnit.building.portfolio.id" [editorOptions]="portfolioControlOptions" isRequired="true" #portfolioControl></dxi-item>
      <dxi-item dataField="buildingUnit.building.id" [editorOptions]="buildingControlOptions" isRequired="true" #buildingControl></dxi-item>
      <dxi-item dataField="buildingUnit.id" isRequired="true" [editorOptions]="buildingUnitControlOptions" #buildingUnitControl></dxi-item>
      <dxi-item dataField="availableOn" [editorOptions]="{ dateSerializationFormat: 'yyyy-MM-dd' }"></dxi-item>
      <dxi-item dataField="sizeMin" dataType="number" [editorOptions]="{ min: 0 }"></dxi-item>
      <dxi-item dataField="sizeMax" isRequired="true" dataType="number" [editorOptions]="{ min: 0 }"></dxi-item>
      <dxi-item dataField="priceType" isRequired="true" caption="Price Type" [editorOptions]="{ showClearButton: true }">
      </dxi-item>
      <dxi-item dataField="priceMin" dataType="number" [editorOptions]="{ min: 0 }"></dxi-item>
      <dxi-item dataField="priceMax" dataType="number" [editorOptions]="{ min: 0 }"></dxi-item>
      <dxi-item dataField="priceUnitMetrics" [editorOptions]="{ showClearButton: true }"></dxi-item>
      <dxi-item dataField="notes"></dxi-item>
      <dxi-item dataField="createdOn" isRequired="true"
                [editorOptions]="{ dateSerializationFormat: 'yyyy-MM-dd' }"></dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
