<h2 class="content-block">Leases with 3rd party lease</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [remoteOperations]="true"
>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" caption="Lease" cellTemplate="leaseCellTemplate">
    <dxo-lookup [dataSource]="leaseDataSource" valueExpr="leaseId" [displayExpr]="Tools.shortLeaseNameExpression">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'leaseCellTemplate'" class="lookup-user-field">
    {{Tools.leaseVmNameExpression(item?.data)}}
  </div>

  <dxi-column dataField="landlordCompanyId" caption="Landlord Company" cellTemplate="landlordCellTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'landlordCellTemplate'" class="lookup-user-field">
    <div>
      {{ item?.data?.landlordCompany?.name }}
    </div>
  </div>

  <dxi-column dataField="buildingId" caption="Building" cellTemplate="buildingCellTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'buildingCellTemplate'" class="lookup-user-field">
    <img alt=""
         [src]="getBuildingPicture(item)"/>
    <div>
      {{ getBuildingName(item) }}
    </div>
  </div>

  <dxi-column caption="Files urls" cellTemplate="fileUrlTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'fileUrlTemplate'" class="lookup-user-field">
    <div>
      <a
        (click)="downloadFiles($event, item)" href="">
        Download Files
      </a>
    </div>

  </div>

  <dxi-column dataField="buildingUnitId" caption="Building Unit" cellTemplate="buildingUnitCellTemplate">
  </dxi-column>
  <div *dxTemplate="let item of 'buildingUnitCellTemplate'" class="lookup-user-field">
    <div>
      {{ getBuildingUnitName(item) }}
    </div>
  </div>

  <dxi-column dataField="thirdPartyLeaseId" cellTemplate="thirdPartyLeaseCellTemplate">
    <dxo-lookup [dataSource]="thirdPartyLeasesDataSource" valueExpr="id" [displayExpr]="Tools.leaseVmNameExpression">
    </dxo-lookup>
  </dxi-column>

  <div *dxTemplate="let item of 'thirdPartyLeaseCellTemplate'" class="lookup-user-field">
    {{Tools.leaseVmNameExpression(item?.data)}}
  </div>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false">
    <dxo-popup
      title="Leases with 3rd party lease"
      [maxWidth]="500"
      [showTitle]="true"
      [position]="{ my: 'center', at: 'center', of: _window }"
    >
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="id" caption="Lease"
                [disabled]="true" [editorOptions]="leasesEditorOptions"
      ></dxi-item>
      <dxi-item dataField="thirdPartyLeaseId" caption="3rd Party Lease"
                [editorOptions]="thirdPartyLeasesEditorOptions"
      ></dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
