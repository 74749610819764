import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

export class InnerContext<T> {
  private readonly _variableDirective: VariableDirective<T>;

  constructor(variableDirective: VariableDirective<T>) {
    this._variableDirective = variableDirective;
  }

  get appVariable(): T {
    return this._variableDirective.appVariable;
  }
}

@Directive({
  selector: '[appVariable]',
})
export class VariableDirective<T> implements OnInit {
  @Input() appVariable: T;

  private readonly _viewContainerRef: ViewContainerRef;
  private readonly _templateRef: TemplateRef<InnerContext<T>>;

  constructor(viewContainerRef: ViewContainerRef, templateRef: TemplateRef<InnerContext<T>>) {
    this._viewContainerRef = viewContainerRef;
    this._templateRef = templateRef;
  }

  ngOnInit(): void {
    this._viewContainerRef.createEmbeddedView(this._templateRef, new InnerContext<T>(this));
  }
}
