import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortfolioService } from './services/portfolio.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    PortfolioService,
  ],
})
export class PortfolioModule {
  static forRoot(): ModuleWithProviders<PortfolioModule> {
    return {
      ngModule: PortfolioModule,
      providers: [
        PortfolioService,
      ],
    };
  }
}
