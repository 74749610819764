import { Injectable } from '@angular/core';
import { DxToastComponent } from 'devextreme-angular';

@Injectable()
export class ToastService {

    public toast: DxToastComponent;

    constructor() { }

    public success(message: string, duration: number = 2000) {
        this.show(message, duration, 'success');
    }

    public warning(message: string, duration: number = 2000) {
        this.show(message, duration, 'warning');
    }

    public error(message: string, duration: number = 5000) {
        this.show(message, duration, 'error');
    }

    public info(message: string, duration: number = 5000) {
        this.show(message, duration, 'info');
    }

    private show(message: string, duration: number, type: string) {
        if (this.toast) {
            this.toast.message = message;
            this.toast.displayTime = duration;
            this.toast.type = type;
            this.toast.instance.show();
        }
    }

}