import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { ImportService } from '../../../import/services/import.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { PopupService } from '../../../infrastructure/services/popup.service';

import * as models from '../../../../shared/models/generated';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';

@Component({
  templateUrl: 'point-of-interest-import-mapping.component.html',
  styleUrls: ['point-of-interest-import-mapping.component.scss'],
})
export class PointOfInterestImportMappingComponent implements OnInit {
  private readonly _importService: ImportService;
  private readonly _popupService: PopupService;
  private readonly _popupRefService: PopupRefService;
  private readonly _router: Router;

  @ViewChild(NgForm, {static: false}) form: NgForm;

  importFile: models.IFileViewModel;
  importFileError: string;

  importTaskPayload: models.IImportPointsOfInterestTaskPayload;

  importFileHeaders: Array<{name: string, value: number, disabled?: boolean}>;

  fieldMappings: models.IImportPointsOfInterestTaskFieldMappingViewModel;

  isLoading: BehaviorSubject<boolean>;

  constructor(
    importService: ImportService,
    popupService: PopupService,
    popupRefService: PopupRefService,
    router: Router,
  ) {
    this._importService = importService;
    this._popupService = popupService;
    this._popupRefService = popupRefService;
    this._router = router;

    this.isLoading = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
    this.fieldMappings = <models.IImportPointsOfInterestTaskFieldMappingViewModel>{};
    this.importFileHeaders = <Array<{name: string, value: number, disabled: boolean}>>[];

    if (this.importFile && this.importFile.url) {
      const subscription = this._importService
        .getFileHeaders(this.importFile.url)
        .subscribe(
          (fileHeaders: Array<string>): void => {
            subscription.unsubscribe();

            this.importFileHeaders = fileHeaders.map((header: string, index: number) => {
              return {
                name: header,
                value: index,
              };
            });
          },
          (httpError: HttpErrorResponse) => {
            this.importFileError = httpError.error;
          }
        );
    }
  }

  handleSelectBoxChange({value, previousValue}): void {
    if ((value || value === 0) && this.importFileHeaders[value]) {
      this.importFileHeaders[value].disabled = true;
    }

    if ((previousValue || previousValue === 0) && this.importFileHeaders[previousValue]) {
      this.importFileHeaders[previousValue].disabled = false;
    }
  }

  submit(): void {
    if (!this.form || this.form.invalid) {
      return;
    }

    const importTask = <models.IImportTaskViewModel>{
      taskType: models.ImportTaskType.ImportPointsOfInterestTask,
      file: this.importFile,
      payload: {
        ...this.importTaskPayload,
        fieldMappings: this.fieldMappings,
      },
    };

    this.isLoading.next(true);

    const subscription = this._importService
      .createImportTask(importTask)
      .subscribe(() => {
        subscription.unsubscribe();

        this.isLoading.next(false);

        this._popupRefService.hideAll();

        this._router.navigate(['import-tasks']);
      });
  }

  cancel(): void {
    if (this.form && this.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel?',
        acceptFn: () => {
          this._popupRefService.hide();
        },
      },
    });
  }
}
