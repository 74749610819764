import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxFileUploaderComponent } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { environment } from 'src/environments/environment';

import { Tools } from 'src/app/shared/tools';

import { PopupService } from '../../../infrastructure/services/popup.service';

import { FloorPlanUpsertComponent } from '../floor-plan-upsert/floor-plan-upsert.component';

import * as models from '../../../../shared/models/generated';

@Component({
  selector: 'app-floor-plan-list',
  templateUrl: './floor-plan-list.component.html',
  styleUrls: ['./floor-plan-list.component.scss']
})
export class FloorPlanListComponent implements OnInit {
  @ViewChild('fileUploader', {static: false}) fileUploader: DxFileUploaderComponent;
  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  dataSource: any;
  url = `${environment.adminUrl}/plan`;
  uploadUrl = environment.apiUrl + '/storage/Upload';
  popupVisible = false;
  currentPictrue = {} as models.IFile;
  pictureEditor: any;
  buildingsDataSource: any;
  portfolioItems: any;

  Tools = Tools;

  _portfolioId = 0;
  set portfolioId(val) {
    this._portfolioId = val;
    this.buildingsDataSource = Tools.lookupDataSource(`building?id=${val}&withDeleted=false`);
  }
  get portfolioId() {
    return this._portfolioId;
  }

  private readonly _popupService: PopupService;

  constructor(popupService: PopupService) {
    this._popupService = popupService;

    this.showPlanEditPopup = this.showPlanEditPopup.bind(this);
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      deleteUrl: this.url,
      onBeforeSend: function(operation, ajaxOptions) {
        if (operation === 'load') {
          ajaxOptions.data = ajaxOptions.data ?
            { ...ajaxOptions.data, kind: models.PlanKind.Floor } :
            { kind: models.PlanKind.Floor };
        }
      }
    });

    Tools
      .lookupDataSource('Portfolio')
      .on('loaded', data => { this.portfolioItems = data; })
      .load();
  }

  prepareToolbar($event) {
    if ($event.toolbarOptions) {
      $event.toolbarOptions.items = [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'createFloorPlan',
          options: {
            icon: 'fa fa-plus',
            disabled: false,
            text: 'Create Floor Plan',
            onClick: () => this._showPlanEditPopup(),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        ...$event.toolbarOptions.items,
      ];
    }
  }

  onUploaded(e) {
    this.currentPictrue = JSON.parse(e.request.responseText);
    this.fileUploader.instance.reset();
    this.pictureEditor.option('value', this.currentPictrue);
    this.popupVisible = false;
  }

  showPlanEditPopup(event: {row: {data: models.IPlanViewModel}}): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._showPlanEditPopup(event.row.data);
  }

  private _showPlanEditPopup(floorPlan?: models.IPlanViewModel): void {
    this._popupService.show(FloorPlanUpsertComponent, {
      title: `${floorPlan ? 'Edit' : 'Create'} Floor Plan${floorPlan ? '' : '(s)'}`,
      width: 550,
      maxHeight: '90%',
      showCloseButton: false,
      closeOnOutsideClick: false,
      injectableData: {
        floorPlan: floorPlan,
        reloadFloorPlans: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      },
    });
  }
}
