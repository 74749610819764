import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

@Injectable()
export class CompanyService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  getCompaniesList(companyType?: models.CompanyType): Observable<{data: Array<models.ICompanyViewModel>}> {
    let params = new HttpParams();

    if (companyType) {
      params = params.set('companyType', companyType.toString(10));
    }

    return this._httpClient.get<{data: Array<models.ICompanyViewModel>}>(`/admin/company`, { params });
  }
}
