import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

import { environment } from '../../../../environments/environment';

@Injectable()
export class LeaseUserService {
  private readonly _httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  updateLeaseUser(leaseUser: Partial<models.ILeaseUserViewModel>): Observable<void> {
    return this._httpClient
      .put<void>(
        `${environment.adminUrl}/lease-users/${leaseUser.id}`,
        leaseUser,
      );
  }
}
