import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { InviteUserDialogComponent } from './components/invite-user-dialog/invite-user-dialog.component';
import { UserInvitationRequestsComponent } from './components/user-invitation-requests/user-invitation-requests.component';

import { InvitationService } from './services/invitation.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    dx.DxButtonModule,
    dx.DxLookupModule,
    dx.DxTextBoxModule,
    dx.DxDropDownBoxModule,
    dx.DxDataGridModule,
    dx.DxScrollViewModule
  ],
  declarations: [
    InviteUserDialogComponent,
    UserInvitationRequestsComponent,
  ],
  providers: [
    InvitationService,
  ],
  entryComponents: [
    InviteUserDialogComponent,
  ],
})
export class InvitationModule {
  static forRoot(): ModuleWithProviders<InvitationModule> {
    return {
      ngModule: InvitationModule,
      providers: [
        InvitationService,
      ],
    };
  }
}
