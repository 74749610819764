<div class="lease-marking-popup">
  <dx-scroll-view width="100%" height="100%">
    <form name="form" class="form px-4" novalidate
          (ngSubmit)="createLeaseTemplate()" #form="ngForm">
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <!-- Start: Landlord company lookup -->
        <div class="dx-field">
          <div class="dx-field-label"
               [class.text-danger]="form.submitted && landlordCompany.invalid">
            Landlord company
          </div>
          <div class="dx-field-value">
            <dx-lookup name="landlordCompany"
                       noDataText="No landlords found matching your criteria"
                       fieldTemplate="field" itemTemplate="listItem" valueExpr="id" required
                       [showPopupTitle]="false" [showCancelButton]="false" [closeOnOutsideClick]="true"
                       [cleanSearchOnOpening]="true" [items]="landlordCompaniesList | async"
                       [isValid]="form.submitted ? landlordCompany.valid : true"
                       [searchExpr]="setupSearchableCompanyFields"
                       [(ngModel)]="landlordCompanyId" #landlordCompany="ngModel">
              <!-- Start: Field template -->
              <ng-container *dxTemplate="let company of 'field'">
                <ng-container *ngIf="company">
                  <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                    <div class="lookup-field-img px-1">
                      <img width="20" height="20" style="object-fit: cover"
                           [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                           [alt]="company.name" />
                    </div>
                    <div class="lookup-field-summary px-1 min-width-0">
                      <p class="mb-0 w-100 text-truncate">
                        {{company.name}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!company">
                  <span class="text-muted">
                    Select landlord company
                  </span>
                </ng-container>
              </ng-container>
              <!-- End: Field template -->
              <!-- Start: List item template -->
              <ng-container *dxTemplate="let company of 'listItem'">
                <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                  <div class="lookup-field-img px-1">
                    <img width="40" height="40" style="object-fit: cover"
                         [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                         [alt]="company.name" />
                  </div>
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <!-- End: List item template -->
            </dx-lookup>
            <div class="my-1 invalid-feedback d-block"
                 *ngIf="form.submitted && landlordCompany.hasError('required')">
              Landlord company is required
            </div>
          </div>
        </div>
        <!-- End: Landlord company lookup -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          Template
        </div>
        <!-- Start: File uploader -->
        <div class="dx-field">
          <!-- Start: File uploader control -->
          <ng-container *ngIf="!template; else templatePreviewTpl">
            <div class="file-control">
              <dx-file-uploader name="file" accept=".pdf,.doc,.docx" uploadMode="useForm"
                                [multiple]="false" [showFileList]="false" [allowedFileExtensions]="['.pdf', '.doc', '.docx']"
                                (onValueChanged)="handleFileChange($event)">
              </dx-file-uploader>
            </div>
          </ng-container>
          <!-- End: File uploader control -->
          <!-- Start: File preview -->
          <ng-template #templatePreviewTpl>
            <div class="d-flex flex-row align-items-center flex-nowrap mx-n1 mt-1">
              <div class="px-1">
                <dx-button stylingMode="outlined" type="danger" icon="fa fa-times"
                           (onClick)="removeTemplate()">
                </dx-button>
              </div>
              <div class="px-1">
                <p class="mb-0">
                  {{template.name}}
                </p>
              </div>
            </div>
          </ng-template>
          <!-- End: File preview -->
        </div>
        <!-- End: File uploader -->
      </div>
      <!-- End: Fieldset -->
      <!-- Start: Fieldset -->
      <div class="dx-fieldset">
        <div class="d-flex flex-row mx-n1">
          <div class="px-1">
            <dx-button type="success" text="Upload"
                       [useSubmitBehavior]="true">
            </dx-button>
          </div>
          <div class="px-1">
            <dx-button type="normal" text="Cancel"
                       (click)="cancel()">
            </dx-button>
          </div>
        </div>
      </div>
      <!-- End: Fieldset -->
    </form>
  </dx-scroll-view>
</div>
