import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DxDataGridComponent, DxFileUploaderComponent } from 'devextreme-angular';
import { DxiTabComponent } from 'devextreme-angular/ui/nested/tab-dxi';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import * as vm from 'src/app/shared/models/generated';

import { Tools } from 'src/app/shared/tools';

import { environment } from 'src/environments/environment';

import { LeaseAbstractService } from '../../modules/lease-abstract/services/lease-abstract.service';
import { TermService } from '../../modules/term/services/term.service';
import { PopupService } from '../../modules/infrastructure/services/popup.service';
import { TermsService } from '../../shared/services/terms.service';

import { SelectItem } from '../../shared/models/select-item.model';

import { NoticeComponent } from '../../modules/infrastructure/components/notice/notice.component';
import { ImportComponent } from '../../modules/lease-abstract/components/import/import.component';
import { UploadComponent } from '../../modules/lease-abstract/components/upload/upload.component';

@Component({
  selector: 'app-leases',
  templateUrl: './leases.component.html',
  styleUrls: ['./leases.component.scss'],
})
export class LeasesComponent implements OnInit, OnDestroy {
  @ViewChild('fileUploader', {static: false}) fileUploader: DxFileUploaderComponent;
  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;
  @ViewChildren('dxiTab') dxiTabs: QueryList<DxiTabComponent>;

  LeaseStatus = vm.LeaseStatus;
  LeaseType = vm.LeaseType;

  url = `${environment.adminUrl}/leases`;
  uploadUrl = environment.apiUrl + '/storage/Upload';
  leaseFiles: vm.IFile[] = [];
  Tools = Tools;
  LeaseTermType = vm.LeaseTermType;

  BaseRentalRateType = vm.BaseRentalRateType;
  baseRentalRateTypes = Tools.EnumToArray(vm.BaseRentalRateType);

  baseRentalRateTypesOriginal: Array<SelectItem> = [
    {id: vm.BaseRentalRateType.BaseYear, text: 'Base Year'},
    {id: vm.BaseRentalRateType.Net, text: 'Net'},
    {id: vm.BaseRentalRateType.Gross, text: 'Gross'},
  ];

  baseRentalRateUnitMetricsDataSource: Array<{ label: string, value: vm.BaseRentalRateUnitMetrics }> = [
    {
      label: 'PSF/Yr',
      value: vm.BaseRentalRateUnitMetrics.PsfPerYear,
    },
    {
      label: 'PSF/Mo',
      value: vm.BaseRentalRateUnitMetrics.PsfPerMonth,
    }
  ];

  TenantSquareFootageTermType = vm.TenantSquareFootageTermType;
  tenantSquareFootageTermTypes = Tools.EnumToArray(vm.TenantSquareFootageTermType);

  FreeRentTermType = vm.FreeRentTermType;
  freeRentTermTypes = Tools.EnumToArray(vm.FreeRentTermType);

  FreeRentTaxesType = vm.FreeRentTaxesType;
  freeRentTaxesTypes = Tools.EnumToArray(vm.FreeRentTaxesType);

  TenantImprovementsType = vm.TenantImprovementsType;
  TenantImprovementsTypes = Tools.EnumToArray(vm.TenantImprovementsType);

  ContractorTypeForTenantImprovementsWork = vm.ContractorTypeForTenantImprovementsWork;
  ContractorTypesForTenantImprovementsWork = Tools.EnumToArray(vm.ContractorTypeForTenantImprovementsWork);

  WarrantyDurationTypeForLandlordsContractors = vm.WarrantyDurationTypeForLandlordsContractors;
  WarrantyDurationTypesForLandlordsContractors = Tools.EnumToArray(vm.WarrantyDurationTypeForLandlordsContractors);

  RenewalOptionTermType = vm.RenewalOptionTermType;
  RenewalOptionTermTypes = Tools.EnumToArray(vm.RenewalOptionTermType);

  SecurityDepositTermType = vm.SecurityDepositTermType;
  SecurityDepositTermTypes = Tools.EnumToArray(vm.SecurityDepositTermType);

  SecurityGuarantyOptionType = vm.SecurityGuarantyOptionType;
  SecurityGuarantyOptionTypes = Tools.EnumToArray(vm.SecurityGuarantyOptionType);

  TerminationOptionType = vm.TerminationOptionType;
  TerminationOptionTypes = Tools.EnumToArray(vm.TerminationOptionType);

  HvacTermType = vm.HvacTermType;
  HvacTermTypes = Tools.EnumToArray(vm.HvacTermType);

  RealEstateTaxesType = vm.RealEstateTaxesType;
  RealEstateTaxesTypes = Tools.EnumToArray(vm.RealEstateTaxesType);

  OperatingExpensesType = vm.OperatingExpensesType;
  OperatingExpensesTypes = Tools.EnumToArray(vm.OperatingExpensesType);

  RealEstateTaxesCamExpensesTermType = vm.RealEstateTaxesCamExpensesTermType;
  RealEstateTaxesCamExpensesTermTypes = Tools.EnumToArray(vm.RealEstateTaxesCamExpensesTermType);

  RealEstateTaxesCamExpensesCumulativeType = vm.RealEstateTaxesCamExpensesCumulativeType;
  RealEstateTaxesCamExpensesCumulativeTypes = Tools.EnumToArray(vm.RealEstateTaxesCamExpensesCumulativeType);

  EscalationTermType = vm.EscalationTermType;
  EscalationTermTypes = Tools.EnumToArray(vm.EscalationTermType);

  RentalRateEscalationTaxType = vm.RentalRateEscalationTaxType;
  RentalRateEscalationTaxTypes = Tools.EnumToArray(vm.RentalRateEscalationTaxType);

  TermTypeEnum = vm.TermTypeEnum;
  termTypes = Tools.EnumToArray(vm.TermTypeEnum);

  HoldoverProvisionType = vm.HoldoverProvisionType;
  HoldoverProvisionTypes = Tools.EnumToArray(vm.HoldoverProvisionType);

  //
  // Data Sources
  //

  dataSource: CustomStore;

  cellBuildingDataSource: CustomStore;
  cellBuildingUnitDataSource: CustomStore;
  cellLeaseCategoryDataSource: CustomStore;
  cellLeaseStatusDataSource: Array<SelectItem>;
  cellLeaseTypeDataSource: Array<SelectItem>;
  cellCompanyDataStore: CustomStore;

  abstractLeases: Array<vm.ILease>;
  abstractLeaseDataSource: DataSource;

  editingRowKey: number;

  lease: vm.ILease;

  spaceUseItems = [];

  private readonly _termsService: TermsService;
  private readonly _popupService: PopupService;
  private readonly _termService: TermService;
  private readonly _leaseAbstractService: LeaseAbstractService;
  private readonly _destroy$: Subject<void>;

  constructor(
    termsService: TermsService,
    popupService: PopupService,
    termService: TermService,
    leaseAbstractService: LeaseAbstractService
  ) {
    this._termsService = termsService;
    this._popupService = popupService;
    this._termService = termService;
    this._leaseAbstractService = leaseAbstractService;

    this.cellCompanyDataStore = Tools.lookupDataSource('company');
    this.cellBuildingDataSource = Tools.lookupDataSource('building?withDeleted=false');
    this.cellBuildingUnitDataSource = Tools.lookupDataSource('BuildingUnit?withDeleted=false');
    this.cellLeaseCategoryDataSource = Tools.lookupDataSource('LeaseCategory');
    this.cellLeaseStatusDataSource = Tools.EnumToArray(vm.LeaseStatus);
    this.cellLeaseTypeDataSource = Tools.EnumToArray(vm.LeaseType);

    this.abstractLeases = [];
    this.abstractLeaseDataSource = new DataSource({store: this.abstractLeases});

    this.editingRowKey = -1;

    this.lease = <vm.ILease>{};

    this._destroy$ = new Subject<void>();

    this.showLeaseEditDialog = this.showLeaseEditDialog.bind(this);
    this.copyLeaseAndShowLeaseEditDialog = this.copyLeaseAndShowLeaseEditDialog.bind(this);
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: this.url,
      deleteUrl: this.url,
    });

    Tools.lookupDataSource('SpaceUse')
      .on('loaded', data => {
        this.spaceUseItems = data;
      })
      .load();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onRowPrepared(e): void {
    if (e.rowType === 'data' && e.data && e.data.deletedOn && e.rowElement) {
      e.rowElement.classList.add('is-deleted');
    }

    if (e.rowType === 'data' && e.data && e.data.leaseActionType === vm.LeaseActionType.LeaseShouldBeAbstracted && e.rowElement) {
      e.rowElement.classList.add('is-action-required');
    }
  }

  getBuildingPicture(item) {
    if (item && item.data && item.data.building && item.data.building.pictures && item.data.building.pictures.length) {
      return item.data.building.pictures[0].file.thumbnailUrl;
    }

    if (item && item.pictures && item.pictures.length) {
      return item.pictures[0].file.thumbnailUrl;
    }

    return Tools.noImageUrl;
  }

  getBuildingName(item) {
    if (item && item.data && item.data.building) {
      return item.data.building.name;
    } else if (item) {
      return item.name;
    }
    return '';
  }

  onUploaded(e) {
    try {
      const file: vm.IFile = JSON.parse(e.request.responseText);

      this.leaseFiles.push(file);
      this.fileUploader.instance.reset();

      this._pushLeaseFilesStateChangeIntoDataSource();
    } catch (e) {
      console.error(e.message || e);
    }
  }

  private _pushLeaseFilesStateChangeIntoDataSource() {
    if (this.dxDataGridComponent && 0 <= this.editingRowKey) {
      const rowIndex = this.dxDataGridComponent.instance.getRowIndexByKey(this.editingRowKey) || 0;
      this.dxDataGridComponent.instance.cellValue(rowIndex, 'leaseFiles', Date.now());
    }
  }

  prepareToolbar($event) {
    if ($event.toolbarOptions) {
      $event.toolbarOptions.items = [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'leaseAbstractImportTool',
          options: {
            icon: 'fa fa-upload',
            disabled: false,
            text: 'Import abstract lease',
            onClick: () => this.openUploadLeaseAbstractPopup(),
          },
          showText: 'inMenu',
          sortIndex: 21,
          widget: 'dxButton',
        },
        ...$event.toolbarOptions.items,
      ];
    }
  }

  openUploadLeaseAbstractPopup(): void {
    this._popupService.show(UploadComponent, {
      title: 'Upload lease abstract',
      width: 750,
      maxHeight: '90%',
      showCloseButton: false,
      closeOnOutsideClick: false,
      injectableData: {
        reloadLeases: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      },
    });
  }

  isEditButtonVisible(e): boolean {
    return e && e.row && e.row.data && !e.row.data.deletedOn;
  }

  isDeleteButtonVisible(e): boolean {
    return e && e.row && e.row.data && e.row.data.leaseStatus !== vm.LeaseStatus.Draft && !e.row.data.deletedOn;
  }

  showLeaseEditDialog(event: {row: {data: vm.ILeaseViewModel}}): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._showLeaseEditDialog(event.row.data.id);
  }

  copyLeaseAndShowLeaseEditDialog(event: {row: {data: vm.ILeaseViewModel}}): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to copy this record?',
        acceptFn: () => {
          this._leaseAbstractService
            .copyLeaseAbstractImportDraft(event.row.data.id)
            .pipe(
              take(1),
              tap(leaseAbstractImportDraft => this._showLeaseEditDialog(leaseAbstractImportDraft.leaseId)),
              takeUntil(this._destroy$),
            )
            .subscribe();
        },
      },
    });
  }

  private _showLeaseEditDialog(leaseAbstractImportDraftId: number): void {
    this._popupService.show(ImportComponent, {
      fullScreen: true,
      showCloseButton: false,
      closeOnOutsideClick: false,
      dragEnabled: false,
      injectableData: {
        leaseAbstractImportDraftId: leaseAbstractImportDraftId,
        reloadLeases: async () => {
          if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
            const dataSource = this.dxDataGridComponent.instance.getDataSource();
            await dataSource.reload();
          }
        },
      },
    });
  }
}
