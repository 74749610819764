<h2 class="content-block">Portfolio Invitations</h2>

<dx-data-grid class="dx-card wide-card"
              [dataSource]="dataSource" [columnAutoWidth]="true" [columnHidingEnabled]="true"
              (onRowPrepared)="onRowPrepared($event)">
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-builder-popup></dxo-filter-builder-popup>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" dataType="number"
              [width]="70">
  </dxi-column>

  <dxi-column dataField="isConsumed" dataType="boolean" caption="Is Consumed"
              [width]="70">
  </dxi-column>

  <dxi-column dataField="kind" dataType="number" caption="Kind">
    <dxo-lookup [dataSource]="invitationKindDataSource" valueExpr="id" displayExpr="text">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="companyId" caption="Company">
    <dxo-lookup [dataSource]="companiesDataSource" valueExpr="id" displayExpr="name">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="invitedById" caption="Invited By" cellTemplate="invitedByTemplate">
  </dxi-column>
  <div class="lookup-user-field"
       *dxTemplate="let item of 'invitedByTemplate'">
    <img alt=""
         [src]="getUserAvatar(item)" />
    <div>
      {{ getUserDisplayName(item) }}
    </div>
  </div>

  <dxi-column dataField="inviteeEmailAddress"></dxi-column>

  <dxi-column dataField="additionalNotes"></dxi-column>

  <dxi-column dataField="createdOn" dataType="date" caption="Created On" ></dxi-column>
</dx-data-grid>
