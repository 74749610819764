import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IStateraClaim } from '../models/generated';

@Injectable({
  providedIn: 'root'
})
export class StateraClaimService {

  constructor(private httpClient: HttpClient) { }

  getStateraClaims(): Observable<{ data: Array<IStateraClaim> }> {
    return this.httpClient.get<{ data: Array<IStateraClaim> }>(`${environment.adminUrl}/stateraClaim`);
  }
}
