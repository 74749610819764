import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { alert } from 'devextreme/ui/dialog';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { Tools } from 'src/app/shared/tools';
import * as models from '../../../../shared/models/generated';
import {
  InviteUserDialogComponent
} from '../../../invitation/components/invite-user-dialog/invite-user-dialog.component';
import { LeaseUserService } from '../../../lease-user/services/lease-user.service';
import { PortfolioInvitationUserService } from '../../services/portfolio-invitation-user.service';

@Component({
  selector: 'app-portfolio-invitation-user-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class PortfolioInvitationUserListComponent implements OnInit, OnDestroy {
  @ViewChild('dxDataGrid', {static: false}) dxDataGridComponent: DxDataGridComponent;

  url = `${environment.adminUrl}/portfolio/invitation/user`;
  tools = Tools;

  dataSource: any;
  companiesDataSource: any;

  acceptanceStatusDataSource = Tools.EnumToArray(models.PortfolioInvitationUserAcceptanceStatus);

  get _window() {
    return window;
  }

  private readonly _portfolioInvitationUserService: PortfolioInvitationUserService;

  private readonly _destroy: Subject<void>;

  constructor(portfolioInvitationUserService: PortfolioInvitationUserService) {
    this._portfolioInvitationUserService = portfolioInvitationUserService;

    this._destroy = new Subject<void>();

    this.isAcceptRejectButtonsVisible = this.isAcceptRejectButtonsVisible.bind(this);
    this.acceptPortfolioInvitationUser = this.acceptPortfolioInvitationUser.bind(this);
    this.rejectPortfolioInvitationUser = this.rejectPortfolioInvitationUser.bind(this);
  }

  ngOnInit() {
    this.dataSource = AspNetData.createStore({
      key: ['portfolioInvitationId', 'userId'],
      loadUrl: this.url,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });

    this.companiesDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${environment.adminUrl}/company`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.xhrFields = {withCredentials: true};
      },
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onRowPrepared(e): void {
    if (e.rowType === 'data' && e.data && e.data.deletedOn && e.rowElement) {
      e.rowElement.classList.add('is-deleted');
    }
  }

  async reloadList(): Promise<void> {
    if (this.dxDataGridComponent && this.dxDataGridComponent.instance) {
      const dataSource = this.dxDataGridComponent.instance.getDataSource();
      await dataSource.reload();
    }
  }

  getUserAvatar(item): string {
    if (!item?.data?.user?.profile?.avatar) {
      return 'assets/img/avatar.png';
    }

    return item.data.user.profile.avatar.url;
  }

  getUserDisplayName(item): string {
    if (!item?.data?.user?.profile) {
      return '';
    }

    const { firstName, lastName } = item.data.user.profile;

    return [firstName, lastName]
      .filter(Boolean)
      .join(' ');
  }

  isAcceptRejectButtonsVisible(event): boolean {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    return event.row.data.acceptanceStatus === models.PortfolioInvitationUserAcceptanceStatus.Pending;
  }

  acceptPortfolioInvitationUser(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    const portfolioInvitationUser = this._rowDataToPortfolioInvitationUser(event.row.data);

    this._portfolioInvitationUserService
      .acceptPortfolioInvitationUser(portfolioInvitationUser)
      .pipe(
        tap(() => {
          this.reloadList();
        }),
        catchError(error => {
          if (error) {
            alert(error.error, 'Error');
          }

          return of(error);
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  rejectPortfolioInvitationUser(event): void {
    if (!event || !event.row || !event.row.data) {
      return;
    }

    const portfolioInvitationUser = this._rowDataToPortfolioInvitationUser(event.row.data);

    this._portfolioInvitationUserService
      .rejectPortfolioInvitationUser(portfolioInvitationUser)
      .pipe(
        tap(() => {
          this.reloadList();
        }),
        catchError(error => {
          if (error) {
            alert(error.error, 'Error');
          }

          return of(error);
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  private _rowDataToPortfolioInvitationUser(data): models.IPortfolioInvitationUserViewModel {
    return <models.IPortfolioInvitationUserViewModel>{
      portfolioInvitationId: data.portfolioInvitationId,
      userId: data.userId,
    };
  }
}
