import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { Tools } from '../../../shared/tools';

@Pipe({
  name: 'safeNumber'
})
@Injectable()
export class SafeNumberPipe implements PipeTransform {
  private readonly _angularDecimalPipe: DecimalPipe;

  constructor(angularDecimalPipe: DecimalPipe) {
    this._angularDecimalPipe = angularDecimalPipe;
  }

  transform(value: any, ...rest: Array<any>): any {
    return Tools.isNumeric(value) ?
      this._angularDecimalPipe.transform(value, ...rest) :
      value;
  }
}
