<div class="image-viewer-image-standard">
  <div class="image-viewer-image-standard-konva"
       #imageViewerImageStandardElementRef>
  </div>

  <ng-container *ngIf="allowChangeMarkers">
    <a role="button" class="image-viewer-image-standard-marker-adder" draggable="true"
       #markerAdder>
      <i class="fa fa-map-pin"></i>
    </a>

    <dx-tooltip position="top" showEvent="mouseenter" hideEvent="mouseleave"
                [target]="markerAdder">
      <div *dxTemplate="let data of 'content'">
        Drag and drop this marker to create a marker on the image
      </div>
    </dx-tooltip>
  </ng-container>
</div>
