import { Component, OnDestroy, OnInit } from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Tools } from 'src/app/shared/tools';
import { IStateraClaim, StateraClaimTypeAsEnum, StateraClaimValueAsEnum } from '../../shared/models/generated';
import { StateraClaimGroupService } from '../../shared/services/statera-claim-group.service';
import { StateraClaimService } from '../../shared/services/statera-claim.service';

interface IClaim {
  id: number;
  claimType: StateraClaimTypeAsEnum;
  claimValue: StateraClaimValueAsEnum;
  stateraClaimGroupId: number;
  stateraClaimGroupName: string;

  claimName: string;
}
@Component({
  selector: 'app-statera-claim-groups',
  templateUrl: './statera-claim-groups.component.html',
  styleUrls: ['./statera-claim-groups.component.scss']
})
export class StateraClaimGroupsComponent implements OnInit, OnDestroy {

  Tools = Tools;
  dataSource: any;
  url = `${environment.adminUrl}/stateraClaimGroup`;
  claims: Array<IClaim>;

  get _window() {
    return window;
  }

  private readonly _stateraClaimGroupService: StateraClaimGroupService;
  private readonly _destroy$: Subject<void>;
  constructor(stateraClaimGroupService: StateraClaimGroupService, stateraClaimService: StateraClaimService) {
    this._stateraClaimGroupService = stateraClaimGroupService;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this._stateraClaimGroupService
      .getStateraClaimGroups()
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(stateraClaimGroups => {
        if (stateraClaimGroups && stateraClaimGroups.data) {
          let result =  [];
          stateraClaimGroups.data.forEach(claimGroup => {
            const claims = claimGroup.stateraClaims.map(claim => <IClaim> {
              claimType: claim.claimType,
              claimValue: claim.claimValue,
              stateraClaimGroupId: claimGroup.id,
              stateraClaimGroupName: claimGroup.name,
              claimName: this.getStateraClaimName(claim)
            });
            result = [ ...result, ...claims];
          });
          this.claims = result;
        }
      });
  }
  getClaimName(item: IClaim) {
    if (!item) {
      return '';
    }

    let result = StateraClaimTypeAsEnum[item.claimType];

    if (item.claimValue) {
      result += ' ' + StateraClaimValueAsEnum[item.claimValue];
    }

    return result;
  }

  getClaimGroupName(item: IClaim) {
    if (!item) {
      return '';
    }

    return item.stateraClaimGroupName;
  }

  getStateraClaimName(item: IStateraClaim) {
    if (!item) {
      return '';
    }

    let result = StateraClaimTypeAsEnum[item.claimType];

    if (item.claimValue) {
      result += ' ' + StateraClaimValueAsEnum[item.claimValue];
    }

    return result;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
