import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';
import { GlobalFeatureService } from '../../services/global-feature.service';

import * as models from '../../../../shared/models/generated';

interface Feature {
  name: string;
  description: string;
  model: string;
}

@Component({
  templateUrl: 'edit-global-feature-dialog.component.html',
  styleUrls: ['edit-global-feature-dialog.component.scss'],
})
export class EditGlobalFeatureDialogComponent implements OnInit, OnDestroy {
  private static readonly _features: Array<Feature> = [
    {
      name: 'Colábo',
      description: 'Globally enables/disables Colábo two panel view',
      model: 'isColaboFeatureEnabled',
    },
    {
      name: 'Project Schedule',
      description: 'Globally enables/disabled Project Schedule',
      model: 'isProjectScheduleFeatureEnabled',
    },
    {
      name: 'Floor Plan',
      description: 'Enables/disables Floor Plan feature',
      model: 'IsFloorPlanFeatureEnabled',
    },
  ];

  globalFeature: models.IGlobalFeatureToggleViewModel;

  readonly reloadList: () => void;

  features: Array<Feature>;

  private readonly _popupRefService: PopupRefService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _globalFeatureService: GlobalFeatureService;
  private readonly _destroy: Subject<void>;

  constructor(
    popupRefService: PopupRefService,
    changeDetectorRef: ChangeDetectorRef,
    globalFeatureService: GlobalFeatureService,
  ) {
    this._popupRefService = popupRefService;
    this._changeDetectorRef = changeDetectorRef;
    this._globalFeatureService = globalFeatureService;
    this._destroy = new Subject<void>();

    this.features = EditGlobalFeatureDialogComponent._features;
  }

  ngOnInit(): void {
    const isRequestInjected = !!this.globalFeature;

    this.globalFeature = this.globalFeature || <models.IGlobalFeatureToggleViewModel>{
      isColaboFeatureEnabled: true,
    };

    if (isRequestInjected) {
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  submit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this._globalFeatureService
      .updateGlobalFeatureToggles(this.globalFeature)
      .pipe(
        tap(() => {
          this._popupRefService.hide();
          this.reloadList();
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  cancel(): void {
    this._popupRefService.hide();
  }
}
