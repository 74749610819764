<h2 class="content-block">Statera claim groups</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="claims"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>

  <dxi-column dataField="id" [calculateDisplayValue]="getClaimName"></dxi-column>
  <dxi-column dataField="stateraClaimGroupName" caption="Claim Group:" [groupIndex]="0"></dxi-column>

</dx-data-grid>

<h2 class="content-block">Statera claims</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="claims"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>

  <dxi-column dataField="id" [calculateDisplayValue]="getClaimGroupName"></dxi-column>
  <dxi-column dataField="claimName" caption="Claim Name:" [groupIndex]="0"></dxi-column>

</dx-data-grid>

