import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { BuildingService } from '../../services/building.service';
import { UserService } from '../../../user/services/user.service';
import { PortfolioService } from '../../../portfolio/services/portfolio.service';
import { ImportService } from '../../../import/services/import.service';
import { PopupService } from '../../../infrastructure/services/popup.service';
import { PopupRefService } from '../../../infrastructure/services/popup-ref.service';

import { RoleNames } from '../../../../shared/models/roles';

import * as models from '../../../../shared/models/generated';

import { NoticeComponent } from '../../../infrastructure/components/notice/notice.component';
import { ImportMappingComponent } from '../import-mapping/import-mapping.component';

@Component({
  templateUrl: 'import.component.html',
  styleUrls: ['import.component.scss'],
})
export class ImportComponent implements OnInit, OnDestroy {
  private readonly _buildingService: BuildingService;
  private readonly _userService: UserService;
  private readonly _portfolioService: PortfolioService;
  private readonly _importService: ImportService;
  private readonly _popupService: PopupService;
  private readonly _popupRefService: PopupRefService;

  @ViewChild(NgForm, {static: false}) form: NgForm;
  @ViewChild(DxFileUploaderComponent, {static: false}) fileUploader: DxFileUploaderComponent;

  landlordsList$: Observable<Array<models.IProfileViewModel>>;
  portfoliosList$: Observable<Array<models.IPortfolioViewModel>>;

  buildingsImportTaskPayload: models.IImportBuildingsTaskPayloadViewModel;

  importFile: File;

  csvMimeTypes = ['text/comma-separated-values', 'text/csv', 'application/csv'];

  constructor(
    buildingService: BuildingService,
    userService: UserService,
    portfolioService: PortfolioService,
    importService: ImportService,
    popupService: PopupService,
    popupRefService: PopupRefService,
  ) {
    this._buildingService = buildingService;
    this._userService = userService;
    this._portfolioService = portfolioService;
    this._importService = importService;
    this._popupService = popupService;
    this._popupRefService = popupRefService;
  }

  ngOnInit(): void {
    this.buildingsImportTaskPayload = <models.IImportBuildingsTaskPayloadViewModel>{};

    this.landlordsList$ = this._userService.getUsersByRoleName(RoleNames.Landlord);
    this.portfoliosList$ = of<Array<models.IPortfolioViewModel>>([]);
  }

  ngOnDestroy(): void {
    this.resetForm();
  }

  setupSearchableUserFields(profile: models.IProfileViewModel): Array<string> {
    const searchableFields = [
      profile.firstName,
      profile.lastName,
    ];

    if (profile.company && profile.company.name) {
      searchableFields.push(profile.company.name);
    }

    return searchableFields;
  }

  handleLandlordChange(event: { value: number, previousValue: number }): void {
    if (!event.value || event.value === event.previousValue) {
      return;
    }

    this.buildingsImportTaskPayload.portfolioId = null;

    this.portfoliosList$ = of(this.portfoliosList$)
      .pipe(
        take(1),
        switchMap(() => {
          if (!event || !event.value || !event.value) {
            return of(<Array<models.IPortfolioViewModel>>[]);
          }

          return this._portfolioService
            .getLandlordPortfolios(event.value);
        }),
      );
  }

  handleImportFileChange(event: { value: Array<File>, previousValue: Array<File> }): void {
    if (event.value && event.value instanceof Array && 0 < event.value.length) {
      this.importFile = event.value.shift();
      return;
    }

    this.importFile = null;
  }

  isCSVFile(file: File): boolean {
    return (
      0 <= this.csvMimeTypes.indexOf(file.type) ||
      file.name.split('.').pop() === 'csv'
    );
  }

  removeImportFile(): void {
    this.importFile = null;

    if (this.fileUploader && this.fileUploader.instance) {
      this.fileUploader.instance.reset();
    }
  }

  submitImportFile(): void {
    if ((this.form && this.form.invalid) || (!this.importFile || !this.isCSVFile(this.importFile))) {
      return;
    }

    const subscription = this._importService
      .uploadImportFile(this.importFile)
      .subscribe((importFile: models.IFileViewModel): void => {
        subscription.unsubscribe();

        this._popupService.show(ImportMappingComponent, {
          title: 'Import buildings – filed mapping',
          width: 750,
          maxHeight: '90%',
          showCloseButton: false,
          closeOnOutsideClick: false,
          injectableData: {
            importFile: importFile,
            buildingsImportTaskPayload: this.buildingsImportTaskPayload,
          },
        });
      });
  }

  resetForm(): void {
    if (this.form) {
      this.form.resetForm();
    }
  }

  cancel(): void {
    if (this.form && this.form.pristine) {
      this._popupRefService.hide();
      return;
    }

    this._showCancelPopup();
  }

  private _showCancelPopup(): void {
    this._popupService.show(NoticeComponent, {
      injectableData: {
        message: 'Are you sure you want to cancel the buildings importing process?',
        acceptFn: () => {
          this._popupRefService.hide();
        },
      },
    });
  }
}
