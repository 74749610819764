<ng-progress #progressBar [spinner]="false" [color]="'#59b680'"></ng-progress>

<ng-container *ngIf="isAutorized(); else notAuthorized">
    <app-side-nav-inner-toolbar title="{{appInfo.title}}">
        <router-outlet></router-outlet>
        <app-footer>
            Copyright © 2011-2024 Statera Inc.
            <br/>
            All trademarks or registered trademarks are property of their respective owners.
        </app-footer>
    </app-side-nav-inner-toolbar>
</ng-container>

<ng-template #notAuthorized>
    <app-single-card *ngIf="showContent">
        <router-outlet></router-outlet>
    </app-single-card>
</ng-template>

 <form #signOutForm ngNoForm action="{{signOutUrl}}" method="POST"></form>

 <dx-toast #appToast position="top"></dx-toast>
