import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import * as models from '../../../shared/models/generated';

@Injectable({
  providedIn: 'root',
})
export class LeaseAbstractStore {
  private readonly _leaseAbstractImportDraft: BehaviorSubject<models.ILeaseAbstractImportDraftViewModel>;
  private readonly _termExtractionResult: BehaviorSubject<models.ITermExtractionResultSimplifiedView>;
  private readonly _importTaskStatus: BehaviorSubject<models.ImportTaskStatus>;
  private readonly _isInterfaceLocked: BehaviorSubject<boolean>;

  constructor() {
    this._leaseAbstractImportDraft = new BehaviorSubject<models.ILeaseAbstractImportDraftViewModel>(null);
    this._termExtractionResult = new BehaviorSubject<models.ITermExtractionResultSimplifiedView>(null);
    this._importTaskStatus = new BehaviorSubject<models.ImportTaskStatus>(models.ImportTaskStatus.NotStarted);
    this._isInterfaceLocked = new BehaviorSubject<boolean>(false);
  }

  getLeaseAbstractImportDraft(): Observable<models.ILeaseAbstractImportDraftViewModel> {
    return this._leaseAbstractImportDraft.asObservable();
  }

  storeLeaseAbstractImportDraft(leaseAbstractImportDraft: models.ILeaseAbstractImportDraftViewModel): void {
    this._leaseAbstractImportDraft.next(leaseAbstractImportDraft);
  }

  getTermExtractionResult(): Observable<models.ITermExtractionResultSimplifiedView> {
    return this._termExtractionResult.asObservable();
  }

  storeTermExtractionResult(termExtractionResult: models.ITermExtractionResultSimplifiedView): void {
    this._termExtractionResult.next(termExtractionResult);
  }

  getImportTaskStatus(): Observable<models.ImportTaskStatus> {
    return this._importTaskStatus.asObservable();
  }

  storeImportTaskStatus(importTaskStatus: models.ImportTaskStatus): void {
    this._importTaskStatus.next(importTaskStatus);
  }

  getIsInterfaceLocked(): Observable<boolean> {
    return this._isInterfaceLocked.asObservable();
  }

  storeIsInterfaceLocked(isInterfaceLocked: boolean): void {
    this._isInterfaceLocked.next(isInterfaceLocked);
  }

  clear(): void {
    this._leaseAbstractImportDraft.next(null);
    this._termExtractionResult.next(null);
    this._importTaskStatus.next(models.ImportTaskStatus.NotStarted);
  }
}
