<h2 class="content-block">Lease Signers</h2>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [remoteOperations]="true"
>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 15, 30]" [showInfo]="true"></dxo-pager>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-column-chooser [allowSearch]="true" mode="dragAndDrop"></dxo-column-chooser>
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxi-column dataField="id" [width]="50"></dxi-column>
  <dxi-column dataField="leaseId" caption="Lease" cellTemplate="leaseFieldTemplate">
    <dxo-lookup [dataSource]="leaseDataSource" valueExpr="leaseId" [displayExpr]="Tools.shortLeaseNameExpression">
    </dxo-lookup>
  </dxi-column>
  <div *dxTemplate="let item of 'leaseFieldTemplate'" class="lookup-user-field">
    <div>{{ Tools.leaseNameExpression(item.data.lease) }}</div>
  </div>
  <dxi-column dataField="letterOfIntentTenantSignerEmail"></dxi-column>
  <dxi-column dataField="letterOfIntentTenantSignerName"></dxi-column>

  <dxi-column dataField="letterOfIntentLandlordSignerEmail"></dxi-column>
  <dxi-column dataField="letterOfIntentLandlordSignerName"></dxi-column>

  <dxi-column dataField="leaseAmendmentTenantSignerEmail"></dxi-column>
  <dxi-column dataField="leaseAmendmentTenantSignerName"></dxi-column>

  <dxi-column dataField="leaseAmendmentLandlordSignerEmail"></dxi-column>
  <dxi-column dataField="leaseAmendmentLandlordSignerName"></dxi-column>

  <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
    <dxo-popup
      title="Add Lease Signers"
      [maxWidth]="900"
      [showTitle]="true"
      [position]="{ my: 'center', at: 'center', of: _window }"
    >
    </dxo-popup>
    <dxo-form [colCount]="1">
      <dxi-item dataField="leaseId" caption="Lease" isRequired="true"></dxi-item>
      <dxi-item dataField="letterOfIntentTenantSignerEmail">
      </dxi-item>
      <dxi-item dataField="letterOfIntentTenantSignerName">
      </dxi-item>
      <dxi-item dataField="letterOfIntentLandlordSignerEmail">
      </dxi-item>
      <dxi-item dataField="letterOfIntentLandlordSignerName">
      </dxi-item>
      <dxi-item dataField="leaseAmendmentTenantSignerEmail">
      </dxi-item>
      <dxi-item dataField="leaseAmendmentTenantSignerName">
      </dxi-item>
      <dxi-item dataField="leaseAmendmentLandlordSignerEmail">
      </dxi-item>
      <dxi-item dataField="leaseAmendmentLandlordSignerName">
      </dxi-item>
    </dxo-form>
  </dxo-editing>
</dx-data-grid>
